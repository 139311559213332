import { IonButton, IonButtons, IonInput, IonItem, IonItemDivider, IonLabel, IonTextarea } from '@ionic/react';
import React, { useState } from 'react';
import DataTable, { ExpanderComponentProps } from 'react-data-table-component';
import { toast } from 'react-toastify';

import API from '../../API';

const DeveloperMyAppComponent: React.FC<ExpanderComponentProps<any>> = ({ data }) => {
	const [app, setApp] = useState(data);

	const [success, setSuccess] = useState<string | null>(app.offer_responce?.success || null);
	const [error, setError] = useState<string | null>(app.offer_responce?.error || null);
	const [successHeader, setSuccessHeader] = useState<string | null>(app.offer_responce?.success_header || null);
	const [errorHeader, setErrorHeader] = useState<string | null>(app.offer_responce?.error_header || null);

	const [proxyEndpoint, setProxyEndpoint] = useState<null | string>(app?.smart_proxy_server?.endpoint || null);
	const [proxyType, setProxyType] = useState<null | string>(app?.smart_proxy_server?.type || null);
	const [proxyPayload, setProxyPayload] = useState<null | string>(app?.smart_proxy_server?.check_payload || null);

	return (
		<div style={{ paddingLeft: 32 }}>
			<strong>
				<p style={{ fontSize: 18 }}>Информация о прокси сервере</p>
			</strong>
			<IonItem>
				<IonLabel position="stacked">Endpoint</IonLabel>
				<IonInput
					placeholder="https://ojuxqfbgs8.execute-api.eu-central-1.amazonaws.com/default/ochko"
					value={proxyEndpoint}
					onIonChange={(e) => {
						setProxyEndpoint(e.target.value?.toString() || null);
					}}></IonInput>
			</IonItem>
			<IonItem>
				<IonLabel position="stacked">Type</IonLabel>
				<IonInput
					placeholder="AWSDexXML"
					value={proxyType}
					onIonChange={(e) => {
						setProxyType(e.target.value?.toString() || null);
					}}></IonInput>
			</IonItem>
			<IonItem>
				<IonLabel position="stacked">Payload</IonLabel>
				<IonInput
					placeholder="Полезные данные"
					value={proxyPayload}
					onIonChange={(e) => {
						setProxyPayload(e.target.value?.toString() || null);
					}}></IonInput>
			</IonItem>

			<IonButtons>
				<IonButton
					color={'success'}
					onClick={() => {
						if (proxyEndpoint == null) {
							return toast.error('Заполнить endpoint');
						}
						if (proxyType == null) {
							return toast.error('Тип прокси');
						}
						if (proxyPayload == null) {
							return toast.error('пэйлоад не заполнен. Если не знаете заполните просто {} как пустой JSON объект');
						}

						API.developerUpdateProxyForApp(app.id, proxyEndpoint, proxyType, proxyPayload).then(({ data }) => {
							setApp(data);
							setSuccess(data?.offer_responce?.success || null);
							setError(data?.offer_responce?.error || null);
							toast.success('Успешно!');
						});
					}}>
					Сохранить прокси
				</IonButton>
			</IonButtons>
			{app.telegraph_token && (
				<>
					<strong>
						<p style={{ fontSize: 18 }}>Telegraph token: {app.telegraph_token}</p>
					</strong>
				</>
			)}

			{app.smart_proxy_server ? (
				<>
					<strong>
						<p style={{ fontSize: 18 }}>Кастомные ответы</p>
					</strong>
					<p>Успешный вариант. Не забудьте поставить %USER_ID% и %LINK%</p>
					<IonItem>
						<IonLabel position="stacked">BODY</IonLabel>
						<IonTextarea
							placeholder="Успешный ответ"
							value={success}
							onIonChange={(e) => {
								setSuccess(e.target.value || null);
							}}
							autoGrow></IonTextarea>
					</IonItem>

					<IonItem>
						<IonLabel position="stacked">HEADERS</IonLabel>
						<IonTextarea
							placeholder="Успешный ответ"
							value={successHeader}
							onIonChange={(e) => {
								setSuccessHeader(e.target.value || null);
							}}
							autoGrow></IonTextarea>
					</IonItem>

					<p>Неудачный вариант</p>
					<IonItem>
						<IonLabel position="stacked">BODY</IonLabel>
						<IonTextarea
							placeholder="Неудачный ответ"
							value={error}
							onIonChange={(e) => {
								setError(e.target.value || null);
							}}
							autoGrow></IonTextarea>
					</IonItem>
					<IonItem>
						<IonLabel position="stacked">HEADERS</IonLabel>
						<IonTextarea
							placeholder="Неудачный ответ"
							value={errorHeader}
							onIonChange={(e) => {
								setErrorHeader(e.target.value || null);
							}}
							autoGrow></IonTextarea>
					</IonItem>

					<IonButtons>
						<IonButton
							color={'success'}
							onClick={() => {
								if (success == null) {
									return toast.error('Заполнить успешный ответ');
								}
								if (error == null) {
									return toast.error('Заполнить неудачный ответ');
								}
								if (successHeader == null) {
									return toast.error('Заполнить неудачный ответ. Headers');
								}
								if (errorHeader == null) {
									return toast.error('Заполнить неудачный ответ. Headers');
								}

								API.developerUpdateApp(app.id, success, error, successHeader, errorHeader).then(({ app }) => {
									setSuccess(app?.offer_responce?.success || null);
									setError(app?.offer_responce?.error || null);
									toast.success('Успешно!');
								});
							}}>
							Сохранить
						</IonButton>
					</IonButtons>

					<br />
					<p>Информация по ботам:</p>
					<DataTable
						noDataComponent={<p>Нет данных</p>}
						columns={[
							{
								name: 'ID',
								selector: (row: any) => row.id,
								maxWidth: '50px',
							},
							{
								name: 'IP',
								selector: (row: any) => row.ip,
							},
							{
								name: 'Причина отклона',
								selector: (row: any) => row.reason,
							},
							{
								name: 'Запрос',
								selector: (row: any) => <p style={{ whiteSpace: 'pre-wrap' }}>{JSON.stringify(JSON.parse(row.body), null, 2)}</p>,
							},
						]}
						data={app.bots}
						theme="default"
					/>
				</>
			) : (
				<p>Заполните информацию о прокси чтобы получить доступ к остальному функционалу</p>
			)}
		</div>
	);
};

export default DeveloperMyAppComponent;
