import {
	IonButton,
	IonButtons,
	IonCard,
	IonCardContent,
	IonCardHeader,
	IonCardTitle,
	IonContent,
	IonDatetime,
	IonHeader,
	IonIcon,
	IonInput,
	IonItem,
	IonItemDivider,
	IonLabel,
	IonMenuButton,
	IonPage,
	IonPopover,
	IonSelect,
	IonSelectOption,
	IonText,
	IonTitle,
	IonToolbar,
} from '@ionic/react';
import { filterCircleOutline, filterCircle } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import AnimateHeight from 'react-animate-height';

import API from '../../API';
import AccountingExpenditure from '../../components/Accounting/AccountingExpenditure';

const monthAgoDate = () => {
	const date = new Date();
	date.setMonth(date.getMonth() - 1);
	return date;
};

const AccountingPage: React.FC = () => {
	const [showFilter, setShowFilter] = useState(false);
	const [dateRange, setDateRange] = useState({
		fromDate: monthAgoDate().toJSON(),
		toDate: new Date().toJSON(),
	});
	const [banks, setBanks] = useState([]);
	const [admins, setAdmins] = useState([]);
	const [selectedUserId, setSelectedUserId] = useState<number | null>(null);
	const [outcomesByExpenditures, setOutcomesByExpenditures] = useState<Array<any>>([]);
	const [currencies, setCurrencies] = useState<Array<string>>([]);

	const [incomes, setIncomes] = useState<Array<any>>([]);

	const [addIncomeBankId, setAddIncomeBankId] = useState<string | null>('');
	const [addIncomeSumm, setAddIncomeSumm] = useState<string | null>('');
	const [addIncomeComment, setAddIncomeComment] = useState<string | null>('');

	const [sortedOutcomesByExpenditures, setSortedOutcomesByExpenditures] = useState<Array<any>>([]);
	const [sortByCurrency, setSortByCurrency] = useState('rub');

	useEffect(() => {
		API.getFinanceOutcomes(dateRange.fromDate.split('T')[0] + ' 00:00:00', dateRange.toDate.split('T')[0] + ' 23:59:59', null, selectedUserId).then(({ data }) => {
			setBanks(data.banks);
			setAdmins(data.admins);
			setCurrencies(data.currencies);
			setOutcomesByExpenditures(data.outcomesByExpenditures);
			setIncomes(data.incomes);

			setSortByCurrency(data.currencies[0]);
		});
	}, [dateRange, selectedUserId]);

	useEffect(() => {
		const sorted = outcomesByExpenditures;
		const index = currencies.indexOf(sortByCurrency);
		sorted.sort((a, b) => a.currencies[index].amount - b.currencies[index].amount).reverse();

		setSortedOutcomesByExpenditures(sorted);
	}, [outcomesByExpenditures, sortByCurrency]);

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonButtons slot="start">
						<IonMenuButton />
					</IonButtons>
					<IonTitle>Бухгалтерия</IonTitle>

					<IonButtons slot="end">
						<IonButton onClick={() => setShowFilter(!showFilter)}>
							<IonIcon icon={showFilter ? filterCircleOutline : filterCircle} slot="start" />
							Фильтр
						</IonButton>
					</IonButtons>
				</IonToolbar>
			</IonHeader>
			<IonContent>
				<AnimateHeight style={{ position: 'absolute', width: '100%' }} duration={200} height={showFilter ? 'auto' : 0}>
					<IonItem button={true} id="open-date-input-from">
						<IonLabel>От</IonLabel>
						<IonText slot="end">{dateRange.fromDate?.split('T')[0]}</IonText>
						<IonPopover trigger="open-date-input-from" showBackdrop={false}>
							<IonDatetime
								max={dateRange.toDate}
								value={dateRange.fromDate}
								presentation="date"
								onIonChange={(ev) => {
									setDateRange((p: any) => ({
										...p,
										fromDate: ev.detail.value!,
									}));
								}}
							/>
						</IonPopover>
					</IonItem>

					<IonItem button={true} id="open-date-input-to">
						<IonLabel>До</IonLabel>
						<IonText slot="end">{dateRange.toDate?.split('T')[0]}</IonText>
						<IonPopover trigger="open-date-input-to" showBackdrop={false}>
							<IonDatetime
								min={dateRange.fromDate}
								value={dateRange.toDate}
								presentation="date"
								onIonChange={(ev) => {
									setDateRange((p: any) => ({
										...p,
										toDate: ev.detail.value!,
									}));
								}}
							/>
						</IonPopover>
					</IonItem>
					<IonItem>
						<IonLabel>Сотрудники</IonLabel>
						<IonSelect
							value={selectedUserId}
							placeholder="Выберите"
							onIonChange={(e) => {
								setSelectedUserId(e.detail.value);
							}}>
							<IonSelectOption value={null}>Не выбран</IonSelectOption>
							{admins.map((a: any) => {
								return (
									<IonSelectOption key={'accounting_admin_' + a.id} value={a.id}>
										{a.name} | {a.role}
									</IonSelectOption>
								);
							})}
						</IonSelect>
					</IonItem>
				</AnimateHeight>

				<div style={{ margin: 8 }}>
					<table style={{ width: '100%' }}>
						<tr
							style={{
								display: 'flex',
							}}>
							<td style={{ flex: 1 }}>
								<p>Статья</p>
							</td>
							{currencies.map((currency: any) => {
								return (
									<td key={'accounting_curr_' + currency} style={{ flex: 1 }}>
										<p style={{ fontWeight: currency == sortByCurrency ? 800 : 400 }}>{currency.toUpperCase()}</p>
									</td>
								);
							})}
							<td style={{ width: 100 }}></td>
						</tr>

						{sortedOutcomesByExpenditures.map((out) => {
							return <AccountingExpenditure key={'accounting_out_' + out.ae.id} dateRange={dateRange} out={out} selectedUserId={selectedUserId} />;
						})}

						<tr
							style={{
								display: 'flex',
							}}>
							<td style={{ flex: 1 }}>
								<p>ИТОГО</p>
							</td>
							{currencies.map((currency: any) => {
								return (
									<td key={'accounting_curr_' + currency} style={{ flex: 1 }}>
										<p style={{ fontWeight: currency == sortByCurrency ? 800 : 400 }}>
											{sortedOutcomesByExpenditures
												.map((out) => out.currencies.find((c: any) => c.currency == currency))
												.map((c) => c.amount)
												.reduce((a, b) => a + b, 0)}
										</p>
									</td>
								);
							})}
							<td style={{ width: 100 }}></td>
						</tr>
					</table>

					<IonItemDivider>Поступления</IonItemDivider>

					{incomes.length == 0 ? (
						<h2>Поступлений не было за этот период</h2>
					) : (
						incomes.map((i: any) => {
							return (
								<IonItem key={'accounting_income_' + i.id}>
									<IonLabel>
										<h2>
											{i.summ} {i.bank_currency} [{i.bank_title}]
										</h2>
										<p>{new Date(i.created).toLocaleString()}</p>
									</IonLabel>
								</IonItem>
							);
						})
					)}

					<IonCard>
						<IonCardHeader>
							<IonCardTitle>Добавить поступление</IonCardTitle>
						</IonCardHeader>
						<IonCardContent>
							<IonItem>
								<IonSelect
									slot="start"
									value={addIncomeBankId}
									placeholder="Выберите"
									interface="popover"
									onIonChange={(e) => {
										setAddIncomeBankId(e.detail.value);
									}}>
									<IonSelectOption value={null}>Выберите банк</IonSelectOption>
									{banks.map((b: any) => {
										return (
											<IonSelectOption key={'income_bank_' + b.id} value={b.id}>
												{b.title} | {b.balance} {b.currency}
											</IonSelectOption>
										);
									})}
								</IonSelect>
							</IonItem>
							<IonItem>
								<IonInput
									placeholder="Сумма"
									type="number"
									value={addIncomeSumm}
									onIonChange={(e) => {
										setAddIncomeSumm(e.detail.value!);
									}}
									clearInput></IonInput>
							</IonItem>
							<IonItem>
								<IonInput
									placeholder="Комментарий"
									type="text"
									value={addIncomeComment}
									onIonChange={(e) => {
										setAddIncomeComment(e.detail.value!);
									}}
									clearInput></IonInput>
							</IonItem>
							<IonButton
								slot="end"
								onClick={async () => {
									const res = await API.addIncomeTransaction(addIncomeBankId, parseInt(addIncomeSumm ?? '0'), addIncomeComment ?? '');
									if (res.success) {
										setAddIncomeComment('');
										setAddIncomeSumm(null);
										setAddIncomeBankId(null);

										setSortByCurrency(sortByCurrency);
									}
								}}>
								Добавить
							</IonButton>
						</IonCardContent>
					</IonCard>
				</div>
			</IonContent>
		</IonPage>
	);
};

export default AccountingPage;
