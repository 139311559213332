import { IonIcon } from '@ionic/react';
import { ellipse, ellipseOutline } from 'ionicons/icons';
import React from 'react';

export default class extends React.Component<{ title: string; value: string; didChanged: (value: boolean) => void }> {
	render(): React.ReactNode {
		return (
			<>
				<p style={{ fontSize: 12, fontWeight: 700, marginBottom: 2 }}>{this.props.title}</p>
				<div
					onClick={() => {
						this.props.didChanged(true);
					}}
					style={{ display: 'flex', alignItems: 'center', marginBottom: 8 }}>
					<IonIcon icon={this.props.value ? ellipse : ellipseOutline} style={{ color: this.props.value ? 'green' : 'grey' }} />
					&nbsp;
					<p style={{ fontSize: 12, marginBottom: 0, color: this.props.value ? 'green' : 'grey' }}>Включено</p>
				</div>
				<div
					onClick={() => {
						this.props.didChanged(false);
					}}
					style={{ display: 'flex', alignItems: 'center' }}>
					<IonIcon icon={this.props.value ? ellipseOutline : ellipse} style={{ color: this.props.value ? 'grey' : 'red' }} />
					&nbsp;
					<p style={{ fontSize: 12, marginBottom: 0, color: this.props.value ? 'grey' : 'red' }}>Выключено</p>
				</div>
			</>
		);
	}
}
