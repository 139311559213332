import { IonPage, IonHeader, IonToolbar, IonButtons, IonMenuButton, IonTitle, IonContent, IonList, IonButton, IonItem, IonLabel, IonIcon, IonSelectOption, IonSelect, IonAlert } from '@ionic/react';
import { filterCircle, filterCircleOutline } from 'ionicons/icons';
import React from 'react';
import AnimateHeight from 'react-animate-height';

import API from '../../API';
import LoadingFullScreen from '../../components/LoadingFullScreen';

export default class extends React.Component {
	state: any = {
		accounts: null,

		showFilter: false,
		stores: [],

		store_id: null,
		is_remote: null,

		showPopover: false,

		alertMessage: null,
	};

	componentDidMount() {
		this.getPublisherApps();
	}

	async getPublisherApps() {
		const res = await API.publisherGetFarmAccounts(this.state.store_id, this.state.is_remote);
		if (res.success) {
			this.setState({
				accounts: res.data.offers,
				stores: res.data.stores,
			});
		}
	}

	render() {
		return (
			<IonPage>
				<IonHeader>
					<IonToolbar>
						<IonButtons slot="start">
							<IonMenuButton />
						</IonButtons>
						<IonTitle>Прилки</IonTitle>
						<IonButtons slot="end">
							<IonButton onClick={() => this.setState({ showFilter: !this.state.showFilter })}>
								<IonIcon icon={this.state.showFilter ? filterCircle : filterCircleOutline} slot="start" />
								Фильтр
							</IonButton>
						</IonButtons>
					</IonToolbar>
				</IonHeader>
				<IonContent>
					<IonAlert
						isOpen={this.state.alertMessage != null}
						header={this.state.alertMessage}
						buttons={['Закрыть']}
						onDidDismiss={() => {
							this.setState({
								alertMessage: null,
							});
						}}></IonAlert>
					<div
						style={{
							position: 'absolute',
							width: '100%',
							height: '100%',
							zIndex: this.state.showFilter ? 101 : undefined,
						}}>
						<AnimateHeight id="example-panel" duration={200} height={this.state.showFilter ? 'auto' : 0}>
							<IonItem>
								<IonLabel>Стор</IonLabel>
								<IonSelect
									value={this.state.store_id}
									placeholder="Выберите"
									onIonChange={(e) => {
										this.setState({
											store_id: e.detail.value,
										});
									}}>
									<IonSelectOption value={null}>Не выбран</IonSelectOption>
									{this.state.stores.map((e: any) => {
										return (
											<IonSelectOption key={'1_exp_a_id_' + e.id} value={e.id}>
												{e.title}
											</IonSelectOption>
										);
									})}
								</IonSelect>
							</IonItem>
							<IonItem>
								<IonLabel>Удаленный фарм</IonLabel>
								<IonSelect
									value={this.state.is_remote}
									placeholder="Выберите"
									onIonChange={(e) => {
										this.setState({
											is_remote: e.detail.value,
										});
									}}>
									<IonSelectOption value={null}>Не важно</IonSelectOption>
									<IonSelectOption value={true}>Да</IonSelectOption>
									<IonSelectOption value={false}>Нет</IonSelectOption>
								</IonSelect>
							</IonItem>

							<IonItem>
								<IonButtons slot="end">
									<IonButton
										color="success"
										onClick={() => {
											this.setState({
												accounts: null,
											});

											this.getPublisherApps();

											this.setState({
												showFilter: false,
											});
										}}>
										Применить
									</IonButton>
								</IonButtons>
							</IonItem>
						</AnimateHeight>
					</div>

					{this.state.showFilter ? (
						<div
							style={{
								position: 'absolute',
								width: '100%',
								height: '100%',
								backgroundColor: 'rgba(0, 0, 0, 0.5)',
								zIndex: 100,
							}}
						/>
					) : (
						false
					)}

					{this.state.accounts == null ? (
						<LoadingFullScreen />
					) : (
						<div>
							{this.state.accounts.length == 0 ? (
								<p>Аккаунтов нет</p>
							) : (
								<IonList>
									{this.state.accounts.map((acc: any) => {
										return (
											<IonItem
												button
												key={'farmed_account_' + acc.id}
												onClick={() => {
													navigator.clipboard.writeText(`${acc.login}\n${acc.password}`);
												}}>
												<IonLabel>
													<p style={{ color: 'black' }}>Логин: {acc.login}</p>
													<p style={{ color: 'black' }}>Пароль: {acc.password}</p>
													{acc.notes ? (
														<p
															onClick={() => {
																this.setState({
																	alertMessage: acc.notes,
																});
															}}
															style={{ color: 'green' }}>
															Открыть заметки
														</p>
													) : (
														false
													)}
													<p style={{ color: acc.is_remote ? 'purple' : 'green' }}>Удаленный фарм: {acc.is_remote ? 'ДА' : 'НЕТ'}</p>
													<p style={{ color: 'black' }}>Дата фарма: {new Date(acc.farmed_at).toLocaleString()}</p>
												</IonLabel>
												<img
													slot="end"
													style={{
														height: 30,
														width: 30,
														right: 8,
														top: 8,
													}}
													src={`/assets/store_${acc.store_id}.svg`}
													alt=""
												/>
											</IonItem>
										);
									})}
								</IonList>
							)}
						</div>
					)}
				</IonContent>
			</IonPage>
		);
	}
}
