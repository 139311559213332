import { IonContent, IonIcon, IonItem, IonLabel, IonList, IonMenuToggle } from '@ionic/react';
import { appsOutline, trophyOutline } from 'ionicons/icons';
import React from 'react';
import { RouteComponentProps, useLocation } from 'react-router-dom';

import PM_BrandsStatisticsPage from '../pages/Pm/PM_BrandsStatisticsPage';
import PM_AppListPage from '../pages/Pm/PM_AppListPage';

interface AppPage {
	url: string;
	icon: string;
	title: string;
	component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
}

export const appPages: AppPage[] = [
	{
		title: 'Статка по брендам',
		url: '/brands-statistics',
		icon: trophyOutline,
		component: PM_BrandsStatisticsPage,
	},
	{
		title: 'Прилы',
		url: '/apps',
		icon: appsOutline,
		component: PM_AppListPage,
	},
];

const Menu: React.FC = () => {
	const location = useLocation();

	return (
		<IonContent>
			<IonList id="inbox-list">
				{appPages.map((appPage, index) => {
					return (
						<IonMenuToggle key={index} autoHide={false}>
							<IonItem
								style={{
									'--height': 20,
								}}
								className={location.pathname.startsWith(appPage.url) ? 'selected' : ''}
								routerLink={appPage.url}
								routerDirection="none"
								lines="none"
								detail={false}>
								<IonIcon icon={appPage.icon} size={'4'} />
								<IonLabel style={{ paddingLeft: 8, fontSize: 8 }}>{appPage.title}</IonLabel>
							</IonItem>
						</IonMenuToggle>
					);
				})}
			</IonList>
		</IonContent>
	);
};

export default Menu;
