import {
	IonButtons,
	IonContent,
	IonHeader,
	IonPage,
	IonTitle,
	IonToolbar,
	IonBackButton,
	IonCard,
	IonCardTitle,
	IonCardSubtitle,
	IonCardContent,
	IonList,
	IonItem,
	IonLabel,
	IonItemDivider,
	IonListHeader,
	IonRadioGroup,
	IonRadio,
	IonInput,
	IonButton,
	IonImg,
	IonModal,
	IonIcon,
	IonGrid,
	IonCol,
	IonRow,
	IonChip,
} from '@ionic/react';
import React from 'react';
import { RouteComponentProps } from 'react-router';
import { ImagePicker } from 'react-file-picker';
import { logoAppleAppstore, pin, close, checkmark, fastFood } from 'ionicons/icons';
import { Area, AreaChart, Brush, CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import API from '../../API';
import LoadingFullScreen from '../../components/LoadingFullScreen';
import TypeSelectorComponent from '../../components/TypeSelectorComponent';
import { countries } from '../../Constants';

interface Props
	extends RouteComponentProps<{
		id: string;
	}> {}

export default class extends React.Component<Props> {
	state: any = {
		app: null,
		countries: [],
		selectedCountries: [],
		selectCountiesOpened: false,
		linkStartsWithoutSSL: false,
		selectedCountryForChart: null,
		filterCountryForChart: '',
		newComment: '',
	};

	componentDidMount() {
		this.getAppInfo();
	}

	getCountryById(id: any) {
		let country = null;

		for (let index = 0; index < this.state.countries.length; index++) {
			const c = this.state.countries[index];

			if (c.id == id) {
				country = c;
				break;
			}
		}

		return country;
	}

	getCountryByCode(code: any) {
		let country = null;

		for (let index = 0; index < this.state.countries.length; index++) {
			const c = this.state.countries[index];

			if (c.code == code) {
				country = c;
				break;
			}
		}

		return country;
	}

	getAppInfo() {
		API.PM_getAppInfo(this.props.match.params.id, this.state.selectedCountryForChart).then((res: any) => {
			const selectedCountries: Array<any> = [];
			this.state.countries = [];
			for (const [key, value] of Object.entries(res.countries)) {
				const country: any = value;
				this.state.countries.push(value);
				if ((res.data.allowed_country_ids as Array<number>).includes(country.id)) {
					selectedCountries.push(value);
				}
			}

			if (res.data.smartlink) {
				res.data.smartlink = Object.entries(JSON.parse(res.data.smartlink)).map(([key, value]) => {
					return {
						code: key,
						value: value,
					};
				});
			}

			this.setState({
				app: res.data,
				countries: this.state.countries,
				selectedCountries: selectedCountries,
				linkStartsWithoutSSL: res.data.link.startsWith('http://'),
			});
		});
	}

	render() {
		const app = this.state.app;

		return (
			<IonPage key={'app_page' + this.props.match.params.id}>
				<IonModal
					isOpen={this.state.selectCountiesOpened}
					onDidDismiss={() => {
						this.setState({ selectCountiesOpened: false });
					}}>
					<TypeSelectorComponent
						beforeSelected={this.state.selectedCountries}
						items={this.state.countries}
						didSelected={(items: any) => {
							app.allowed_country_ids = items.map((i: any) => i.id);
							this.setState({
								app: app,
								selectedCountries: items,
								selectCountiesOpened: false,
							});
						}}
						didCancelled={() => this.setState({ selectCountiesOpened: false })}
					/>
				</IonModal>

				<IonHeader>
					<IonToolbar>
						<IonButtons slot="start">
							<IonBackButton defaultHref="/brands-statistics"></IonBackButton>
						</IonButtons>
						<IonTitle>Информация о приложении {this.props.match.params.id}</IonTitle>
					</IonToolbar>
				</IonHeader>
				<IonContent>
					{app == null ? (
						<LoadingFullScreen />
					) : (
						<IonCard>
							<div className="ion-padding">
								<IonCardTitle>{app.title}</IonCardTitle>
								<IonCardSubtitle>{app.bundle_id}</IonCardSubtitle>
								<br />
								{app.store_link ? (
									app.is_in_store ? (
										<IonButtons>
											<IonButton
												color="primary"
												onClick={() => {
													window.open(app.store_link, '_blank');
												}}>
												<IonIcon icon={logoAppleAppstore}></IonIcon>
												&nbsp;Открыть в сторе
											</IonButton>
											<IonButton
												color="danger"
												onClick={() => {
													app.is_in_store = false;
													this.setState({
														app: app,
													});
												}}>
												Прила была забанена
											</IonButton>
										</IonButtons>
									) : (
										<div>
											<p style={{ color: 'red' }}>Прила забанена</p>
											<IonButton
												color="success"
												onClick={async () => {
													await API.appRestoreAppAlive(app.id);
													this.getAppInfo();
												}}>
												Восстановить прилу
											</IonButton>
										</div>
									)
								) : (
									<p>Ссылка не установлена</p>
								)}
							</div>

							<IonCardContent>
								<IonList>
									<IonItemDivider>
										<h2>Информация</h2>
									</IonItemDivider>
									<IonItem>
										<IonLabel>
											<p>Установок: {app.install_count} </p>
											<p>Регистраций: {app.reg_count}</p>
											<p>Депозитов: {app.dep_count}</p>
										</IonLabel>
									</IonItem>
									<IonItemDivider>
										<h2>Сведения</h2>
									</IonItemDivider>
									<IonItem>
										<IonLabel>
											<h2>{new Date(app.created).toString()}</h2>
											<p>Дата создания</p>
										</IonLabel>
									</IonItem>
									<IonItem>
										<IonLabel>
											<h2>{app.published_to_store_at ? new Date(app.published_to_store_at).toString() : 'Еще не вышел'}</h2>
											<p>Дата выхода в стор</p>
										</IonLabel>
									</IonItem>
									<IonItem>
										<IonLabel>
											<h2>{app.publisher.name}</h2>
											<p>Публикатор</p>
										</IonLabel>
									</IonItem>
									<IonItem>
										<IonLabel>
											<h2>{app.developer.name}</h2>
											<p>Разработчик</p>
										</IonLabel>
									</IonItem>
									<IonItem>
										<IonLabel>
											<h2>{app.designer.name}</h2>
											<p>Дизайнер</p>
										</IonLabel>
									</IonItem>
									<IonItem>
										<IonLabel>
											<h2>Статистика</h2>
											<div
												style={{
													display: 'flex',
													flexDirection: 'row',
													justifyContent: 'space-between',
												}}>
												<div>
													<h2>Пользователи</h2>
													{app.stats.users.map((c: any) => {
														return (
															<p key={'app_stats_users_' + c?.country}>
																{
																	//@ts-ignore
																	(countries[c.country]?.title || 'Неизвестно') + ' ' + c.count
																}
															</p>
														);
													})}
												</div>
												<div>
													<h2>Боты</h2>
													{app.stats.bots.map((c: any) => {
														return (
															<p key={'app_stats_bots_' + c?.country}>
																{
																	//@ts-ignore
																	(countries[c?.country]?.title || 'Неизвестно') + ' ' + c.count
																}
															</p>
														);
													})}
												</div>
											</div>
										</IonLabel>
									</IonItem>

									<IonItemDivider>Комментарии</IonItemDivider>

									{app.comments.length == 0 ? <h2 className="ion-padding">Комментариев нет</h2> : false}
									{app.comments.map((comment: any) => {
										return (
											<IonItem key={'app_comment_' + comment.id}>
												<IonLabel className="ion-text-wrap">
													<h5>
														{comment.is_completed ? '✅' : '⏳'} {comment.comment}
													</h5>
													<p
														onClick={() => {
															window.open('/employees/' + comment.user_id, '_blank');
														}}>
														{comment.user_name} [{comment.user_role}] {new Date(comment.created).toLocaleString()}
													</p>
													{comment.is_completed ? <p>Завершен: {new Date(comment.completed_at).toLocaleString()}</p> : false}
												</IonLabel>
												<IonButtons slot="end">
													{comment.is_completed ? (
														false
													) : (
														<IonButton
															onClick={() => {
																API.setAppCommentCompleted(comment.id).then(() => {
																	this.getAppInfo();
																});
															}}>
															Выполнен
														</IonButton>
													)}
												</IonButtons>
											</IonItem>
										);
									})}
									<IonItem>
										<IonLabel position="floating">Добавить комментарий</IonLabel>
										<IonInput
											placeholder="Комментарий"
											value={this.state.newComment}
											onIonChange={(e) => {
												this.setState({
													newComment: e.detail.value!,
												});
											}}></IonInput>
										<IonButtons slot="end">
											<IonButton
												onClick={() => {
													API.addAppComment(app.id, this.state.newComment).then(() => {
														this.setState({
															newComment: '',
														});
														this.getAppInfo();
													});
												}}>
												Отправить
											</IonButton>
										</IonButtons>
									</IonItem>

									<br />

									<IonItemDivider>Управление</IonItemDivider>
									<IonRadioGroup
										value={app.is_enabled ? '1' : '2'}
										onIonChange={(e) => {
											app.is_enabled = e.detail.value! == '1';
											this.setState({
												app: app,
											});
										}}>
										<IonListHeader>
											<IonLabel>
												Клоака <span style={{ color: app.is_enabled ? 'green' : 'red' }}>{app.is_enabled ? 'ON' : 'OFF'}</span>{' '}
											</IonLabel>
										</IonListHeader>

										<IonItem>
											<IonLabel>Включена</IonLabel>
											<IonRadio slot="start" value="1" />
										</IonItem>
										<IonItem>
											<IonLabel>Выключена</IonLabel>
											<IonRadio slot="start" value="2" />
										</IonItem>
									</IonRadioGroup>
									<IonRadioGroup
										value={app.mode}
										onIonChange={(e) => {
											app.mode = e.detail.value!;
											this.setState({
												app: app,
											});
										}}>
										<IonListHeader>
											<IonLabel>Тип приложения</IonLabel>
										</IonListHeader>

										<IonItem>
											<IonLabel>Web View</IonLabel>
											<IonRadio slot="start" value="webview" />
										</IonItem>
										<IonItem>
											<IonLabel>Выборка</IonLabel>
											<IonRadio slot="start" value="selector" />
										</IonItem>
									</IonRadioGroup>

									<IonItem>
										<IonLabel position="floating">
											Ссылка <span style={{ color: 'yellow' }}>{this.state.linkStartsWithoutSSL ? 'Не рекомендуем ставить ссылку без SSL' : ''}</span>
										</IonLabel>
										<IonInput
											value={app.link}
											onIonChange={(e) => {
												app.link = e.detail.value?.trim();
												this.setState({
													linkStartsWithoutSSL: app.link.startsWith('http://'),
												});
											}}></IonInput>
									</IonItem>

									<br />
									{app.smartlink == null ? (
										<IonItem>
											<IonButtons>
												<IonButton
													shape="round"
													color="success"
													onClick={() => {
														app.smartlink = [
															{
																code: 'XX',
																value: 'http://google.com',
															},
														];
														this.setState({
															app: app,
														});
													}}>
													Включить смартлинк
												</IonButton>
											</IonButtons>
										</IonItem>
									) : (
										<IonList>
											<p>Вместо XX введите код страны с больших букв. Далее на втором поле ссылку которая должна включиться для страны. Если страны нет то включится главная ссылка</p>
											{app.smartlink.map((smart: any, index: number) => {
												return (
													<IonItem key={'smartlink_' + smart.code + new Date().toString()}>
														<IonGrid>
															<IonRow>
																<IonCol size="1">
																	<IonInput
																		onIonChange={(e) => {
																			e.detail.value = e.detail.value?.toUpperCase().trim();
																			smart.code = e.detail.value?.toUpperCase().trim();
																		}}
																		value={smart.code}></IonInput>
																</IonCol>
																<IonCol size="11">
																	<IonInput
																		onIonChange={(e) => {
																			smart.value = e.detail.value?.trim();
																		}}
																		value={smart.value as string}></IonInput>
																</IonCol>
															</IonRow>
														</IonGrid>
														<IonButtons slot="end">
															<IonButton
																color="danger"
																onClick={() => {
																	delete app.smartlink[index];
																	this.setState({
																		app: app,
																	});
																}}>
																Удалить
															</IonButton>
														</IonButtons>
													</IonItem>
												);
											})}

											<IonItem>
												<IonButtons>
													<IonButton
														shape="round"
														color="success"
														onClick={() => {
															app.smartlink.push({
																code: 'XX',
																value: 'http://google.com',
															});
															this.setState({
																app: app,
															});
														}}>
														Добавить страну +
													</IonButton>
													&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
													<IonButton
														shape="round"
														color="danger"
														onClick={() => {
															app.smartlink = null;
															this.setState({
																app: app,
															});
														}}>
														Удалить все смартлинки
													</IonButton>
												</IonButtons>
											</IonItem>
										</IonList>
									)}

									<IonRadioGroup
										value={app.cache_enabled ? '1' : '2'}
										onIonChange={(e) => {
											app.cache_enabled = e.detail.value! == '1';
											this.setState({
												app: app,
											});
										}}>
										<IonListHeader>
											<IonLabel>
												Кэширование ссылок{' '}
												<span
													style={{
														color: app.cache_enabled ? 'green' : 'red',
													}}>
													{app.cache_enabled ? 'ON' : 'OFF'}
												</span>{' '}
											</IonLabel>
										</IonListHeader>

										<IonItem>
											<IonLabel>Включена</IonLabel>
											<IonRadio slot="start" value="1" />
										</IonItem>
										<IonItem>
											<IonLabel>Выключена</IonLabel>
											<IonRadio slot="start" value="2" />
										</IonItem>
									</IonRadioGroup>
									<IonRadioGroup
										value={app.mode}
										onIonChange={(e) => {
											app.mode = e.detail.value!;
											this.setState({
												app: app,
											});
										}}></IonRadioGroup>

									<IonListHeader>
										<IonLabel>Страны</IonLabel>
									</IonListHeader>
									<IonItem>
										<IonLabel>
											<h2>Страны</h2>
											{app.allowed_country_ids.map((c: any) => {
												return <p key={'app_country_' + c}>{this.getCountryById(c)?.title}</p>;
											})}
										</IonLabel>
									</IonItem>
									<IonItem>
										<IonLabel>Выбрано стран: {this.state.selectedCountries.length}</IonLabel>
									</IonItem>

									<br />
								</IonList>

								<br />

								<h2>Последние боты</h2>

								<IonList>
									{app.lastBots.map((b: any) => {
										return (
											<IonItem key={'last_bot_' + b.id}>
												<IonLabel>
													<h2>{b.reason}</h2>
													<p>
														{b.ip +
															' | ' +
															// @ts-ignore
															countries[b.country]?.title +
															' | ' +
															new Date(b.created).toLocaleString()}
													</p>
												</IonLabel>
											</IonItem>
										);
									})}
								</IonList>
							</IonCardContent>
						</IonCard>
					)}
				</IonContent>
			</IonPage>
		);
	}
}
