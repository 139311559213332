import { IonButtons, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonBackButton, IonCard, IonCardContent, IonList, IonItem, IonLabel, IonItemDivider, IonButton } from '@ionic/react';
import React from 'react';
import { RouteComponentProps } from 'react-router';

import API from '../../../API';
import LoadingFullScreen from '../../../components/LoadingFullScreen';
import { getAppTrafRoleById } from '../../RegisterPage';

interface Props
	extends RouteComponentProps<{
		id: string;
	}> {}

export default class extends React.Component<Props> {
	state: any = {
		employer: null,
		accountingExpenditures: [],
		allBanks: [],
	};

	allowedAccountingExpenditures = Array<number>();
	allowedBanks = Array<number>();

	componentDidMount() {
		this.getEmployer();
	}

	async getEmployer() {
		API.getEmployer(this.props.match.params.id).then((res) => {
			this.allowedAccountingExpenditures = res.data.employer.allowedAccountingExpenditures.map((aae: any) => aae.id);
			this.allowedBanks = res.data.employer.allowedBanks.map((b: any) => b.id);

			this.setState({
				employer: res.data.employer,
				accountingExpenditures: res.data.accountingExpenditures,
				allBanks: res.data.allBanks,
			});
		});
	}

	render() {
		const employer = this.state.employer;
		return (
			<IonPage>
				<IonHeader>
					<IonToolbar>
						<IonButtons slot="start">
							<IonBackButton defaultHref="/employees"></IonBackButton>
						</IonButtons>
						<IonTitle>Информация о сотруднике {this.props.match.params.id}</IonTitle>
					</IonToolbar>
				</IonHeader>
				<IonContent>
					{employer == null ? (
						<LoadingFullScreen />
					) : (
						<IonCard>
							<IonCardContent>
								<IonList>
									<IonItem>
										<IonLabel>
											<h2>
												ID: {employer.id} | Login: {employer.login} | Имя: {employer.name}
											</h2>
											<p>Роль: {getAppTrafRoleById(employer.role)?.description ?? 'НЕ ОПРЕДЕЛЕН'}</p>
										</IonLabel>

										<IonButtons slot="end">
											<IonButton
												disabled={employer.is_banned}
												color="danger"
												onClick={async () => {
													const res = await API.banEmployees(employer.id);
													if (res.success) {
														this.getEmployer();
													}
												}}>
												Забанить
											</IonButton>
										</IonButtons>
									</IonItem>

									<IonItemDivider>Доступы к статьям расхода</IonItemDivider>
									{employer.allowedAccountingExpenditures.map((aae: any) => {
										return (
											<IonItem key={'aae_' + aae.id}>
												<IonLabel>
													<h2>{aae.title}</h2>
												</IonLabel>
												<IonButtons slot="end">
													<IonButton
														onClick={async () => {
															const res = await API.editEmployerAccountingExpenditures(employer.id, 'delete', aae.id);
															if (res.success) {
																this.getEmployer();
															}
														}}>
														Удалить
													</IonButton>
												</IonButtons>
											</IonItem>
										);
									})}

									<IonItemDivider>Все статьи расхода</IonItemDivider>
									{(this.state.accountingExpenditures as Array<any>)
										.filter((ae) => !this.allowedAccountingExpenditures.includes(ae.id))
										.map((ae: any) => {
											return (
												<IonItem key={'ae_' + ae.id}>
													<IonLabel>
														<h2>{ae.title}</h2>
													</IonLabel>
													<IonButtons slot="end">
														<IonButton
															onClick={async () => {
																const res = await API.editEmployerAccountingExpenditures(employer.id, 'add', ae.id);
																if (res.success) {
																	this.getEmployer();
																}
															}}>
															Добавить
														</IonButton>
													</IonButtons>
												</IonItem>
											);
										})}

									<br />
									<br />

									<IonItemDivider>Доступы к банкам</IonItemDivider>
									{employer.allowedBanks.map((b: any) => {
										return (
											<IonItem key={'b_' + b.id}>
												<IonLabel>
													<h2>{b.title}</h2>
												</IonLabel>
												<IonButtons slot="end">
													<IonButton
														onClick={async () => {
															const res = await API.editEmployeBankAccess(employer.id, 'delete', b.id);
															if (res.success) {
																this.getEmployer();
															}
														}}>
														Удалить
													</IonButton>
												</IonButtons>
											</IonItem>
										);
									})}

									<IonItemDivider>Все доступные банки</IonItemDivider>
									{(this.state.allBanks as Array<any>)
										.filter((b) => !this.allowedBanks.includes(b.id))
										.map((b: any) => {
											return (
												<IonItem key={'allowed_b_' + b.id}>
													<IonLabel>
														<h2>{b.title}</h2>
													</IonLabel>
													<IonButtons slot="end">
														<IonButton
															onClick={async () => {
																const res = await API.editEmployeBankAccess(employer.id, 'add', b.id);
																if (res.success) {
																	this.getEmployer();
																}
															}}>
															Добавить
														</IonButton>
													</IonButtons>
												</IonItem>
											);
										})}
								</IonList>
							</IonCardContent>
						</IonCard>
					)}
				</IonContent>
			</IonPage>
		);
	}
}
