import { IonButtons, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonBackButton, IonCard, IonCardContent, IonList, IonItem, IonLabel, IonItemDivider } from '@ionic/react';
import React from 'react';
import { RouteComponentProps } from 'react-router';

import API from '../../API';
import LoadingFullScreen from '../../components/LoadingFullScreen';

interface Props
	extends RouteComponentProps<{
		id: string;
	}> {}

export default class extends React.Component<Props> {
	state: any = {
		user: null,
	};
	componentDidMount() {
		API.getUserInfo(this.props.match.params.id).then((res) =>
			this.setState({
				user: res.data,
			}),
		);
	}

	render() {
		const user = this.state.user;
		return (
			<IonPage>
				<IonHeader>
					<IonToolbar>
						<IonButtons slot="start">
							<IonBackButton defaultHref="/users"></IonBackButton>
						</IonButtons>
						<IonTitle>Информация о пользователе {this.props.match.params.id}</IonTitle>
					</IonToolbar>
				</IonHeader>
				<IonContent>
					{user == null ? (
						<LoadingFullScreen />
					) : (
						<IonCard>
							<IonCardContent>
								<IonList>
									<IonItem>
										<IonLabel>
											<h2>{new Date(user.last_active).toLocaleString()}</h2>
											<p>Был онлайн</p>
										</IonLabel>
									</IonItem>
									<IonItem>
										<IonLabel>
											<h2>{new Date(user.created).toLocaleString()}</h2>
											<p>Создан</p>
										</IonLabel>
									</IonItem>
									<IonItem>
										<IonLabel>
											<h2>{user.ip}</h2>
											<p>IP</p>
										</IonLabel>
									</IonItem>
									<IonItem routerLink={'/apps/' + user.offer.id}>
										<IonLabel>
											<h2>{user.offer.title}</h2>
											<p>Оффер</p>
										</IonLabel>
									</IonItem>
									<IonItem>
										<IonLabel>
											<h2>{user.offer_url}</h2>
											<p>Открыл ссылку</p>
										</IonLabel>
									</IonItem>
									<IonItem>
										<IonLabel>
											<h2>{user.country + ' ' + user.region + ' ' + user.city + ' | Часовой пояс: ' + user.timezone}</h2>
											<p>ГЕО</p>
										</IonLabel>
									</IonItem>
									<IonItem>
										<IonLabel>
											<h2>{user.ip}</h2>
											<p>IP</p>
										</IonLabel>
									</IonItem>
									<IonItem>
										<IonLabel>
											<h2>{user.operator[0].brand}</h2>
											<p>Оператор</p>
										</IonLabel>
									</IonItem>
									<IonItem>
										<IonLabel>
											<h2>{user.first_dep == 1 ? 'Да' : 'Нет'}</h2>
											<p>Первый депозит</p>
										</IonLabel>
									</IonItem>
									<IonItem>
										<IonLabel>
											<h2>{user.qualification_dep == 1 ? 'Да' : 'Нет'}</h2>
											<p>Квалификационный депозит</p>
										</IonLabel>
									</IonItem>
									<IonItem>
										<IonLabel>
											<h2>{user.registered == 1 ? 'Да' : 'Нет'}</h2>
											<p>Зарегистрировался</p>
										</IonLabel>
									</IonItem>
									<IonItemDivider>Логи</IonItemDivider>
									{user.logs.map((l: any) => (
										<IonItem key={l.id}>{l.description}</IonItem>
									))}
								</IonList>
							</IonCardContent>
						</IonCard>
					)}
				</IonContent>
			</IonPage>
		);
	}
}
