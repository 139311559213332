import { IonPage, IonHeader, IonToolbar, IonButtons, IonMenuButton, IonTitle, IonContent, IonList, IonButton, IonItem, IonLabel, IonAlert } from '@ionic/react';
import React, { useEffect, useState } from 'react';

import API from '../../API';
import LoadingFullScreen from '../../components/LoadingFullScreen';

export default function () {
	const [apps, setApps] = useState<null | any[]>(null);
	const [addAppShow, setAddAppShow] = useState(false);

	const getPublisherApps = () => {
		API.getPublisherAppsInReview().then((res) => {
			if (res.success) {
				setApps(res.data);
			}
		});
	};

	useEffect(() => {
		getPublisherApps();
	}, []);

	return (
		<IonPage>
			<IonAlert
				isOpen={addAppShow}
				onDidDismiss={() => {
					setAddAppShow(false);
				}}
				header="Добавить прилу"
				message="В данный момент только Google Play"
				inputs={[
					{
						name: 'bundleId',
						type: 'text',
						placeholder: 'bundle id (package id)',
					},
				]}
				buttons={[
					{
						text: 'Отмена',
						role: 'cancel',
						cssClass: 'secondary',
					},
					{
						text: 'Добавить прилу',
						handler: async (inputs) => {
							const res = await API.getPublisherAddAppInReview(inputs.bundleId, 'googleplay');
							if (res.success) {
								getPublisherApps();
							}
						},
					},
				]}
			/>
			<IonHeader>
				<IonToolbar>
					<IonButtons slot="start">
						<IonMenuButton />
					</IonButtons>
					<IonTitle>Ожидание проверки</IonTitle>
					<IonButtons slot="end">
						<IonButton
							onClick={() => {
								setAddAppShow(true);
							}}>
							Добавить прилу
						</IonButton>
					</IonButtons>
				</IonToolbar>
			</IonHeader>
			<IonContent>
				{apps == null ? (
					<LoadingFullScreen />
				) : (
					<div className="ion-padding">
						{apps.length == 0 ? (
							<p>Вы не добавляли прилы</p>
						) : (
							<IonList>
								{apps.map((app: any) => {
									return (
										<IonItem key={'app_' + app.id}>
											<IonLabel>
												<h2>{app.bundle_id}</h2>
												<p>{app.store}</p>
												{app.last_checked ? <p>Последняя проверка: {new Date(app.last_checked).toString()}</p> : false}
												<p>Создан: {new Date(app.created).toString()}</p>
											</IonLabel>
											<IonButtons slot="end">
												<IonButton
													onClick={async () => {
														const res = await API.publisherDeleteAppInReview(app.id);
														if (res.success) {
															getPublisherApps();
														}
													}}>
													Удалить из проверки
												</IonButton>
											</IonButtons>
										</IonItem>
									);
								})}
							</IonList>
						)}
						<br />
						<br />
					</div>
				)}
			</IonContent>
		</IonPage>
	);
}
