import { IonButtons, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonBackButton, IonCard, IonModal, IonButton, IonTextarea, IonItem, IonInput, IonLabel, IonItemDivider, IonSpinner } from '@ionic/react';
import React from 'react';
import { RouteComponentProps } from 'react-router';

import API from '../../../API';
import LoadingFullScreen from '../../../components/LoadingFullScreen';

interface Props
	extends RouteComponentProps<{
		id: string;
	}> {}

export default class extends React.Component<Props> {
	state: any = {
		keywords: null,
		loading: false,
	};

	addKeywords = Array<string>();

	country = 'ru';

	componentDidMount() {
		this.getAppInfo();
	}

	getAppInfo() {
		API.getAppGalleryKeywordPosition(this.props.match.params.id).then((res) => {
			this.setState({
				keywords: res.data,
			});
		});
	}

	render() {
		const keywords = this.state.keywords;

		return (
			<IonPage>
				<IonHeader>
					<IonToolbar>
						<IonButtons slot="start">
							<IonBackButton defaultHref="/appgallery"></IonBackButton>
						</IonButtons>
						<IonTitle>Информация о приложении {this.props.match.params.id}</IonTitle>
					</IonToolbar>
				</IonHeader>
				<IonContent>
					{keywords == null ? (
						<LoadingFullScreen />
					) : (
						<IonCard>
							{Object.entries(keywords).map(([key, value]) => {
								return (
									<div key={'app_' + this.props.match.params.id + '_keyword_' + key}>
										<IonItemDivider>{key}</IonItemDivider>
										{(value as Array<any>).map((k) => {
											return (
												<IonItem key={'app_' + this.props.match.params.id + '_keyword_' + key}>
													<IonLabel>
														<p>{k.keyword + ': ' + k.position}</p>
													</IonLabel>
												</IonItem>
											);
										})}
									</div>
								);
							})}

							<br />

							<IonButton
								onClick={() => {
									API.getAppGalleryUpdatePositions(this.props.match.params.id);
								}}>
								Обновить позиции
							</IonButton>
							<IonItem>
								<IonInput placeholder="1xbet,vulkan,pin up,joy" onIonChange={(e) => (this.addKeywords = e.detail.value!.split(','))}></IonInput>
								{this.state.loading ? (
									<IonSpinner></IonSpinner>
								) : (
									<IonButton
										slot="end"
										onClick={async () => {
											this.setState({
												loading: true,
											});
											const res = await API.addAppGalleryKeywords(this.props.match.params.id, this.addKeywords, this.country);
											await API.getAppGalleryUpdatePositions(this.props.match.params.id);
											window.location.reload();
										}}>
										Добавить
									</IonButton>
								)}
							</IonItem>
						</IonCard>
					)}
				</IonContent>
			</IonPage>
		);
	}
}
