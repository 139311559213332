import { IonPage, IonHeader, IonToolbar, IonButtons, IonMenuButton, IonTitle, IonContent, IonTextarea, IonButton } from '@ionic/react';
import React from 'react';

import API from '../../API';

export default class extends React.Component {
	links = '';

	componentDidMount() {}

	render() {
		return (
			<IonPage>
				<IonHeader>
					<IonToolbar>
						<IonButtons slot="start">
							<IonMenuButton />
						</IonButtons>
						<IonTitle>Ссылки в стор</IonTitle>
					</IonToolbar>
				</IonHeader>
				<IonContent>
					<IonTextarea onIonChange={(e) => (this.links = e.detail.value!)} autofocus={true} rows={15}></IonTextarea>
					<IonButton
						onClick={async () => {
							const res = await API.uploadLinks(this.links.split('\n'));
							alert('Импортировано ' + res.importedCount + ' ссылок в стор');
						}}>
						Загрузить
					</IonButton>
				</IonContent>
			</IonPage>
		);
	}
}
