import { IonPage, IonHeader, IonToolbar, IonButtons, IonMenuButton, IonTitle, IonContent, IonList, IonItem, IonLabel, IonButton, IonItemDivider } from '@ionic/react';
import React, { useEffect, useState } from 'react';

import API from '../../API';
import LoadingFullScreen from '../../components/LoadingFullScreen';

const ManagerBanksPage: React.FC = () => {
	const [banks, setBanks] = useState<null | Array<any>>(null);
	const [balanceInSafe, setBalanceInSafe] = useState(0);
	const [inSafeBankLogs, setInSafeBankLogs] = useState<null | Array<any>>(null);

	const [update, setUpdate] = useState(0);

	useEffect(() => {
		API.managerAccountingBanks().then((res) => {
			setBanks(res.data.banks);
			setBalanceInSafe(res.data.balanceInSafe);
		});
		API.managerAccountingGetLogList().then((res) => {
			setInSafeBankLogs(res.data);
		});
	}, [update]);

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonButtons slot="start">
						<IonMenuButton />
					</IonButtons>
					<IonTitle>Бухгалтерия</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent>
				{banks == null ? (
					<LoadingFullScreen />
				) : (
					<div className="ion-padding">
						<p>{balanceInSafe} руб в сейфе</p>

						{banks.map((bank) => {
							return (
								<IonItem key={'bank_' + bank.id}>
									<IonLabel>
										<h2>
											{bank.title} [{bank.currency}]
										</h2>
										<p>Balance: {bank.balance}</p>
									</IonLabel>
									<IonButtons slot="end">
										<IonButton
											onClick={() => {
												const text = window.prompt('Введите сумму');
												if (text != null && text != '') {
													const amount = parseInt(text);
													API.managerAddAccountingRecord(amount, bank.id).then(() => {
														setUpdate(update + 1);
													});
												}
											}}>
											Добавить запись
										</IonButton>
									</IonButtons>
								</IonItem>
							);
						})}

						<IonItemDivider>
							<IonLabel>Логи</IonLabel>
						</IonItemDivider>
						{inSafeBankLogs?.map((log) => {
							return (
								<IonItem key={'bank_log_' + log.id}>
									<IonLabel>
										<h2>{log.comment}</h2>
										<p>Сумма: {log.summ}</p>
									</IonLabel>
								</IonItem>
							);
						})}
					</div>
				)}
			</IonContent>
		</IonPage>
	);
};

export default ManagerBanksPage;
