import { IonPage, IonHeader, IonToolbar, IonButtons, IonMenuButton, IonTitle, IonContent, IonList, IonButton } from '@ionic/react';
import React from 'react';

import API from '../../API';
import LoadingFullScreen from '../../components/LoadingFullScreen';

export default class extends React.Component {
	state: any = {
		campaigns: null,
	};

	componentDidMount() {
		this.getMBCampaigns();
	}

	async getMBCampaigns() {
		const res = await API.getMBCampaigns();
		if (res.success) {
			this.setState({
				campaigns: res.data,
			});
		}
	}

	render() {
		return (
			<IonPage>
				<IonHeader>
					<IonToolbar>
						<IonButtons slot="start">
							<IonMenuButton />
						</IonButtons>
						<IonTitle>Кампании</IonTitle>
						<IonButtons slot="end">
							<IonButton routerLink="/mb/campaigns/create">Создать кампанию</IonButton>
						</IonButtons>
					</IonToolbar>
				</IonHeader>
				<IonContent>
					{this.state.campaigns == null ? <LoadingFullScreen /> : <div className="ion-padding">{this.state.campaigns.length == 0 ? <p>Вы не запускали кампании</p> : <IonList></IonList>}</div>}
				</IonContent>
			</IonPage>
		);
	}
}
