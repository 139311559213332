import { IonPage, IonHeader, IonToolbar, IonButtons, IonMenuButton, IonTitle, IonContent, IonList, IonButton, IonItem, IonLabel, IonToast, IonAlert, IonSpinner, IonIcon } from '@ionic/react';
import { pauseSharp, playSharp } from 'ionicons/icons';
import React from 'react';
import { toast } from 'react-toastify';

import API from '../../API';
import LoadingFullScreen from '../../components/LoadingFullScreen';
import UserService from '../../UserService';

export default class extends React.Component {
	state: any = {
		projects: null,
		creatingProject: false,
		play: false,
		white: false,
	};

	constructor(props: any) {
		super(props);
	}

	componentDidMount() {
		this.getProjects();
	}

	async getProjects() {
		this.setState({
			creatingProject: true,
		});
		const res = await API.developerAndroidGetProjects(this.state.white);
		if (res.success) {
			this.setState({
				projects: res.data,
				creatingProject: false,
			});
		}
	}

	render() {
		return (
			<IonPage>
				<IonAlert
					isOpen={this.state.showCreateProjectAlert}
					onDidDismiss={() => {
						this.setState({ showCreateProjectAlert: false });
					}}
					header="Создание проекта"
					inputs={[
						{
							name: 'companyName',
							type: 'text',
							placeholder: 'Название компании',
						},
						{
							name: 'projectName',
							type: 'text',
							placeholder: 'Название проекта (прилы)',
						},
					]}
					buttons={[
						{
							text: 'Отмена',
							role: 'cancel',
							cssClass: 'secondary',
						},
						{
							text: 'Создать проект',
							handler: async (inputs) => {
								await API.developerAndroidCreateProject(inputs.companyName, inputs.projectName);
								this.getProjects();
							},
						},
					]}
				/>
				<IonHeader>
					<IonToolbar>
						<IonButtons slot="start">
							<IonMenuButton />
						</IonButtons>
						<IonTitle>Проекты Android</IonTitle>
						<IonButtons slot="end">
							{UserService.getUser().subrole == 'lead-android' && (
								<IonButton
									onClick={async () => {
										const { success } = await API.closeTestedApplication();

										if (success) {
											toast.success('Успешно закрылись');
										}
									}}>
									Закрыть доступы
								</IonButton>
							)}
							<IonButton
								onClick={() => {
									this.state.white = !this.state.white;
									this.setState({
										white: this.state.white,
									});
									this.getProjects();
								}}>
								{this.state.white ? 'Показывать мои' : 'Показать белые'}
							</IonButton>
							{this.state.creatingProject ? (
								<IonSpinner></IonSpinner>
							) : (
								<IonButton
									onClick={async () => {
										this.setState({
											showCreateProjectAlert: true,
										});
									}}>
									Создать проект
								</IonButton>
							)}
						</IonButtons>
					</IonToolbar>
				</IonHeader>
				<IonContent>
					{this.state.projects == null ? (
						<LoadingFullScreen />
					) : (
						<div className="ion-padding">
							{this.state.projects.length == 0 ? (
								<div>
									<p>Вы не создавали проекты</p>
								</div>
							) : (
								<IonList>
									{this.state.projects.map((project: any) => {
										return (
											<IonItem routerLink={'/developer/android/projects/' + project.id} key={'developer_android_project_' + project.id}>
												<IonLabel>
													<h2>{project.title}</h2>
													<p>
														ID: {project.id}{' '}
														{UserService.getUser().subrole == 'lead-android' ? (
															<span style={{ color: project.is_closed ? 'green' : 'red' }}>Доступ: {project.is_closed ? 'Закрыт' : 'Открыт'}</span>
														) : null}{' '}
													</p>
												</IonLabel>
											</IonItem>
										);
									})}
								</IonList>
							)}
						</div>
					)}
				</IonContent>
			</IonPage>
		);
	}
}
