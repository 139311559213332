import { IonPage, IonHeader, IonToolbar, IonButtons, IonMenuButton, IonTitle, IonContent, IonTextarea, IonItem, IonInput, IonLabel } from '@ionic/react';
import React from 'react';

export default class extends React.Component {
	state = {
		javaCode: '',
		out: '',
	};

	key = 1;
	proxyUrl = 'http://bubenchick-07.top';

	constructor(props: any) {
		super(props);
	}

	componentDidMount() {}

	crypt(input: string): string {
		let crypted = '';
		for (let index = 0; index < input.length; index++) {
			const char = input[index];
			crypted += String.fromCharCode(char.charCodeAt(0) + this.key);
		}
		return crypted;
	}

	decrypt(input: string): string {
		let decrypted = '';
		for (let index = 0; index < input.length; index++) {
			const char = input[index];
			decrypted += String.fromCharCode(char.charCodeAt(0) - this.key);
		}
		return decrypted;
	}

	render() {
		return (
			<IonPage>
				<IonHeader>
					<IonToolbar>
						<IonButtons slot="start">
							<IonMenuButton />
						</IonButtons>
						<IonTitle>Цезарь генератор</IonTitle>
					</IonToolbar>
				</IonHeader>
				<IonContent>
					<div className="ion-padding">
						<IonItem>
							<IonLabel position="fixed">Прокси</IonLabel>

							<IonInput
								placeholder="http://bubenchick-07.top"
								onIonChange={(e) => {
									this.proxyUrl = e.detail.value!;
								}}
								clearInput></IonInput>
						</IonItem>
						<IonItem>
							<IonLabel position="fixed">Ключ</IonLabel>

							<IonInput
								placeholder="X"
								value={this.key}
								type="number"
								onIonChange={(e) => {
									this.key = parseInt(e.detail.value!);
								}}
								clearInput></IonInput>
						</IonItem>

						<IonItem
							button
							onClick={() => {
								this.setState({
									out: `import java.util.ArrayList;

                public class WebViewElements {
                
                    private static final int key = ${this.key};
                    private static Decrypt decrypt = new Base();
                
                    static String getWebViewClassName(){
                        return decrypt.decrypt("${this.crypt('android.webkit.WebView')}",key);
                    }
                
                    static String setLayoutParams(){
                
                        return decrypt.decrypt("${this.crypt('setLayoutParams')}",key);
                    }
                
                    static String getSettings() {
                        return decrypt.decrypt("${this.crypt('getSettings')}",key);
                    }
                
                    static private final String rawParams = decrypt.decrypt("${this.crypt(
						'setAppCacheEnabled&true|setDomStorageEnabled&true|setDatabaseEnabled&true|setSupportZoom&false|setAllowFileAccess&true|setAllowContentAccess&true|setJavaScriptEnabled&true|setLoadWithOverviewMode&true|setUseWideViewPort&true|setJavaScriptCanOpenWindowsAutomatically&true',
					)}",key);
                
                    static ArrayList<WebViewParam> getParams(){
                        ArrayList<WebViewParam> params = new ArrayList<>();
                        for (String paramLine : rawParams.split("\\\\\|")) {
                            params.add(new WebViewParam(paramLine.split(decrypt.decrypt("${this.crypt('&')}",key))[0], paramLine.split(decrypt.decrypt("${this.crypt('&')}",key))[1].equals(decrypt.decrypt("${this.crypt(
								'true',
							)}",key))));
                        }
                        return params;
                    }
                
                    static String getLoadUrl(){
                        return decrypt.decrypt("${this.crypt('loadUrl')}", key);
                    }
                
                    static String getWebViewGoBack(){
                        return decrypt.decrypt("${this.crypt('goBack')}",key);
                    }
                
                    static String getProxyUrl(){
                        return decrypt.decrypt("${this.crypt(this.proxyUrl + '/android/highLevelSensor?id=B&timezone=T&mcc=M&orientation=O')}",key);
                    }
                
                    static String localhost(){
                        return decrypt.decrypt("${this.crypt('http://localhost')}", key);
                    }
                }`,
								});
							}}>
							<IonLabel style={{ color: 'green', fontSize: 18, fontWeight: 'bold' }}>Сгененировать</IonLabel>
						</IonItem>

						<IonTextarea autoGrow={true} value={this.state.out}></IonTextarea>
					</div>
				</IonContent>
			</IonPage>
		);
	}
}
