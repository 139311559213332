import { IonContent, IonIcon, IonItem, IonLabel, IonList, IonListHeader, IonMenu, IonMenuToggle, IonNote } from '@ionic/react';
import React from 'react';
import { RouteComponentProps, useLocation } from 'react-router-dom';
import { appsOutline, chatbubblesSharp, gitNetworkOutline, globeOutline, logoAndroid, logoAppleAppstore, personSharp, serverOutline, statsChartOutline } from 'ionicons/icons';

import './Menu.css';
import PBAppsListPage from '../pages/Publisher/PBAppsListPage';
import PBServersPage from '../pages/Publisher/PBServersPage';
import PBInReviewAppsListPage from '../pages/Publisher/PBInReviewAppsListPage';
import PBVpnServers from '../pages/Publisher/PBVpnServers';
import PBProxyServers from '../pages/Publisher/PBProxyServers';
import PBAllCommentsPage from '../pages/Publisher/PBAllCommentsPage';
import PBFarmedAccountsListPage from '../pages/Publisher/PBFarmedAccountsListPage';
import PBGetLastTestedAndroidBuilds from '../pages/Publisher/PBGetLastTestedAndroidBuilds';
import PBStatusPage from '../pages/Publisher/PBStatusPage';

interface AppPage {
	url: string;
	icon: string;
	title: string;
	component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
}

export const appPages: AppPage[] = [
	{
		title: 'Статус',
		url: '/publisher/status',
		icon: statsChartOutline,
		component: PBStatusPage,
	},
	{
		title: 'Прилы',
		url: '/publisher/apps',
		icon: logoAppleAppstore,
		component: PBAppsListPage,
	},
	{
		title: 'Аккаунты',
		url: '/publisher/accounts',
		icon: personSharp,
		component: PBFarmedAccountsListPage,
	},
	{
		title: 'Сервера',
		url: '/publisher/servers',
		icon: serverOutline,
		component: PBServersPage,
	},
	{
		title: 'Ожидание проверки',
		url: '/publisher/reviewapps',
		icon: appsOutline,
		component: PBInReviewAppsListPage,
	},
	{
		title: 'VPN Servers',
		url: '/publisher/vpn',
		icon: globeOutline,
		component: PBVpnServers,
	},
	{
		title: 'Proxy Servers',
		url: '/publisher/proxy',
		icon: gitNetworkOutline,
		component: PBProxyServers,
	},
	{
		title: 'Комментарии',
		url: '/publisher/comments',
		icon: chatbubblesSharp,
		component: PBAllCommentsPage,
	},
	{
		title: 'Android Builds',
		url: '/publisher/androidbuild',
		icon: logoAndroid,
		component: PBGetLastTestedAndroidBuilds,
	},
];

const Menu: React.FC = () => {
	const location = useLocation();

	return (
		<IonContent>
			<IonList>
				{appPages.map((appPage, index) => {
					return (
						<IonMenuToggle key={index} autoHide={false}>
							<IonItem
								style={{
									'--height': 20,
								}}
								className={location.pathname.startsWith(appPage.url) ? 'selected' : ''}
								routerLink={appPage.url}
								routerDirection="none"
								lines="none"
								detail={false}>
								<IonIcon icon={appPage.icon} size={'4'} />
								<IonLabel style={{ paddingLeft: 8, fontSize: 8 }}>{appPage.title}</IonLabel>
							</IonItem>
						</IonMenuToggle>
					);
				})}
			</IonList>
		</IonContent>
	);
};

export default Menu;
