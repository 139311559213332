import {
	IonPage,
	IonHeader,
	IonToolbar,
	IonButtons,
	IonTitle,
	IonContent,
	IonList,
	IonButton,
	IonItem,
	IonLabel,
	IonBackButton,
	IonInput,
	IonRadioGroup,
	IonRadio,
	IonMenuButton,
	IonItemDivider,
	IonDatetime,
	IonPopover,
	IonIcon,
} from '@ionic/react';
import { calendarSharp } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import API from '../../API';

interface Props
	extends RouteComponentProps<{
		id: string;
	}> {}

const ReportsPage: React.FC = () => {
	const [logs, setLogs] = useState([]);
	const [date, setDate] = useState(new Date().toJSON().split('T')[0]);

	const [popoverVisible, setPopoverVisible] = useState(false);

	const [load, setLoad] = useState(1);

	useEffect(() => {
		API.sharedGetReports(date).then(({ data }) => {
			setLogs(data);
		});
	}, [load]);

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonButtons slot="start">
						<IonMenuButton />
					</IonButtons>
					<IonTitle>Отчеты</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent>
				<div
					style={{
						display: 'flex',
					}}>
					<div style={{ flex: 1 }}></div>
					<IonButtons>
						<IonButton onClick={() => setPopoverVisible(true)} id="open-popover-add-holiday">
							<IonIcon icon={calendarSharp}></IonIcon>
						</IonButton>
					</IonButtons>
					<IonInput style={{ maxWidth: 100 }} value={date} disabled={true}></IonInput>
					<IonPopover
						onIonPopoverDidDismiss={() => {
							setPopoverVisible(false);
						}}
						isOpen={popoverVisible}
						trigger="open-popover-add-holiday"
						showBackdrop={true}>
						<IonDatetime
							presentation="date"
							value={date}
							onIonChange={(ev) => {
								console.log(ev.detail.value);
								setDate(ev.detail.value! as string);
								setPopoverVisible(false);
								setLoad(load + 1);
							}}></IonDatetime>
					</IonPopover>
					<IonButton onClick={() => setLoad(load + 1)}>Поиск</IonButton>
				</div>

				<div className="ion-padding">
					{logs.length == 0 ? (
						<p>Нет данных для отображения</p>
					) : (
						<>
							<div style={{ display: 'flex' }}>
								<p style={{ flex: 1, fontWeight: 800 }}>ID лога</p>
								<p style={{ flex: 2, fontWeight: 800 }}>Сотрудник</p>
								<p style={{ flex: 1, fontWeight: 800 }}>Время работы</p>
								<p style={{ flex: 1, fontWeight: 800 }}>Режим</p>
								<p style={{ flex: 2, fontWeight: 800 }}>Отчет</p>
							</div>
							<div style={{ width: '100%', height: 1, backgroundColor: '#3e3e3e' }}></div>
							{logs.map((log: any) => {
								return (
									<div>
										<div key={'log_' + log.id} style={{ display: 'flex' }}>
											<p style={{ flex: 1 }}>{log.id}</p>
											<p style={{ flex: 2 }}>{log.user.name}</p>
											<p style={{ flex: 1 }}>{log.work_duration}</p>
											<p style={{ flex: 1 }}>{log.work_mode}</p>
											<div style={{ flex: 2 }}>
												{log.report?.split('\n').map((line: string) => {
													return <p style={{ padding: 0, margin: 0 }}>{line}</p>;
												})}
											</div>
										</div>
										<div style={{ width: '100%', height: 1, backgroundColor: '#3e3e3e' }}></div>
									</div>
								);
							})}
						</>
					)}
				</div>
			</IonContent>
		</IonPage>
	);
};

export default ReportsPage;
