import { IonContent, IonIcon, IonItem, IonLabel, IonList, IonListHeader, IonMenu, IonMenuToggle, IonNote } from '@ionic/react';
import React from 'react';
import { RouteComponentProps, useLocation } from 'react-router-dom';
import { bagHandle, cashOutline, personAddOutline, personOutline, timeOutline, walletOutline } from 'ionicons/icons';

import './Menu.css';
import MBCampaingsPage from '../pages/MediaBuyer/MBCampaingsPage';
import ManagerTransactionsPage from '../pages/Manager/ManagerTransactionsPage';
import ManagerBanksPage from '../pages/Manager/ManagerBanksPage';
import ManagerWorkTimeTable from '../pages/Manager/ManagerWorkTimeTable';
import ManagerVerificationPage from '../pages/Manager/ManagerVerificationPage';

interface AppPage {
	url: string;
	icon: string;
	title: string;
	component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
}

export const appPages: AppPage[] = [
	{
		title: 'Выплаты',
		url: '/tranasctions',
		icon: walletOutline,
		component: ManagerTransactionsPage,
	},
	{
		title: 'Бухгалтерия',
		url: '/accounting',
		icon: cashOutline,
		component: ManagerBanksPage,
	},
	{
		title: 'Табель',
		url: '/tabel',
		icon: timeOutline,
		component: ManagerWorkTimeTable,
	},
	{
		title: 'Сотрудники',
		url: '/emplouers',
		icon: personAddOutline,
		component: ManagerVerificationPage,
	},
];

const Menu: React.FC = () => {
	const location = useLocation();

	return (
		<IonContent>
			<IonList id="inbox-list">
				{appPages.map((appPage, index) => {
					return (
						<IonMenuToggle key={index} autoHide={false}>
							<IonItem
								style={{
									'--height': 20,
								}}
								className={location.pathname.startsWith(appPage.url) ? 'selected' : ''}
								routerLink={appPage.url}
								routerDirection="none"
								lines="none"
								detail={false}>
								<IonIcon icon={appPage.icon} size={'4'} />
								<IonLabel style={{ paddingLeft: 8, fontSize: 8 }}>{appPage.title}</IonLabel>
							</IonItem>
						</IonMenuToggle>
					);
				})}
			</IonList>
		</IonContent>
	);
};

export default Menu;
