import { IonContent, IonIcon, IonItem, IonLabel, IonList, IonMenuToggle } from '@ionic/react';
import React from 'react';
import { RouteComponentProps, useLocation } from 'react-router-dom';
import { appsOutline, gitNetworkSharp, logoAndroid, personRemoveSharp } from 'ionicons/icons';

import './Menu.css';
import PUSHEROffersPage from '../pages/Pusher/PusherUsersPage';
import TesterAppListPage from '../pages/Tester/TesterAppListPage';
import TesterChooseBotsPage from '../pages/Tester/TesterChooseBotsPage';
import TesterMaksedIPPage from '../pages/Tester/TesterMaksedIPPage';
import TesterGetLastAndroidBuilds from '../pages/Tester/TesterGetLastAndroidBuilds';

interface AppPage {
	url: string;
	icon: string;
	title: string;
	component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
}

export const appPages: AppPage[] = [
	{
		title: 'Прилы',
		url: '/tester/apps',
		icon: appsOutline,
		component: TesterAppListPage,
	},
	{
		title: 'Боты',
		url: '/tester/bots',
		icon: personRemoveSharp,
		component: TesterChooseBotsPage,
	},
	{
		title: 'Masked IP',
		url: '/tester/maskedip',
		icon: gitNetworkSharp,
		component: TesterMaksedIPPage,
	},
	{
		title: 'Android builds',
		url: '/tester/androidbuilds',
		icon: logoAndroid,
		component: TesterGetLastAndroidBuilds,
	},
];

const TesterMenu: React.FC = () => {
	const location = useLocation();

	return (
		<IonContent>
			<IonList id="inbox-list">
				{appPages.map((appPage, index) => {
					return (
						<IonMenuToggle key={index} autoHide={false}>
							<IonItem
								style={{
									'--height': 20,
								}}
								className={location.pathname.startsWith(appPage.url) ? 'selected' : ''}
								routerLink={appPage.url}
								routerDirection="none"
								lines="none"
								detail={false}>
								<IonIcon icon={appPage.icon} size={'4'} />
								<IonLabel style={{ paddingLeft: 8, fontSize: 8 }}>{appPage.title}</IonLabel>
							</IonItem>
						</IonMenuToggle>
					);
				})}
			</IonList>
		</IonContent>
	);
};

export default TesterMenu;
