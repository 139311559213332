import { IonCol, IonItem, IonLabel, IonList, IonRow } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import AnimateHeight from 'react-animate-height';

import API from '../../API';

const AccountingExpenditure: React.FC<{
	dateRange: {
		fromDate: string;
		toDate: string;
	};
	out: any;
	selectedUserId: number | null;
}> = ({ dateRange, out, selectedUserId }) => {
	const [showTransactions, setShowTransactions] = useState(false);
	const [transactions, setTransactions] = useState([]);

	useEffect(() => {
		if (!showTransactions) {
			setTransactions([]);
			return;
		}

		API.getDetailedOutcomeTransactions(dateRange.fromDate.split('T')[0] + ' 00:00:00', dateRange.toDate.split('T')[0] + ' 23:59:59', out.ae.id, selectedUserId).then(({ data }) => {
			setTransactions(data);
		});
	}, [showTransactions]);

	return (
		<>
			<tr
				style={{
					display: 'flex',
				}}>
				<td key={'accounting_out_' + out.ae.id + '_title'} style={{ flex: 1 }}>
					<p style={{ fontWeight: 800 }}>{out.ae.title}</p>
				</td>
				{out.currencies.map((currency: any) => {
					return (
						<td key={'accounting_out_' + out.ae.id + '_' + currency.currency} style={{ flex: 1 }}>
							<p>{currency.amount}</p>
						</td>
					);
				})}
				<td
					onClick={() => {
						setShowTransactions(!showTransactions);
					}}
					style={{ width: 100 }}>
					<p style={{ color: '#3880ff' }}>{showTransactions ? 'Скрыть' : 'Подробно'}</p>
				</td>
			</tr>
			<AnimateHeight height={showTransactions ? 'auto' : 0}>
				<tr style={{ display: 'flex', background: 'rgb(199 199 199)' }}>
					<td style={{ flex: 1 }}>
						<p style={{ fontSize: 10 }}>ID</p>
					</td>
					<td style={{ flex: 1 }}>
						<p style={{ fontSize: 10 }}>Сумма</p>
					</td>
					<td style={{ flex: 1 }}>
						<p style={{ fontSize: 10 }}>Работник</p>
					</td>
					<td style={{ flex: 1 }}>
						<p
							style={{
								fontSize: 10,
							}}>
							Комментарий
						</p>
					</td>
					<td style={{ width: 100 }}>
						<p style={{ fontSize: 10 }}>Дата</p>
					</td>
				</tr>
				{transactions.map((t: any) => (
					<tr style={{ display: 'flex', background: 'rgb(199 199 199)' }}>
						<td style={{ flex: 1 }}>
							<p style={{ fontSize: 10 }}>#{t.id}</p>
						</td>
						<td style={{ flex: 1 }}>
							<p style={{ fontSize: 10 }}>
								{t.summ} {t.currency.toUpperCase()}
							</p>
						</td>
						<td style={{ flex: 1 }}>
							<p style={{ fontSize: 10 }}>{t.name}</p>
						</td>
						<td style={{ flex: 1 }}>
							<p
								style={{
									fontSize: 10,
									whiteSpace: 'break-spaces',
								}}>
								{t.comment}
							</p>
						</td>
						<td style={{ width: 100 }}>
							<p style={{ fontSize: 10 }}>{new Date(t.created).toLocaleString()}</p>
						</td>
					</tr>
				))}
			</AnimateHeight>
		</>
	);
};

export default AccountingExpenditure;
