import { IonPage, IonHeader, IonToolbar, IonButtons, IonMenuButton, IonTitle, IonContent, IonFooter, IonList, IonLabel, IonItem, IonAlert, IonButton } from '@ionic/react';
import React from 'react';
import Pagination from 'react-js-pagination';

import API from '../../../API';
import LoadingFullScreen from '../../../components/LoadingFullScreen';

const PAGE_OFFSET = 400;

export default class extends React.Component {
	state = {
		page: 1,
		transactions: [],
		totalCount: 0,
		isLoading: false,
		showAddTransaction: false,
	};

	componentDidMount() {
		this.getTransactions();
	}

	async getTransactions() {
		const res = await API.adminGetAllTransactions(PAGE_OFFSET, this.state.page - 1);
		console.log(res);
		if (res.success) {
			this.setState({
				transactions: res.data.transactions,
				totalCount: res.data.totalCount,
			});
		}
	}

	render() {
		return (
			<IonPage>
				<IonHeader>
					<IonToolbar>
						<IonButtons slot="start">
							<IonMenuButton />
						</IonButtons>
						<IonTitle>Внутренняя бухгалтерия</IonTitle>
						<IonButtons slot="end">
							<IonButtons>
								<IonButton
									onClick={() => {
										this.setState({
											showAddTransaction: true,
										});
									}}>
									Добавить транзакцию
								</IonButton>
							</IonButtons>
						</IonButtons>
					</IonToolbar>
				</IonHeader>
				<IonContent>
					<IonAlert
						onDidDismiss={() => {
							this.setState({
								showAddTransaction: false,
							});
						}}
						isOpen={this.state.showAddTransaction}
						header="Добавить транзакцию"
						inputs={[
							{
								name: 'userId',
								type: 'number',
								placeholder: 'ID пользователя',
							},
							{
								name: 'amount',
								type: 'number',
								placeholder: 'Сумма',
							},
							{
								name: 'comment',
								type: 'textarea',
								placeholder: 'Коммент',
							},
						]}
						buttons={[
							'Отмена',
							{
								text: 'Добавить',
								handler: async (e) => {
									console.log(e);

									await API.adminCreateTransaction(e.userId, e.amount, e.comment, false);
									this.getTransactions();
								},
							},
						]}></IonAlert>
					{this.state.transactions == null ? (
						<LoadingFullScreen />
					) : (
						<IonList>
							{this.state.transactions.map((transaction: any) => (
								<IonItem routerLink={'/employees/' + transaction.user_id} key={'transaction_' + transaction.id}>
									<IonLabel>
										<h3>
											№{transaction.id} | {transaction.user_name} | {transaction.amount} RUB
										</h3>
										<p>{transaction.comment ?? 'Без описания'}</p>
										<p>{transaction.is_hold ? 'В холде' : 'Не в холде'}</p>
										<p>{new Date(transaction.created).toLocaleString()}</p>
									</IonLabel>
								</IonItem>
							))}
						</IonList>
					)}
				</IonContent>
				<IonFooter>
					<IonToolbar style={{ textAlign: 'center' }}>
						<Pagination
							activePage={this.state.page}
							itemsCountPerPage={PAGE_OFFSET}
							totalItemsCount={this.state.totalCount}
							pageRangeDisplayed={10}
							onChange={(data) => {
								this.state.page = data;
								this.setState({
									page: data,
								});
								this.getTransactions();
							}}
						/>
					</IonToolbar>
				</IonFooter>
			</IonPage>
		);
	}
}
