import { IonPage, IonHeader, IonToolbar, IonButtons, IonMenuButton, IonTitle, IonContent } from '@ionic/react';
import React from 'react';

import API from '../../API';
import BrandsStatisitcsComponent from '../../components/BrandsStatisitcsComponent';
import LoadingFullScreen from '../../components/LoadingFullScreen';

export default class extends React.Component {
	state: any = {
		brands: null,
	};

	componentDidMount() {
		this.getStatistics();
	}

	componentDidCatch() {}

	async getStatistics() {
		const res = await API.getBrandsStatistics();
		console.log(res);

		this.setState({
			brands: res.data,
		});
	}

	render() {
		return (
			<IonPage>
				<IonHeader>
					<IonToolbar>
						<IonButtons slot="start">
							<IonMenuButton />
						</IonButtons>
						<IonTitle>Бренды</IonTitle>
					</IonToolbar>
				</IonHeader>
				<IonContent>{this.state.brands == null ? <LoadingFullScreen /> : <BrandsStatisitcsComponent brands={this.state.brands} />}</IonContent>
			</IonPage>
		);
	}
}
