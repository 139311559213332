import {
	IonPage,
	IonHeader,
	IonToolbar,
	IonButtons,
	IonTitle,
	IonContent,
	IonList,
	IonBackButton,
	IonItem,
	IonLabel,
	IonInput,
	IonButton,
	IonItemDivider,
	IonSegment,
	IonSegmentButton,
	IonIcon,
	IonSelect,
	IonSelectOption,
	IonCheckbox,
} from '@ionic/react';
import { logoAndroid, logoApple } from 'ionicons/icons';
import React, { RefObject } from 'react';

import API from '../../API';

function getBase64(file: any) {
	return new Promise<string>((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result as string);
		reader.onerror = (error) => reject(error);
	});
}

export default class extends React.Component {
	imagePickerRef: RefObject<HTMLInputElement>;

	state: any = {
		p12: '',
		appName: '',
		bundleId: '',
		gcm_sender_id: '',
		gcm_key: '',
		os: 'ios',
		brandId: null,
		storeId: '',

		brands: Array<any>(),
		stores: Array<any>(),

		developers: Array<any>(),
		designers: Array<any>(),
		proxyServers: Array<any>(),
		accounts: Array<any>(),

		developer_id: null,
		designer_id: null,
		integrator_id: null,
		proxy_server_id: null,
		farmed_account_id: null,

		is_rent_app: false,
	};

	constructor(props: any) {
		super(props);
		this.imagePickerRef = React.createRef();
	}

	async onP12Choosed(e: any) {
		if (e.target.files.length == 0) {
			return;
		}

		let base64 = await getBase64(e.target.files[0]);
		base64 = base64.split('base64,')[1];

		this.setState({
			p12: base64,
		});
	}

	componentDidMount() {
		API.publisherGetBrands().then((res) =>
			this.setState({
				brands: res.data,
			}),
		);
		API.publisherGetStores().then((res) =>
			this.setState({
				stores: res.data,
			}),
		);
		API.PBGetReferences().then((res) => {
			this.setState({
				developers: res.data.developers,
				designers: res.data.designers,
				proxyServers: res.data.proxyServers,
				accounts: res.data.accounts,
			});
		});
	}

	render() {
		return (
			<IonPage>
				<input ref={this.imagePickerRef} style={{ display: 'none' }} type="file" accept="p12" onChange={this.onP12Choosed.bind(this)} />
				<IonHeader>
					<IonToolbar>
						<IonButtons slot="start">
							<IonBackButton defaultHref="/publisher/apps"></IonBackButton>
						</IonButtons>
						<IonTitle>Создать прилу</IonTitle>
					</IonToolbar>
				</IonHeader>
				<IonContent>
					<IonList>
						<IonSegment
							value={this.state.os}
							onIonChange={(e) => {
								this.setState({
									os: e.detail.value!,
								});
								if (e.detail.value == 'ios') {
									this.state.store = 'App Store';
								}
							}}>
							<IonSegmentButton value="ios">
								<IonIcon icon={logoApple} />
								<IonLabel>iOS</IonLabel>
							</IonSegmentButton>
							<IonSegmentButton value="android">
								<IonIcon icon={logoAndroid} />
								<IonLabel>Android</IonLabel>
							</IonSegmentButton>
						</IonSegment>
						<IonItem>
							<IonInput
								placeholder="Название приложения"
								onIonChange={(e) => {
									this.setState({
										appName: e.detail.value!,
									});
								}}
								clearInput></IonInput>
						</IonItem>
						<IonItem>
							<IonLabel>Бренд</IonLabel>
							<IonSelect
								interface="popover"
								value={this.state.brandId}
								placeholder="Выберите бренд"
								onIonChange={(e) => {
									this.state.brandId = e.detail.value;
								}}>
								{this.state.brands.map((b: any) => {
									return (
										<IonSelectOption key={'brand_select_option_' + b.id} value={b.id.toString()}>
											{b.title}
										</IonSelectOption>
									);
								})}
							</IonSelect>
						</IonItem>
						<IonItem>
							<IonLabel>Стор</IonLabel>
							<IonSelect
								interface="popover"
								value={this.state.storeId}
								placeholder="Выберите стор"
								onIonChange={(e) => {
									this.setState({
										storeId: e.detail.value,
									});
								}}>
								{this.state.stores.map((s: any) => {
									return (
										<IonSelectOption key={'store_id_' + s.id} value={s.id.toString()}>
											{s.title}
										</IonSelectOption>
									);
								})}
							</IonSelect>
						</IonItem>
						<IonItem>
							<IonInput
								placeholder="Bundle ID"
								onIonChange={(e) => {
									this.setState({
										bundleId: e.detail.value!,
									});
								}}
								clearInput></IonInput>
						</IonItem>
						{this.state.os == 'ios' ? (
							<IonItem>
								<IonLabel>
									<h2>P12</h2>
									<p>{this.state.p12.length == 0 ? 'Файл не выбран' : 'P12 выбран'}</p>
								</IonLabel>

								<IonButton
									onClick={() => {
										this.imagePickerRef.current?.click();
									}}
									slot="end">
									Выбрать файл
								</IonButton>
							</IonItem>
						) : (
							<>
								<IonItem>
									<IonInput
										placeholder="GCM Sender ID"
										onIonChange={(e) => {
											this.setState({
												gcm_sender_id: e.detail.value!,
											});
										}}
										clearInput></IonInput>
								</IonItem>
								<IonItem>
									<IonInput
										placeholder="GCM Key"
										onIonChange={(e) => {
											this.setState({
												gcm_key: e.detail.value!,
											});
										}}
										clearInput></IonInput>
								</IonItem>
							</>
						)}

						<IonItem>
							<IonLabel>Разработчик</IonLabel>
							<IonSelect
								interface="popover"
								value={this.state.developer_id}
								placeholder="Разработчик"
								onIonChange={(e) => {
									this.setState({
										developer_id: e.detail.value,
									});
								}}>
								{this.state.developers.map((e: any) => {
									return (
										<IonSelectOption key={'developer_' + e.id} value={e.id}>
											{e.name}
										</IonSelectOption>
									);
								})}
							</IonSelect>
						</IonItem>
						<IonItem>
							<IonLabel>Интегратор клоаки</IonLabel>
							<IonSelect
								interface="popover"
								value={this.state.integrator_id}
								placeholder="Интегратор клоаки"
								onIonChange={(e) => {
									this.setState({
										integrator_id: e.detail.value,
									});
								}}>
								{this.state.developers.map((e: any) => {
									return (
										<IonSelectOption key={'developer_' + e.id} value={e.id}>
											{e.name}
										</IonSelectOption>
									);
								})}
							</IonSelect>
						</IonItem>
						<IonItem>
							<IonLabel>Дизайнер</IonLabel>
							<IonSelect
								interface="popover"
								value={this.state.designer_id}
								placeholder="Дизайнер"
								onIonChange={(e) => {
									this.setState({
										designer_id: e.detail.value,
									});
								}}>
								{this.state.designers.map((e: any) => {
									return (
										<IonSelectOption key={'developer_' + e.id} value={e.id}>
											{e.name}
										</IonSelectOption>
									);
								})}
							</IonSelect>
						</IonItem>
						<IonItem>
							<IonLabel>Прокси сервер</IonLabel>
							<IonSelect
								interface="action-sheet"
								value={this.state.proxy_server_id}
								placeholder="Proxy server"
								onIonChange={(e) => {
									this.setState({
										proxy_server_id: e.detail.value,
									});
								}}>
								{this.state.proxyServers.map((e: any) => {
									return (
										<IonSelectOption style={{ width: '400px !important' }} key={'proxy_server_' + e.id} value={e.id}>
											{e.connected_domains.split(',').reverse()[0]} {e.ip}
										</IonSelectOption>
									);
								})}
							</IonSelect>
						</IonItem>

						<IonItem>
							<IonLabel>Аккаунт</IonLabel>
							<IonSelect
								interface="action-sheet"
								value={this.state.farmed_account_id}
								placeholder="Это удаленный фармер"
								onIonChange={(e) => {
									this.setState({
										farmed_account_id: e.detail.value,
									});
								}}>
								{this.state.accounts
									.filter((e: any) => e.store_id == this.state.storeId)
									.map((e: any) => {
										return (
											<IonSelectOption style={{ width: '400px !important' }} key={'account_farm_' + e.id} value={e.id}>
												<IonLabel>
													[{e.id}] {e.login} {e.is_remote ? 'Удаленщик' : 'Саморег'} {new Date(e.farmed_at).toLocaleDateString()}
												</IonLabel>
											</IonSelectOption>
										);
									})}
							</IonSelect>
						</IonItem>
						<IonItem>
							<IonLabel>Это прила под аренду?</IonLabel>
							<IonCheckbox
								value={this.state.is_rent_app}
								onIonChange={(e) => {
									this.setState({
										is_rent_app: e.detail.checked,
									});
								}}
							/>
						</IonItem>

						<IonItemDivider>Внимание! Удостовертесь в правильности написания bundle id и названия</IonItemDivider>
						{(this.state.os == 'ios' ? this.state.p12.length != 0 : true) &&
						this.state.appName.length != 0 &&
						this.state.bundleId.length != 0 &&
						this.state.developer_id &&
						this.state.designer_id ? (
							<IonItem>
								<IonLabel>
									<h2>Добавление приложения</h2>
								</IonLabel>

								<IonButton
									onClick={async () => {
										const res = await API.PBCreateApp(
											this.state.appName,
											this.state.brandId,
											this.state.p12,
											this.state.bundleId,
											this.state.gcm_key,
											this.state.gcm_sender_id,
											this.state.os,
											this.state.storeId,
											this.state.developer_id,
											this.state.designer_id,
											this.state.integrator_id,
											this.state.proxy_server_id,
											this.state.farmed_account_id,
											this.state.is_rent_app,
										);
										if (res.success) {
											window.location.href = '/publisher/apps/' + res.data.id;
										} else {
											alert('Ошибка создания приложения.');
										}
									}}
									slot="end">
									Добавить прилу
								</IonButton>
							</IonItem>
						) : null}
					</IonList>
				</IonContent>
			</IonPage>
		);
	}
}
