import { IonPage, IonHeader, IonToolbar, IonButtons, IonTitle, IonContent, IonButton, IonInput, IonMenuButton, IonDatetime, IonPopover, IonIcon, IonItem, IonLabel } from '@ionic/react';
import { calendarSharp } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import API from '../../API';

const PBStatusPage: React.FC = () => {
	const [status, setStatus] = useState({
		createdApps: 0,
		minApps: 4,
		minUpdates: 3,
		revenue: 0,
		updatesCount: 0,
	});

	useEffect(() => {
		API.PBGetStatus().then(({ data }) => {
			setStatus(data);
		});
	}, []);

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonButtons slot="start">
						<IonMenuButton />
					</IonButtons>
					<IonTitle>Статус</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent>
				<div className="ion-padding">
					<IonItem>
						<IonLabel>
							<h2>Создано приложений: {status.createdApps}</h2>
							<p>Минимальный план: {status.minApps}</p>
						</IonLabel>
					</IonItem>
					<IonItem>
						<IonLabel>
							<h2>Обновлено приложений: {status.updatesCount}</h2>
							<p>Минимальный план: {status.minUpdates}</p>
						</IonLabel>
					</IonItem>

					<h2>Заработок: {status.revenue} руб</h2>
				</div>
			</IonContent>
		</IonPage>
	);
};

export default PBStatusPage;
