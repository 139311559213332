import { IonPage, IonHeader, IonToolbar, IonButtons, IonMenuButton, IonTitle, IonContent, IonList, IonButton, IonItem, IonLabel, IonToast, IonTextarea } from '@ionic/react';
import React from 'react';

import API from '../../API';
import LoadingFullScreen from '../../components/LoadingFullScreen';

export default class extends React.Component {
	state = {
		link: '',
	};
	data: any = null;

	componentDidMount() {
		this.getServers();
	}

	async getServers() {
		const res = await API.developerGetServersList();
		if (res.success) {
			this.setState({
				servers: res.data,
			});
		}
	}

	render() {
		return (
			<IonPage>
				<IonHeader>
					<IonToolbar>
						<IonButtons slot="start">
							<IonMenuButton />
						</IonButtons>
						<IonTitle>Метрики</IonTitle>
					</IonToolbar>
				</IonHeader>
				<IonContent>
					<pre>
						<IonTextarea onIonChange={(e) => (this.data = e.detail.value)}></IonTextarea>
					</pre>

					<IonButton
						onClick={async () => {
							const res = await API.developerHideData(JSON.parse(this.data));
							if (res.success) {
								this.setState({
									link: res.data,
								});
							}
						}}>
						Сгенерировать
					</IonButton>

					<br />
					<br />
					<p>{this.state.link}</p>
				</IonContent>
			</IonPage>
		);
	}
}
