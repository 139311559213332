import { IonPage, IonHeader, IonToolbar, IonButtons, IonMenuButton, IonTitle, IonContent, IonCard, IonList, IonButton, IonIcon, IonSpinner } from '@ionic/react';
import { chevronDown, chevronUp } from 'ionicons/icons';
import React from 'react';
import AnimateHeight from 'react-animate-height';

import API from '../../API';
import LoadingFullScreen from '../../components/LoadingFullScreen';

export default class extends React.Component {
	state: any = {
		apps: [],
	};

	componentDidMount() {
		this.getStatistics();
	}

	componentDidCatch() {}

	async getStatistics() {
		API.adminGetAppsKpi().then((res) => {
			this.setState({
				apps: res.data,
			});
		});
	}

	async getEmployerTransactions(balance: any) {
		const res = await API.adminGetAllTransactions(200, 0, {
			user_id: balance.id,
		});
		balance.transactions = res.data.transactions;
		balance.loading = false;

		this.setState({
			balances: this.state.balances,
		});
	}

	render() {
		return (
			<IonPage>
				<IonHeader>
					<IonToolbar>
						<IonButtons slot="start">
							<IonMenuButton />
						</IonButtons>
						<IonTitle>KPI Бонусы</IonTitle>
					</IonToolbar>
				</IonHeader>
				<IonContent>
					{this.state.apps != null ? (
						this.state.apps.map((app: any) => {
							return (
								<IonCard key={'kpi_app_' + app.id}>
									<div
										style={{
											display: 'flex',
											alignItems: 'center',
										}}>
										<div style={{ flex: 3 }}>
											<p
												style={{
													paddingLeft: 16,
													color: 'black',
													marginBottom: 0,
													fontSize: 14,
													fontWeight: 400,
													paddingTop: 8,
													paddingBottom: 8,
												}}>
												[{app.id}] {app.title}
											</p>
											<div style={{ paddingLeft: 8 }}>
												<p
													style={{
														padding: 8,
														fontSize: 15,
														fontWeight: 600,
														backgroundColor: '#3e3e3e',
														borderRadius: 10,
													}}>
													<span style={{ color: app.is_in_store ? 'green' : 'red' }}>В сторе: {app.is_in_store ? 'да' : 'нет'}</span>
													&nbsp;&nbsp;
													<span
														style={{
															color: app.income > 350 ? 'green' : 'orange',
														}}>
														{app.income > 350 ? 'Холд: нет Сумма 350$ достигнута' : 'Холд: да Сумма не 350$ достигнута'}
													</span>
												</p>
											</div>
										</div>
										<div style={{ flex: 1 }}></div>

										<IonButtons style={{ flex: 1 }}>
											<IonButton routerLink={'/apps/' + app.id}>Перейти в приложение</IonButton>
										</IonButtons>
										<div style={{ flex: 1 }}></div>
										<div
											style={{
												display: 'flex',
												alignItems: 'center',
												paddingLeft: 16,
												flex: 1,
											}}
											onClick={() => {
												if (app.isOpen) {
													app.isOpen = false;
												} else {
													app.isOpen = true;
												}
												this.setState({
													apps: this.state.apps,
												});

												API.adminGetTransactionsByApp(app.id).then((res) => {
													app.transactions = res.data;
													this.setState({
														apps: this.state.apps,
													});
												});
											}}>
											&nbsp;&nbsp;&nbsp;
											<IonIcon icon={app.isOpen ? chevronUp : chevronDown} color="primary"></IonIcon>
											<p
												style={{
													paddingLeft: 4,
													color: '#3880ff',
													marginBottom: 0,
													paddingRight: 16,
												}}>
												{app.isOpen ? 'Скрыть' : 'Показать'}
											</p>
											&nbsp;&nbsp;&nbsp;
										</div>
									</div>
									<AnimateHeight duration={100} height={app.isOpen ? 'auto' : 0}>
										{app.transactions ? (
											<>
												<IonList>
													{app.transactions.map((tr: any) => {
														return (
															<div
																style={{
																	display: 'flex',
																	paddingLeft: 16,
																	paddingRight: 16,
																}}>
																<p
																	style={{
																		flex: 1,
																		color: tr.user ? 'black' : 'red',
																	}}>
																	{tr.user ? `[${tr.user.id}] ${tr.user.name}` : `Получатель денег не указан`}
																</p>
																<p
																	style={{
																		flex: 1,
																		color: tr.error ? 'red' : 'black',
																	}}>
																	{tr.comment}
																</p>
																<p
																	style={{
																		flex: 1,
																		textAlign: 'end',
																		color: 'green',
																	}}>
																	Выплата: {tr.amount} руб
																</p>
															</div>
														);
													})}

													<div
														style={{
															display: 'flex',
															paddingLeft: 16,
															paddingRight: 16,
														}}>
														<p
															style={{
																flex: 1,
																textAlign: 'end',
																paddingTop: 8,
																fontSize: 16,
																fontWeight: 400,
																color: 'black',
															}}>
															Отправить на оплату {app.transactions.map((tr: any) => tr.amount).reduce((a: number, b: number) => a + b, 0)} руб.
														</p>
														&nbsp;
														{app.isPayed ? (
															<p style={{ color: 'green' }}>ОПЛАЧЕНО</p>
														) : (
															<IonButtons style={{ flex: 0 }}>
																<IonButton
																	onClick={() => {
																		if (app.isPaying) {
																			return;
																		}
																		app.isPaying = true;
																		this.setState({
																			apps: this.state.apps,
																		});

																		API.adminPayKpiByApp(app.id, app.transactions).then((res) => {
																			app.isPayed = true;
																			app.isOpen = false;
																			this.setState({
																				apps: this.state.apps,
																			});
																		});
																	}}
																	fill="solid"
																	color="success">
																	{app.isPaying ? (
																		<>
																			<IonSpinner />
																			&nbsp; Выполняется...
																		</>
																	) : (
																		'Оплатить'
																	)}
																</IonButton>
															</IonButtons>
														)}
													</div>
												</IonList>
											</>
										) : (
											<IonSpinner />
										)}
									</AnimateHeight>
								</IonCard>
							);
						})
					) : (
						<LoadingFullScreen />
					)}
				</IonContent>
			</IonPage>
		);
	}
}
