import {
	IonPage,
	IonHeader,
	IonToolbar,
	IonButtons,
	IonTitle,
	IonContent,
	IonItem,
	IonLabel,
	IonList,
	IonBackButton,
	IonItemDivider,
	IonTextarea,
	IonCard,
	IonCardTitle,
	IonCardHeader,
	IonCardContent,
	IonModal,
	IonSpinner,
	IonRow,
	IonCol,
	IonGrid,
	IonButton,
} from '@ionic/react';
import React from 'react';
import { RouteComponentProps } from 'react-router';

import API from '../../../API';
import TypeSelectorComponent from '../../../components/TypeSelectorComponent';
import './ChooseAppKeywords.css';

interface Props
	extends RouteComponentProps<{
		appId: string;
	}> {}

export default class extends React.Component<Props> {
	state: any = {
		countries: [],
		selectedCountries: [],
		selectCountiesOpened: false,
		keywords: '',
		addingKeywords: false,
		keywordsPositions: null,
		keywordsExists: null,
		fetchingPositions: false,
		recommendedKeywords: null,
		recommendedCountries: null,
	};

	componentDidMount() {
		API.getKeywordsCountries().then((res) =>
			this.setState({
				countries: res.data,
			}),
		);
		API.getAppRecommendedKeywords(this.props.match.params.appId).then((res) =>
			this.setState({
				recommendedKeywords: res.data,
				recommendedCountries: res.countries,
			}),
		);

		this.getKeywordsPositions();
	}

	getKeywordsPositions() {
		API.getAppKeywords(this.props.match.params.appId).then((res) =>
			this.setState({
				keywordsPositions: res.data.positions,
				keywordsExists: res.data.keywords,
			}),
		);
	}

	getKeywordPosition(date: string, keywordId: any) {
		return this.state.keywordsPositions.filter((e: any) => e.created_date == date && e.keyword_id == keywordId)[0] || null;
	}

	getDays() {
		const days = [new Date()];
		const now = new Date();
		for (let index = 0; index < 10; index++) {
			days.push(new Date(now.setDate(now.getDate() - 1)));
		}

		return days.map((d: Date) => d.toJSON().split('T')[0]).reverse();
	}

	render() {
		return (
			<IonPage>
				<IonModal
					isOpen={this.state.selectCountiesOpened}
					onDidDismiss={() => {
						this.setState({ selectCountiesOpened: false });
					}}>
					<TypeSelectorComponent
						beforeSelected={this.state.selectedCountries}
						items={this.state.countries}
						didSelected={(items: any) => {
							this.setState({
								selectedCountries: items,
								selectCountiesOpened: false,
							});
						}}
						didCancelled={() => this.setState({ selectCountiesOpened: false })}
					/>
				</IonModal>
				<IonHeader>
					<IonToolbar>
						<IonButtons slot="start">
							<IonBackButton defaultHref={`/apps/${this.props.match.params.appId}`}></IonBackButton>
						</IonButtons>
						<IonTitle>Ключевые слова</IonTitle>
					</IonToolbar>
				</IonHeader>

				<IonContent>
					{this.state.keywordsPositions ? (
						<IonCard>
							<IonCardHeader>
								<IonCardTitle>Позиции по ключам</IonCardTitle>
								<IonButtons>
									<IonButton
										onClick={async () => {
											this.setState({
												fetchingPositions: true,
											});

											await API.fetchAppKeywords(this.props.match.params.appId);

											this.setState({
												fetchingPositions: false,
											});

											this.getKeywordsPositions();
										}}>
										{this.state.fetchingPositions ? <IonSpinner /> : false}Обновить
									</IonButton>
								</IonButtons>
							</IonCardHeader>
							<IonCardContent style={{ overflow: 'scroll' }}>
								<IonGrid style={{ width: '150vw' }}>
									<IonRow>
										<IonCol className="keyword-col" style={{ width: 400 }}>
											Ключевое слово
										</IonCol>
										{this.getDays().map((d: any) => {
											return (
												<IonCol key={'keyword_' + this.props.match.params.appId + '_d_' + d} className="keyword-col">
													{d}
												</IonCol>
											);
										})}
									</IonRow>
									{this.state.keywordsExists.map((keyword: any) => {
										return (
											<IonRow key={'keyword_' + this.props.match.params.appId + '_k_' + keyword.id}>
												<IonCol className="keyword-col" style={{ width: 400 }}>
													<IonLabel>
														<h2>{keyword.keyword}</h2>
														<p>{keyword.country_title}</p>
													</IonLabel>
												</IonCol>
												{this.getDays().map((d: any) => {
													return (
														<IonCol key={'keyword_' + this.props.match.params.appId + '_k_' + keyword.id + '_d_' + d} className="keyword-col" style={{ width: 100 }}>
															{this.getKeywordPosition(d, keyword.id)?.position || '-'}
														</IonCol>
													);
												})}
											</IonRow>
										);
									})}
								</IonGrid>
							</IonCardContent>
						</IonCard>
					) : (
						<div className="ion-padding">
							<IonSpinner />
						</div>
					)}
					<IonCard>
						<IonCardHeader>
							<IonCardTitle>Добавить ключевики</IonCardTitle>
						</IonCardHeader>
						<IonCardContent>
							<IonList>
								<IonItemDivider>Введите ключевики через запятую</IonItemDivider>
								<IonItem>
									<IonTextarea
										placeholder="pin up,pinup,pin-up"
										value={this.state.keywords}
										onIonChange={(e) => {
											this.setState({
												keywords: e.detail.value,
											});
										}}></IonTextarea>
								</IonItem>
								{this.state.recommendedKeywords ? (
									<IonItem
										onClick={async () => {
											console.log(this.state.recommendedCountries);

											if (this.state.recommendedCountries.length != 0) {
												const countries: Array<any> = [];

												const ids = this.state.recommendedCountries.split(',');

												for (let index = 0; index < ids.length; index++) {
													const id = ids[index];

													countries.push(this.state.countries.find((c: any) => c.id == parseInt(id)));

													console.log(countries);

													this.setState({
														selectedCountries: countries,
													});
												}
											}

											this.setState({
												keywords: this.state.recommendedKeywords,
											});
										}}
										button={true}>
										<IonLabel>Добавить рекомендуемые ключевые слова</IonLabel>
									</IonItem>
								) : (
									false
								)}
								<IonItem
									onClick={() => {
										this.setState({
											selectCountiesOpened: true,
										});
									}}
									button={true}>
									<IonLabel>
										<h2>Выберите страны</h2>
										<p>Выбрано: {this.state.selectedCountries.length}</p>
									</IonLabel>
								</IonItem>
								<IonItem
									onClick={async () => {
										if (this.state.keywords.length == 0) {
											return alert('Укажите ключевые слова');
										}
										this.setState({
											addingKeywords: true,
										});
										await API.addKeywordsToApp(
											this.props.match.params.appId,
											this.state.selectedCountries.map((c: any) => c.id),
											this.state.keywords.split(',').map((k: string) => k.trim()),
										);

										this.setState({
											keywords: '',
											addingKeywords: false,
										});
									}}
									button={true}>
									<IonLabel>Добавить ключевые слова</IonLabel>
									{this.state.addingKeywords ? <IonSpinner></IonSpinner> : false}
								</IonItem>
							</IonList>
						</IonCardContent>
					</IonCard>
				</IonContent>
			</IonPage>
		);
	}
}
