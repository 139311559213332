import { IonPage, IonHeader, IonToolbar, IonButtons, IonMenuButton, IonTitle, IonContent, IonList, IonButton, IonItem, IonLabel, IonIcon } from '@ionic/react';
import React from 'react';

import API from '../../API';
import LoadingFullScreen from '../../components/LoadingFullScreen';

export default class extends React.Component {
	state: any = {
		servers: null,
	};

	componentDidMount() {
		this.getServers();
	}

	async getServers() {
		const res = await API.publisherGetServers();
		if (res.success) {
			this.setState({
				servers: res.data,
			});
		}
	}

	render() {
		return (
			<IonPage>
				<IonHeader>
					<IonToolbar>
						<IonButtons slot="start">
							<IonMenuButton />
						</IonButtons>
						<IonTitle>Серверы</IonTitle>
					</IonToolbar>
				</IonHeader>
				<IonContent>
					{this.state.servers == null ? (
						<LoadingFullScreen />
					) : (
						<div className="ion-padding">
							{this.state.servers.length == 0 ? (
								<p>Нет доступных серверов</p>
							) : (
								<IonList>
									{this.state.servers.map((server: any) => {
										return (
											<IonItem key={'server_' + server.id}>
												<IonLabel>
													<h2
														onClick={() => {
															navigator.clipboard.writeText(server.ip);
														}}>
														IP: {server.ip}
													</h2>
													<p
														onClick={() => {
															navigator.clipboard.writeText(server.password);
														}}>
														Пароль: {server.password}
													</p>
													<p
														onClick={() => {
															navigator.clipboard.writeText(server.store_username);
														}}>
														Пользователь: {server.store_username}
													</p>
													<p>Store: {server.store_type}</p>
												</IonLabel>
												<IonButtons slot="end">
													<IonButton color="success" routerLink={'/publisher/servers/' + server.id}>
														Информация
													</IonButton>
													{server.status == 'stopped' || server.status == 'completed' ? (
														<IonButton
															onClick={async () => {
																const res = await API.publisherStartStopServer(server.id, server.status == 'stopped' ? 'start' : 'stop');
																if (res.success) {
																	this.getServers();
																}
															}}
															color={server.status == 'completed' ? 'danger' : 'success'}>
															{server.status == 'completed' ? 'Остановить' : 'Запустить'}
														</IonButton>
													) : (
														false
													)}
												</IonButtons>
											</IonItem>
										);
									})}
								</IonList>
							)}
						</div>
					)}
				</IonContent>
			</IonPage>
		);
	}
}
