import { IonButtons, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonLabel, IonMenuButton, IonAlert, IonButton, IonDatetime, IonPopover, IonList, IonItem, IonInput, IonIcon } from '@ionic/react';
import { informationCircle } from 'ionicons/icons';
import moment from 'moment';
import React from 'react';
import { RouteComponentProps } from 'react-router';

import API from '../../API';
import LoadingFullScreen from '../../components/LoadingFullScreen';
import WorkTimeEmployer from '../../components/WorktimeTable/WorkTimeEmployer';
import WorkTimeRow from '../../components/WorktimeTable/WorkTimeRow';
import { getAppTrafRoleById } from '../RegisterPage';

interface Props
	extends RouteComponentProps<{
		id: string;
	}> {}

class WorkTimeTable extends React.Component<Props> {
	state: any = {
		table: null,
		editWorkTime: null,
		month: new Date().toJSON(),
		holidayDate: new Date().toJSON(),
		showPopover: false,
		totalSalary_1: 0,
		totalSalary_2: 0,

		scrollLeft: 0,
	};
	lastDay = 0;
	salaryTotal = Array<any>();
	halfMonth_1_min = 8100;
	halfMonth_2_min = 0;

	getWorkDescription(mode: string) {
		switch (mode) {
			case 'normal':
				return 'Штатный режим работы';
			case 'timeoff':
				return 'Отгул';
			case 'hospital':
				return 'Больничный';
			case 'remote':
				return 'Удаленка';
			case 'remote_hospital':
				return 'Удаленка по болезни';
			case 'holiday':
				return 'Выходной';
			default:
				return '';
		}
	}

	formatTable(data: any, businessDaysCount: number) {
		this.lastDay = 0;
		this.salaryTotal = Array<any>();

		const d = new Date();
		const year = d.getFullYear();
		const month = d.getMonth();

		// this.lastDay = new Date(year, month, 0).getDate();
		this.lastDay = 31;

		this.halfMonth_2_min = this.lastDay - 15 * 540;

		const formattedTable = Array<any>();

		for (let index = 0; index < data.users.length; index++) {
			const user = data.users[index];

			const userTable: any = {
				user: user,
				workDays: [],
				salaryMonthHalf_1: 0,
				salaryMonthHalf_2: 0,
				timeMonthHalf_1: 0,
				timeMonthHalf_2: 0,
				businessDaysCount: businessDaysCount,
			};

			for (let index = 1; index <= this.lastDay; index++) {
				const workDay = data.table.filter((t: any) => t.user_id == user.id && new Date(t.started).getDate() == index)[0] ?? null;
				userTable.workDays.push({
					day: index,
					workDay: workDay,
				});
				if (index < 16) {
					userTable.salaryMonthHalf_1 += workDay?.salary || 0;
					userTable.timeMonthHalf_1 += workDay?.work_duration || 0;
				} else {
					userTable.salaryMonthHalf_2 += workDay?.salary || 0;
					userTable.timeMonthHalf_2 += workDay?.work_duration || 0;
				}
			}

			this.state.totalSalary_1 += userTable.salaryMonthHalf_1;
			this.state.totalSalary_2 += userTable.salaryMonthHalf_2;

			formattedTable.push(userTable);
		}

		for (let index = 1; index <= this.lastDay; index++) {
			const s = data.table
				.filter((t: any) => new Date(t.started).getDate() == index)
				.map((t: any) => t?.salary ?? 0)
				.reduce((a: number, b: number) => a + b, 0);
			const m = data.table
				.filter((t: any) => new Date(t.started).getDate() == index)
				.map((t: any) => t?.work_duration ?? 0)
				.reduce((a: number, b: number) => a + b, 0);

			this.salaryTotal.push({
				s: s,
				m: m,
			});
		}

		formattedTable.sort(function (a, b) {
			if (a.user.role < b.user.role) {
				return -1;
			}
			if (a.user.role > b.user.role) {
				return 1;
			}
			return 0;
		});

		console.log(formattedTable);

		this.setState({
			table: formattedTable,
			totalSalary_1: this.state.totalSalary_1,
			totalSalary_2: this.state.totalSalary_2,
		});
	}

	componentDidMount() {
		this.getWorktimeTable();
	}

	async getWorktimeTable() {
		const date = new Date(this.state.month);
		API.getWorkTimeTable(moment(date).format('YYYY-MM')).then((res) => {
			API.sharedWorkTimeGetCurrentTime().then((r) => {
				console.log(r.data.daysInMonth);
				this.formatTable(res.data, r.data.daysInMonth);
			});
		});
	}

	render() {
		const table = this.state.table;
		const editWorkTime = this.state.editWorkTime;
		return (
			<IonPage>
				<IonAlert
					isOpen={editWorkTime != null}
					onDidDismiss={() => {
						this.setState({ editWorkTime: null });
					}}
					header={`Рабочий день ID:${editWorkTime?.id}`}
					inputs={
						editWorkTime
							? [
									{
										name: 'startedTime',
										type: 'time',
										value: new Date(editWorkTime.started).toLocaleString().split(' ')[1],
										placeholder: 'Начало работы',
									},
									{
										name: 'endedTime',
										type: 'time',
										value: new Date(editWorkTime.ended).toLocaleString().split(' ')[1],
										placeholder: 'Окончание работы',
									},
							  ]
							: []
					}
					buttons={[
						{
							text: 'Отмена',
							role: 'cancel',
							cssClass: 'secondary',
						},
						{
							text: 'Сохранить',
							handler: async (inputs) => {
								console.log(inputs);

								const res = await API.updateWorkTime({
									id: editWorkTime.id,
									started: new Date(editWorkTime.started.split('T')[0] + ' ' + inputs.startedTime),
									ended: new Date(editWorkTime.started.split('T')[0] + ' ' + inputs.endedTime),
								});

								console.log(res);

								this.getWorktimeTable();
							},
						},
					]}
				/>
				<IonHeader>
					<IonToolbar>
						<IonButtons slot="start">
							<IonMenuButton />
						</IonButtons>
						<IonTitle>Табель</IonTitle>
						<IonButtons slot="end">
							<IonButton onClick={() => this.setState({ showPopover: true })} id="open-popover-add-holiday">
								Добавить выходной
							</IonButton>
							<IonPopover
								onIonPopoverDidDismiss={() => {
									this.setState({ showPopover: false });
								}}
								isOpen={this.state.showPopover}
								trigger="open-popover-add-holiday"
								showBackdrop={true}>
								<IonDatetime
									presentation="date"
									value={this.state.holidayDate}
									onIonChange={(ev) => {
										// eslint-disable-next-line react/no-direct-mutation-state
										this.state.holidayDate = ev.detail.value;
										this.setState({
											holidayDate: ev.detail.value,
										});
									}}></IonDatetime>
								<IonButton
									onClick={async () => {
										this.setState({ showPopover: false });

										API.worktimeAddHolidays(this.state.holidayDate).then(() => {
											this.getWorktimeTable();
										});
									}}>
									Добавить выходной
								</IonButton>
							</IonPopover>
							<IonButton id="open-modal-date-work">{moment(new Date(this.state.month)).format('MMMM yyyy')}</IonButton>
							<IonPopover trigger="open-modal-date-work" showBackdrop={true}>
								<IonDatetime
									value={this.state.month}
									presentation="month-year"
									onIonChange={(ev) => {
										// eslint-disable-next-line react/no-direct-mutation-state
										this.state.month = ev.detail.value;
										this.setState({
											month: ev.detail.value,
										});
										this.getWorktimeTable();
									}}
								/>
							</IonPopover>
						</IonButtons>
					</IonToolbar>
				</IonHeader>
				<IonContent>
					{table == null ? (
						<LoadingFullScreen />
					) : table.length == 0 ? (
						<IonPage>
							No worktimetable data
						</IonPage>
					) : (
						<>
							<div style={{ display: 'flex' }}>
								<div className="employersSide" style={{ minWidth: 150 }}>
									<div style={{ height: 100 }}></div>
									{table.map((userTable: any, index: any) => {
										return <WorkTimeEmployer userTable={userTable} key={index} />;
									})}
									<div
										style={{
											height: 100,
											border: '1px solid black',
											minWidth: 150,
											textAlign: 'center',
											backgroundColor: 'white',
											flexGrow: 1,
											verticalAlign: 'center',
										}}>
										<p style={{ fontSize: 11 }}>
											Общая 1-15: {this.state.totalSalary_1} 16-31: {this.state.totalSalary_2}
											<br />
											ЗП: 1-15: нал: {table.map((userTable: any) => Math.floor(userTable.salaryMonthHalf_1 / 5000) * 5000).reduce((a: number, b: number) => a + b)} сбер:{' '}
											<span
												onClick={() => {
													let text = '';

													for (let index = 0; index < table.length; index++) {
														const userTable = table[index];
														text += `${userTable.user.name} (${userTable.user.login}) [${getAppTrafRoleById(userTable.user.role)?.description ?? 'Без позиции'}] : [${
															Math.floor(userTable.salaryMonthHalf_1 / 5000) * 5000
														}] ${userTable.salaryMonthHalf_1 % 5000}\n`;
													}

													navigator.clipboard.writeText(text);
												}}>
												{table.map((userTable: any) => userTable.salaryMonthHalf_1 % 5000).reduce((a: number, b: number) => a + b)}
											</span>
											<br />
											ЗП: 16-31: нал: {table.map((userTable: any) => Math.floor(userTable.salaryMonthHalf_2 / 5000) * 5000).reduce((a: number, b: number) => a + b)} сбер:{' '}
											<span
												onClick={() => {
													let text = '';

													for (let index = 0; index < table.length; index++) {
														const userTable = table[index];
														text += `${userTable.user.name} (${userTable.user.login}) [${getAppTrafRoleById(userTable.user.role)?.description ?? 'Без позиции'}] : [${
															Math.floor(userTable.salaryMonthHalf_2 / 5000) * 5000
														}] ${userTable.salaryMonthHalf_2 % 5000}\n`;
													}

													navigator.clipboard.writeText(text);
												}}>
												{table.map((userTable: any) => userTable.salaryMonthHalf_2 % 5000).reduce((a: number, b: number) => a + b)}
											</span>
										</p>
									</div>
								</div>
								<div
									className="worktimetable"
									style={{
										width: '100%',
										overflow: 'auto',
									}}
									onScroll={(e) => {
										this.setState({
											scrollLeft: e.currentTarget.scrollLeft,
										});
									}}>
									<WorkTimeRow
										style={{
											left: this.state.scrollLeft * -1,
										}}
										isHeader={true}
										items={[
											<h4 key={'day'}>день</h4>,
											...Array(this.lastDay)
												.fill(null)
												.map((_, index) => {
													return <h4 key={index}>{index + 1}</h4>;
												}),
										]}
									/>
									{table.map((userTable: any, index: any) => {
										return (
											<WorkTimeRow
												key={index}
												items={userTable.workDays.map((wd: any, index2: any) => {
													return (
														<div key={index2}>
															{wd.workDay ? (
																<div>
																	<p
																		style={{
																			fontSize: 10,
																			padding: 0,
																			margin: 0,
																		}}>
																		{moment(new Date(wd.workDay.started)).format('HH:mm:ss')} - {wd.workDay.ended ? moment(new Date(wd.workDay.ended)).format('HH:mm:ss') : ''}
																	</p>
																	{wd.workDay.work_duration == 0 ? (
																		<p
																			style={{
																				fontSize: 10,
																				padding: 0,
																				margin: 0,
																			}}>
																			Минут: 0
																		</p>
																	) : (
																		<p
																			style={{
																				fontSize: 10,
																				padding: 0,
																				margin: 0,
																				color: wd.workDay.work_duration < 540 ? 'red' : 'green',
																			}}>
																			Минут: {wd.workDay.work_duration} (
																			{wd.workDay.work_duration < 540 ? '-' + (540 - wd.workDay.work_duration) : '+' + (wd.workDay.work_duration - 540)})
																		</p>
																	)}
																	<p
																		style={{
																			fontSize: 10,
																			padding: 0,
																			margin: 0,
																		}}>
																		ЗП: {wd.workDay.salary} руб.
																	</p>
																	<p
																		style={{
																			fontSize: 10,
																			padding: 0,
																			margin: 0,
																		}}>
																		Режим: {this.getWorkDescription(wd.workDay.work_mode)}
																		{wd.workDay.report ? (
																			<IonIcon
																				onClick={() => {
																					alert(wd.workDay.report);
																				}}
																				color="primary"
																				icon={informationCircle}></IonIcon>
																		) : null}
																	</p>

																	<p
																		style={{ marginBottom: 0 }}
																		onClick={() => {
																			console.log(wd.workDay);
																			this.setState({
																				editWorkTime: {
																					...wd.workDay,
																				},
																			});
																		}}>
																		Изменить
																	</p>
																	{wd.workDay.hospital_proof ? (
																		<p
																			style={{ marginBottom: 0, color: 'green' }}
																			onClick={() => {
																				window.open(wd.workDay.hospital_proof, '_blank');
																			}}>
																			Доказательство
																		</p>
																	) : null}
																</div>
															) : (
																<p>❌</p>
															)}
														</div>
													);
												})}
											/>
										);
									})}

									<WorkTimeRow
										items={this.salaryTotal.map((s, index) => {
											return (
												<div key={'salary_total_' + index}>
													<p>
														{s.s} руб.
														<br />
														{s.m} мин.
													</p>
												</div>
											);
										})}
									/>
								</div>
							</div>
						</>
					)}
				</IonContent>
			</IonPage>
		);
	}
}

export default WorkTimeTable;
