import { IonContent, IonIcon, IonImg, IonItem, IonLabel, IonList, IonMenuToggle } from '@ionic/react';
import React from 'react';
import { RouteComponentProps, useLocation } from 'react-router-dom';
import { keyOutline, personSharp, serverOutline } from 'ionicons/icons';

import './Menu.css';
import FARMERServersPage from '../pages/Farmer/FARMERServersPage';
import FARMERAntifrodDecryptPage from '../pages/Farmer/FARMERAntifrodDecryptPage';
import FARMERAccountsPage from '../pages/Farmer/FARMERAccountsPage';

interface AppPage {
	url: string;
	icon: string;
	title: string;
	component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
}

export const appPages: AppPage[] = [
	{
		title: 'Аккаунты',
		url: '/farmer/accounts',
		icon: personSharp,
		component: FARMERAccountsPage,
	},
	{
		title: 'Серверы',
		url: '/farmer/servers',
		icon: serverOutline,
		component: FARMERServersPage,
	},
	{
		title: 'Антифрод',
		url: '/farmer/antifrod',
		icon: keyOutline,
		component: FARMERAntifrodDecryptPage,
	},
];

const FarmerMenu: React.FC = () => {
	const location = useLocation();

	return (
		<IonContent>
			<IonList id="inbox-list">
				{appPages.map((appPage, index) => {
					return (
						<IonMenuToggle key={index} autoHide={false}>
							<IonItem
								style={{
									'--height': 20,
								}}
								className={location.pathname.startsWith(appPage.url) ? 'selected' : ''}
								routerLink={appPage.url}
								routerDirection="none"
								lines="none"
								detail={false}>
								<IonIcon icon={appPage.icon} size={'4'} />
								<IonLabel style={{ paddingLeft: 8, fontSize: 8 }}>{appPage.title}</IonLabel>
							</IonItem>
						</IonMenuToggle>
					);
				})}
				<br />
				<br />
				<IonImg src="/assets/farmer2.jpeg" />
				<br />
				<p>It's Honest Work!</p>
			</IonList>
		</IonContent>
	);
};

export default FarmerMenu;
