import { IonIcon } from '@ionic/react';
import React from 'react';

export default class extends React.Component<{ text: string; icon: string }> {
	render(): React.ReactNode {
		return (
			<div style={{ display: 'flex', alignItems: 'center', paddingTop: 8 }}>
				<IonIcon icon={this.props.icon} style={{ color: '#3781FC' }} size="10"></IonIcon>
				<p style={{ fontSize: 14, fontWeight: 700, marginBottom: 0, paddingLeft: 4 }}>{this.props.text}</p>
			</div>
		);
	}
}
