import { IonPage, IonHeader, IonToolbar, IonButtons, IonTitle, IonContent, IonList, IonButton, IonItem, IonLabel, IonBackButton, IonItemDivider, IonProgressBar, IonAlert } from '@ionic/react';
import React, { RefObject } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import API from '../../API';
import LoadingFullScreen from '../../components/LoadingFullScreen';

interface Props
	extends RouteComponentProps<{
		id: string;
	}> {}

export default class extends React.Component<Props> {
	filePickerRef: RefObject<HTMLInputElement>;

	state: any = {
		project: null,
		loading: false,
		percent: 0,
		errorMessage: null,
	};

	constructor(props: any) {
		super(props);
		this.filePickerRef = React.createRef();
	}

	async onZipChoosed(e: any) {
		if (e.target.files.length == 0) {
			return;
		}

		this.setState({
			loading: true,
		});

		API.whiteAndroidUploadAndroidProject(
			e.target.files[0],
			this.props.match.params.id,
			(percent) => {
				console.log(percent);
				this.setState({
					percent: percent,
				});
			},
			(res) => {
				console.log(res);
				if (res.success) {
					alert('Проект успешно загружен!');
					window.location.reload();
				} else {
					alert(JSON.stringify(res.error));
				}
			},
		);
	}

	componentDidMount() {
		this.getProject();
	}

	async getProject() {
		const res = await API.whiteAndroidDeveloperAndroidGetFullProject(this.props.match.params.id);
		if (res.success) {
			this.setState({
				project: res.data,
			});
		}
	}

	render() {
		const project = this.state.project;
		return (
			<IonPage>
				<IonAlert
					isOpen={this.state.errorMessage != null}
					onDidDismiss={() => {
						this.setState({ errorMessage: null });
					}}
					header="Ошибка"
					message={this.state.errorMessage}
					buttons={[
						{
							text: 'Закрыть',
							role: 'cancel',
							cssClass: 'secondary',
						},
					]}
				/>
				<IonHeader>
					<IonToolbar>
						<IonButtons slot="start">
							<IonBackButton defaultHref="/android-developer/projects"></IonBackButton>
						</IonButtons>
						<IonTitle>Информация о проекте</IonTitle>
					</IonToolbar>
				</IonHeader>
				<IonContent>
					<input
						ref={this.filePickerRef}
						style={{ display: 'none' }}
						type="file"
						accept="zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed"
						onChange={this.onZipChoosed.bind(this)}
					/>
					{this.state.project == null ? (
						<LoadingFullScreen />
					) : (
						<div className="ion-padding">
							<IonList>
								<IonList>
									<IonItemDivider>Project info</IonItemDivider>
									<IonItem>
										<IonLabel>
											<h2>{project.title}</h2>
											<p>Проект</p>
										</IonLabel>
									</IonItem>
									<IonItem>
										<IonLabel>
											<h2>{new Date(project.created).toString()}</h2>
											<p>Создан</p>
										</IonLabel>
									</IonItem>
									<IonItemDivider>Keystore</IonItemDivider>
									<IonItem>
										<IonLabel>
											<h2>{project.keystore_alias}</h2>
											<p>Alias</p>
										</IonLabel>
									</IonItem>
									<IonItem>
										<IonLabel>
											<h2>{project.keystore_password}</h2>
											<p>Password</p>
										</IonLabel>
									</IonItem>
									<IonItem>
										<IonLabel>
											<h2>{project.keystore_store_password}</h2>
											<p>Store password</p>
										</IonLabel>
									</IonItem>
									<IonItem>
										<IonLabel>
											<h2>Keystore file</h2>
										</IonLabel>
										<IonButton
											onClick={() => {
												const downloadLink = document.createElement('a');
												downloadLink.href = 'data:application/octet-stream;base64,' + project.keystore_binary;
												downloadLink.download = 'keystore.jks';
												downloadLink.click();
											}}>
											Скачать
										</IonButton>
									</IonItem>
									<IonItemDivider>Source codes</IonItemDivider>

									{project.android_projects_source_code.length == 0 ? (
										<p>Вы не загружали версии</p>
									) : (
										<IonList>
											{project.android_projects_source_code.map((p: any) => {
												return (
													<IonItem key={'android_' + project.id + '_' + p.id}>
														<IonLabel>
															<h2>{p.id}</h2>
															<p>{new Date(p.created).toString()}</p>
															{p.apk_path ? (
																<>
																	<a href={p.apk_path}>APK: {p.apk_path}</a>
																	<br />
																</>
															) : (
																false
															)}

															{p.aab_path ? (
																<>
																	<a href={p.aab_path}>AAB: {p.aab_path}</a>
																	<br />
																</>
															) : (
																false
															)}

															{p.is_tested ? <p style={{ color: 'green' }}>Тест прошёл успешно</p> : p.is_need_fixing ? <p style={{ color: 'red' }}>Нужен фикс</p> : <p>Тест не прошёл</p>}

															{p.is_reviewed ? (
																<p style={{ color: 'green' }}>Ревью пройден</p>
															) : p.review_comment ? (
																<p
																	style={{ color: 'yellow' }}
																	onClick={() => {
																		alert(p.review_comment);
																	}}>
																	Ревью написан
																</p>
															) : (
																<p>Ревью нет</p>
															)}
														</IonLabel>
														<IonButtons slot="end">
															{p.error != null ? (
																<IonButton
																	onClick={() => {
																		this.setState({
																			errorMessage: p.error,
																		});
																	}}>
																	Открыть ошибку
																</IonButton>
															) : (
																false
															)}
														</IonButtons>
													</IonItem>
												);
											})}
										</IonList>
									)}

									{this.state.loading ? (
										<div className="ion-padding">
											<h2>Загрузка ({Math.round(this.state.percent * 100)}%)</h2>
											<IonProgressBar value={this.state.percent}></IonProgressBar>
										</div>
									) : (
										<div className="ion-padding">
											<p>Загрузить новую версию</p>

											<IonButton
												onClick={() => {
													this.filePickerRef.current?.click();
												}}>
												Загрузить архив
											</IonButton>
										</div>
									)}
								</IonList>
							</IonList>
						</div>
					)}
				</IonContent>
			</IonPage>
		);
	}
}
