import { IonPage, IonHeader, IonToolbar, IonButtons, IonMenuButton, IonTitle, IonContent, IonList, IonItem, IonLabel, IonInput, IonButton, IonIcon } from '@ionic/react';
import React from 'react';
import { checkmarkDone } from 'ionicons/icons';

import API from '../../API';
import LoadingFullScreen from '../../components/LoadingFullScreen';
import { countries } from '../../Constants';

export default class extends React.Component {
	state: any = {
		apps: null,
	};

	componentDidMount() {
		this.getData();
	}

	getData() {
		API.publisherGetAndroidBuilds().then((res) => {
			this.setState({
				apps: res.data,
			});
		});
	}

	render() {
		return (
			<IonPage>
				<IonHeader>
					<IonToolbar>
						<IonButtons slot="start">
							<IonMenuButton />
						</IonButtons>
						<IonTitle>Android сборки</IonTitle>
					</IonToolbar>
				</IonHeader>
				<IonContent>
					<div className="ion-padding">
						{this.state.apps == null ? (
							<LoadingFullScreen />
						) : (
							<IonList>
								{this.state.apps.map((a: any) => {
									return (
										<IonItem key={'android_app_' + a.id}>
											<IonLabel>
												<h2>
													[{a.id}] {a.title} <span style={{ color: a.is_tested ? 'green' : 'red' }}>{a.is_tested ? 'ПРОТЕСТИРОВАН' : 'ТЕСТ НЕ ПРОВОДИЛСЯ'}</span>
												</h2>
												<p>{a.name}</p>
												<p>
													{a.aab_path ? (
														<>
															<a href={a.aab_path}>AAB: {a.aab_path}</a>
															<br />
														</>
													) : (
														false
													)}
												</p>
												<p>
													{a.apk_path ? (
														<>
															<a href={a.apk_path}>APK: {a.apk_path}</a>
															<br />
														</>
													) : (
														false
													)}
												</p>
											</IonLabel>
										</IonItem>
									);
								})}
							</IonList>
						)}
					</div>
				</IonContent>
			</IonPage>
		);
	}
}
