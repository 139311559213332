import { IonButtons, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonBackButton, IonCard, IonCardContent, IonList, IonItem, IonLabel, IonItemDivider, IonButton, IonModal, IonInput } from '@ionic/react';
import React from 'react';
import { RouteComponentProps } from 'react-router';

import API from '../../API';
import LoadingFullScreen from '../../components/LoadingFullScreen';
import TypeSelectorComponent from '../../components/TypeSelectorComponent';
import { countries } from '../../Constants';

interface Props
	extends RouteComponentProps<{
		id: string;
	}> {}

export default class extends React.Component<Props> {
	state: any = {
		app: null,
		countries: [],
		selectedCountries: [],
		selectCountiesOpened: false,

		newComment: '',
	};

	componentDidMount() {
		this.getData();
	}

	async getData() {
		const res = await API.getTesterAppInfo(this.props.match.params.id);

		const selectedCountries: Array<any> = [];
		this.state.countries = [];
		for (const [key, value] of Object.entries(res.countries)) {
			const country: any = value;
			this.state.countries.push(value);
			if ((res.data.allowed_country_ids as Array<number>).includes(country.id)) {
				selectedCountries.push(value);
			}
		}

		this.setState({
			app: res.data,
			countries: this.state.countries,
			selectedCountries: selectedCountries,
		});
	}

	getCountryById(id: any) {
		let country = null;

		for (let index = 0; index < this.state.countries.length; index++) {
			const c = this.state.countries[index];

			if (c.id == id) {
				country = c;
				break;
			}
		}

		return country;
	}

	getCountryByCode(code: any) {
		let country = null;

		for (let index = 0; index < this.state.countries.length; index++) {
			const c = this.state.countries[index];

			if (c.code == code) {
				country = c;
				break;
			}
		}

		return country;
	}

	render() {
		const app = this.state.app;
		return (
			<IonPage>
				<IonModal
					isOpen={this.state.selectCountiesOpened}
					onDidDismiss={() => {
						this.setState({ selectCountiesOpened: false });
					}}>
					<TypeSelectorComponent
						beforeSelected={this.state.selectedCountries}
						items={this.state.countries}
						didSelected={async (items: any) => {
							this.setState({ selectCountiesOpened: false });

							await API.PBChangeAppCountries(
								app.id,
								items.map((i: any) => i.id),
							);
							alert('Данные обновлены');
							this.getData();
						}}
						didCancelled={() => this.setState({ selectCountiesOpened: false })}
					/>
				</IonModal>

				<IonHeader>
					<IonToolbar>
						<IonButtons slot="start">
							<IonBackButton defaultHref="/tester/apps"></IonBackButton>
						</IonButtons>
						<IonTitle>Информация о приложении №{this.props.match.params.id}</IonTitle>
					</IonToolbar>
				</IonHeader>
				<IonContent>
					{app == null ? (
						<LoadingFullScreen />
					) : (
						<IonCard>
							<IonCardContent>
								<IonList>
									<IonItem>
										<IonLabel>
											<h2>{app.title}</h2>
											<p>Название</p>
										</IonLabel>
									</IonItem>
									<IonItem>
										<IonLabel className="ion-text-wrap">
											<h2>{app.countries.map((c: any) => c.title).join(' | ')}</h2>
											<p>Включенные страны</p>
										</IonLabel>
									</IonItem>

									<IonItem>
										<IonLabel>
											<h2>{app.is_enabled ? 'Включен' : 'Выключен'}</h2>
											<p>Статус клоаки</p>
										</IonLabel>
										<IonButton
											slot="end"
											onClick={async () => {
												const reason = window.prompt('Комментарий действия') || 'Без комментария';

												await API.TesterChangeCloacaStatus(app.id, app.is_enabled ? false : true, reason);
												alert('Данные обновлены');
												this.getData();
											}}>
											{app.is_enabled ? 'Выключить' : 'Включить'}
										</IonButton>
									</IonItem>

									<IonItemDivider>Ссылки</IonItemDivider>
									<IonItem>
										<IonLabel>
											<h2>{app.link}</h2>
											<p>Главная ссылка</p>
										</IonLabel>
									</IonItem>

									{app.smartlink
										? Object.entries(app.smartlink).map(([key, value]) => {
												return (
													<IonItem key={'app_' + app.id + '_country_' + key}>
														<IonLabel>
															<h2>{value as string}</h2>
															<p>Страна {key}</p>
														</IonLabel>
													</IonItem>
												);
										  })
										: false}

									{app.random_url
										? app.random_url.map((url: string) => {
												return (
													<IonItem key={'app_' + app.id + '_url_' + url}>
														<IonLabel>
															<h2>{url}</h2>
															<p>Рандомная ссылка</p>
														</IonLabel>
													</IonItem>
												);
										  })
										: false}

									<IonItem>
										<IonLabel>
											<h2>{app.store_link ? app.store_link : 'Ссылка не найдена'}</h2>
											<p>Ссылка в стор</p>
										</IonLabel>
										{app.store_link ? (
											<IonButtons slot="end">
												<IonButton
													onClick={() => {
														window.open(app.store_link, '_blank');
													}}>
													Открыть в сторе
												</IonButton>
											</IonButtons>
										) : (
											false
										)}
									</IonItem>

									<IonItem>
										<IonLabel position="floating">Добавить комментарий</IonLabel>
										<IonInput
											placeholder="Комментарий"
											value={this.state.newComment}
											onIonChange={(e) => {
												this.setState({
													newComment: e.detail.value!,
												});
											}}></IonInput>
										<IonButtons slot="end">
											<IonButton
												onClick={() => {
													API.testerAddAppComment(app.id, this.state.newComment).then(() => {
														this.setState({
															newComment: '',
														});
														this.getData();
													});
												}}>
												Отправить
											</IonButton>
										</IonButtons>
									</IonItem>

									<IonItemDivider>Статистика</IonItemDivider>

									<IonItem>
										<IonLabel>
											<h2>{app.stats.users}</h2>
											<p>Пользователей</p>
										</IonLabel>
									</IonItem>
									<IonItem>
										<IonLabel>
											<h2>{app.stats.bots}</h2>
											<p>Ботов</p>
										</IonLabel>
									</IonItem>

									<IonItemDivider>Комментарии</IonItemDivider>

									{app.comments.length == 0 ? <h2 className="ion-padding">Комментариев нет</h2> : false}
									{app.comments.map((comment: any) => {
										return (
											<IonItem key={'app_comment_' + comment.id}>
												<IonLabel className="ion-text-wrap">
													<h5>{comment.comment}</h5>
													<p>
														{comment.user_name} [{comment.user_role}] {new Date(comment.created).toLocaleString()}
													</p>
												</IonLabel>
											</IonItem>
										);
									})}

									<IonItemDivider>Боты</IonItemDivider>
									<IonList>
										{app.bots.map((b: any) => {
											return (
												<IonItem key={'last_app_' + app.id + '_bot' + b.id}>
													<IonLabel>
														<h2>{b.reason}</h2>
														<p>
															{b.ip +
																' | ' +
																// @ts-ignore
																countries[b.country]?.title +
																' | ' +
																new Date(b.created).toLocaleString()}
														</p>
													</IonLabel>
												</IonItem>
											);
										})}
									</IonList>
								</IonList>
							</IonCardContent>
						</IonCard>
					)}
				</IonContent>
			</IonPage>
		);
	}
}
