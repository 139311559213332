import { IonPage, IonHeader, IonToolbar, IonButtons, IonTitle, IonContent, IonList, IonButton, IonItem, IonLabel, IonBackButton, IonInput, IonRadioGroup, IonRadio, IonMenuButton, IonItemDivider } from '@ionic/react';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import API from '../../API';
import UserService from '../../UserService';

const ru = require('moment/locale/ru');

interface Props
	extends RouteComponentProps<{
		id: string;
	}> {}

export default class extends React.Component<Props> {
	state: any = {
		transactions: [],
		sum: 0,
		hold: 0,
		inHoldTransactions: [],
	};

	componentDidMount() {
		this.getTransactions();
	}

	async getTransactions() {
		const res = await API.sharedGetTransactions();
		this.setState({
			transactions: res.data.transactions,
			sum: res.data.sum,
			hold: res.data.hold,
			inHoldTransactions: res.data.inHoldTransactions,
		});
	}

	render() {
		return (
			<IonPage>
				<IonHeader>
					<IonToolbar>
						<IonButtons slot="start">
							<IonMenuButton />
						</IonButtons>
						<IonTitle>Мой KPI</IonTitle>
					</IonToolbar>
				</IonHeader>
				<IonContent>
					<div className="ion-padding">
						<h2>Ваш баланс: {this.state.sum} RUB</h2>
						<h2>Холд: {this.state.inHoldTransactions.map((t: any) => t.amount).reduce((a: number, b: number) => a + b, 0)} RUB</h2>
					</div>
					<IonList>
						{this.state.transactions.map((t: any) => {
							return (
								<IonItem key={'transaction_' + t.id}>
									<IonLabel>
										<h2 style={{ color: t.amount >= 0 ? 'green' : 'red' }}>{t.amount} RUB</h2>
										<p>{t.comment}</p>
										{t.in_hold ? <p style={{ fontWeight: 'bold', color: 'orange' }}>Деньги в холде</p> : false}
										{t.is_deleted ? <p style={{ fontWeight: 'bold', color: 'red' }}>Деньги отменены</p> : false}
										<p>{new Date(t.created).toLocaleString()}</p>
									</IonLabel>
								</IonItem>
							);
						})}

						<IonItemDivider>
							<IonLabel>Транзакции в холде</IonLabel>
						</IonItemDivider>
						{this.state.inHoldTransactions.map((t: any) => {
							return (
								<IonItem>
									<IonLabel>
										<h2>
											{t.title} | {t.amount} RUB
										</h2>
										<p>{t.comment}</p>
									</IonLabel>
								</IonItem>
							);
						})}
					</IonList>
				</IonContent>
			</IonPage>
		);
	}
}
