import { stat } from 'fs';

import { IonCard, IonCardContent, IonCardTitle, IonCardSubtitle } from '@ionic/react';
import React from 'react';

interface Store {
	title: string;
	count: number;
}

interface Published {
	total: number;
	stores: Store[];
}

interface Banned {
	total: number;
	stores: Store[];
}

interface Farm {
	total: number;
	stores: Store[];
}

interface TopApps {
	usd: number;
	rub: number;
}

interface Total {
	usd: number;
	rub: number;
}

interface Income {
	topApps: TopApps;
	total: Total;
}

interface RegToDep {
	usd: number;
	rub: number;
}

interface Report {
	published: Published;
	banned: Banned;
	farm: Farm;
	income: Income;
	regToDep: RegToDep;
	start?: string;
	end?: string;
}

interface Props {
	report: Report;
	previous?: Report;
}

export default class extends React.Component<Props> {
	render() {
		return (
			<IonCard>
				<IonCardContent>
					<IonCardTitle>Период</IonCardTitle>
					<IonCardSubtitle>
						с {this.props.report.start} по {this.props.report.end}
					</IonCardSubtitle>
					<br />
					<IonCardTitle>Заработано</IonCardTitle>
					<IonCardSubtitle>
						USD: {this.props.report.income.total.usd?.toFixed(2)}
						{this.props.previous != null ? ' | ' + (this.props.report.income.total.usd - this.props.previous.income.total.usd).toFixed(2) : null}
					</IonCardSubtitle>
					<IonCardSubtitle>
						RUB: {this.props.report.income.total.rub?.toFixed(2)}
						{this.props.previous != null ? ' | ' + (this.props.report.income.total.rub - this.props.previous.income.total.rub).toFixed(2) : null}
					</IonCardSubtitle>
					<br />
					<IonCardTitle>Конверсия Рег-Деп</IonCardTitle>
					<IonCardSubtitle>
						USD: {this.props.report.regToDep.usd?.toFixed(2)}%{this.props.previous != null ? ' | ' + (this.props.report.regToDep.usd - this.props.previous.regToDep.usd).toFixed(2) : null}%
					</IonCardSubtitle>
					<IonCardSubtitle>
						RUB: {this.props.report.regToDep.rub?.toFixed(2)}%{this.props.previous != null ? ' | ' + (this.props.report.regToDep.rub - this.props.previous.regToDep.rub).toFixed(2) : null}%
					</IonCardSubtitle>
					<br />
					<IonCardTitle>Прил с доходом</IonCardTitle>
					<IonCardSubtitle>
						USD 7k &gt;: {this.props.report.income.topApps.usd}
						{this.props.previous != null ? ' | ' + (this.props.report.income.topApps.usd - this.props.previous.income.topApps.usd) : null}
					</IonCardSubtitle>
					<IonCardSubtitle>
						RUB 500k &gt;: {this.props.report.income.topApps.rub}
						{this.props.previous != null ? ' | ' + (this.props.report.income.topApps.rub - this.props.previous.income.topApps.rub) : null}
					</IonCardSubtitle>
					<br />
					<IonCardTitle>
						Опубликовано прил: {this.props.report.published.total}
						{this.props.previous != null ? ' | ' + (this.props.report.published.total - this.props.previous.published.total) : null}
					</IonCardTitle>
					{this.props.report.published.stores.map((store: any) => {
						return (
							<IonCardSubtitle key={'weekly_published_' + store.title}>
								-{store.title}: {store.count}
								{this.props.previous != null ? ' | ' + (store.count - (this.props.previous!.published.stores.filter((s: any) => s.title == store.title)[0]?.count ?? 0)) : null}
							</IonCardSubtitle>
						);
					})}
					<br />
					<IonCardTitle>
						Забанено прил: {this.props.report.banned.total}
						{this.props.previous != null ? ' | ' + (this.props.report.banned.total - this.props.previous.banned.total) : null}
					</IonCardTitle>
					{this.props.report.banned.stores.map((store: any) => {
						return (
							<IonCardSubtitle key={'weekly_banned_' + store.title}>
								-{store.title}: {store.count}
								{this.props.previous != null ? ' | ' + (store.count - (this.props.previous.banned.stores.filter((s: any) => s.title == store.title)[0]?.count ?? 0)) : null}
							</IonCardSubtitle>
						);
					})}
					<br />
					<IonCardTitle>
						Зафармлено акков: {this.props.report.farm.total}
						{this.props.previous != null ? ' | ' + (this.props.report.farm.total - this.props.previous.farm.total) : null}
					</IonCardTitle>
					{this.props.report.farm.stores.map((store: any) => {
						return (
							<IonCardSubtitle key={'weekly_farm_' + store.title}>
								-{store.title}: {store.count}
								{this.props.previous != null ? ' | ' + (store.count - (this.props.previous.farm.stores.filter((s: any) => s.title == store.title)[0]?.count ?? 0)) : null}
							</IonCardSubtitle>
						);
					})}
				</IonCardContent>
			</IonCard>
		);
	}
}
