import React from 'react';
import { IonApp, IonButton, IonIcon, IonMenu, IonRouterOutlet, IonSplitPane, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Redirect, Route } from 'react-router-dom';
import { logOut } from 'ionicons/icons';

import * as AdminMenu from './components/AdminMenu';
import * as MediaByerMenu from './components/MediaByerMenu';
import * as DeveloperMenu from './components/DeveloperMenu';
import * as PublisherMenu from './components/PublisherMenu';
import * as FarmerMenu from './components/FarmerMenu';
import * as PusherMenu from './components/PusherMenu';
import * as AndroidDeveloperMenu from './components/AndroidDeveloperMenu';
import * as TesterMenu from './components/TesterMenu';
import * as PmMenu from './components/PmMenu';
import * as ManagerMenu from './components/ManagerMenu';
import * as ManagerIosAppsMenu from './components/ManagerIosAppsMenu';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import UserService from './UserService';
import AuthPage from './pages/AuthPage';
import ChooseAppPage from './pages/Admin/Apps/ChooseAppPage';
import ChooseUserPage from './pages/Admin/ChooseUserPage';
import MBCreateCampaignPage from './pages/MediaBuyer/MBCreateCampaignPage';
import PBChoosePublishedApp from './pages/Publisher/PBChoosePublishedApp';
import API from './API';
import PBCreateAppPage from './pages/Publisher/PBCreateAppPage';
import FARMERChooseServerPage from './pages/Farmer/FARMERChooseServerPage';
import PBChooseServerPage from './pages/Publisher/PBChooseServerPage';
import WaitingReviewPage from './pages/WaitingReviewPage';
import RegisterPage from './pages/RegisterPage';
import WorkTimeLogger from './pages/Shared/WorkTimeLogger';
import ChooseEmployer from './pages/Admin/Empoyeers/ChooseEmployer';
import DeveloperAndroidChooseProject from './pages/Developer/DeveloperAndroidChooseProject';
import ChooseAppGalleryKeywords from './pages/Admin/Analyzer/ChooseAppGalleryKeywords';
import AndroidDeveloperAndroidChooseProject from './pages/AndroidDeveloper/AndroidDeveloperAndroidChooseProject';
import ChooseAppKeywords from './pages/Admin/Keywords/ChooseAppKeywords';
import TesterChooseAppPage from './pages/Tester/TesterChooseAppPage';
import AppsArchivePage from './pages/Admin/Apps/AppsArchivePage';
import FARMERChooseAccountPage from './pages/Farmer/FARMERChooseAccountPage';
import TransactionsPage from './pages/Shared/TransactionsPage';
import FarmersKPI from './pages/Admin/FarmersKPI';
import PM_ChooseAppPage from './pages/Pm/PM_ChooseAppPage';
import ReportsPage from './pages/Shared/ReportsPage';

setupIonicReact({
	mode: 'md',
});

function getMenu() {
	switch (UserService.getUser().role) {
		case 'admin':
			return <AdminMenu.default />;
		case 'mediabuyer':
			return <MediaByerMenu.default />;
		case 'developer':
			return <DeveloperMenu.default />;
		case 'android-developer':
			return <AndroidDeveloperMenu.default />;
		case 'publisher':
			return <PublisherMenu.default />;
		case 'farmer':
			return <FarmerMenu.default />;
		case 'pusher':
			return <PusherMenu.default />;
		case 'tester':
			return <TesterMenu.default />;
		case 'pm':
			return <PmMenu.default />;
		case 'manager':
			return <ManagerMenu.default />;
		case 'manageriosapps':
			return <ManagerIosAppsMenu.default />;
		default:
			break;
	}
}

function getOutlets() {
	switch (UserService.getUser().role) {
		case 'admin':
			return (
				<IonRouterOutlet id="main">
					{AdminMenu.appPages.map((page) => (
						<Route key={page.url} path={page.url} component={page.component} exact />
					))}
					<Route path="/apps/:id" component={ChooseAppPage} exact />
					<Route path="/apps/archive" component={AppsArchivePage} exact />
					<Route path="/kpi/farmers" component={FarmersKPI} exact />
					<Route path="/users/:id" component={ChooseUserPage} exact />
					<Route path="/appgallery/:id" component={ChooseAppGalleryKeywords} exact />
					<Route path="/employees/:id" component={ChooseEmployer} exact />
					<Route path="/keywords/:appId" component={ChooseAppKeywords} exact />
					<Redirect from="/" to={AdminMenu.appPages[0].url} exact />
					<Route path="/worktimelogger" component={WorkTimeLogger} exact />
					<Route path="/d-report" component={ReportsPage} exact />
					<Route path="/transactions" component={TransactionsPage} exact />
				</IonRouterOutlet>
			);
		case 'mediabuyer':
			return (
				<IonRouterOutlet id="main">
					{MediaByerMenu.appPages.map((page) => (
						<Route key={page.url} path={page.url} component={page.component} exact />
					))}
					<Redirect from="/" to={MediaByerMenu.appPages[0].url} exact />
					<Route path="/mb/campaigns/create" component={MBCreateCampaignPage} exact />
					<Route path="/worktimelogger" component={WorkTimeLogger} exact />
					<Route path="/d-report" component={ReportsPage} exact />
					<Route path="/transactions" component={TransactionsPage} exact />
				</IonRouterOutlet>
			);

		case 'developer':
			return (
				<IonRouterOutlet id="main">
					{DeveloperMenu.appPages.map((page) => (
						<Route key={page.url} path={page.url} component={page.component} exact />
					))}
					<Redirect from="/" to={DeveloperMenu.appPages[0].url} exact />
					<Route path="/developer/android/projects/:id" component={DeveloperAndroidChooseProject} exact />
					<Route path="/worktimelogger" component={WorkTimeLogger} exact />
					<Route path="/d-report" component={ReportsPage} exact />
					<Route path="/transactions" component={TransactionsPage} exact />
				</IonRouterOutlet>
			);
		case 'android-developer':
			return (
				<IonRouterOutlet id="main">
					{AndroidDeveloperMenu.appPages.map((page) => (
						<Route key={page.url} path={page.url} component={page.component} exact />
					))}
					<Redirect from="/" to={AndroidDeveloperMenu.appPages[0].url} exact />
					<Route path="/android-developer/projects/:id" component={AndroidDeveloperAndroidChooseProject} exact />
					<Route path="/worktimelogger" component={WorkTimeLogger} exact />
					<Route path="/d-report" component={ReportsPage} exact />
					<Route path="/transactions" component={TransactionsPage} exact />
				</IonRouterOutlet>
			);
		case 'publisher':
			return (
				<IonRouterOutlet id="main">
					{PublisherMenu.appPages.map((page) => (
						<Route key={page.url} path={page.url} component={page.component} exact />
					))}
					<Redirect from="/" to={PublisherMenu.appPages[0].url} exact />
					<Route path="/publisher/apps/:id" component={PBChoosePublishedApp} exact />
					<Route path="/publisher/apps/create" component={PBCreateAppPage} exact />
					<Route path="/publisher/servers/:id" component={PBChooseServerPage} exact />
					<Route path="/worktimelogger" component={WorkTimeLogger} exact />
					<Route path="/d-report" component={ReportsPage} exact />
					<Route path="/transactions" component={TransactionsPage} exact />
				</IonRouterOutlet>
			);
		case 'farmer':
			return (
				<IonRouterOutlet id="main">
					{FarmerMenu.appPages.map((page) => (
						<Route key={page.url} path={page.url} component={page.component} exact />
					))}
					<Redirect from="/" to={FarmerMenu.appPages[0].url} exact />
					<Route path="/farmer/servers/:id" component={FARMERChooseServerPage} exact />
					<Route path="/farmer/accounts/:id" component={FARMERChooseAccountPage} exact />
					<Route path="/worktimelogger" component={WorkTimeLogger} exact />
					<Route path="/d-report" component={ReportsPage} exact />
					<Route path="/transactions" component={TransactionsPage} exact />
				</IonRouterOutlet>
			);
		case 'pusher':
			return (
				<IonRouterOutlet id="main">
					{PusherMenu.appPages.map((page) => (
						<Route key={page.url} path={page.url} component={page.component} exact />
					))}
					<Redirect from="/" to={PusherMenu.appPages[0].url} exact />
					{/* <Route
            path="/pusher/apps/:id"
            component={PUSHERChooseOffersPage}
            exact
          /> */}
					<Route path="/worktimelogger" component={WorkTimeLogger} exact />
					<Route path="/d-report" component={ReportsPage} exact />
					<Route path="/transactions" component={TransactionsPage} exact />
				</IonRouterOutlet>
			);
		case 'tester': //
			return (
				<IonRouterOutlet id="main">
					{TesterMenu.appPages.map((page) => (
						<Route key={page.url} path={page.url} component={page.component} exact />
					))}
					<Redirect from="/" to={TesterMenu.appPages[0].url} exact />
					<Route path="/tester/apps/:id" component={TesterChooseAppPage} exact />
					<Route path="/worktimelogger" component={WorkTimeLogger} exact />
					<Route path="/d-report" component={ReportsPage} exact />
					<Route path="/transactions" component={TransactionsPage} exact />
				</IonRouterOutlet>
			);
		case 'pm':
			return (
				<IonRouterOutlet id="main">
					{PmMenu.appPages.map((page) => (
						<Route key={page.url} path={page.url} component={page.component} exact />
					))}
					<Redirect from="/" to={PmMenu.appPages[0].url} exact />
					<Route path="/apps/:id" component={PM_ChooseAppPage} exact />
					<Route path="/worktimelogger" component={WorkTimeLogger} exact />
					<Route path="/d-report" component={ReportsPage} exact />
					<Route path="/transactions" component={TransactionsPage} exact />
				</IonRouterOutlet>
			);

		case 'manager':
			return (
				<IonRouterOutlet id="main">
					{ManagerMenu.appPages.map((page) => (
						<Route key={page.url} path={page.url} component={page.component} exact />
					))}
					<Redirect from="/" to={ManagerMenu.appPages[0].url} exact />
					<Route path="/worktimelogger" component={WorkTimeLogger} exact />
					<Route path="/d-report" component={ReportsPage} exact />
					<Route path="/transactions" component={TransactionsPage} exact />
				</IonRouterOutlet>
			);
		case 'manageriosapps':
			return (
				<IonRouterOutlet id="main">
					{ManagerIosAppsMenu.appPages.map((page) => (
						<Route key={page.url} path={page.url} component={page.component} exact />
					))}
					<Redirect from="/" to={ManagerIosAppsMenu.appPages[0].url} exact />
					<Route path="/worktimelogger" component={WorkTimeLogger} exact />
					<Route path="/d-report" component={ReportsPage} exact />
					<Route path="/transactions" component={TransactionsPage} exact />
				</IonRouterOutlet>
			);
		default:
			return (
				<IonRouterOutlet id="main">
					<Redirect from="/" to="/worktimelogger" exact />
					<Route path="/worktimelogger" component={WorkTimeLogger} exact />
					<Route path="/d-report" component={ReportsPage} exact />
				</IonRouterOutlet>
			);
	}
}

class App extends React.Component {
	componentDidMount() {
		if (UserService.isLogged()) {
			this.checkAuth();
		}
	}

	async checkAuth() {
		const res = await API.getProfile();

		if (UserService.getUser().role != res.data.role) {
			UserService.setUser(res.data);
			window.location.href = '/';
		}
		UserService.setUser(res.data);
	}

	render() {
		return (
			<IonApp>
				{UserService.isLogged() ? (
					UserService.getUser().is_confirmed ? (
						<IonReactRouter>
							<IonSplitPane contentId="main">
								<IonMenu contentId="main" type="overlay">
									<div className="ion-padding">
										<h2>AppTraf</h2>
										<p style={{ padding: 0, margin: 0 }}>User id: {UserService.getUser().id}</p>
										<p style={{ padding: 0, margin: 0 }}>Пользователь: {UserService.getUser().name}</p>
										<p style={{ padding: 0, margin: 0 }}>Логин: {UserService.getUser().login}</p>
									</div>
									{getMenu()}
									{/* {UserService.getUser().can_change_role ? (
                    <IonItem>
                      <IonInput placeholder="Сменить роль"></IonInput>
                      <IonButtons slot="end">
                        <IonButton><IonIcon icon={checkmarkDone}></IonIcon></IonButton>
                      </IonButtons>
                    </IonItem>
                  ) : (
                    false
                  )} */}
									{UserService.getUser().show_finance_bot ? (
										<IonButton
											onClick={() => {
												window.open(`https://t.me/apptraf_finances_bot?start=${UserService.getToken()}`, '_blank');
											}}>
											Открыть бота финанса
										</IonButton>
									) : (
										false
									)}
									<IonButton routerLink="/worktimelogger">Табель посещаемости</IonButton>
									{/* <IonButton routerLink="/transactions">Мой KPI</IonButton> */}
									<IonButton routerLink="/d-report">Отчеты</IonButton>

									<IonButton
										onClick={() => {
											if (window.confirm('Вы действительно хотите выйти?')) {
												UserService.dropUserData();
												document.location.href = '/auth';
											}
										}}
										color="danger">
										Выйти&nbsp;<IonIcon icon={logOut}></IonIcon>
									</IonButton>
								</IonMenu>

								{getOutlets()}
							</IonSplitPane>
						</IonReactRouter>
					) : (
						<IonReactRouter>
							<>
								<Route path="/moderate" component={WaitingReviewPage} exact />
								<Redirect from="/" to="/moderate" exact />
							</>
						</IonReactRouter>
					)
				) : (
					<IonReactRouter>
						<>
							<Route path="/register" component={RegisterPage} exact />
							<Route path="/auth" component={AuthPage} exact />
							<Redirect from="/" to="/auth" exact />
						</>
					</IonReactRouter>
				)}
			</IonApp>
		);
	}
}

export default App;
