import React from 'react';

export default class extends React.Component<{ title: string; value: string }> {
	render(): React.ReactNode {
		return (
			<>
				<p style={{ fontSize: 12, fontWeight: 700, marginBottom: 2 }}>{this.props.title}</p>
				<p style={{ fontSize: 12, fontWeight: 400 }}>{this.props.value}</p>
			</>
		);
	}
}
