import { IonPage, IonHeader, IonToolbar, IonButtons, IonMenuButton, IonTitle, IonContent, IonTextarea, IonItem, IonInput, IonLabel } from '@ionic/react';
import React from 'react';

import API from '../../API';

export default class extends React.Component {
	state = {
		code: '',
	};

	key = 1;
	proxyUrl = 'http://bubenchick-07.top';
	methodsCount = 4;
	classesCount = 4;

	constructor(props: any) {
		super(props);
	}

	componentDidMount() {}

	render() {
		return (
			<IonPage>
				<IonHeader>
					<IonToolbar>
						<IonButtons slot="start">
							<IonMenuButton />
						</IonButtons>
						<IonTitle>Генератор мусора</IonTitle>
					</IonToolbar>
				</IonHeader>
				<IonContent>
					<div className="ion-padding">
						<IonItem>
							<IonLabel position="floating">Прокси</IonLabel>

							<IonInput
								placeholder="bubenchick-07.top (БЕЗ http://)"
								onIonChange={(e) => {
									this.proxyUrl = e.detail.value!;
								}}
								clearInput></IonInput>
						</IonItem>
						<IonItem>
							<IonLabel position="floating">Ключ</IonLabel>
							<IonInput
								placeholder="X"
								value={this.key}
								type="number"
								onIonChange={(e) => {
									this.key = parseInt(e.detail.value!);
								}}
								clearInput></IonInput>
						</IonItem>
						<IonItem>
							<IonLabel position="floating">Количество методов</IonLabel>
							<IonInput
								placeholder="X"
								value={this.methodsCount}
								type="number"
								onIonChange={(e) => {
									this.methodsCount = parseInt(e.detail.value!);
								}}
								clearInput></IonInput>
						</IonItem>
						<IonItem>
							<IonLabel position="floating">Количество классов</IonLabel>
							<IonInput
								placeholder="X"
								value={this.classesCount}
								type="number"
								onIonChange={(e) => {
									this.classesCount = parseInt(e.detail.value!);
								}}
								clearInput></IonInput>
						</IonItem>

						<IonItem
							button
							onClick={async () => {
								const res = await API.developerAndroidTrashCodegenerator(this.key, this.proxyUrl, this.classesCount, this.methodsCount);
								this.setState({
									code: res.data,
								});
							}}>
							<IonLabel style={{ color: 'green', fontSize: 18, fontWeight: 'bold' }}>Сгененировать</IonLabel>
						</IonItem>

						<IonTextarea autoGrow={true} value={this.state.code}></IonTextarea>
					</div>
				</IonContent>
			</IonPage>
		);
	}
}
