import React from 'react';

export default class extends React.Component<{ title: string; value?: boolean }> {
	render(): React.ReactNode {
		return (
			<div>
				<p style={{ fontSize: 12, fontWeight: 700, marginBottom: 2 }}>{this.props.title}</p>

				<p style={{ fontSize: 12, fontWeight: 400 }}>
					{this.props.value == null ? 'Отсутcтвует' : undefined}
					{this.props.value != null ? (
						<span style={{ color: 'grey' }}>
							<span style={{ color: this.props.value ? 'green' : 'grey' }}>ДА</span>
							&nbsp;|&nbsp;
							<span style={{ color: this.props.value ? 'grey' : 'red' }}>НЕТ</span>
						</span>
					) : undefined}
				</p>
			</div>
		);
	}
}
