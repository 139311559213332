import { IonPage, IonHeader, IonToolbar, IonButtons, IonMenuButton, IonTitle, IonContent, IonList, IonButton, IonItem, IonLabel, IonIcon, IonAlert, IonPopover } from '@ionic/react';
import React from 'react';
import { refreshOutline } from 'ionicons/icons';

import API from '../../API';
import LoadingFullScreen from '../../components/LoadingFullScreen';

export default class extends React.Component {
	state: any = {
		admins: null,
	};

	componentDidMount() {
		this.getTransactions();
	}

	async getTransactions() {
		const res = await API.managerGetTransactions();
		if (res.success) {
			this.setState({
				admins: res.data,
			});
		}
	}

	render() {
		return (
			<IonPage>
				<IonHeader>
					<IonToolbar>
						<IonButtons slot="start">
							<IonMenuButton />
						</IonButtons>
						<IonTitle>Выплаты</IonTitle>
					</IonToolbar>
				</IonHeader>
				<IonContent>
					{this.state.admins == null ? (
						<LoadingFullScreen />
					) : (
						<div className="ion-padding">
							{this.state.admins.length == 0 ? (
								<p>Выплат нет</p>
							) : (
								<IonList>
									{this.state.admins.map((admin: any) => {
										return (
											<IonItem key={'admins_' + admin.id}>
												<IonLabel>
													<h4>{admin.name}</h4>
													<p>Выплата: {admin.balance} руб.</p>
												</IonLabel>
												<IonButtons>
													<IonButton
														onClick={async () => {
															await API.managerCompleteWithdraw(admin.id, admin.balance * -1);
															this.getTransactions();
														}}
														color="success">
														Выплачено
													</IonButton>
												</IonButtons>
											</IonItem>
										);
									})}
								</IonList>
							)}
						</div>
					)}
				</IonContent>
			</IonPage>
		);
	}
}
