import React from 'react';

export default class extends React.Component<{ role: string; name?: string }> {
	render(): React.ReactNode {
		return (
			<div>
				<p style={{ fontSize: 12, fontWeight: 700, marginBottom: 0 }}>{this.props.role}</p>
				<p style={{ fontSize: 12, fontWeight: 400 }}>{this.props.name ?? 'Не установлен'}</p>
			</div>
		);
	}
}
