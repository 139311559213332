import { IonImg, IonSpinner } from '@ionic/react';
import React, { CSSProperties } from 'react';

export default class extends React.Component<{ id: any; style?: CSSProperties }> {
	state = {
		isExists: null,
	};

	componentDidMount() {
		fetch(`/assets/partners_logo/${this.props.id}.png`)
			.then((res) => {
				return res.blob();
			})
			.then((res) => {
				this.setState({
					isExists: res.type != 'text/html',
				});
			})
			.catch(() => {
				this.setState({
					isExists: false,
				});
			});
	}

	render(): React.ReactNode {
		return (
			<div style={{ ...this.props.style }}>
				{this.state.isExists == null ? <IonSpinner /> : this.state.isExists == true ? <IonImg src={`/assets/partners_logo/${this.props.id}.png`}></IonImg> : <IonImg src={`/assets/no-logo.png`}></IonImg>}
			</div>
		);
	}
}
