import {
	IonPage,
	IonHeader,
	IonToolbar,
	IonButtons,
	IonTitle,
	IonContent,
	IonList,
	IonItem,
	IonLabel,
	IonBackButton,
	IonListHeader,
	IonSegment,
	IonSegmentButton,
	IonInput,
	IonCheckbox,
	IonTextarea,
	IonSelect,
	IonSelectOption,
} from '@ionic/react';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { toast } from 'react-toastify';

import LoadingFullScreen from '../../components/LoadingFullScreen';
import API from '../../API';

interface Props
	extends RouteComponentProps<{
		id: string;
	}> {}

export default class extends React.Component<Props> {
	state: any = {
		account: null,
		stores: [],
		paymentsNames: [],
		cardTypes: [],
		newTag: '',
	};

	componentDidMount() {
		this.setState({
			account: null,
			stores: [],
		});
		this.getAccount();
	}

	async getAccount() {
		const res = await API.farmerAccountInfo(this.props.match.params.id);
		res.data.account.tags = JSON.parse(res.data.account.tags);
		if (res.success) {
			this.setState({
				account: res.data.account,
				stores: res.data.stores,
				paymentsNames: res.data.paymentsNames,
				cardTypes: res.data.cardTypes,
			});
		}
	}

	render() {
		const account = this.state.account;
		return (
			<IonPage>
				<IonHeader>
					<IonToolbar>
						<IonButtons slot="start">
							<IonBackButton defaultHref="/farmer/accounts"></IonBackButton>
						</IonButtons>
						<IonTitle>Информация об аккаунте</IonTitle>
					</IonToolbar>
				</IonHeader>
				<IonContent>
					{this.state.account == null ? (
						<LoadingFullScreen />
					) : (
						<div className="ion-padding">
							<IonList>
								<br />
								<IonListHeader>
									<p>Информация об аккаунте</p>
								</IonListHeader>

								<IonSegment
									value={this.state.account.store_id}
									onIonChange={(e) => {
										account.store_id = e.detail.value;
										this.setState({
											account: account,
										});
									}}>
									{this.state.stores.map((s: any) => (
										<IonSegmentButton key={'store_' + s.id} value={s.id}>
											<img
												style={{
													height: 30,
													width: 30,
													right: 8,
													top: 8,
												}}
												src={`/assets/store_${s.id}.svg`}
												alt=""
											/>
											<IonLabel>{s.title}</IonLabel>
										</IonSegmentButton>
									))}
								</IonSegment>

								<IonItem>
									<IonInput
										placeholder="Логин от стора"
										value={account.login}
										onIonChange={(e) => {
											account.login = e.detail.value;
											this.setState({
												account: account,
											});
										}}
										clearInput></IonInput>
								</IonItem>
								<IonItem>
									<IonInput
										placeholder="Пароль от стора"
										value={account.password}
										onIonChange={(e) => {
											account.password = e.detail.value;
											this.setState({
												account: account,
											});
										}}
										clearInput></IonInput>
								</IonItem>
								<IonItem>
									<IonTextarea
										placeholder="Дополнительная информация от стора"
										value={account.notes}
										autoGrow={true}
										onIonChange={(e) => {
											account.notes = e.detail.value;
											this.setState({
												account: account,
											});
										}}></IonTextarea>
								</IonItem>
								{account.store_id == 2 ? (
									<>
										<IonItem>
											<IonLabel>Это удаленный аккаунт</IonLabel>
											<IonCheckbox
												checked={account.is_remote}
												onIonChange={(e) => {
													account.is_remote = e.detail.checked;

													this.setState({
														account: account,
													});
												}}></IonCheckbox>
										</IonItem>
									</>
								) : (
									false
								)}

								{account.store_id == 2 || account.store_id == 1 ? (
									<>
										<IonItem>
											<IonLabel>Платежка</IonLabel>
											<IonSelect
												interface="popover"
												value={account.payment_name}
												placeholder="Не выбран"
												onIonChange={(e) => {
													account.payment_name = e.detail.value;
													this.setState({
														account: account,
													});
												}}>
												{this.state.paymentsNames.map((e: any) => {
													return (
														<IonSelectOption key={'payment_name_' + e} value={e}>
															{e}
														</IonSelectOption>
													);
												})}
											</IonSelect>
										</IonItem>

										<IonItem>
											<IonLabel>Тип карты</IonLabel>
											<IonSelect
												interface="popover"
												value={account.card_type}
												placeholder="Не выбран"
												onIonChange={(e) => {
													account.card_type = e.detail.value;
													this.setState({
														account: account,
													});
												}}>
												{this.state.cardTypes.map((e: any) => {
													return (
														<IonSelectOption key={'cart_type_' + e} value={e}>
															{e}
														</IonSelectOption>
													);
												})}
											</IonSelect>
										</IonItem>
										<IonItem>
											<IonLabel>Последние 4 цифры</IonLabel>
											<IonInput
												maxlength={4}
												type="number"
												placeholder="XXXX"
												value={account.card_last_numbers}
												onIonChange={(e) => {
													account.card_last_numbers = e.detail.value;
													this.setState({
														account: account,
													});
												}}
												clearInput></IonInput>
										</IonItem>
									</>
								) : (
									false
								)}

								<IonItem
									button
									onClick={async () => {
										if (!account.store_id) {
											return alert('Выберите стор');
										}
										if (!account.login) {
											return alert('Введите логин от аккаунта');
										}

										await API.farmerSaveAccountData({
											...account,
											tags: JSON.stringify(account.tags),
										});
										this.getAccount();

										toast.info('Изменения сохранены');
									}}>
									<IonLabel style={{ fontWeight: 'bold', color: 'green' }}>Сохранить изменения</IonLabel>
								</IonItem>
								<br />
								<br />
								<br />

								{account.is_farmed ? (
									<IonItem>
										<IonLabel style={{ fontWeight: 'bold', color: 'green' }}>Аккаунт зафармлен {new Date(account.farmed_at).toLocaleString()}</IonLabel>
									</IonItem>
								) : (
									<IonItem
										button
										onClick={async () => {
											await API.farmerCompleteAccountFarm(account.id);
											this.getAccount();
										}}>
										<IonLabel style={{ fontWeight: 'bold', color: 'green' }}>Сдать аккаунт и зафиксировать KPI</IonLabel>
									</IonItem>
								)}
							</IonList>
						</div>
					)}
				</IonContent>
			</IonPage>
		);
	}
}
