import { IonApp, IonContent } from '@ionic/react';
import React from 'react';

import API from '../API';
import UserService from '../UserService';

export default class extends React.Component {
	componentDidMount() {
		setInterval(() => {
			this.checkAuth();
		}, 3000);
		this.checkAuth();
	}

	async checkAuth() {
		const res = await API.getProfile();

		if (res.data.is_confirmed) {
			UserService.setUser(res.data);
			window.location.href = '/';
		}
	}

	render() {
		return (
			<IonApp>
				<IonContent
					style={{
						textAlign: 'center',
					}}>
					<div style={{ paddingTop: '30vh' }}>
						<h1>AppTraf</h1>
						<p>Ваша учетная запись находится на модерации</p>
						<p>ID: {UserService.getUser().id}</p>
						<p>Login: {UserService.getUser().login}</p>
					</div>
				</IonContent>
			</IonApp>
		);
	}
}
