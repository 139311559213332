import { IonPage, IonHeader, IonToolbar, IonButtons, IonMenuButton, IonTitle, IonContent, IonButton, IonLabel, IonIcon, IonItem, IonList } from '@ionic/react';
import React from 'react';
import { checkmark } from 'ionicons/icons';

import API from '../../../API';
import LoadingFullScreen from '../../../components/LoadingFullScreen';

export default class extends React.Component {
	state: any = {
		apps: null,
	};

	componentDidMount() {
		this.getStatistics();
	}

	async getStatistics() {
		const res = await API.getAppGalleryApps();
		if (res.success) {
			this.setState({
				apps: res.data,
			});
		}
	}

	render() {
		return (
			<IonPage>
				<IonHeader>
					<IonToolbar>
						<IonButtons slot="start">
							<IonMenuButton />
						</IonButtons>
						<IonTitle>Приложения App Gallery</IonTitle>
					</IonToolbar>
				</IonHeader>
				<IonContent>
					{this.state.apps == null ? (
						<LoadingFullScreen />
					) : (
						<IonList>
							{this.state.apps.map((app: any) => (
								<IonItem key={app.id}>
									<IonIcon slot="start" icon={checkmark} color={app.is_enabled ? 'success' : 'danger'} />
									<IonLabel>
										<h2>{app.title}</h2>
										<p>Опубликовал: {app.publisher_name}</p>
										<p style={{ color: app.is_enabled ? 'green' : 'red' }}>Клоака: {app.is_enabled ? 'ВКЛ' : 'ВЫКЛ'}</p>
										<p style={{ color: app.is_in_store ? 'green' : 'red' }}>Есть в сторе: {app.is_in_store ? 'ДА' : 'НЕТ'}</p>
									</IonLabel>
									<IonButtons>
										<IonButton routerLink={'/appgallery/' + app.id} color="success">
											Подробнее
										</IonButton>
									</IonButtons>
								</IonItem>
							))}
						</IonList>
					)}
				</IonContent>
			</IonPage>
		);
	}
}
