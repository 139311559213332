import { useFilePicker } from 'use-file-picker';
import {
	IonPage,
	IonHeader,
	IonToolbar,
	IonButtons,
	IonTitle,
	IonContent,
	IonList,
	IonButton,
	IonItem,
	IonLabel,
	IonRadioGroup,
	IonRadio,
	IonMenuButton,
	IonIcon,
	IonTextarea,
	IonItemDivider,
	IonPopover,
} from '@ionic/react';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { checkbox } from 'ionicons/icons';

import API from '../../API';
import UserService from '../../UserService';

const ru = require('moment/locale/ru');

function getWorkDescription(mode: string) {
	switch (mode) {
		case 'normal':
			return 'Штатный режим работы';
		case 'timeoff':
			return 'Отгул';
		case 'hospital':
			return 'Больничный';
		case 'remote':
			return 'Удаленка';
		case 'remote_hospital':
			return 'Удаленка по болезни';
		default:
			return '';
	}
}

const WorkTimeLogger: React.FC = () => {
	const [currentTime, setCurrentTime] = useState<null | string>(null);
	const [workDay, setWorkDay] = useState<null | any>(null);
	const [inOffice, setInOffice] = useState(false);
	const [workDays, setWorkDays] = useState([]);
	const [workMode, setWorkMode] = useState('remote');
	const [hospitalProof, setHospitalProof] = useState<string | null>(null);
	const [endWorkDayReport, setEndWorkDayReport] = useState('');
	const [daysInMonth, setDaysInMonth] = useState(0);
	const [workedMonthMinutes, setWorkedMonthMinutes] = useState(0);
	const [personalWorkdayMinutes, setPersonalWorkdayMinutes] = useState(0);

	const [report, setReport] = useState('');

	const [openFileSelector, { filesContent, loading }] = useFilePicker({
		accept: 'image/*',
		imageSizeRestrictions: {},
		readAs: 'ArrayBuffer',
	});

	useEffect(() => {
		if (filesContent.length == 0) {
			return;
		}

		const image = filesContent[0].content as unknown;
		const base64 = btoa(new Uint8Array(image as ArrayBuffer).reduce((data, byte) => data + String.fromCharCode(byte), ''));
		API.uploadFile(base64).then(({ data }) => {
			setHospitalProof(data.url);
		});
	}, [filesContent]);

	useEffect(() => {
		moment.locale('ru', ru);
		API.sharedWorkTimeGetCurrentTime().then((r) => {
			setCurrentTime(moment(new Date(r.data.date)).format('dddd, DD MMMM YYYY'));
			setInOffice(r.data.inOffice);
			setWorkDay(r.data.workDay);
			setWorkDays(r.data.workDays);
			setDaysInMonth(r.data.daysInMonth);
			setWorkedMonthMinutes(r.data.workedMonthMinutes);
			setPersonalWorkdayMinutes(r.data.personalWorkdayMinutes);
		});
	}, [currentTime]);

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonButtons slot="start">
						<IonMenuButton />
					</IonButtons>
					<IonTitle>Табель посещаемости</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent>
				{currentTime ? (
					<div className="ion-padding">
						<p>Текущая дата: {currentTime}</p>
						<p>Норма минут за месяц: {daysInMonth * personalWorkdayMinutes}</p>
						<p>Отработано минут за месяц: {workedMonthMinutes}</p>

						{workDay ? (
							workDay.ended ? (
								<div>
									<h2>Рабочий день закончен</h2>
									<p>Отработано: {(workDay.work_duration / 60).toFixed(1)} часов</p>
								</div>
							) : (
								<div>
									<br />
									<br />

									<p>Вы начали работу {new Date(workDay.started).toString()}</p>
									<br />
									<IonItemDivider>Завершение рабочего дня</IonItemDivider>
									<IonTextarea onIonChange={(e) => setEndWorkDayReport(e.detail.value || '')} placeholder="Что вы сделали за день?" autoGrow={true} style={{ minHeight: 100 }} />
									<br />
									<IonButton
										disabled={endWorkDayReport.length <= 10}
										onClick={async () => {
											const res = await API.sharedWorkTimeEndWork(endWorkDayReport);
											if (res.success) {
												setCurrentTime('Updaing...');
											}
										}}>
										Завершить
									</IonButton>
								</div>
							)
						) : (
							<div>
								{['remote_hospital', 'hospital'].includes(workMode) ? (
									<IonItem>
										{hospitalProof ? <IonIcon icon={checkbox} color={'success'} slot="start"></IonIcon> : null}
										<IonLabel>
											<h2>Загрузите фото подтвеждение</h2>
											<p>градусник, селфи из больницы, поликлиники и т.д.</p>
										</IonLabel>
										<IonButton onClick={openFileSelector} slot="end">
											Загрузить фото
										</IonButton>
									</IonItem>
								) : null}
								<IonButton
									onClick={async () => {
										if (['remote_hospital', 'hospital'].includes(workMode) && hospitalProof == null) {
											return alert('Загрузите пруф');
										}
										const res = await API.sharedWorkTimeStartWork(workMode, hospitalProof);
										if (res.success) {
											setCurrentTime('Updaing...');
										}
									}}>
									Начать
								</IonButton>
							</div>
						)}

						<IonList>
							{workDays.map((wd: any) => {
								return (
									<IonItem key={'work_day_' + wd.id}>
										<IonLabel>
											<p>ID: {wd.id}</p>
											<h2>Начало: {moment(new Date(wd.started)).format('HH:mm dddd, DD MMMM YYYY')}</h2>
											{wd.ended ? <h2>Конец: {moment(new Date(wd.ended)).format('HH:mm dddd, DD MMMM YYYY')}</h2> : false}
											<p>Длительность: {wd.work_duration} мин.</p>
											{wd.salary != 0 ? <p>ЗП: {wd.salary} руб.</p> : false}
											{wd.notes.length != 0 ? <p>{wd.notes}</p> : false}
										</IonLabel>
										{!wd.report && (
											<IonButtons>
												<IonButton id={'worktime_log_' + wd.id}>Написать отчет</IonButton>
												<IonPopover trigger={'worktime_log_' + wd.id} showBackdrop={true} size="auto">
													<p>{moment(new Date(wd.started)).format('HH:mm dddd, DD MMMM YYYY')}</p>
													<IonTextarea onIonChange={(e) => setReport(e.detail.value || '')} placeholder="Что вы сделали за день?" style={{ minHeight: 300 }} />
													<IonButton
														onClick={() => {
															API.sharedWorkSendReport(wd.id, report).then((res) => {
																if (res.success) {
																	setReport('');
																	alert('Отправлено!');
																	window.location.reload();
																}
															});
														}}>
														Отправить
													</IonButton>
												</IonPopover>
											</IonButtons>
										)}
									</IonItem>
								);
							})}
						</IonList>
					</div>
				) : (
					false
				)}
			</IonContent>
		</IonPage>
	);
};

export default WorkTimeLogger;
