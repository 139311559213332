import React from 'react';
import { createRoot } from 'react-dom/client';
import { ToastContainer } from 'react-toastify';

import App from './App';
import 'react-toastify/dist/ReactToastify.min.css';
const container = document.getElementById('root');
const root = createRoot(container!); // createRoot(container!) if you use TypeScript
root.render(
	<>
		<ToastContainer />
		<App />
	</>,
);
