import { IonPage, IonHeader, IonToolbar, IonButtons, IonMenuButton, IonTitle, IonContent, IonList, IonButton, IonItem, IonLabel, IonToast } from '@ionic/react';
import React from 'react';

import API from '../../API';
import LoadingFullScreen from '../../components/LoadingFullScreen';

export default class extends React.Component {
	state: any = {
		servers: null,
		toastMsg: null,
	};

	componentDidMount() {
		this.getServers();
	}

	async getServers() {
		const res = await API.developerGetServersList();
		if (res.success) {
			this.setState({
				servers: res.data,
			});
		}
	}

	render() {
		return (
			<IonPage>
				<IonToast
					isOpen={this.state.toastMsg != null}
					onDidDismiss={() =>
						this.setState({
							toastMsg: null,
						})
					}
					message={this.state.toastMsg}
					duration={1000}
				/>
				<IonHeader>
					<IonToolbar>
						<IonButtons slot="start">
							<IonMenuButton />
						</IonButtons>
						<IonTitle>Виртуальные столы</IonTitle>
						<IonButtons slot="end">
							<IonButton
								onClick={async () => {
									const res = await API.developerDeployServer();
									if (res.success) {
										alert('Сервер успешно запущен');
										this.getServers();
									}
								}}>
								Создать стол
							</IonButton>
						</IonButtons>
					</IonToolbar>
				</IonHeader>
				<IonContent>
					{this.state.servers == null ? (
						<LoadingFullScreen />
					) : (
						<div className="ion-padding">
							{this.state.servers.length == 0 ? (
								<div>
									<p>
										Вы не запускали серверы. Характеристики сервера ОЗУ 32GB | 8 ЯДЕР | 100GB SSD. Если нужно мощнее то пишите в тг. Просто так не создавайте, я в любом случае получу уведомление о том
										что именно вы запустили сервер
									</p>
								</div>
							) : (
								<div>
									<h2 style={{ color: 'red' }}>НЕ ЗАБЫВАЙТЕ УДАЛЯТЬ СЕРВЕР</h2>
									<h3>У ВСЕХ СЕРВЕРОВ ДОЛЖЕН БЫТЬ СТАТУС deleted</h3>

									<IonList>
										{this.state.servers.map((server: any) => {
											return (
												<IonItem key={'developer_server_' + server.id}>
													<IonLabel>
														<h2
															onClick={() => {
																navigator.clipboard.writeText(server.ip);
																this.setState({
																	toastMsg: 'Скопировано !',
																});
															}}>
															IP: {server.ip}
														</h2>
														<p
															onClick={() => {
																navigator.clipboard.writeText(server.username);
																this.setState({
																	toastMsg: 'Скопировано !',
																});
															}}>
															Пользователь: {server.username}
														</p>
														<p
															onClick={() => {
																navigator.clipboard.writeText(server.password);
																this.setState({
																	toastMsg: 'Скопировано !',
																});
															}}>
															Пароль: {server.password}
														</p>
														<p>Статус: {server.status}</p>
													</IonLabel>
													<IonButtons slot="end">
														<IonButton
															onClick={async () => {
																if (window.confirm('Вы действительно хотите удалить сервер ' + server.ip)) {
																	await API.developerDeleteServer(server.id);
																	this.getServers();
																}
															}}
															color="danger">
															Удалить
														</IonButton>
													</IonButtons>
												</IonItem>
											);
										})}
									</IonList>
								</div>
							)}
						</div>
					)}
				</IonContent>
			</IonPage>
		);
	}
}
