import { IonPage, IonHeader, IonToolbar, IonButtons, IonMenuButton, IonTitle, IonContent, IonList, IonItem, IonLabel, IonInput, IonButton, IonIcon } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import DataTable from 'react-data-table-component';
import { add, linkOutline, pencilOutline } from 'ionicons/icons';

import API from '../../API';
import PusherFolderLinksComponent from '../../components/Pusher/PusherFolderLinksComponent';

const PusherFoldersPage: React.FC = () => {
	const [folders, setFolders] = useState<Array<any>>([]);

	useEffect(() => {
		API.pusherFolderList().then(({ data }) => {
			setFolders(data);
		});
	}, []);

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonButtons slot="start">
						<IonMenuButton />
					</IonButtons>
					<IonTitle>Папки</IonTitle>
					<IonButtons slot="end">
						<IonButton
							onClick={() => {
								API.pusherFolderCreate().then(({ data }) => {
									setFolders(data);
								});
							}}>
							Добавить&nbsp;
							<IonIcon icon={add} />
						</IonButton>
					</IonButtons>
				</IonToolbar>
			</IonHeader>
			<IonContent>
				<DataTable
					data={folders}
					columns={[
						{
							name: 'ID',
							selector: (row) => row.id,
						},
						{
							name: 'Название',
							selector: (row) => row.name,
						},
						{
							name: 'Обновлен',
							selector: (row) => new Date(row.updated_at).toLocaleString(),
						},
						{
							name: 'Создан',
							selector: (row) => new Date(row.created_at).toLocaleString(),
						},
						{
							name: 'Действия',
							cell: (row) => {
								return (
									<IonButtons>
										<IonButton
											onClick={() => {
												const promt = prompt('Новое название папки');
												if (promt) {
													API.pusherFolderRename(row.id, promt).then(({ data }) => {
														setFolders(data);
													});
												}
											}}>
											<IonIcon icon={pencilOutline}></IonIcon>
										</IonButton>
									</IonButtons>
								);
							},
						},
					]}
					expandableRows
					expandableRowsComponent={(row) => {
						return <PusherFolderLinksComponent folder_id={row.data.id} />;
					}}
				/>
			</IonContent>
		</IonPage>
	);
};

export default PusherFoldersPage;
