import { IonContent, IonIcon, IonItem, IonLabel, IonList, IonListHeader, IonMenu, IonMenuToggle, IonNote } from '@ionic/react';
import React from 'react';
import { RouteComponentProps, useLocation } from 'react-router-dom';
import { bagHandle } from 'ionicons/icons';

import './Menu.css';
import MBCampaingsPage from '../pages/MediaBuyer/MBCampaingsPage';

interface AppPage {
	url: string;
	icon: string;
	title: string;
	component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
}

export const appPages: AppPage[] = [
	{
		title: 'Кампании',
		url: '/mb/campaigns',
		icon: bagHandle,
		component: MBCampaingsPage,
	},
];

const Menu: React.FC = () => {
	const location = useLocation();

	return (
		<IonContent>
			<IonList id="inbox-list">
				{appPages.map((appPage, index) => {
					return (
						<IonMenuToggle key={index} autoHide={false}>
							<IonItem
								style={{
									'--height': 20,
								}}
								className={location.pathname.startsWith(appPage.url) ? 'selected' : ''}
								routerLink={appPage.url}
								routerDirection="none"
								lines="none"
								detail={false}>
								<IonIcon icon={appPage.icon} size={'4'} />
								<IonLabel style={{ paddingLeft: 8, fontSize: 8 }}>{appPage.title}</IonLabel>
							</IonItem>
						</IonMenuToggle>
					);
				})}
			</IonList>
		</IonContent>
	);
};

export default Menu;
