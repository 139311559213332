import { IonPage, IonHeader, IonToolbar, IonButtons, IonMenuButton, IonTitle, IonContent, IonList, IonBackButton, IonItem, IonLabel, IonIcon } from '@ionic/react';
import React from 'react';
import { checkmark } from 'ionicons/icons';

import API from '../../API';
import LoadingFullScreen from '../../components/LoadingFullScreen';

export default class extends React.Component {
	state: any = {
		apps: null,
		selectedApp: null,
	};

	componentDidMount() {
		this.MBGetApps();
	}

	async MBGetApps() {
		const res = await API.MBGetApps();
		if (res.success) {
			this.setState({
				apps: res.data,
			});
		}
	}

	render() {
		return (
			<IonPage>
				<IonHeader>
					<IonToolbar>
						<IonButtons slot="start">
							<IonBackButton defaultHref="/mb/campaigns"></IonBackButton>
						</IonButtons>
						<IonTitle>Создать кампанию</IonTitle>
					</IonToolbar>
				</IonHeader>
				<IonContent>
					{this.state.apps == null ? (
						<LoadingFullScreen />
					) : (
						<div className="ion-padding">
							<h2>Выберите приложение</h2>
							<IonList>
								{this.state.apps.map((a: any) => {
									return (
										<IonItem
											onClick={() => {
												this.setState({
													selectedApp: a,
												});
											}}
											key={'app_' + a.id}>
											<IonLabel>
												<h2>{a.title}</h2>
												{a.countries.map((c: any) => {
													return <p key={'app_' + a.id + '_country_' + c.id}>{c.title}</p>;
												})}
											</IonLabel>

											{this.state.selectedApp?.id == a.id ? <IonIcon color="success" icon={checkmark}></IonIcon> : false}
										</IonItem>
									);
								})}
							</IonList>
						</div>
					)}
				</IonContent>
			</IonPage>
		);
	}
}
