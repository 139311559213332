import { IonPage, IonHeader, IonToolbar, IonButtons, IonMenuButton, IonTitle, IonContent, IonItem, IonLabel, IonSelect, IonSelectOption, IonDatetime, IonPopover, IonText, IonButton } from '@ionic/react';
import React from 'react';
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import API from '../../API';
import LoadingFullScreen from '../../components/LoadingFullScreen';

export default class extends React.Component {
	map: any = null;
	state: any = {
		offers: null,
		employers: null,
		chart: [],
		stores: [],
		focusRole: 'developer',
		filterIsInStore: null,
		filterInterval: null,
		filterStoreId: null,

		filterDateFrom: null,
		filterDateTo: null,
	};

	interval = '24';

	componentDidMount() {
		this.getStatistics();
	}

	componentDidCatch() {}

	async getStatistics() {
		const res = await API.getEmployeesKpi();
		console.log(res);
		this.setState({
			employers: res.data.employers,
			offers: res.data.offers,
			stores: res.data.stores,
		});

		this.loadChartData();
	}

	loadChartData() {
		this.setState({
			chart: this.state.employers
				.filter((e: any) => {
					let role = e.role;
					if (role == 'android-developer') {
						role = 'developer';
					}
					return role == this.state.focusRole;
				})
				.map((e: any) => {
					return {
						name: e.name,
						apps: this.state.offers
							.filter((offer: any) => {
								if (this.state.filterIsInStore == null) {
									return true;
								} else {
									return (offer.is_in_store == 1) == this.state.filterIsInStore;
								}
							})
							.filter((offer: any) => {
								if (this.state.filterStoreId == null) {
									return true;
								} else {
									return offer.store_id == this.state.filterStoreId;
								}
							})
							.filter((offer: any) => {
								if (this.state.filterDateFrom == null) {
									return true;
								} else {
									return new Date(offer.published_to_store_at) >= this.state.filterDateFrom;
								}
							})
							.filter((offer: any) => {
								if (this.state.filterDateTo == null) {
									return true;
								} else {
									return new Date(offer.published_to_store_at) <= this.state.filterDateTo;
								}
							})
							.filter((offer: any) => {
								switch (this.state.focusRole) {
									case 'developer':
										return offer.developer_id == e.id;
									case 'publisher':
										return offer.published_user_id == e.id;
									case 'none':
										return offer.designer_id == e.id;
									default:
										return false;
								}
							}).length,
					};
				}),
		});
	}

	render() {
		return (
			<IonPage>
				<IonHeader>
					<IonToolbar>
						<IonButtons slot="start">
							<IonMenuButton />
						</IonButtons>
						<IonTitle>KPI</IonTitle>
						<IonButtons slot="end">
							<IonButton routerLink="/kpi/farmers">KPI Фармеров</IonButton>
						</IonButtons>
					</IonToolbar>
				</IonHeader>
				<IonContent>
					{this.state.employers != null && this.state.offers != null ? (
						<>
							<IonItem>
								<IonLabel>Роль</IonLabel>
								<IonSelect
									interface="popover"
									value={this.state.focusRole}
									onIonChange={(e) => {
										this.setState({
											focusRole: e.detail.value,
										});
										this.loadChartData();
									}}>
									<IonSelectOption value="developer">Разработчики</IonSelectOption>
									<IonSelectOption value="publisher">Публикаторы</IonSelectOption>
									<IonSelectOption value="none">Дизайнеры</IonSelectOption>
								</IonSelect>
							</IonItem>
							<IonItem>
								<IonLabel>В сторе</IonLabel>
								<IonSelect
									interface="popover"
									value={this.state.filterIsInStore}
									onIonChange={(e) => {
										this.setState({
											filterIsInStore: e.detail.value,
										});
										this.loadChartData();
									}}>
									<IonSelectOption value={null}>Не важно</IonSelectOption>
									<IonSelectOption value={true}>В сторе</IonSelectOption>
									<IonSelectOption value={false}>Не в сторе</IonSelectOption>
								</IonSelect>
							</IonItem>
							<IonItem>
								<IonLabel>Сторы</IonLabel>
								<IonSelect
									interface="popover"
									value={this.state.filterStoreId}
									onIonChange={(e) => {
										this.setState({
											filterStoreId: e.detail.value,
										});
										this.loadChartData();
									}}>
									<IonSelectOption value={null}>Не важно</IonSelectOption>
									{this.state.stores.map((s: any) => {
										return (
											<IonSelectOption key={'kpi_store_filter_' + s.id} value={s.id}>
												{s.title}
											</IonSelectOption>
										);
									})}
								</IonSelect>
							</IonItem>
							<IonItem button id="interval-from">
								<IonLabel>Интервал от</IonLabel>
								<IonText slot="end">{this.state.filterDateFrom ? this.state.filterDateFrom.toLocaleString() : 'Не задано'}</IonText>
								<IonPopover trigger="interval-from" showBackdrop={false}>
									<IonDatetime
										presentation="date"
										onIonChange={(ev) => {
											const date = new Date(ev.detail.value! as string);
											date.setHours(0, 0, 0, 0);
											this.setState({
												filterDateFrom: date,
											});
											this.loadChartData();
										}}
									/>
								</IonPopover>
							</IonItem>
							<IonItem button id="interval-to">
								<IonLabel>Интервал до</IonLabel>
								<IonText slot="end">{this.state.filterDateTo ? this.state.filterDateTo.toLocaleString() : 'Не задано'}</IonText>
								<IonPopover trigger="interval-to" showBackdrop={false}>
									<IonDatetime
										presentation="date"
										onIonChange={(ev) => {
											const date = new Date(ev.detail.value! as string);
											date.setHours(23, 59, 59, 0);
											this.setState({
												filterDateTo: date,
											});
											this.loadChartData();
										}}
									/>
								</IonPopover>
							</IonItem>

							<ResponsiveContainer width="100%" height="90%">
								<BarChart
									width={500}
									height={300}
									data={this.state.chart}
									margin={{
										top: 5,
										right: 30,
										left: 20,
										bottom: 5,
									}}>
									<CartesianGrid strokeDasharray="3 3" />
									<XAxis dataKey="name" />
									<YAxis />
									<Tooltip
										content={({ active, payload, label }) => {
											if (active && payload && payload.length) {
												return (
													<div style={{ background: 'white', padding: 8 }}>
														<p>{`${label} : ${payload[0].value}`}</p>
													</div>
												);
											}
											return '';
										}}
									/>

									<Legend />
									<Bar dataKey="apps" barSize={20} fill="#8884d8" />
								</BarChart>
							</ResponsiveContainer>
						</>
					) : (
						<LoadingFullScreen />
					)}
				</IonContent>
			</IonPage>
		);
	}
}
