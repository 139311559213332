import { IonPage, IonHeader, IonToolbar, IonButtons, IonMenuButton, IonTitle, IonContent, IonList, IonItem, IonLabel } from '@ionic/react';
import React from 'react';

import API from '../../API';
import LoadingFullScreen from '../../components/LoadingFullScreen';

export default class extends React.Component {
	state: any = {
		comments: null,
	};

	componentDidMount() {
		this.getComments();
	}

	async getComments() {
		const res = await API.publisherGetAllAppComments();
		if (res.success) {
			this.setState({
				comments: res.data.comments,
			});
		}
	}

	render() {
		return (
			<IonPage>
				<IonHeader>
					<IonToolbar>
						<IonButtons slot="start">
							<IonMenuButton />
						</IonButtons>
						<IonTitle>Все комментарии</IonTitle>
					</IonToolbar>
				</IonHeader>
				<IonContent>
					{this.state.comments == null ? (
						<LoadingFullScreen />
					) : (
						<div className="ion-padding">
							{Object.entries(this.state.comments).length == 0 ? (
								<p>У вас нет комментариев</p>
							) : (
								<IonList>
									{Object.entries(this.state.comments).map(([key, commentsUnknown]) => {
										const comments = commentsUnknown as Array<any>;
										return (
											<IonList>
												<IonItem routerLink={'/publisher/apps/' + key} key={'uncomplete_app_' + key}>
													<IonLabel>
														<h2>{comments[0].app_title}</h2>

														{comments.map((c: any) => {
															return <p key={'uncompleted_comment_' + c.id}>{c.comment}</p>;
														})}
													</IonLabel>
												</IonItem>
											</IonList>
										);
									})}
								</IonList>
							)}
						</div>
					)}
				</IonContent>
			</IonPage>
		);
	}
}
