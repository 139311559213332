import {
	IonPage,
	IonHeader,
	IonToolbar,
	IonButtons,
	IonMenuButton,
	IonTitle,
	IonContent,
	IonTextarea,
	IonButton,
	IonItem,
	IonInput,
	IonModal,
	IonList,
	IonSpinner,
	IonItemGroup,
	IonLabel,
	IonItemDivider,
	IonGrid,
	IonCol,
	IonRow,
} from '@ionic/react';
import React from 'react';

import API from '../../../API';
import TypeSelectorComponent from '../../../components/TypeSelectorComponent';
import { countries } from '../../../Constants';

export default class extends React.Component {
	keywords = '';

	state: any = {
		selectCountiesOpened: false,
		selectedCountries: [],
		result: [],
		isLoading: false,
	};

	_countries: any = [];

	componentDidMount() {
		for (const [key, value] of Object.entries(countries)) {
			this._countries.push(value);
		}
	}

	render() {
		return (
			<IonPage>
				<IonModal
					isOpen={this.state.selectCountiesOpened}
					onDidDismiss={() => {
						this.setState({ selectCountiesOpened: false });
					}}>
					<TypeSelectorComponent
						beforeSelected={this.state.selectedCountries}
						items={this._countries}
						didSelected={(items: any) => {
							this.setState({
								selectedCountries: items,
								selectCountiesOpened: false,
							});
						}}
						didCancelled={() => this.setState({ selectCountiesOpened: false })}
					/>
				</IonModal>
				<IonHeader>
					<IonToolbar>
						<IonButtons slot="start">
							<IonMenuButton />
						</IonButtons>
						<IonTitle>Рейтинг ключей</IonTitle>
					</IonToolbar>
				</IonHeader>
				<IonContent>
					<div className="ion-padding">
						{this.state.selectedCountries.length == 0 ? (
							<IonButton onClick={() => this.setState({ selectCountiesOpened: true })}>Выбрать страны</IonButton>
						) : (
							<div>
								<p>
									Выбранные страны{' '}
									<span onClick={() => this.setState({ selectCountiesOpened: true })} style={{ color: 'green' }}>
										СМЕНИТЬ
									</span>
									&nbsp;&nbsp;&nbsp;
								</p>

								{this.state.selectedCountries.map((country: any) => (
									<p key={'sel_country_' + country.id}>{country.title}</p>
								))}
								<p onClick={() => this.setState({ selectedCountries: [] })} style={{ color: 'red' }}>
									Удалить все
								</p>
							</div>
						)}
						<p>Разделите кейворды через запятую ","</p>
						<IonItem>
							<IonInput placeholder="1xbet,vulkan,pin up,joy" onIonChange={(e) => (this.keywords = e.detail.value!)}></IonInput>
						</IonItem>
						<IonButton
							onClick={async () => {
								if (this.state.isLoading) {
									return;
								}
								if (this.state.selectedCountries.length == 0) {
									alert('Выберите страны');
									return;
								}
								if (this.keywords.split(',').length == 0) {
									alert('Введите ключевые слова');
									return;
								}
								this.setState({
									isLoading: true,
								});
								const res = await API.analyzeKeywordsScores(this.keywords.split(','), this.state.selectedCountries);

								this.setState({
									isLoading: false,
									result: res.data,
								});
								console.log(res);
							}}>
							{this.state.isLoading ? <IonSpinner></IonSpinner> : false}
							&nbsp;Загрузить
						</IonButton>

						<br />
						<br />
						<br />
						<p>Результаты</p>
						{this.state.result ? (
							<IonList>
								{this.state.result.map((it: any) => {
									return (
										<>
											<IonItemDivider key={it.country.code}>{it.country.title}</IonItemDivider>
											{it.keywords.map((k: any) => {
												return (
													<IonItem key={'term_' + k.term}>
														<IonGrid>
															<IonRow>
																<IonCol>
																	<p>{k.term}</p>
																</IonCol>
																<IonCol>
																	<p>Installs: {k.metrics.traffic.installs.avg?.toFixed(2) ?? 0.0}</p>
																</IonCol>
																<IonCol>
																	<p>Rank: {k.metrics.traffic.ranked.count?.toFixed(2) ?? 0.0}</p>
																</IonCol>
																<IonCol>
																	<p>Rating: {k.metrics.difficulty.rating.avg?.toFixed(2) ?? 0.0}</p>
																</IonCol>
															</IonRow>
														</IonGrid>
													</IonItem>
												);
											})}
										</>
									);
								})}
							</IonList>
						) : (
							false
						)}
					</div>
				</IonContent>
			</IonPage>
		);
	}
}
