import { IonPage, IonHeader, IonToolbar, IonButtons, IonMenuButton, IonTitle, IonContent, IonGrid, IonCol, IonRow, IonList, IonItem, IonLabel, IonInput, IonButton } from '@ionic/react';
import React from 'react';

import API from '../../API';
import LoadingFullScreen from '../../components/LoadingFullScreen';
import { countries } from '../../Constants';

export default class extends React.Component {
	map: any = null;
	state: any = {
		bots: null,
		users: null,
	};

	interval = '24';

	componentDidMount() {
		this.getStatistics();
	}

	componentDidCatch() {}

	async getStatistics() {
		const res = await API.getStatistics(this.interval);
		if (res.success) {
			console.log(res);
			this.setState({
				...res.data,
			});
		}
	}

	render() {
		return (
			<IonPage>
				<IonHeader>
					<IonToolbar>
						<IonButtons slot="start">
							<IonMenuButton />
						</IonButtons>
						<IonTitle>Статистика</IonTitle>
					</IonToolbar>
				</IonHeader>
				<IonContent>
					<IonItem>
						<IonLabel>За последние часы:</IonLabel>
						<IonInput
							placeholder="24"
							value={this.interval}
							type={'number'}
							onIonChange={(e) => {
								this.interval = e.detail.value!;
							}}
						/>
						<IonButtons>
							<IonButton
								slot="end"
								onClick={() => {
									this.getStatistics();
								}}>
								Выгрузить
							</IonButton>
						</IonButtons>
					</IonItem>
					{this.state.users == null ? (
						<LoadingFullScreen />
					) : (
						<IonGrid>
							<IonRow>
								<IonCol>
									<h2>Пользователи</h2>
									<IonList>
										{this.state.users.map((u: any) => {
											return (
												<IonItem routerLink={'/apps/' + u.offer?.id} key={'users_offer_' + u.bundle_id}>
													<IonLabel>
														<p onClick={() => {}}>
															{u.offer?.title}:&nbsp;{u.count}
														</p>
													</IonLabel>
												</IonItem>
											);
										})}
									</IonList>
								</IonCol>
								<IonCol>
									<h2>Боты</h2>
									<IonList>
										{this.state.bots.map((u: any) => {
											return (
												<IonItem routerLink={'/apps/' + u.offer?.id} key={'users_offer_' + u.bundle_id}>
													<IonLabel>
														<p onClick={() => {}}>
															{u.offer?.title}:&nbsp;{u.count}
														</p>
													</IonLabel>
												</IonItem>
											);
										})}
									</IonList>
								</IonCol>
							</IonRow>
							<IonRow>
								<IonCol>
									<h2>Последние боты</h2>
									<IonList>
										{this.state.lastBots.map((b: any) => {
											return (
												<IonItem key={'statistics_last_bot_' + b.id}>
													<IonLabel>
														<h2>{b.offer.title}</h2>
														<p>{b.reason}</p>
														<p>
															{b.ip +
																' | ' +
																// @ts-ignore
																countries[b.country]?.title +
																' | ' +
																new Date(b.created).toLocaleString()}
														</p>
													</IonLabel>
												</IonItem>
											);
										})}
									</IonList>
								</IonCol>
							</IonRow>
						</IonGrid>
					)}
				</IonContent>
			</IonPage>
		);
	}
}
