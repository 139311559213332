import {
	IonButtons,
	IonContent,
	IonHeader,
	IonPage,
	IonTitle,
	IonToolbar,
	IonBackButton,
	IonCard,
	IonCardContent,
	IonList,
	IonItem,
	IonLabel,
	IonItemDivider,
	IonButton,
	IonModal,
	IonSelect,
	IonSelectOption,
	IonInput,
	IonCardHeader,
	IonCardTitle,
	IonIcon,
} from '@ionic/react';
import { person, calendar, barChart, extensionPuzzle, build, chevronUp, chevronDown } from 'ionicons/icons';
import React from 'react';
import AnimateHeight from 'react-animate-height';
import { RouteComponentProps } from 'react-router';
import Flag from 'react-world-flags';
import { ResponsiveContainer, LineChart, CartesianGrid, XAxis, YAxis, Tooltip, Line, Brush, AreaChart, Area } from 'recharts';
import { toast } from 'react-toastify';

import API from '../../API';
import AppDateItem from '../../components/App/AppDateItem';
import AppEmployerItem from '../../components/App/AppEmployerItem';
import AppEnableDisableComponent from '../../components/App/AppEnableDisableComponent';
import AppInfoItem from '../../components/App/AppInfoItem';
import AppYesOrNo from '../../components/App/AppYesOrNo';
import BlockHeaderComponent from '../../components/BlockHeaderComponent';
import BrandLogo from '../../components/BrandLogo';
import LoadingFullScreen from '../../components/LoadingFullScreen';
import TypeSelectorComponent from '../../components/TypeSelectorComponent';
import { countries } from '../../Constants';

interface Props
	extends RouteComponentProps<{
		id: string;
	}> {}

export default class extends React.Component<Props> {
	state: any = {
		app: null,
		countries: [],
		selectedCountries: [],
		selectCountiesOpened: false,
		developers: [],
		designers: [],
		proxyServers: [],
		accounts: [],

		firebaseServerKey: null,
		firebaseSenderId: null,
	};

	componentDidMount() {
		this.getData();
	}

	async getData() {
		const res = await API.getPublisherAppInfo(this.props.match.params.id);

		const selectedCountries: Array<any> = [];
		this.state.countries = [];
		for (const [key, value] of Object.entries(res.countries)) {
			const country: any = value;
			this.state.countries.push(value);
			if ((res.data.allowed_country_ids as Array<number>).includes(country.id)) {
				selectedCountries.push(value);
			}
		}

		if (res.data.account) {
			res.data.account.tags = JSON.parse(res.data.account?.tags || '[]');
		}

		this.setState({
			developers: res.developers,
			designers: res.designers,
			proxyServers: res.proxyServers,
			accounts: res.accounts,
			app: res.data,
			countries: this.state.countries,
			selectedCountries: selectedCountries,
		});
	}

	getCountryById(id: any) {
		let country = null;

		for (let index = 0; index < this.state.countries.length; index++) {
			const c = this.state.countries[index];

			if (c.id == id) {
				country = c;
				break;
			}
		}

		return country;
	}

	getCountryByCode(code: any) {
		let country = null;

		for (let index = 0; index < this.state.countries.length; index++) {
			const c = this.state.countries[index];

			if (c.code == code) {
				country = c;
				break;
			}
		}

		return country;
	}

	render() {
		const app = this.state.app;
		return (
			<IonPage>
				<IonModal
					isOpen={this.state.selectCountiesOpened}
					onDidDismiss={() => {
						this.setState({ selectCountiesOpened: false });
					}}>
					<TypeSelectorComponent
						beforeSelected={this.state.selectedCountries}
						items={this.state.countries}
						didSelected={async (items: any) => {
							this.setState({ selectCountiesOpened: false });

							await API.PBChangeAppCountries(
								app.id,
								items.map((i: any) => i.id),
							);
							alert('Данные обновлены');
							this.getData();
						}}
						didCancelled={() => this.setState({ selectCountiesOpened: false })}
					/>
				</IonModal>

				<IonHeader>
					<IonToolbar>
						<IonButtons slot="start">
							<IonBackButton defaultHref="/publisher/apps"></IonBackButton>
						</IonButtons>
						<IonTitle>Информация о приложении №{this.props.match.params.id}</IonTitle>
					</IonToolbar>
				</IonHeader>
				<IonContent>
					{app == null ? (
						<LoadingFullScreen />
					) : (
						<div>
							<IonCard>
								<IonCardContent>
									<IonList>
										<IonItem>
											<IonLabel>
												<h2 style={{ zIndex: 9999 }}>{app.title}</h2>
												<p>Название</p>
												<p
													style={{ color: 'blue' }}
													onClick={async () => {
														const newAppName = prompt('Введите новое название');
														if (newAppName) {
															const res = await API.PBChangeAppName(app.id, newAppName);
															if (res.success) {
																alert('Обновлено!');
																this.getData();
															} else {
																alert('Ошибка при обновлении');
															}
														}
													}}>
													Сменить название
												</p>
											</IonLabel>
										</IonItem>
										<IonItem>
											<IonLabel>
												<h2>{app.bundle_id}</h2>
												<p>Bundle ID</p>
											</IonLabel>
										</IonItem>
										<IonItem>
											<IonLabel>
												<h2>{app.mode}</h2>
												<p>Мод</p>
											</IonLabel>
										</IonItem>
										<IonItem>
											<IonLabel>
												<h2>{app.one_signal_app_id || 'Отсутствует'}</h2>
												<p>One Signal App id</p>
											</IonLabel>
										</IonItem>
										<IonItem>
											<IonLabel>Выбрано стран: {this.state.selectedCountries.length}</IonLabel>
											<IonButtons slot="end">
												<IonButton
													onClick={async () => {
														this.setState({
															selectCountiesOpened: true,
														});
													}}>
													Выбрать страны
												</IonButton>
											</IonButtons>
										</IonItem>
										<IonItem>
											<IonLabel>
												<h2>{app.link}</h2>
												<p>Должно включиться</p>
											</IonLabel>
										</IonItem>
										<IonItem>
											<IonLabel>
												<h2>Отправка на проверку</h2>
											</IonLabel>
											<IonButton
												slot="end"
												disabled={app.isInTesting}
												onClick={async () => {
													await API.getPublisherAddAppInReview(app.bundle_id, 'googleplay');
													toast.success('Приложение добавлено в проверку');
													this.getData();
												}}>
												Отправить на проверку
											</IonButton>
										</IonItem>
										<IonItem>
											<IonLabel>
												<h2>{app.is_enabled ? 'Включен' : 'Выключен'}</h2>
												<p>Статус клоаки</p>
											</IonLabel>
											<IonButton
												slot="end"
												onClick={async () => {
													const reason = window.prompt('Комментарий действия') || 'Без комментария';

													await API.PBChangeCloacaStatus(app.id, app.is_enabled ? false : true, reason);
													alert('Данные обновлены');
													this.getData();
												}}>
												{app.is_enabled ? 'Выключить' : 'Включить'}
											</IonButton>
										</IonItem>
										<IonItem>
											<IonLabel>
												<h2>{app.is_on_updating ? 'На обновлении' : 'Не в обновлении'}</h2>
												<p>Статус обновления</p>
											</IonLabel>
											<IonButton
												slot="end"
												onClick={async () => {
													await API.PBChangeUpdateOnUpdateStatus(app.id, !app.is_on_updating);
													alert('Данные обновлены');
													this.getData();
												}}>
												{app.is_on_updating ? 'Вышел с обновы' : 'Отправил на обнову'}
											</IonButton>
										</IonItem>
										<IonItem>
											<IonLabel>
												<h2>{app.is_remote_farmer ? 'Прила удаленного фармера' : 'Не удаленный фарм'}</h2>
												<p>Тип фарма</p>
											</IonLabel>
											<IonButton
												slot="end"
												onClick={async () => {
													await API.PBChangeIsRemoteFarmer(app.id, !app.is_remote_farmer);
													alert('Данные обновлены');
													this.getData();
												}}>
												{app.is_remote_farmer ? 'Это прила удаленного фармера' : 'Это прила собственного акка'}
											</IonButton>
										</IonItem>

										<IonItemDivider>Участники</IonItemDivider>
										<IonItem>
											<IonLabel>Дизайнер</IonLabel>
											<IonSelect
												interface="popover"
												value={this.state.app.designer_id}
												placeholder="Дизайнер"
												onIonChange={(e) => {
													app.designer_id = e.detail.value;
													this.setState({
														app: app,
													});
												}}>
												{this.state.designers.map((e: any) => {
													return (
														<IonSelectOption key={'designter_' + e.id} value={e.id}>
															{e.name}
														</IonSelectOption>
													);
												})}
											</IonSelect>
										</IonItem>

										<IonItem>
											<IonLabel>Разработчик</IonLabel>
											<IonSelect
												interface="popover"
												value={this.state.app.developer_id}
												placeholder="Разработчик"
												onIonChange={(e) => {
													app.developer_id = e.detail.value;
													this.setState({
														app: app,
													});
												}}>
												{this.state.developers.map((e: any) => {
													return (
														<IonSelectOption key={'developer_' + e.id} value={e.id}>
															{e.name}
														</IonSelectOption>
													);
												})}
											</IonSelect>
										</IonItem>
										<IonItem>
											<IonLabel>Интегратор</IonLabel>
											<IonSelect
												interface="popover"
												value={this.state.app.integrator_id}
												placeholder="Разработчик"
												onIonChange={(e) => {
													app.integrator_id = e.detail.value;
													this.setState({
														app: app,
													});
												}}>
												{this.state.developers.map((e: any) => {
													return (
														<IonSelectOption key={'integrator_developer_' + e.id} value={e.id}>
															{e.name}
														</IonSelectOption>
													);
												})}
											</IonSelect>
										</IonItem>

										<IonItem>
											<IonLabel>Прокси сервер</IonLabel>
											<IonSelect
												interface="action-sheet"
												value={this.state.app.proxy_server_id}
												placeholder="Proxy server"
												onIonChange={(e) => {
													app.proxy_server_id = e.detail.value;
													this.setState({
														app: app,
													});
												}}>
												{this.state.proxyServers.map((e: any) => {
													return (
														<IonSelectOption style={{ width: '400px !important' }} key={'proxy_server_' + e.id} value={e.id}>
															{e.connected_domains.split(',').reverse().join(' ')}
														</IonSelectOption>
													);
												})}
											</IonSelect>
										</IonItem>

										<IonItem>
											<IonLabel>Аккаунт</IonLabel>
											<IonSelect
												interface="action-sheet"
												value={this.state.app.farmed_account_id}
												placeholder="Account"
												onIonChange={(e) => {
													app.farmed_account_id = e.detail.value;
													this.setState({
														app: app,
													});
												}}>
												{this.state.accounts.map((e: any) => {
													return (
														<IonSelectOption style={{ width: '400px !important' }} key={'account_farm_' + e.id} value={e.id}>
															{e.login}
														</IonSelectOption>
													);
												})}
											</IonSelect>
										</IonItem>
										<IonItem>
											<IonButtons slot="end">
												<IonButton
													color="success"
													onClick={() => {
														API.publisherUpdateAppLinks(app).then(() => {
															this.getData();
														});
													}}>
													Сохранить
												</IonButton>
											</IonButtons>
										</IonItem>

										<br />
										<br />
										<IonItemDivider>PUSH Уведомления</IonItemDivider>
										{!app.one_signal_app_id && app.store_id == 2 ? (
											<>
												<IonLabel>Подключение OneSignal</IonLabel>
												<IonItem>
													<IonLabel>Firebase Server key</IonLabel>
													<IonInput
														placeholder="Server key"
														onIonChange={(e) => {
															this.setState({
																firebaseServerKey: e.detail.value!,
															});
														}}
														clearInput></IonInput>
												</IonItem>
												<IonItem>
													<IonLabel>Firebase Sender ID</IonLabel>
													<IonInput
														placeholder="Sender ID"
														onIonChange={(e) => {
															this.setState({
																firebaseSenderId: e.detail.value!,
															});
														}}
														clearInput></IonInput>
												</IonItem>
												<IonItem>
													<IonLabel>Интеграция пушей</IonLabel>
													<IonButtons slot="end">
														<IonButton
															color="success"
															disabled={!this.state.firebaseSenderId || !this.state.firebaseServerKey}
															onClick={() => {
																API.publisherConnectOneSignalToApp(app.id, this.state.firebaseServerKey.trim(), this.state.firebaseSenderId.trim()).then((res) => {
																	alert('Запрос отправлен');
																	this.getData();
																});
															}}>
															Подключить
														</IonButton>
													</IonButtons>
												</IonItem>
											</>
										) : (
											<IonItem>
												<h2>Отправить тестовое сообщение</h2>
												<IonButton
													onClick={async () => {
														const res = await API.publishedSendPush(app.id);
														if (res.success) {
															alert('PUSH отправлен');
														} else {
															alert('Ошибка при отправке пуш уведомления');
														}
													}}
													fill="outline"
													slot="end">
													Отправить PUSH
												</IonButton>
											</IonItem>
										)}

										<IonItemDivider>Комментарии</IonItemDivider>

										{app.comments.length == 0 ? <h2 className="ion-padding">Комментариев нет</h2> : false}
										{app.comments.map((comment: any) => {
											return (
												<IonItem key={'app_comment_' + comment.id}>
													<IonLabel className="ion-text-wrap">
														<h5>
															{comment.is_completed ? '✅' : '⏳'} {comment.comment}
														</h5>
														<p>
															{comment.user_name} [{comment.user_role}] {new Date(comment.created).toLocaleString()}
														</p>
														{comment.in_progress ? (
															comment.is_completed ? (
																<p>Завершен: {new Date(comment.completed_at).toLocaleString()}</p>
															) : (
																<p
																	style={{ color: 'green', fontWeight: 'bold' }}
																	onClick={() => {
																		API.publisherSetAppCommentCompleted(comment.id).then(() => {
																			this.getData();
																		});
																	}}>
																	ВЫШЛА ОБНОВА ✅
																</p>
															)
														) : (
															<p
																style={{ color: 'green', fontWeight: 'bold' }}
																onClick={() => {
																	API.publisherSetAppCommentInProgress(comment.id).then(() => {
																		this.getData();
																	});
																}}>
																ОТПРАВИЛ НА РЕВЬЮ
															</p>
														)}
													</IonLabel>
												</IonItem>
											);
										})}
									</IonList>
								</IonCardContent>
							</IonCard>
							<IonCard>
								<IonCardHeader>
									<IonCardTitle>Инфа</IonCardTitle>
								</IonCardHeader>
								<IonCardContent>
									<div style={{ display: 'flex' }}>
										<div style={{ width: 250 }}>
											<BrandLogo id={app.brand_id} style={{ width: 100 }} />
											<AppEmployerItem role={app.title} name={app.bundle_id} />

											<div style={{ width: '100%', height: 0.5, backgroundColor: '#3e3e3e' }}></div>

											<BlockHeaderComponent icon={person} text="Сотрудники:" />
											<div style={{ height: 8 }} />
											<AppEmployerItem role="Разработчик" name={app.developer?.name} />
											<AppEmployerItem role="Дизайнер" name={app.designer?.name} />
											<AppEmployerItem role="Интегратор" name={app.integrator?.name} />
											<AppEmployerItem role="Публикатор" name={app.publisher?.name} />

											<div style={{ width: '100%', height: 0.5, backgroundColor: '#3e3e3e' }}></div>

											<BlockHeaderComponent icon={calendar} text="Дата:" />

											<AppDateItem title="Фарм:" date={app.account?.farmed_at} />
											<AppDateItem title="Отправка:" date={app.created} />
											<AppDateItem title="Выход:" date={app.account?.published_to_store_at} />
											<AppDateItem title="Бан:" date={app.account?.banned_at} />
										</div>
										<div style={{ flex: 1 }}>
											<BlockHeaderComponent icon={barChart} text="График:" />
											{app.metrics.length != 0 ? (
												<div style={{ height: 500, width: '100%' }}>
													<ResponsiveContainer height="100%" width="100%">
														<LineChart
															data={app.metrics.map((stat: any) => {
																stat.date_string = new Date(stat.created_date).toLocaleDateString();
																return stat;
															})}
															margin={{ top: 0, right: 0, bottom: 0, left: 0 }}>
															<CartesianGrid vertical={false} />
															<XAxis dataKey="date_string" label="Дата" />
															<YAxis domain={['auto', 'auto']} />
															<Tooltip
																wrapperStyle={{
																	borderColor: 'white',
																	boxShadow: '2px 2px 3px 0px rgb(204, 204, 204)',
																}}
																contentStyle={{
																	backgroundColor: 'rgba(255, 255, 255, 0.8)',
																}}
																labelStyle={{ fontWeight: 'bold', color: '#666666' }}
															/>
															<Line dataKey="users" stroke="#ff7300" dot={false} />
															{app.metrics.length > 40 ? (
																<Brush dataKey="created_date" startIndex={app.metrics.length - 40}>
																	<AreaChart>
																		<CartesianGrid />
																		<YAxis hide domain={['auto', 'auto']} />
																		<Area dataKey="users" stroke="#ff7300" fill="#ff7300" dot={false} />
																	</AreaChart>
																</Brush>
															) : null}
														</LineChart>
													</ResponsiveContainer>
												</div>
											) : (
												<p>Нет данных для отображения графика</p>
											)}
										</div>
									</div>
									<div style={{ width: '100%', height: 0.5, backgroundColor: '#3e3e3e' }}></div>

									<div style={{ display: 'flex' }}>
										<div style={{ flex: 1 }}>
											<BlockHeaderComponent icon={extensionPuzzle} text="Аккаунт:" />

											<div style={{ display: 'flex' }}>
												<div style={{ flex: 1 }}>
													<AppInfoItem title="Дата создания:" value={app.account?.created ? new Date(app.account.created).toLocaleString() : 'Отсутствует'} />
													<AppYesOrNo title="Удаленный аккаунт:" value={app.account?.is_remote} />
												</div>

												<div style={{ flex: 1 }}>
													<AppInfoItem title="Платежка:" value={app.account?.payment_name ?? 'NONE'} />
													<AppInfoItem title="Карта:" value={(app.account?.card_type ?? 'NONE') + '*' + (app.account?.card_last_numbers ?? 'NONE')} />
												</div>
											</div>
											<div>
												<div style={{ flex: 1 }}>
													<AppInfoItem title="Теги:" value={app.account?.tags.map((tag: string) => tag).join(' | ')} />
												</div>
											</div>
										</div>
										<div style={{ flex: 1 }}>
											<BlockHeaderComponent icon={build} text="Управление:" />

											<div style={{ display: 'flex' }}>
												<div style={{ flex: 1 }}>
													<AppEnableDisableComponent
														title="Кэширование ссылок:"
														value={app.cache_enabled}
														didChanged={(newValue) => {
															app.cache_enabled = newValue;
															this.setState({
																app: app,
															});
														}}
													/>
												</div>
												<div style={{ flex: 1 }}>
													<AppEnableDisableComponent
														title="Клоака:"
														value={app.is_enabled}
														didChanged={(newValue) => {
															app.is_enabled = newValue;
															this.setState({
																app: app,
															});
														}}
													/>
												</div>
											</div>

											<div style={{ display: 'flex' }}>
												<div style={{ flex: 8 }}>
													<p style={{ fontSize: 12, fontWeight: 700, marginBottom: 2 }}>Страна</p>
												</div>
												<div style={{ flex: 3 }}>
													<p style={{ fontSize: 12, fontWeight: 700, marginBottom: 2 }}>Юзеры</p>
												</div>
												<div style={{ flex: 3 }}>
													<p style={{ fontSize: 12, fontWeight: 700, marginBottom: 2 }}>Боты</p>
												</div>
												<div
													style={{ display: 'flex', width: 100 }}
													onClick={() => {
														if (app.showFullStats) {
															app.showFullStats = false;
														} else {
															app.showFullStats = true;
														}
														this.setState({
															brands: this.state.brands,
														});
													}}>
													<IonIcon icon={app.showFullStats ? chevronUp : chevronDown} color="primary"></IonIcon>
													<p
														style={{
															paddingLeft: 4,
															color: '#3880ff',
															marginBottom: 0,
															paddingRight: 16,
														}}>
														{app.showFullStats ? 'Скрыть' : 'Показать'}
													</p>
												</div>
											</div>
											<AnimateHeight duration={100} height={app.showFullStats ? 'auto' : 60}>
												{app.oneBuildedStats.map((stat: any) => {
													return (
														<div key={'app_' + app.id + '_stats_' + stat.country} style={{ display: 'flex', height: 20 }}>
															<div style={{ flex: 8, display: 'flex', alignItems: 'center' }}>
																<Flag code={stat.country} width={15} height={10} />
																&nbsp;<p style={{ marginBottom: 0, fontSize: 12, maxLines: 0, overflow: 'hidden' }}>{(countries as any)[stat.country]?.title}</p>
															</div>

															<div style={{ flex: 3 }}>{stat.users}</div>
															<div style={{ flex: 3 }}>{stat.bots}</div>
															<div style={{ width: 100 }} />
														</div>
													);
												})}
											</AnimateHeight>
										</div>
									</div>
									<div style={{ width: '100%', height: 0.5, backgroundColor: '#3e3e3e' }}></div>
								</IonCardContent>
							</IonCard>
						</div>
					)}
				</IonContent>
			</IonPage>
		);
	}
}
