import { IonPage, IonHeader, IonToolbar, IonButtons, IonMenuButton, IonTitle, IonContent, IonButton, IonItem, IonLabel, IonIcon, IonSelect, IonSelectOption, IonSearchbar } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { archiveOutline, filterCircle, filterCircleOutline } from 'ionicons/icons';
import DataTable from 'react-data-table-component';
import { useHistory } from 'react-router';

import LoadingFullScreen from '../../components/LoadingFullScreen';
import API from '../../API';

const PM_AppListPage: React.FC = () => {
	const [apps, setApps] = useState<Array<any> | null>(null);
	const [showFilter, setShowFilter] = useState(false);

	const [stores, setStores] = useState<Array<any>>([]);
	const [brands, setBrands] = useState<Array<any>>([]);
	const [publishers, setPublishers] = useState<Array<any>>([]);

	const [storeId, setStoreId] = useState<number | null>(null);
	const [brandId, setBrandId] = useState<number | null>(null);
	const [isInStore, setIsInStore] = useState<boolean | null>(null);
	const [isEnabled, setIsEnabled] = useState<boolean | null>(null);
	const [publishedUserId, setPublishedUserId] = useState<number | null>(null);
	const [isOnUpdating, setIsOnUpdating] = useState<boolean | null>(null);
	const [showPopover, setShowPopover] = useState<boolean>(false);
	const [searchFilter, setSearchFilter] = useState<string>('');
	const [sort, setSort] = useState<string>('offers.id');

	const [offset, setOffset] = useState(25);

	const history = useHistory();

	useEffect(() => {
		getStatistics();
	}, []);

	async function getStatistics() {
		const res = await API.pmGetApps(storeId, brandId, isInStore, isEnabled, publishedUserId, isOnUpdating, false, sort);
		if (res.success) {
			setApps(res.data.offers);
			setStores(res.data.stores);
			setBrands(res.data.brands);
			setPublishers(res.data.publishers);
		}
	}

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonButtons slot="start">
						<IonMenuButton />
					</IonButtons>
					<IonTitle>Приложения</IonTitle>
					<IonButtons slot="end">
						{/* <IonButton routerLink="/apps/archive">
							<IonIcon icon={archiveOutline} slot="start" />
							Архив
						</IonButton> */}
						<IonButton onClick={() => setShowFilter(!showFilter)}>
							<IonIcon icon={showFilter ? filterCircle : filterCircleOutline} slot="start" />
							Фильтр
						</IonButton>
					</IonButtons>
				</IonToolbar>
				<IonToolbar>
					<IonSearchbar placeholder="Поиск..." onIonChange={(e) => setSearchFilter(e.detail.value! as string)}></IonSearchbar>
				</IonToolbar>
			</IonHeader>
			<IonContent>
				<div
					style={{
						position: 'fixed',
						width: '100%',
						height: '100%',
						zIndex: showFilter ? 101 : undefined,
					}}>
					<AnimateHeight id="example-panel" duration={200} height={showFilter ? 'auto' : 0}>
						<IonItem>
							<IonLabel>Стор</IonLabel>
							<IonSelect
								interface="popover"
								value={storeId}
								placeholder="Выберите"
								onIonChange={(e) => {
									setStoreId(e.detail.value);
								}}>
								<IonSelectOption value={null}>Не выбран</IonSelectOption>
								{stores.map((e: any) => {
									return (
										<IonSelectOption key={'1_exp_a_id_' + e.id} value={e.id}>
											{e.title}
										</IonSelectOption>
									);
								})}
							</IonSelect>
						</IonItem>
						<IonItem>
							<IonLabel>Бренд</IonLabel>
							<IonSelect
								interface="popover"
								value={brandId}
								placeholder="Выберите"
								onIonChange={(e) => {
									setBrandId(e.detail.value);
								}}>
								<IonSelectOption value={null}>Не выбран</IonSelectOption>
								{brands.map((e: any) => {
									return (
										<IonSelectOption key={'2_exp_a_id_' + e.id} value={e.id}>
											{e.title}
										</IonSelectOption>
									);
								})}
							</IonSelect>
						</IonItem>
						<IonItem>
							<IonLabel>Статус клоаки</IonLabel>
							<IonSelect
								interface="popover"
								value={isEnabled}
								placeholder="Выберите"
								onIonChange={(e) => {
									setIsEnabled(e.detail.value);
								}}>
								<IonSelectOption value={null}>Не выбран</IonSelectOption>
								<IonSelectOption value={true}>Включен</IonSelectOption>
								<IonSelectOption value={false}>Выключен</IonSelectOption>
							</IonSelect>
						</IonItem>
						<IonItem>
							<IonLabel>На обнове</IonLabel>
							<IonSelect
								interface="popover"
								value={isOnUpdating}
								placeholder="Выберите"
								onIonChange={(e) => {
									setIsOnUpdating(e.detail.value);
								}}>
								<IonSelectOption value={null}>Не выбран</IonSelectOption>
								<IonSelectOption value={true}>ДА</IonSelectOption>
								<IonSelectOption value={false}>Нет</IonSelectOption>
							</IonSelect>
						</IonItem>
						<IonItem>
							<IonLabel>Статус публикации</IonLabel>
							<IonSelect
								interface="popover"
								value={isInStore}
								placeholder="Выберите"
								onIonChange={(e) => {
									setIsInStore(e.detail.value);
								}}>
								<IonSelectOption value={null}>Не выбран</IonSelectOption>
								<IonSelectOption value={true}>Опубликован</IonSelectOption>
								<IonSelectOption value={false}>Не опубликован</IonSelectOption>
							</IonSelect>
						</IonItem>
						<IonItem>
							<IonLabel>Паблишер</IonLabel>
							<IonSelect
								interface="popover"
								value={publishedUserId}
								placeholder="Выберите"
								onIonChange={(e) => {
									setPublishedUserId(e.detail.value);
								}}>
								<IonSelectOption value={null}>Не выбран</IonSelectOption>
								{publishers.map((e: any) => {
									return (
										<IonSelectOption key={'3_exp_a_id_' + e.id} value={e.id}>
											{e.name}
										</IonSelectOption>
									);
								})}
							</IonSelect>
						</IonItem>
						<IonItem>
							<IonLabel>Сортировка</IonLabel>
							<IonSelect
								interface="popover"
								value={sort}
								placeholder="Выберите"
								onIonChange={(e) => {
									setSort(e.detail.value);
								}}>
								<IonSelectOption value={'offers.id'}>По дате создания (Самые свежие)</IonSelectOption>
								<IonSelectOption value={'offers.published_to_store_at'}>По Дате публикации (Самые новые)</IonSelectOption>
								<IonSelectOption value={'offers.banned_at'}>По Дате бана (Недавно забаненные)</IonSelectOption>
								<IonSelectOption value={'income'}>По Доходу (самые прибыльные)</IonSelectOption>
								<IonSelectOption value={'life_time'}>По времени жизни (самые долгоживущие)</IonSelectOption>
								<IonSelectOption value={'review_time'}>По времени проверки</IonSelectOption>
							</IonSelect>
						</IonItem>

						<IonItem>
							<IonButtons slot="end">
								<IonButton
									color="success"
									onClick={() => {
										setApps([]);
										getStatistics();
										setShowFilter(false);
									}}>
									Применить
								</IonButton>
							</IonButtons>
						</IonItem>
					</AnimateHeight>
				</div>

				{showFilter ? (
					<div
						style={{
							position: 'fixed',
							width: '100%',
							height: '100%',
							backgroundColor: 'rgba(0, 0, 0, 0.5)',
							zIndex: 100,
						}}
					/>
				) : (
					false
				)}

				{apps == null ? (
					<LoadingFullScreen />
				) : (
					<DataTable
						columns={[
							{
								name: 'ID',
								selector: (row) => row.id,
								sortable: true,
								maxWidth: '20px',
							},
							{
								name: 'Стор',
								cell: (row) => (
									<img
										style={{
											height: 30,
											width: 30,
											right: 8,
											top: 8,
										}}
										src={`/assets/store_${row.store_id}.svg`}
										alt=""
									/>
								),
							},
							{
								name: 'Тип',
								selector: (row) => (row.is_rent_app ? 'Аренда' : 'АСО'),
								sortable: true,
							},
							{
								name: 'Название',
								cell: (row) => <p style={{ whiteSpace: 'pre-wrap' }}>{row.title}</p>,
								minWidth: '250px',
								sortFunction: (a, b) => {
									if (a.title > b.title) {
										return 1;
									}
									if (b.title > a.title) {
										return -1;
									}
									return 0;
								},
							},
							{
								name: 'Бренд',
								selector: (row) => row.brand_title,
								sortable: true,
							},
							{
								name: 'Доход',
								selector: (row) => row.income || 0,
								sortable: true,
							},
							{
								name: 'Срок жизни',
								sortable: true,
								sortFunction: (a, b) => {
									if (a.life_time > b.life_time) {
										return 1;
									}
									if (b.life_time > a.life_time) {
										return -1;
									}

									return 0;
								},
								minWidth: '200px',
								cell(row, rowIndex, column, id) {
									return row.life_time ? (
										<p>
											<span style={{ color: 'black' }}>{row.life_time} дн.</span>
											<br />
											{new Date(row.published_to_store_at).toLocaleDateString()} - {row.banned_at ? new Date(row.banned_at).toLocaleDateString() : 'Сегодня'}
										</p>
									) : (
										<p>Еще не вышел в стор</p>
									);
								},
							},
							{
								name: 'Позиция',
								// selector: (row) => row.maxTopPosition?.last_position.toString() ?? "Нет данных",
								cell: (row) => (
									<p
										onClick={() => {
											//									alert(row.id)
											history.push('/keywords/' + row.id);
										}}
										style={{ color: row.maxTopPosition?.last_position <= 10 ? 'green' : 'red' }}>
										{row.maxTopPosition?.last_position.toString() ?? 'Нет данных'}
									</p>
								),
								sortFunction: (a, b) => {
									const Apos = parseInt(a.maxTopPosition?.last_position);
									const Bpos = parseInt(b.maxTopPosition?.last_position);

									if (Apos === Bpos) {
										return 0;
									}

									// nulls sort after anything else
									if (Apos === null || isNaN(Apos)) {
										return 1;
									}
									if (Bpos === null || isNaN(Bpos)) {
										return -1;
									}
									return Apos < Bpos ? -1 : 1;
								},
							},
							{
								name: 'Пользователи',
								sortable: true,
								selector: (row) => row.install_count,
							},
							{
								name: 'Клоака',
								sortable: true,
								cell: (row) => <p style={{ color: row.is_enabled ? 'green' : 'red' }}>{row.is_enabled ? 'ДА' : 'НЕТ'}</p>,
								sortFunction: (a, b) => {
									if (a.is_enabled > b.is_enabled) {
										return 1;
									}
									if (b.is_enabled > a.is_enabled) {
										return -1;
									}
									return 0;
								},
							},
							{
								name: 'В сторе',
								cell: (row) => <p style={{ color: row.is_in_store ? 'green' : 'red' }}>{row.is_in_store ? 'ДА' : 'НЕТ'}</p>,
								sortFunction: (a, b) => {
									if (a.is_in_store > b.is_in_store) {
										return 1;
									}
									if (b.is_in_store > a.is_in_store) {
										return -1;
									}
									return 0;
								},
							},
							{
								name: 'На обновлении',
								sortable: true,
								cell: (row) => <p style={{ color: row.is_on_updating ? 'orange' : 'green' }}>{row.is_on_updating ? 'ДА' : 'НЕТ'}</p>,
								sortFunction: (a, b) => {
									if (a.is_on_updating > b.is_on_updating) {
										return 1;
									}
									if (b.is_on_updating > a.is_on_updating) {
										return -1;
									}
									return 0;
								},
							},
							{
								name: 'Паблишер',
								sortable: true,
								selector: (row) => row.publisher_name,
								minWidth: '250px',
							},
						]}
						data={searchFilter.length == 0 ? apps : apps.filter((app) => app.title.toLowerCase().includes(searchFilter) || app.bundle_id.toLowerCase().includes(searchFilter))}
						pagination
						fixedHeader
						paginationPerPage={offset}
						onChangeRowsPerPage={(offset, page) => {
							setOffset(offset);
						}}
						onRowClicked={(row) => {
							const win = window.open('/apps/' + row.id, '_blank');
							win?.focus();
						}}
						paginationRowsPerPageOptions={[25, 50, 75, 100, 150, 200, 500]}
					/>
				)}
			</IonContent>
		</IonPage>
	);
};

export default PM_AppListPage;
