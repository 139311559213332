import { IonPage, IonHeader, IonToolbar, IonButtons, IonMenuButton, IonTitle, IonContent, IonLabel, IonButton, IonChip, IonIcon } from '@ionic/react';
import { checkmark, shareOutline } from 'ionicons/icons';
import React from 'react';
import { ResponsiveContainer, LineChart, CartesianGrid, XAxis, YAxis, Tooltip, Line, Brush, AreaChart, Area } from 'recharts';

import API from '../../API';

const colors = ['#000000', '#800000', '#FF0000', '#808000', '#008000', '#00FF00', '#008080', '#00FFFF', '#000080', '#0000FF', '#800080', '#FF00FF'];

export default class extends React.Component {
	state: any = {
		traffic: [],
		selectedApps: [],
		allApps: [],
	};
	selectedColors: any = {};

	componentDidMount() {
		this.getStatistics();
	}

	componentDidCatch() {}

	getAppColor(appName: string) {
		if (!this.selectedColors[appName]) {
			this.selectedColors[appName] = colors[Math.floor(Math.random() * colors.length)];
		}

		return this.selectedColors[appName];
	}

	async getStatistics() {
		const res = await API.getTraffic();
		if (res.success) {
			this.setState({
				traffic: res.data.traffic,
				allApps: [...res.data.apps],
			});
		}
	}

	render() {
		return (
			<IonPage>
				<IonHeader>
					<IonToolbar>
						<IonButtons slot="start">
							<IonMenuButton />
						</IonButtons>
						<IonTitle>Общий трафик</IonTitle>
					</IonToolbar>
				</IonHeader>
				<IonContent>
					<div style={{ height: '70%', width: '99%' }}>
						<ResponsiveContainer height="100%" width="100%">
							<LineChart
								data={this.state.traffic.map((t: any) => {
									t.dated = new Date(t.dated).toDateString();
									return t;
								})}
								margin={{ top: 0, right: 0, bottom: 0, left: 0 }}>
								<CartesianGrid vertical={false} />
								<XAxis dataKey="dated" label="Дата" />
								<YAxis domain={['auto', 'auto']} />
								<Tooltip
									wrapperStyle={{
										borderColor: 'white',
										boxShadow: '2px 2px 3px 0px rgb(204, 204, 204)',
									}}
									contentStyle={{ backgroundColor: 'rgba(255, 255, 255, 1.0)' }}
									labelStyle={{ fontWeight: 'bold', color: '#666666' }}
								/>
								{this.state.selectedApps.map((app: any) => {
									return <Line type="monotone" key={'app_install_metric_' + app.id} dataKey={app.title} stroke={this.getAppColor(app.title)} dot={true} />;
								})}
							</LineChart>
						</ResponsiveContainer>
					</div>
					<div className="ion-padding">
						{this.state.allApps.map((app: any) => {
							const isSelected = this.state.selectedApps.includes(app);
							return (
								<IonChip
									style={{ height: 50, '--background': isSelected ? '#66ff47' : '#dedede' }}
									key={'traffic_app_' + app.id}
									onClick={() => {
										if (isSelected) {
											this.state.selectedApps.splice(
												this.state.selectedApps.findIndex((sApp: any) => sApp.id == app.id),
												1,
											);
										} else {
											this.state.selectedApps.push(app);
										}

										this.setState({
											selectedApps: this.state.selectedApps,
										});
									}}>
									{isSelected ? <IonIcon icon={checkmark} color="dark" /> : false}
									<IonLabel>
										<h2>
											<span style={{ color: !app.is_in_store ? 'brown' : 'green' }}>{app.title}</span> ({app.counter})
										</h2>
										<p>
											<span style={{ color: app.is_enabled ? 'green' : 'brown' }}>Клоака</span> [{app.brand_title}]
										</p>
									</IonLabel>
									<IonIcon
										icon={shareOutline}
										onClick={() => {
											window.open('/apps/' + app.id, '_blank');
										}}></IonIcon>
								</IonChip>
							);
						})}
						<br />
						<IonButtons>
							<IonButton
								onClick={() => {
									this.setState({
										selectedApps: [],
									});
								}}>
								Снять выделение от всех
							</IonButton>
							<IonButton
								onClick={() => {
									this.setState({
										selectedApps: [...this.state.allApps],
									});
								}}>
								Выделить все
							</IonButton>
						</IonButtons>
					</div>
				</IonContent>
			</IonPage>
		);
	}
}
