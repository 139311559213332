const forceProd = false;

const isLocalhost = document.location.hostname === 'localhost' || document.location.hostname.startsWith('192.168.');

export const host = isLocalhost && !forceProd ? `http://${document.location.hostname}:3001/` : 'https://api.sequoiamobiletms.com/';

export const serverAddress = isLocalhost && !forceProd ? `http://${document.location.hostname}:3001/api/v1/` : 'https://api.sequoiamobiletms.com/api/v1/';

export const farmTags = ['антидетект', 'вирт стол', 'отлежка', 'кукис', 'своя история бразуера'];

let countriesArray: any = null;

export function getCountryById(id: any) {
	if (!countriesArray) {
		countriesArray = Object.entries(countries);
	}

	for (const [key, value] of countriesArray) {
		if (value.id == id) {
			return value;
		}
	}
	return null;
}

export const countries = {
	AD: {
		id: 1,
		title: 'Andorra 🇦🇩',
		code: 'AD',
		mcc: ['213'],
		timezone_min_seconds: 7200,
		timezone_max_seconds: 7200,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['ca'],
		currencies: ['EUR'],
	},
	AE: {
		id: 2,
		title: 'United Arab Emirates 🇦🇪',
		code: 'AE',
		mcc: ['424', '430', '431'],
		timezone_min_seconds: 14400,
		timezone_max_seconds: 14400,
		is_metric: 1,
		tier: 2,
		is_activated: 0,
		languages: ['ar'],
		currencies: ['AED'],
	},
	AF: {
		id: 3,
		title: 'Afghanistan 🇦🇫',
		code: 'AF',
		mcc: ['412'],
		timezone_min_seconds: 16200,
		timezone_max_seconds: 16200,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['ps', 'uz', 'tk'],
		currencies: ['AFN'],
	},
	AG: {
		id: 4,
		title: 'Antigua and Barbuda 🇦🇬',
		code: 'AG',
		mcc: ['344'],
		timezone_min_seconds: -14400,
		timezone_max_seconds: -14400,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['en'],
		currencies: ['XCD'],
	},
	AI: {
		id: 5,
		title: 'Anguilla 🇦🇮',
		code: 'AI',
		mcc: ['365'],
		timezone_min_seconds: -14400,
		timezone_max_seconds: -14400,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['en'],
		currencies: ['XCD'],
	},
	AL: {
		id: 6,
		title: 'Albania 🇦🇱',
		code: 'AL',
		mcc: ['276'],
		timezone_min_seconds: 7200,
		timezone_max_seconds: 7200,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['sq'],
		currencies: ['ALL'],
	},
	AM: {
		id: 7,
		title: 'Armenia 🇦🇲',
		code: 'AM',
		mcc: ['283', '250'],
		timezone_min_seconds: 14400,
		timezone_max_seconds: 14400,
		is_metric: 1,
		tier: 3,
		is_activated: 1,
		languages: ['hy', 'ru'],
		currencies: ['AMD', 'RUB'],
	},
	AO: {
		id: 8,
		title: 'Angola 🇦🇴',
		code: 'AO',
		mcc: ['631'],
		timezone_min_seconds: 3600,
		timezone_max_seconds: 3600,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['pt'],
		currencies: ['AOA'],
	},
	AQ: {
		id: 9,
		title: 'Antarctica 🇦🇶',
		code: 'AQ',
		mcc: ['999'],
		timezone_min_seconds: -10800,
		timezone_max_seconds: 43200,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: [''],
		currencies: [''],
	},
	AR: {
		id: 10,
		title: 'Argentina 🇦🇷',
		code: 'AR',
		mcc: ['722'],
		timezone_min_seconds: -10800,
		timezone_max_seconds: -10800,
		is_metric: 1,
		tier: 2,
		is_activated: 0,
		languages: ['es', 'gn'],
		currencies: ['ARS'],
	},
	AS: {
		id: 11,
		title: 'American Samoa 🇦🇸',
		code: 'AS',
		mcc: ['544'],
		timezone_min_seconds: -39600,
		timezone_max_seconds: -39600,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['en', 'sm'],
		currencies: ['USD'],
	},
	AT: {
		id: 12,
		title: 'Austria 🇦🇹',
		code: 'AT',
		mcc: ['232'],
		timezone_min_seconds: 7200,
		timezone_max_seconds: 7200,
		is_metric: 1,
		tier: 1,
		is_activated: 0,
		languages: ['de'],
		currencies: ['EUR'],
	},
	AU: {
		id: 13,
		title: 'Australia 🇦🇺',
		code: 'AU',
		mcc: ['505'],
		timezone_min_seconds: 28800,
		timezone_max_seconds: 37800,
		is_metric: 1,
		tier: 1,
		is_activated: 0,
		languages: ['en'],
		currencies: ['AUD'],
	},
	AW: {
		id: 14,
		title: 'Aruba 🇦🇼',
		code: 'AW',
		mcc: ['363'],
		timezone_min_seconds: -14400,
		timezone_max_seconds: -14400,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['nl', 'pa'],
		currencies: ['AWG'],
	},
	AX: {
		id: 15,
		title: 'Åland Islands 🇦🇽',
		code: 'AX',
		mcc: ['999'],
		timezone_min_seconds: 10800,
		timezone_max_seconds: 10800,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['sv'],
		currencies: ['EUR'],
	},
	AZ: {
		id: 16,
		title: 'Azerbaijan 🇦🇿',
		code: 'AZ',
		mcc: ['400'],
		timezone_min_seconds: 14400,
		timezone_max_seconds: 14400,
		is_metric: 1,
		tier: 3,
		is_activated: 1,
		languages: ['az'],
		currencies: ['AZN', 'RUB'],
	},
	BA: {
		id: 17,
		title: 'Bosnia and Herzegovina 🇧🇦',
		code: 'BA',
		mcc: ['218'],
		timezone_min_seconds: 7200,
		timezone_max_seconds: 7200,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['bs', 'hr', 'sr'],
		currencies: ['BAM'],
	},
	BB: {
		id: 18,
		title: 'Barbados 🇧🇧',
		code: 'BB',
		mcc: ['342'],
		timezone_min_seconds: -14400,
		timezone_max_seconds: -14400,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['en'],
		currencies: ['BBD'],
	},
	BD: {
		id: 19,
		title: 'Bangladesh 🇧🇩',
		code: 'BD',
		mcc: ['470'],
		timezone_min_seconds: 21600,
		timezone_max_seconds: 21600,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['bn'],
		currencies: ['BDT'],
	},
	BE: {
		id: 20,
		title: 'Belgium 🇧🇪',
		code: 'BE',
		mcc: ['206'],
		timezone_min_seconds: 7200,
		timezone_max_seconds: 7200,
		is_metric: 1,
		tier: 1,
		is_activated: 0,
		languages: ['nl', 'fr', 'de'],
		currencies: ['EUR'],
	},
	BF: {
		id: 21,
		title: 'Burkina Faso 🇧🇫',
		code: 'BF',
		mcc: ['613'],
		timezone_min_seconds: 0,
		timezone_max_seconds: 0,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['fr', 'ff'],
		currencies: ['XOF'],
	},
	BG: {
		id: 22,
		title: 'Bulgaria 🇧🇬',
		code: 'BG',
		mcc: ['284'],
		timezone_min_seconds: 10800,
		timezone_max_seconds: 10800,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['bg'],
		currencies: ['BGN'],
	},
	BH: {
		id: 23,
		title: 'Bahrain 🇧🇭',
		code: 'BH',
		mcc: ['426'],
		timezone_min_seconds: 10800,
		timezone_max_seconds: 10800,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['ar'],
		currencies: ['BHD'],
	},
	BI: {
		id: 24,
		title: 'Burundi 🇧🇮',
		code: 'BI',
		mcc: ['642'],
		timezone_min_seconds: 7200,
		timezone_max_seconds: 7200,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['fr', 'rn'],
		currencies: ['BIF'],
	},
	BJ: {
		id: 25,
		title: 'Benin 🇧🇯',
		code: 'BJ',
		mcc: ['616'],
		timezone_min_seconds: 3600,
		timezone_max_seconds: 3600,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['fr'],
		currencies: ['XOF'],
	},
	BL: {
		id: 26,
		title: 'Saint Barthélemy 🇧🇱',
		code: 'BL',
		mcc: ['340'],
		timezone_min_seconds: -14400,
		timezone_max_seconds: -14400,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['fr'],
		currencies: ['EUR'],
	},
	BM: {
		id: 27,
		title: 'Bermuda 🇧🇲',
		code: 'BM',
		mcc: ['350'],
		timezone_min_seconds: -10800,
		timezone_max_seconds: -10800,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['en'],
		currencies: ['BMD'],
	},
	BN: {
		id: 28,
		title: 'Brunei 🇧🇳',
		code: 'BN',
		mcc: ['528'],
		timezone_min_seconds: 28800,
		timezone_max_seconds: 28800,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['ms'],
		currencies: ['BND'],
	},
	BO: {
		id: 29,
		title: 'Bolivia 🇧🇴',
		code: 'BO',
		mcc: ['736'],
		timezone_min_seconds: -14400,
		timezone_max_seconds: -14400,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['es', 'ay', 'qu'],
		currencies: ['BOB', 'BOV'],
	},
	BQ: {
		id: 30,
		title: 'Caribbean Netherlands 🇧🇶',
		code: 'BQ',
		mcc: ['362'],
		timezone_min_seconds: -14400,
		timezone_max_seconds: -14400,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['nl'],
		currencies: ['USD'],
	},
	BR: {
		id: 31,
		title: 'Brazil 🇧🇷',
		code: 'BR',
		mcc: ['724'],
		timezone_min_seconds: -18000,
		timezone_max_seconds: -7200,
		is_metric: 1,
		tier: 2,
		is_activated: 0,
		languages: ['pt'],
		currencies: ['BRL'],
	},
	BS: {
		id: 32,
		title: 'Bahamas 🇧🇸',
		code: 'BS',
		mcc: ['364'],
		timezone_min_seconds: -14400,
		timezone_max_seconds: -14400,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['en'],
		currencies: ['BSD'],
	},
	BT: {
		id: 33,
		title: 'Bhutan 🇧🇹',
		code: 'BT',
		mcc: ['402'],
		timezone_min_seconds: 21600,
		timezone_max_seconds: 21600,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['dz'],
		currencies: ['BTN', 'INR'],
	},
	BW: {
		id: 34,
		title: 'Botswana 🇧🇼',
		code: 'BW',
		mcc: ['652'],
		timezone_min_seconds: 7200,
		timezone_max_seconds: 7200,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['en', 'tn'],
		currencies: ['BWP'],
	},
	BY: {
		id: 35,
		title: 'Belarus 🇧🇾',
		code: 'BY',
		mcc: ['257'],
		timezone_min_seconds: 10800,
		timezone_max_seconds: 10800,
		is_metric: 1,
		tier: 3,
		is_activated: 1,
		languages: ['be', 'ru'],
		currencies: ['BYN', 'RUB'],
	},
	BZ: {
		id: 36,
		title: 'Belize 🇧🇿',
		code: 'BZ',
		mcc: ['702'],
		timezone_min_seconds: -21600,
		timezone_max_seconds: -21600,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['en', 'es'],
		currencies: ['BZD'],
	},
	CA: {
		id: 37,
		title: 'Canada 🇨🇦',
		code: 'CA',
		mcc: ['302'],
		timezone_min_seconds: -25200,
		timezone_max_seconds: -9000,
		is_metric: 1,
		tier: 1,
		is_activated: 0,
		languages: ['en', 'fr'],
		currencies: ['CAD'],
	},
	CC: {
		id: 38,
		title: 'Cocos Islands 🇨🇨',
		code: 'CC',
		mcc: ['999'],
		timezone_min_seconds: 23400,
		timezone_max_seconds: 23400,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['en'],
		currencies: ['AUD'],
	},
	CD: {
		id: 39,
		title: 'Democratic Republic of the Congo 🇨🇩',
		code: 'CD',
		mcc: ['630'],
		timezone_min_seconds: 3600,
		timezone_max_seconds: 7200,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['fr', 'ln', 'kg', 'sw', 'lu'],
		currencies: ['CDF'],
	},
	CF: {
		id: 40,
		title: 'Central African Republic 🇨🇫',
		code: 'CF',
		mcc: ['623'],
		timezone_min_seconds: 3600,
		timezone_max_seconds: 3600,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['fr', 'sg'],
		currencies: ['XAF'],
	},
	CG: {
		id: 41,
		title: 'Republic of the Congo 🇨🇬',
		code: 'CG',
		mcc: ['629'],
		timezone_min_seconds: 3600,
		timezone_max_seconds: 3600,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['fr', 'ln'],
		currencies: ['XAF'],
	},
	CH: {
		id: 42,
		title: 'Switzerland 🇨🇭',
		code: 'CH',
		mcc: ['228'],
		timezone_min_seconds: 7200,
		timezone_max_seconds: 7200,
		is_metric: 1,
		tier: 1,
		is_activated: 0,
		languages: ['de', 'fr', 'it'],
		currencies: ['CHE', 'CHF', 'CHW'],
	},
	CI: {
		id: 43,
		title: 'Ivory Coast 🇨🇮',
		code: 'CI',
		mcc: ['612'],
		timezone_min_seconds: 0,
		timezone_max_seconds: 0,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['fr'],
		currencies: ['XOF'],
	},
	CK: {
		id: 44,
		title: 'Cook Islands 🇨🇰',
		code: 'CK',
		mcc: ['548'],
		timezone_min_seconds: -36000,
		timezone_max_seconds: -36000,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['en'],
		currencies: ['NZD'],
	},
	CL: {
		id: 45,
		title: 'Chile 🇨🇱',
		code: 'CL',
		mcc: ['730'],
		timezone_min_seconds: -21600,
		timezone_max_seconds: -10800,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['es'],
		currencies: ['CLF', 'CLP'],
	},
	CM: {
		id: 46,
		title: 'Cameroon 🇨🇲',
		code: 'CM',
		mcc: ['624'],
		timezone_min_seconds: 3600,
		timezone_max_seconds: 3600,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['en', 'fr'],
		currencies: ['XAF'],
	},
	CN: {
		id: 47,
		title: 'China 🇨🇳',
		code: 'CN',
		mcc: ['460', '461'],
		timezone_min_seconds: 21600,
		timezone_max_seconds: 28800,
		is_metric: 1,
		tier: 2,
		is_activated: 0,
		languages: ['zh'],
		currencies: ['CNY'],
	},
	CO: {
		id: 48,
		title: 'Colombia 🇨🇴',
		code: 'CO',
		mcc: ['732'],
		timezone_min_seconds: -18000,
		timezone_max_seconds: -18000,
		is_metric: 1,
		tier: 2,
		is_activated: 0,
		languages: ['es'],
		currencies: ['COP'],
	},
	CR: {
		id: 49,
		title: 'Costa Rica 🇨🇷',
		code: 'CR',
		mcc: ['712'],
		timezone_min_seconds: -21600,
		timezone_max_seconds: -21600,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['es'],
		currencies: ['CRC'],
	},
	CU: {
		id: 50,
		title: 'Cuba 🇨🇺',
		code: 'CU',
		mcc: ['368'],
		timezone_min_seconds: -14400,
		timezone_max_seconds: -14400,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['es'],
		currencies: ['CUC', 'CUP'],
	},
	CV: {
		id: 51,
		title: 'Cabo Verde 🇨🇻',
		code: 'CV',
		mcc: ['625'],
		timezone_min_seconds: -3600,
		timezone_max_seconds: -3600,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['pt'],
		currencies: ['CVE'],
	},
	CW: {
		id: 52,
		title: 'Curaçao 🇨🇼',
		code: 'CW',
		mcc: ['362'],
		timezone_min_seconds: -14400,
		timezone_max_seconds: -14400,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['nl', 'pa', 'en'],
		currencies: ['ANG'],
	},
	CX: {
		id: 53,
		title: 'Christmas Island 🇨🇽',
		code: 'CX',
		mcc: ['999'],
		timezone_min_seconds: 25200,
		timezone_max_seconds: 25200,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['en'],
		currencies: ['AUD'],
	},
	CY: {
		id: 54,
		title: 'Cyprus 🇨🇾',
		code: 'CY',
		mcc: ['280'],
		timezone_min_seconds: 10800,
		timezone_max_seconds: 10800,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['el', 'tr', 'hy'],
		currencies: ['EUR'],
	},
	CZ: {
		id: 55,
		title: 'Czechia 🇨🇿',
		code: 'CZ',
		mcc: ['230'],
		timezone_min_seconds: 7200,
		timezone_max_seconds: 7200,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['cs', 'sk'],
		currencies: ['CZK'],
	},
	DE: {
		id: 56,
		title: 'Germany 🇩🇪',
		code: 'DE',
		mcc: ['262'],
		timezone_min_seconds: 7200,
		timezone_max_seconds: 7200,
		is_metric: 1,
		tier: 1,
		is_activated: 0,
		languages: ['de'],
		currencies: ['EUR'],
	},
	DJ: {
		id: 57,
		title: 'Djibouti 🇩🇯',
		code: 'DJ',
		mcc: ['638'],
		timezone_min_seconds: 10800,
		timezone_max_seconds: 10800,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['fr', 'ar'],
		currencies: ['DJF'],
	},
	DK: {
		id: 58,
		title: 'Denmark 🇩🇰',
		code: 'DK',
		mcc: ['238'],
		timezone_min_seconds: 7200,
		timezone_max_seconds: 7200,
		is_metric: 1,
		tier: 1,
		is_activated: 0,
		languages: ['da'],
		currencies: ['DKK'],
	},
	DM: {
		id: 59,
		title: 'Dominica 🇩🇲',
		code: 'DM',
		mcc: ['366'],
		timezone_min_seconds: -14400,
		timezone_max_seconds: -14400,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['en'],
		currencies: ['XCD'],
	},
	DO: {
		id: 60,
		title: 'Dominican Republic 🇩🇴',
		code: 'DO',
		mcc: ['370'],
		timezone_min_seconds: -14400,
		timezone_max_seconds: -14400,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['es'],
		currencies: ['DOP'],
	},
	DZ: {
		id: 61,
		title: 'Algeria 🇩🇿',
		code: 'DZ',
		mcc: ['603'],
		timezone_min_seconds: 3600,
		timezone_max_seconds: 3600,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['ar'],
		currencies: ['DZD'],
	},
	EC: {
		id: 62,
		title: 'Ecuador 🇪🇨',
		code: 'EC',
		mcc: ['740'],
		timezone_min_seconds: -21600,
		timezone_max_seconds: -18000,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['es'],
		currencies: ['USD'],
	},
	EE: {
		id: 63,
		title: 'Estonia 🇪🇪',
		code: 'EE',
		mcc: ['248'],
		timezone_min_seconds: 10800,
		timezone_max_seconds: 10800,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['et'],
		currencies: ['EUR'],
	},
	EG: {
		id: 64,
		title: 'Egypt 🇪🇬',
		code: 'EG',
		mcc: ['602'],
		timezone_min_seconds: 7200,
		timezone_max_seconds: 7200,
		is_metric: 1,
		tier: 2,
		is_activated: 0,
		languages: ['ar'],
		currencies: ['EGP'],
	},
	EH: {
		id: 65,
		title: 'Western Sahara 🇪🇭',
		code: 'EH',
		mcc: ['999'],
		timezone_min_seconds: 0,
		timezone_max_seconds: 0,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['es'],
		currencies: ['MAD', 'DZD', 'MRU'],
	},
	ER: {
		id: 66,
		title: 'Eritrea 🇪🇷',
		code: 'ER',
		mcc: ['657'],
		timezone_min_seconds: 10800,
		timezone_max_seconds: 10800,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['ti', 'ar', 'en'],
		currencies: ['ERN'],
	},
	ES: {
		id: 67,
		title: 'Spain 🇪🇸',
		code: 'ES',
		mcc: ['214'],
		timezone_min_seconds: 3600,
		timezone_max_seconds: 7200,
		is_metric: 1,
		tier: 1,
		is_activated: 0,
		languages: ['es', 'eu', 'ca', 'gl', 'oc'],
		currencies: ['EUR'],
	},
	ET: {
		id: 68,
		title: 'Ethiopia 🇪🇹',
		code: 'ET',
		mcc: ['636'],
		timezone_min_seconds: 10800,
		timezone_max_seconds: 10800,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['am'],
		currencies: ['ETB'],
	},
	FI: {
		id: 69,
		title: 'Finland 🇫🇮',
		code: 'FI',
		mcc: ['244'],
		timezone_min_seconds: 10800,
		timezone_max_seconds: 10800,
		is_metric: 1,
		tier: 1,
		is_activated: 0,
		languages: ['fi', 'sv'],
		currencies: ['EUR'],
	},
	FJ: {
		id: 70,
		title: 'Fiji 🇫🇯',
		code: 'FJ',
		mcc: ['542'],
		timezone_min_seconds: 43200,
		timezone_max_seconds: 43200,
		is_metric: 1,
		tier: 2,
		is_activated: 0,
		languages: ['en', 'fj', 'hi', 'ur'],
		currencies: ['FJD'],
	},
	FK: {
		id: 71,
		title: 'Falkland Islands 🇫🇰',
		code: 'FK',
		mcc: ['750'],
		timezone_min_seconds: -10800,
		timezone_max_seconds: -10800,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['en'],
		currencies: ['FKP'],
	},
	FM: {
		id: 72,
		title: 'Micronesia 🇫🇲',
		code: 'FM',
		mcc: ['550'],
		timezone_min_seconds: 36000,
		timezone_max_seconds: 39600,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['en'],
		currencies: ['USD'],
	},
	FO: {
		id: 73,
		title: 'Faroe Islands 🇫🇴',
		code: 'FO',
		mcc: ['288'],
		timezone_min_seconds: 3600,
		timezone_max_seconds: 3600,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['fo'],
		currencies: ['DKK'],
	},
	FR: {
		id: 74,
		title: 'France 🇫🇷',
		code: 'FR',
		mcc: ['208'],
		timezone_min_seconds: 7200,
		timezone_max_seconds: 7200,
		is_metric: 1,
		tier: 1,
		is_activated: 0,
		languages: ['fr'],
		currencies: ['EUR'],
	},
	GA: {
		id: 75,
		title: 'Gabon 🇬🇦',
		code: 'GA',
		mcc: ['628'],
		timezone_min_seconds: 3600,
		timezone_max_seconds: 3600,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['fr'],
		currencies: ['XAF'],
	},
	GB: {
		id: 76,
		title: 'United Kingdom 🇬🇧',
		code: 'GB',
		mcc: ['234', '235'],
		timezone_min_seconds: 3600,
		timezone_max_seconds: 3600,
		is_metric: 1,
		tier: 1,
		is_activated: 0,
		languages: ['en'],
		currencies: ['GBP'],
	},
	GD: {
		id: 77,
		title: 'Grenada 🇬🇩',
		code: 'GD',
		mcc: ['352'],
		timezone_min_seconds: -14400,
		timezone_max_seconds: -14400,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['en'],
		currencies: ['XCD'],
	},
	GE: {
		id: 78,
		title: 'Georgia 🇬🇪',
		code: 'GE',
		mcc: ['282'],
		timezone_min_seconds: 14400,
		timezone_max_seconds: 14400,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['ka'],
		currencies: ['GEL'],
	},
	GF: {
		id: 79,
		title: 'French Guiana 🇬🇫',
		code: 'GF',
		mcc: ['742'],
		timezone_min_seconds: -10800,
		timezone_max_seconds: -10800,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['fr'],
		currencies: ['EUR'],
	},
	GG: {
		id: 80,
		title: 'Guernsey 🇬🇬',
		code: 'GG',
		mcc: ['234'],
		timezone_min_seconds: 3600,
		timezone_max_seconds: 3600,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['en', 'fr'],
		currencies: ['GBP'],
	},
	GH: {
		id: 81,
		title: 'Ghana 🇬🇭',
		code: 'GH',
		mcc: ['620'],
		timezone_min_seconds: 0,
		timezone_max_seconds: 0,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['en'],
		currencies: ['GHS'],
	},
	GI: {
		id: 82,
		title: 'Gibraltar 🇬🇮',
		code: 'GI',
		mcc: ['266'],
		timezone_min_seconds: 7200,
		timezone_max_seconds: 7200,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['en'],
		currencies: ['GIP'],
	},
	GL: {
		id: 83,
		title: 'Greenland 🇬🇱',
		code: 'GL',
		mcc: ['290'],
		timezone_min_seconds: -10800,
		timezone_max_seconds: 0,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['kl'],
		currencies: ['DKK'],
	},
	GM: {
		id: 84,
		title: 'Gambia 🇬🇲',
		code: 'GM',
		mcc: ['607'],
		timezone_min_seconds: 0,
		timezone_max_seconds: 0,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['en'],
		currencies: ['GMD'],
	},
	GN: {
		id: 85,
		title: 'Guinea 🇬🇳',
		code: 'GN',
		mcc: ['611'],
		timezone_min_seconds: 0,
		timezone_max_seconds: 0,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['fr', 'ff'],
		currencies: ['GNF'],
	},
	GP: {
		id: 86,
		title: 'Guadeloupe 🇬🇵',
		code: 'GP',
		mcc: ['340'],
		timezone_min_seconds: -14400,
		timezone_max_seconds: -14400,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['fr'],
		currencies: ['EUR'],
	},
	GQ: {
		id: 87,
		title: 'Equatorial Guinea 🇬🇶',
		code: 'GQ',
		mcc: ['627'],
		timezone_min_seconds: 3600,
		timezone_max_seconds: 3600,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['es', 'fr'],
		currencies: ['XAF'],
	},
	GR: {
		id: 88,
		title: 'Greece 🇬🇷',
		code: 'GR',
		mcc: ['202'],
		timezone_min_seconds: 10800,
		timezone_max_seconds: 10800,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['el'],
		currencies: ['EUR'],
	},
	GS: {
		id: 89,
		title: 'South Georgia and the South Sandwich Islands 🇬🇸',
		code: 'GS',
		mcc: ['999'],
		timezone_min_seconds: -7200,
		timezone_max_seconds: -7200,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['en'],
		currencies: ['GBP'],
	},
	GT: {
		id: 90,
		title: 'Guatemala 🇬🇹',
		code: 'GT',
		mcc: ['704'],
		timezone_min_seconds: -21600,
		timezone_max_seconds: -21600,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['es'],
		currencies: ['GTQ'],
	},
	GU: {
		id: 91,
		title: 'Guam 🇬🇺',
		code: 'GU',
		mcc: ['310', '311'],
		timezone_min_seconds: 36000,
		timezone_max_seconds: 36000,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['en', 'ch', 'es'],
		currencies: ['USD'],
	},
	GW: {
		id: 92,
		title: 'Guinea-Bissau 🇬🇼',
		code: 'GW',
		mcc: ['632'],
		timezone_min_seconds: 0,
		timezone_max_seconds: 0,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['pt'],
		currencies: ['XOF'],
	},
	GY: {
		id: 93,
		title: 'Guyana 🇬🇾',
		code: 'GY',
		mcc: ['738'],
		timezone_min_seconds: -14400,
		timezone_max_seconds: -14400,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['en'],
		currencies: ['GYD'],
	},
	HK: {
		id: 94,
		title: 'Hong Kong 🇭🇰',
		code: 'HK',
		mcc: ['454'],
		timezone_min_seconds: 28800,
		timezone_max_seconds: 28800,
		is_metric: 1,
		tier: 2,
		is_activated: 0,
		languages: ['zh', 'en'],
		currencies: ['HKD'],
	},
	HN: {
		id: 95,
		title: 'Honduras 🇭🇳',
		code: 'HN',
		mcc: ['708'],
		timezone_min_seconds: -21600,
		timezone_max_seconds: -21600,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['es'],
		currencies: ['HNL'],
	},
	HR: {
		id: 96,
		title: 'Croatia 🇭🇷',
		code: 'HR',
		mcc: ['219'],
		timezone_min_seconds: 7200,
		timezone_max_seconds: 7200,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['hr'],
		currencies: ['HRK'],
	},
	HT: {
		id: 97,
		title: 'Haiti 🇭🇹',
		code: 'HT',
		mcc: ['372'],
		timezone_min_seconds: -14400,
		timezone_max_seconds: -14400,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['fr', 'ht'],
		currencies: ['HTG', 'USD'],
	},
	HU: {
		id: 98,
		title: 'Hungary 🇭🇺',
		code: 'HU',
		mcc: ['216'],
		timezone_min_seconds: 7200,
		timezone_max_seconds: 7200,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['hu'],
		currencies: ['HUF'],
	},
	ID: {
		id: 99,
		title: 'Indonesia 🇮🇩',
		code: 'ID',
		mcc: ['510'],
		timezone_min_seconds: 25200,
		timezone_max_seconds: 32400,
		is_metric: 1,
		tier: 2,
		is_activated: 0,
		languages: ['id'],
		currencies: ['IDR'],
	},
	IE: {
		id: 100,
		title: 'Ireland 🇮🇪',
		code: 'IE',
		mcc: ['272'],
		timezone_min_seconds: 3600,
		timezone_max_seconds: 3600,
		is_metric: 1,
		tier: 1,
		is_activated: 0,
		languages: ['ga', 'en'],
		currencies: ['EUR'],
	},
	IL: {
		id: 101,
		title: 'Israel 🇮🇱',
		code: 'IL',
		mcc: ['425'],
		timezone_min_seconds: 10800,
		timezone_max_seconds: 10800,
		is_metric: 1,
		tier: 2,
		is_activated: 0,
		languages: ['he', 'ar'],
		currencies: ['ILS'],
	},
	IM: {
		id: 102,
		title: 'Isle of Man 🇮🇲',
		code: 'IM',
		mcc: ['234'],
		timezone_min_seconds: 3600,
		timezone_max_seconds: 3600,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['en', 'gv'],
		currencies: ['GBP'],
	},
	IN: {
		id: 103,
		title: 'India 🇮🇳',
		code: 'IN',
		mcc: ['404', '405', '406'],
		timezone_min_seconds: 19800,
		timezone_max_seconds: 19800,
		is_metric: 1,
		tier: 2,
		is_activated: 0,
		languages: ['hi', 'en'],
		currencies: ['INR'],
	},
	IO: {
		id: 104,
		title: 'British Indian Ocean Territory 🇮🇴',
		code: 'IO',
		mcc: ['995'],
		timezone_min_seconds: 21600,
		timezone_max_seconds: 21600,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['en'],
		currencies: ['USD'],
	},
	IQ: {
		id: 105,
		title: 'Iraq 🇮🇶',
		code: 'IQ',
		mcc: ['418'],
		timezone_min_seconds: 10800,
		timezone_max_seconds: 10800,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['ar', 'ku'],
		currencies: ['IQD'],
	},
	IR: {
		id: 106,
		title: 'Iran 🇮🇷',
		code: 'IR',
		mcc: ['432'],
		timezone_min_seconds: 16200,
		timezone_max_seconds: 16200,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['fa'],
		currencies: ['IRR'],
	},
	IS: {
		id: 107,
		title: 'Iceland 🇮🇸',
		code: 'IS',
		mcc: ['274'],
		timezone_min_seconds: 0,
		timezone_max_seconds: 0,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['is'],
		currencies: ['ISK'],
	},
	IT: {
		id: 108,
		title: 'Italy 🇮🇹',
		code: 'IT',
		mcc: ['222'],
		timezone_min_seconds: 7200,
		timezone_max_seconds: 7200,
		is_metric: 1,
		tier: 1,
		is_activated: 0,
		languages: ['it'],
		currencies: ['EUR'],
	},
	JE: {
		id: 109,
		title: 'Jersey 🇯🇪',
		code: 'JE',
		mcc: ['234'],
		timezone_min_seconds: 3600,
		timezone_max_seconds: 3600,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['en', 'fr'],
		currencies: ['GBP'],
	},
	JM: {
		id: 110,
		title: 'Jamaica 🇯🇲',
		code: 'JM',
		mcc: ['338'],
		timezone_min_seconds: -18000,
		timezone_max_seconds: -18000,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['en'],
		currencies: ['JMD'],
	},
	JO: {
		id: 111,
		title: 'Jordan 🇯🇴',
		code: 'JO',
		mcc: ['416'],
		timezone_min_seconds: 10800,
		timezone_max_seconds: 10800,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['ar'],
		currencies: ['JOD'],
	},
	JP: {
		id: 112,
		title: 'Japan 🇯🇵',
		code: 'JP',
		mcc: ['440', '441'],
		timezone_min_seconds: 32400,
		timezone_max_seconds: 32400,
		is_metric: 1,
		tier: 2,
		is_activated: 0,
		languages: ['ja'],
		currencies: ['JPY'],
	},
	KE: {
		id: 113,
		title: 'Kenya 🇰🇪',
		code: 'KE',
		mcc: ['639'],
		timezone_min_seconds: 10800,
		timezone_max_seconds: 10800,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['en', 'sw'],
		currencies: ['KES'],
	},
	KG: {
		id: 114,
		title: 'Kyrgyzstan 🇰🇬',
		code: 'KG',
		mcc: ['437'],
		timezone_min_seconds: 21600,
		timezone_max_seconds: 21600,
		is_metric: 1,
		tier: 3,
		is_activated: 1,
		languages: ['ky', 'ru'],
		currencies: ['KGS', 'RUB'],
	},
	KH: {
		id: 115,
		title: 'Cambodia 🇰🇭',
		code: 'KH',
		mcc: ['456'],
		timezone_min_seconds: 25200,
		timezone_max_seconds: 25200,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['km'],
		currencies: ['KHR'],
	},
	KI: {
		id: 116,
		title: 'Kiribati 🇰🇮',
		code: 'KI',
		mcc: ['545'],
		timezone_min_seconds: 43200,
		timezone_max_seconds: 50400,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['en'],
		currencies: ['AUD'],
	},
	KM: {
		id: 117,
		title: 'Comoros 🇰🇲',
		code: 'KM',
		mcc: ['654'],
		timezone_min_seconds: 10800,
		timezone_max_seconds: 10800,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['ar', 'fr'],
		currencies: ['KMF'],
	},
	KN: {
		id: 118,
		title: 'Saint Kitts and Nevis 🇰🇳',
		code: 'KN',
		mcc: ['356'],
		timezone_min_seconds: -14400,
		timezone_max_seconds: -14400,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['en'],
		currencies: ['XCD'],
	},
	KP: {
		id: 119,
		title: 'North Korea 🇰🇵',
		code: 'KP',
		mcc: ['467'],
		timezone_min_seconds: 32400,
		timezone_max_seconds: 32400,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['ko'],
		currencies: ['KPW'],
	},
	KR: {
		id: 120,
		title: 'South Korea 🇰🇷',
		code: 'KR',
		mcc: ['450'],
		timezone_min_seconds: 32400,
		timezone_max_seconds: 32400,
		is_metric: 1,
		tier: 2,
		is_activated: 0,
		languages: ['ko'],
		currencies: ['KRW'],
	},
	KW: {
		id: 121,
		title: 'Kuwait 🇰🇼',
		code: 'KW',
		mcc: ['419'],
		timezone_min_seconds: 10800,
		timezone_max_seconds: 10800,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['ar'],
		currencies: ['KWD'],
	},
	KY: {
		id: 122,
		title: 'Cayman Islands 🇰🇾',
		code: 'KY',
		mcc: ['346'],
		timezone_min_seconds: -18000,
		timezone_max_seconds: -18000,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['en'],
		currencies: ['KYD'],
	},
	KZ: {
		id: 123,
		title: 'Kazakhstan 🇰🇿',
		code: 'KZ',
		mcc: ['401', '250'],
		timezone_min_seconds: 18000,
		timezone_max_seconds: 21600,
		is_metric: 1,
		tier: 3,
		is_activated: 1,
		languages: ['kk', 'ru'],
		currencies: ['KZT', 'RUB'],
	},
	LA: {
		id: 124,
		title: 'Laos 🇱🇦',
		code: 'LA',
		mcc: ['457'],
		timezone_min_seconds: 25200,
		timezone_max_seconds: 25200,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['lo'],
		currencies: ['LAK'],
	},
	LB: {
		id: 125,
		title: 'Lebanon 🇱🇧',
		code: 'LB',
		mcc: ['415'],
		timezone_min_seconds: 10800,
		timezone_max_seconds: 10800,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['ar', 'fr'],
		currencies: ['LBP'],
	},
	LC: {
		id: 126,
		title: 'Saint Lucia 🇱🇨',
		code: 'LC',
		mcc: ['358'],
		timezone_min_seconds: -14400,
		timezone_max_seconds: -14400,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['en'],
		currencies: ['XCD'],
	},
	LI: {
		id: 127,
		title: 'Liechtenstein 🇱🇮',
		code: 'LI',
		mcc: ['295'],
		timezone_min_seconds: 7200,
		timezone_max_seconds: 7200,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['de'],
		currencies: ['CHF'],
	},
	LK: {
		id: 128,
		title: 'Sri Lanka 🇱🇰',
		code: 'LK',
		mcc: ['413'],
		timezone_min_seconds: 19800,
		timezone_max_seconds: 19800,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['si', 'ta'],
		currencies: ['LKR'],
	},
	LR: {
		id: 129,
		title: 'Liberia 🇱🇷',
		code: 'LR',
		mcc: ['618'],
		timezone_min_seconds: 0,
		timezone_max_seconds: 0,
		is_metric: 0,
		tier: 3,
		is_activated: 0,
		languages: ['en'],
		currencies: ['LRD'],
	},
	LS: {
		id: 130,
		title: 'Lesotho 🇱🇸',
		code: 'LS',
		mcc: ['651'],
		timezone_min_seconds: 7200,
		timezone_max_seconds: 7200,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['en', 'st'],
		currencies: ['LSL', 'ZAR'],
	},
	LT: {
		id: 131,
		title: 'Lithuania 🇱🇹',
		code: 'LT',
		mcc: ['246'],
		timezone_min_seconds: 10800,
		timezone_max_seconds: 10800,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['lt'],
		currencies: ['EUR'],
	},
	LU: {
		id: 132,
		title: 'Luxembourg 🇱🇺',
		code: 'LU',
		mcc: ['270'],
		timezone_min_seconds: 7200,
		timezone_max_seconds: 7200,
		is_metric: 1,
		tier: 1,
		is_activated: 0,
		languages: ['fr', 'de', 'lb'],
		currencies: ['EUR'],
	},
	LV: {
		id: 133,
		title: 'Latvia 🇱🇻',
		code: 'LV',
		mcc: ['247'],
		timezone_min_seconds: 10800,
		timezone_max_seconds: 10800,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['lv'],
		currencies: ['EUR'],
	},
	LY: {
		id: 134,
		title: 'Libya 🇱🇾',
		code: 'LY',
		mcc: ['606'],
		timezone_min_seconds: 7200,
		timezone_max_seconds: 7200,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['ar'],
		currencies: ['LYD'],
	},
	MA: {
		id: 135,
		title: 'Morocco 🇲🇦',
		code: 'MA',
		mcc: ['604'],
		timezone_min_seconds: 0,
		timezone_max_seconds: 0,
		is_metric: 1,
		tier: 2,
		is_activated: 0,
		languages: ['ar'],
		currencies: ['MAD'],
	},
	MC: {
		id: 136,
		title: 'Monaco 🇲🇨',
		code: 'MC',
		mcc: ['212'],
		timezone_min_seconds: 7200,
		timezone_max_seconds: 7200,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['fr'],
		currencies: ['EUR'],
	},
	MD: {
		id: 137,
		title: 'Moldova 🇲🇩',
		code: 'MD',
		mcc: ['259'],
		timezone_min_seconds: 10800,
		timezone_max_seconds: 10800,
		is_metric: 1,
		tier: 3,
		is_activated: 1,
		languages: ['ro'],
		currencies: ['MDL'],
	},
	ME: {
		id: 138,
		title: 'Montenegro 🇲🇪',
		code: 'ME',
		mcc: ['297'],
		timezone_min_seconds: 7200,
		timezone_max_seconds: 7200,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['sr', 'bs', 'sq', 'hr'],
		currencies: ['EUR'],
	},
	MF: {
		id: 139,
		title: 'Saint Martin 🇲🇫',
		code: 'MF',
		mcc: ['340'],
		timezone_min_seconds: -14400,
		timezone_max_seconds: -14400,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['en', 'fr', 'nl'],
		currencies: ['EUR'],
	},
	MG: {
		id: 140,
		title: 'Madagascar 🇲🇬',
		code: 'MG',
		mcc: ['646'],
		timezone_min_seconds: 10800,
		timezone_max_seconds: 10800,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['fr', 'mg'],
		currencies: ['MGA'],
	},
	MH: {
		id: 141,
		title: 'Marshall Islands 🇲🇭',
		code: 'MH',
		mcc: ['551'],
		timezone_min_seconds: 43200,
		timezone_max_seconds: 43200,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['en', 'mh'],
		currencies: ['USD'],
	},
	MK: {
		id: 142,
		title: 'North Macedonia 🇲🇰',
		code: 'MK',
		mcc: ['294'],
		timezone_min_seconds: 7200,
		timezone_max_seconds: 7200,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['mk'],
		currencies: ['MKD'],
	},
	ML: {
		id: 143,
		title: 'Mali 🇲🇱',
		code: 'ML',
		mcc: ['610'],
		timezone_min_seconds: 0,
		timezone_max_seconds: 0,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['fr'],
		currencies: ['XOF'],
	},
	MM: {
		id: 144,
		title: 'Myanmar 🇲🇲',
		code: 'MM',
		mcc: ['414'],
		timezone_min_seconds: 23400,
		timezone_max_seconds: 23400,
		is_metric: 0,
		tier: 3,
		is_activated: 0,
		languages: ['my'],
		currencies: ['MMK'],
	},
	MN: {
		id: 145,
		title: 'Mongolia 🇲🇳',
		code: 'MN',
		mcc: ['428'],
		timezone_min_seconds: 25200,
		timezone_max_seconds: 28800,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['mn'],
		currencies: ['MNT'],
	},
	MO: {
		id: 146,
		title: 'Macao 🇲🇴',
		code: 'MO',
		mcc: ['455'],
		timezone_min_seconds: 28800,
		timezone_max_seconds: 28800,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['zh', 'pt'],
		currencies: ['MOP'],
	},
	MP: {
		id: 147,
		title: 'Northern Mariana Islands 🇲🇵',
		code: 'MP',
		mcc: ['310'],
		timezone_min_seconds: 36000,
		timezone_max_seconds: 36000,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['en', 'ch'],
		currencies: ['USD'],
	},
	MQ: {
		id: 148,
		title: 'Martinique 🇲🇶',
		code: 'MQ',
		mcc: ['340'],
		timezone_min_seconds: -14400,
		timezone_max_seconds: -14400,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['fr'],
		currencies: ['EUR'],
	},
	MR: {
		id: 149,
		title: 'Mauritania 🇲🇷',
		code: 'MR',
		mcc: ['609'],
		timezone_min_seconds: 0,
		timezone_max_seconds: 0,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['ar'],
		currencies: ['MRU'],
	},
	MS: {
		id: 150,
		title: 'Montserrat 🇲🇸',
		code: 'MS',
		mcc: ['354'],
		timezone_min_seconds: -14400,
		timezone_max_seconds: -14400,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['en'],
		currencies: ['XCD'],
	},
	MT: {
		id: 151,
		title: 'Malta 🇲🇹',
		code: 'MT',
		mcc: ['278'],
		timezone_min_seconds: 7200,
		timezone_max_seconds: 7200,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['mt', 'en'],
		currencies: ['EUR'],
	},
	MU: {
		id: 152,
		title: 'Mauritius 🇲🇺',
		code: 'MU',
		mcc: ['617'],
		timezone_min_seconds: 14400,
		timezone_max_seconds: 14400,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['en'],
		currencies: ['MUR'],
	},
	MV: {
		id: 153,
		title: 'Maldives 🇲🇻',
		code: 'MV',
		mcc: ['472'],
		timezone_min_seconds: 18000,
		timezone_max_seconds: 18000,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['dv'],
		currencies: ['MVR'],
	},
	MW: {
		id: 154,
		title: 'Malawi 🇲🇼',
		code: 'MW',
		mcc: ['650'],
		timezone_min_seconds: 7200,
		timezone_max_seconds: 7200,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['en', 'ny'],
		currencies: ['MWK'],
	},
	MX: {
		id: 155,
		title: 'Mexico 🇲🇽',
		code: 'MX',
		mcc: ['334'],
		timezone_min_seconds: -25200,
		timezone_max_seconds: -18000,
		is_metric: 1,
		tier: 2,
		is_activated: 0,
		languages: ['es'],
		currencies: ['MXN'],
	},
	MY: {
		id: 156,
		title: 'Malaysia 🇲🇾',
		code: 'MY',
		mcc: ['502'],
		timezone_min_seconds: 28800,
		timezone_max_seconds: 28800,
		is_metric: 1,
		tier: 2,
		is_activated: 0,
		languages: ['ms'],
		currencies: ['MYR'],
	},
	MZ: {
		id: 157,
		title: 'Mozambique 🇲🇿',
		code: 'MZ',
		mcc: ['643'],
		timezone_min_seconds: 7200,
		timezone_max_seconds: 7200,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['pt'],
		currencies: ['MZN'],
	},
	NA: {
		id: 158,
		title: 'Namibia 🇳🇦',
		code: 'NA',
		mcc: ['649'],
		timezone_min_seconds: 7200,
		timezone_max_seconds: 7200,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['en', 'af'],
		currencies: ['NAD', 'ZAR'],
	},
	NC: {
		id: 159,
		title: 'New Caledonia 🇳🇨',
		code: 'NC',
		mcc: ['546'],
		timezone_min_seconds: 39600,
		timezone_max_seconds: 39600,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['fr'],
		currencies: ['XPF'],
	},
	NE: {
		id: 160,
		title: 'Niger 🇳🇪',
		code: 'NE',
		mcc: ['614'],
		timezone_min_seconds: 3600,
		timezone_max_seconds: 3600,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['fr'],
		currencies: ['XOF'],
	},
	NF: {
		id: 161,
		title: 'Norfolk Island 🇳🇫',
		code: 'NF',
		mcc: ['505'],
		timezone_min_seconds: 39600,
		timezone_max_seconds: 39600,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['en'],
		currencies: ['AUD'],
	},
	NG: {
		id: 162,
		title: 'Nigeria 🇳🇬',
		code: 'NG',
		mcc: ['621'],
		timezone_min_seconds: 3600,
		timezone_max_seconds: 3600,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['en'],
		currencies: ['NGN'],
	},
	NI: {
		id: 163,
		title: 'Nicaragua 🇳🇮',
		code: 'NI',
		mcc: ['710'],
		timezone_min_seconds: -21600,
		timezone_max_seconds: -21600,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['es'],
		currencies: ['NIO'],
	},
	NL: {
		id: 164,
		title: 'Netherlands 🇳🇱',
		code: 'NL',
		mcc: ['204'],
		timezone_min_seconds: 7200,
		timezone_max_seconds: 7200,
		is_metric: 1,
		tier: 1,
		is_activated: 0,
		languages: ['nl'],
		currencies: ['EUR'],
	},
	NO: {
		id: 165,
		title: 'Norway 🇳🇴',
		code: 'NO',
		mcc: ['242'],
		timezone_min_seconds: 7200,
		timezone_max_seconds: 7200,
		is_metric: 1,
		tier: 1,
		is_activated: 0,
		languages: ['no', 'nb', 'nn'],
		currencies: ['NOK'],
	},
	NP: {
		id: 166,
		title: 'Nepal 🇳🇵',
		code: 'NP',
		mcc: ['429'],
		timezone_min_seconds: 20700,
		timezone_max_seconds: 20700,
		is_metric: 1,
		tier: 2,
		is_activated: 0,
		languages: ['ne'],
		currencies: ['NPR'],
	},
	NR: {
		id: 167,
		title: 'Nauru 🇳🇷',
		code: 'NR',
		mcc: ['536'],
		timezone_min_seconds: 43200,
		timezone_max_seconds: 43200,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['en', 'na'],
		currencies: ['AUD'],
	},
	NU: {
		id: 168,
		title: 'Niue 🇳🇺',
		code: 'NU',
		mcc: ['555'],
		timezone_min_seconds: -39600,
		timezone_max_seconds: -39600,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['en'],
		currencies: ['NZD'],
	},
	NZ: {
		id: 169,
		title: 'New Zealand 🇳🇿',
		code: 'NZ',
		mcc: ['530'],
		timezone_min_seconds: 43200,
		timezone_max_seconds: 45900,
		is_metric: 1,
		tier: 1,
		is_activated: 0,
		languages: ['en', 'mi'],
		currencies: ['NZD'],
	},
	OM: {
		id: 170,
		title: 'Oman 🇴🇲',
		code: 'OM',
		mcc: ['422'],
		timezone_min_seconds: 14400,
		timezone_max_seconds: 14400,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['ar'],
		currencies: ['OMR'],
	},
	PA: {
		id: 171,
		title: 'Panama 🇵🇦',
		code: 'PA',
		mcc: ['714'],
		timezone_min_seconds: -18000,
		timezone_max_seconds: -18000,
		is_metric: 1,
		tier: 2,
		is_activated: 0,
		languages: ['es'],
		currencies: ['PAB', 'USD'],
	},
	PE: {
		id: 172,
		title: 'Peru 🇵🇪',
		code: 'PE',
		mcc: ['716'],
		timezone_min_seconds: -18000,
		timezone_max_seconds: -18000,
		is_metric: 1,
		tier: 2,
		is_activated: 0,
		languages: ['es'],
		currencies: ['PEN'],
	},
	PF: {
		id: 173,
		title: 'French Polynesia 🇵🇫',
		code: 'PF',
		mcc: ['547'],
		timezone_min_seconds: -36000,
		timezone_max_seconds: -32400,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['fr'],
		currencies: ['XPF'],
	},
	PG: {
		id: 174,
		title: 'Papua New Guinea 🇵🇬',
		code: 'PG',
		mcc: ['537'],
		timezone_min_seconds: 36000,
		timezone_max_seconds: 39600,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['en'],
		currencies: ['PGK'],
	},
	PH: {
		id: 175,
		title: 'Philippines 🇵🇭',
		code: 'PH',
		mcc: ['515'],
		timezone_min_seconds: 28800,
		timezone_max_seconds: 28800,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['en'],
		currencies: ['PHP'],
	},
	PK: {
		id: 176,
		title: 'Pakistan 🇵🇰',
		code: 'PK',
		mcc: ['410'],
		timezone_min_seconds: 18000,
		timezone_max_seconds: 18000,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['en', 'ur'],
		currencies: ['PKR'],
	},
	PL: {
		id: 177,
		title: 'Poland 🇵🇱',
		code: 'PL',
		mcc: ['260'],
		timezone_min_seconds: 7200,
		timezone_max_seconds: 7200,
		is_metric: 1,
		tier: 2,
		is_activated: 0,
		languages: ['pl'],
		currencies: ['PLN'],
	},
	PM: {
		id: 178,
		title: 'Saint Pierre and Miquelon 🇵🇲',
		code: 'PM',
		mcc: ['308'],
		timezone_min_seconds: -7200,
		timezone_max_seconds: -7200,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['fr'],
		currencies: ['EUR'],
	},
	PN: {
		id: 179,
		title: 'Pitcairn 🇵🇳',
		code: 'PN',
		mcc: ['999'],
		timezone_min_seconds: -28800,
		timezone_max_seconds: -28800,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['en'],
		currencies: ['NZD'],
	},
	PR: {
		id: 180,
		title: 'Puerto Rico 🇵🇷',
		code: 'PR',
		mcc: ['330'],
		timezone_min_seconds: -14400,
		timezone_max_seconds: -14400,
		is_metric: 1,
		tier: 2,
		is_activated: 0,
		languages: ['es', 'en'],
		currencies: ['USD'],
	},
	PS: {
		id: 181,
		title: 'Palestine 🇵🇸',
		code: 'PS',
		mcc: ['425'],
		timezone_min_seconds: 10800,
		timezone_max_seconds: 10800,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['ar'],
		currencies: ['ILS'],
	},
	PT: {
		id: 182,
		title: 'Portugal 🇵🇹',
		code: 'PT',
		mcc: ['268'],
		timezone_min_seconds: 0,
		timezone_max_seconds: 3600,
		is_metric: 1,
		tier: 2,
		is_activated: 0,
		languages: ['pt'],
		currencies: ['EUR'],
	},
	PW: {
		id: 183,
		title: 'Palau 🇵🇼',
		code: 'PW',
		mcc: ['552'],
		timezone_min_seconds: 32400,
		timezone_max_seconds: 32400,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['en'],
		currencies: ['USD'],
	},
	PY: {
		id: 184,
		title: 'Paraguay 🇵🇾',
		code: 'PY',
		mcc: ['744'],
		timezone_min_seconds: -14400,
		timezone_max_seconds: -14400,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['es', 'gn'],
		currencies: ['PYG'],
	},
	QA: {
		id: 185,
		title: 'Qatar 🇶🇦',
		code: 'QA',
		mcc: ['427'],
		timezone_min_seconds: 10800,
		timezone_max_seconds: 10800,
		is_metric: 1,
		tier: 2,
		is_activated: 0,
		languages: ['ar'],
		currencies: ['QAR'],
	},
	RE: {
		id: 186,
		title: 'Réunion 🇷🇪',
		code: 'RE',
		mcc: ['647'],
		timezone_min_seconds: 14400,
		timezone_max_seconds: 14400,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['fr'],
		currencies: ['EUR'],
	},
	RO: {
		id: 187,
		title: 'Romania 🇷🇴',
		code: 'RO',
		mcc: ['226'],
		timezone_min_seconds: 10800,
		timezone_max_seconds: 10800,
		is_metric: 1,
		tier: 2,
		is_activated: 0,
		languages: ['ro'],
		currencies: ['RON'],
	},
	RS: {
		id: 188,
		title: 'Serbia 🇷🇸',
		code: 'RS',
		mcc: ['220'],
		timezone_min_seconds: 7200,
		timezone_max_seconds: 7200,
		is_metric: 1,
		tier: 2,
		is_activated: 0,
		languages: ['sr'],
		currencies: ['RSD'],
	},
	RU: {
		id: 189,
		title: 'Russia 🇷🇺',
		code: 'RU',
		mcc: ['250'],
		timezone_min_seconds: 7200,
		timezone_max_seconds: 43200,
		is_metric: 1,
		tier: 2,
		is_activated: 1,
		languages: ['ru'],
		currencies: ['RUB'],
	},
	RW: {
		id: 190,
		title: 'Rwanda 🇷🇼',
		code: 'RW',
		mcc: ['635'],
		timezone_min_seconds: 7200,
		timezone_max_seconds: 7200,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['rw', 'en', 'fr'],
		currencies: ['RWF'],
	},
	SA: {
		id: 191,
		title: 'Saudi Arabia 🇸🇦',
		code: 'SA',
		mcc: ['420'],
		timezone_min_seconds: 10800,
		timezone_max_seconds: 10800,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['ar'],
		currencies: ['SAR'],
	},
	SB: {
		id: 192,
		title: 'Solomon Islands 🇸🇧',
		code: 'SB',
		mcc: ['540'],
		timezone_min_seconds: 39600,
		timezone_max_seconds: 39600,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['en'],
		currencies: ['SBD'],
	},
	SC: {
		id: 193,
		title: 'Seychelles 🇸🇨',
		code: 'SC',
		mcc: ['633'],
		timezone_min_seconds: 14400,
		timezone_max_seconds: 14400,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['fr', 'en'],
		currencies: ['SCR'],
	},
	SD: {
		id: 194,
		title: 'Sudan 🇸🇩',
		code: 'SD',
		mcc: ['634'],
		timezone_min_seconds: 7200,
		timezone_max_seconds: 7200,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['ar', 'en'],
		currencies: ['SDG'],
	},
	SE: {
		id: 195,
		title: 'Sweden 🇸🇪',
		code: 'SE',
		mcc: ['240'],
		timezone_min_seconds: 7200,
		timezone_max_seconds: 7200,
		is_metric: 1,
		tier: 1,
		is_activated: 0,
		languages: ['sv'],
		currencies: ['SEK'],
	},
	SG: {
		id: 196,
		title: 'Singapore 🇸🇬',
		code: 'SG',
		mcc: ['525'],
		timezone_min_seconds: 28800,
		timezone_max_seconds: 28800,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['en', 'ms', 'ta', 'zh'],
		currencies: ['SGD'],
	},
	SH: {
		id: 197,
		title: 'Saint Helena, Ascension and Tristan da Cunha 🇸🇭',
		code: 'SH',
		mcc: ['658'],
		timezone_min_seconds: 0,
		timezone_max_seconds: 0,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['en'],
		currencies: ['SHP'],
	},
	SI: {
		id: 198,
		title: 'Slovenia 🇸🇮',
		code: 'SI',
		mcc: ['293'],
		timezone_min_seconds: 7200,
		timezone_max_seconds: 7200,
		is_metric: 1,
		tier: 2,
		is_activated: 0,
		languages: ['sl'],
		currencies: ['EUR'],
	},
	SJ: {
		id: 199,
		title: 'Svalbard and Jan Mayen 🇸🇯',
		code: 'SJ',
		mcc: ['999'],
		timezone_min_seconds: 7200,
		timezone_max_seconds: 7200,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['no'],
		currencies: ['NOK'],
	},
	SK: {
		id: 200,
		title: 'Slovakia 🇸🇰',
		code: 'SK',
		mcc: ['231'],
		timezone_min_seconds: 7200,
		timezone_max_seconds: 7200,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['sk'],
		currencies: ['EUR'],
	},
	SL: {
		id: 201,
		title: 'Sierra Leone 🇸🇱',
		code: 'SL',
		mcc: ['619'],
		timezone_min_seconds: 0,
		timezone_max_seconds: 0,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['en'],
		currencies: ['SLL'],
	},
	SM: {
		id: 202,
		title: 'San Marino 🇸🇲',
		code: 'SM',
		mcc: ['292'],
		timezone_min_seconds: 7200,
		timezone_max_seconds: 7200,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['it'],
		currencies: ['EUR'],
	},
	SN: {
		id: 203,
		title: 'Senegal 🇸🇳',
		code: 'SN',
		mcc: ['608'],
		timezone_min_seconds: 0,
		timezone_max_seconds: 0,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['fr'],
		currencies: ['XOF'],
	},
	SO: {
		id: 204,
		title: 'Somalia 🇸🇴',
		code: 'SO',
		mcc: ['637'],
		timezone_min_seconds: 10800,
		timezone_max_seconds: 10800,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['so', 'ar'],
		currencies: ['SOS'],
	},
	SR: {
		id: 205,
		title: 'Suriname 🇸🇷',
		code: 'SR',
		mcc: ['746'],
		timezone_min_seconds: -10800,
		timezone_max_seconds: -10800,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['nl'],
		currencies: ['SRD'],
	},
	SS: {
		id: 206,
		title: 'South Sudan 🇸🇸',
		code: 'SS',
		mcc: ['659'],
		timezone_min_seconds: 7200,
		timezone_max_seconds: 7200,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['en'],
		currencies: ['SSP'],
	},
	ST: {
		id: 207,
		title: 'Sao Tome and Principe 🇸🇹',
		code: 'ST',
		mcc: ['626'],
		timezone_min_seconds: 0,
		timezone_max_seconds: 0,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['pt'],
		currencies: ['STN'],
	},
	SV: {
		id: 208,
		title: 'El Salvador 🇸🇻',
		code: 'SV',
		mcc: ['706'],
		timezone_min_seconds: -21600,
		timezone_max_seconds: -21600,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['es'],
		currencies: ['SVC', 'USD'],
	},
	SX: {
		id: 209,
		title: 'Sint Maarten 🇸🇽',
		code: 'SX',
		mcc: ['362'],
		timezone_min_seconds: -14400,
		timezone_max_seconds: -14400,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['nl', 'en'],
		currencies: ['ANG'],
	},
	SY: {
		id: 210,
		title: 'Syria 🇸🇾',
		code: 'SY',
		mcc: ['417'],
		timezone_min_seconds: 10800,
		timezone_max_seconds: 10800,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['ar'],
		currencies: ['SYP'],
	},
	SZ: {
		id: 211,
		title: 'Eswatini 🇸🇿',
		code: 'SZ',
		mcc: ['653'],
		timezone_min_seconds: 7200,
		timezone_max_seconds: 7200,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['en', 'ss'],
		currencies: ['SZL'],
	},
	TC: {
		id: 212,
		title: 'Turks and Caicos Islands 🇹🇨',
		code: 'TC',
		mcc: ['376'],
		timezone_min_seconds: -14400,
		timezone_max_seconds: -14400,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['en'],
		currencies: ['USD'],
	},
	TD: {
		id: 213,
		title: 'Chad 🇹🇩',
		code: 'TD',
		mcc: ['622'],
		timezone_min_seconds: 3600,
		timezone_max_seconds: 3600,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['fr', 'ar'],
		currencies: ['XAF'],
	},
	TF: {
		id: 214,
		title: 'French Southern Territories 🇹🇫',
		code: 'TF',
		mcc: ['999'],
		timezone_min_seconds: 18000,
		timezone_max_seconds: 18000,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['fr'],
		currencies: ['EUR'],
	},
	TG: {
		id: 215,
		title: 'Togo 🇹🇬',
		code: 'TG',
		mcc: ['615'],
		timezone_min_seconds: 0,
		timezone_max_seconds: 0,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['fr'],
		currencies: ['XOF'],
	},
	TH: {
		id: 216,
		title: 'Thailand 🇹🇭',
		code: 'TH',
		mcc: ['520'],
		timezone_min_seconds: 25200,
		timezone_max_seconds: 25200,
		is_metric: 1,
		tier: 2,
		is_activated: 0,
		languages: ['th'],
		currencies: ['THB'],
	},
	TJ: {
		id: 217,
		title: 'Tajikistan 🇹🇯',
		code: 'TJ',
		mcc: ['436'],
		timezone_min_seconds: 18000,
		timezone_max_seconds: 18000,
		is_metric: 1,
		tier: 3,
		is_activated: 1,
		languages: ['tg', 'ru'],
		currencies: ['TJS'],
	},
	TK: {
		id: 218,
		title: 'Tokelau 🇹🇰',
		code: 'TK',
		mcc: ['554'],
		timezone_min_seconds: 46800,
		timezone_max_seconds: 46800,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['en'],
		currencies: ['NZD'],
	},
	TL: {
		id: 219,
		title: 'Timor-Leste 🇹🇱',
		code: 'TL',
		mcc: ['514'],
		timezone_min_seconds: 32400,
		timezone_max_seconds: 32400,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['pt'],
		currencies: ['USD'],
	},
	TM: {
		id: 220,
		title: 'Turkmenistan 🇹🇲',
		code: 'TM',
		mcc: ['438'],
		timezone_min_seconds: 18000,
		timezone_max_seconds: 18000,
		is_metric: 1,
		tier: 3,
		is_activated: 1,
		languages: ['tk', 'ru'],
		currencies: ['TMT'],
	},
	TN: {
		id: 221,
		title: 'Tunisia 🇹🇳',
		code: 'TN',
		mcc: ['605'],
		timezone_min_seconds: 3600,
		timezone_max_seconds: 3600,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['ar'],
		currencies: ['TND'],
	},
	TO: {
		id: 222,
		title: 'Tonga 🇹🇴',
		code: 'TO',
		mcc: ['539'],
		timezone_min_seconds: 46800,
		timezone_max_seconds: 46800,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['en', 'to'],
		currencies: ['TOP'],
	},
	TR: {
		id: 223,
		title: 'Turkey 🇹🇷',
		code: 'TR',
		mcc: ['286'],
		timezone_min_seconds: 10800,
		timezone_max_seconds: 10800,
		is_metric: 1,
		tier: 2,
		is_activated: 0,
		languages: ['tr'],
		currencies: ['TRY'],
	},
	TT: {
		id: 224,
		title: 'Trinidad and Tobago 🇹🇹',
		code: 'TT',
		mcc: ['374'],
		timezone_min_seconds: -14400,
		timezone_max_seconds: -14400,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['en'],
		currencies: ['TTD'],
	},
	TV: {
		id: 225,
		title: 'Tuvalu 🇹🇻',
		code: 'TV',
		mcc: ['553'],
		timezone_min_seconds: 43200,
		timezone_max_seconds: 43200,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['en'],
		currencies: ['AUD'],
	},
	TW: {
		id: 226,
		title: 'Taiwan 🇹🇼',
		code: 'TW',
		mcc: ['466'],
		timezone_min_seconds: 28800,
		timezone_max_seconds: 28800,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['zh'],
		currencies: ['TWD'],
	},
	TZ: {
		id: 227,
		title: 'Tanzania 🇹🇿',
		code: 'TZ',
		mcc: ['640'],
		timezone_min_seconds: 10800,
		timezone_max_seconds: 10800,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['sw', 'en'],
		currencies: ['TZS'],
	},
	UA: {
		id: 228,
		title: 'Ukraine 🇺🇦',
		code: 'UA',
		mcc: ['255', '250'],
		timezone_min_seconds: 10800,
		timezone_max_seconds: 10800,
		is_metric: 1,
		tier: 2,
		is_activated: 1,
		languages: ['uk'],
		currencies: ['UAH', 'RUB'],
	},
	UG: {
		id: 229,
		title: 'Uganda 🇺🇬',
		code: 'UG',
		mcc: ['641'],
		timezone_min_seconds: 10800,
		timezone_max_seconds: 10800,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['en', 'sw'],
		currencies: ['UGX'],
	},
	UM: {
		id: 230,
		title: 'United States Minor Outlying Islands 🇺🇲',
		code: 'UM',
		mcc: ['999'],
		timezone_min_seconds: -39600,
		timezone_max_seconds: 43200,
		is_metric: 0,
		tier: 3,
		is_activated: 0,
		languages: ['en'],
		currencies: ['USD'],
	},
	US: {
		id: 231,
		title: 'United States of America 🇺🇸',
		code: 'US',
		mcc: ['310', '311', '312', '313', '314', '315', '316'],
		timezone_min_seconds: -36000,
		timezone_max_seconds: -14400,
		is_metric: 0,
		tier: 1,
		is_activated: 0,
		languages: ['en'],
		currencies: ['USD', 'USN', 'USS'],
	},
	UY: {
		id: 232,
		title: 'Uruguay 🇺🇾',
		code: 'UY',
		mcc: ['748'],
		timezone_min_seconds: -10800,
		timezone_max_seconds: -10800,
		is_metric: 1,
		tier: 2,
		is_activated: 0,
		languages: ['es'],
		currencies: ['UYI', 'UYU'],
	},
	UZ: {
		id: 233,
		title: 'Uzbekistan 🇺🇿',
		code: 'UZ',
		mcc: ['434'],
		timezone_min_seconds: 18000,
		timezone_max_seconds: 18000,
		is_metric: 1,
		tier: 3,
		is_activated: 1,
		languages: ['uz', 'ru'],
		currencies: ['UZS'],
	},
	VA: {
		id: 234,
		title: 'Holy See 🇻🇦',
		code: 'VA',
		mcc: ['999'],
		timezone_min_seconds: 7200,
		timezone_max_seconds: 7200,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['it', 'la'],
		currencies: ['EUR'],
	},
	VC: {
		id: 235,
		title: 'Saint Vincent and the Grenadines 🇻🇨',
		code: 'VC',
		mcc: ['360'],
		timezone_min_seconds: -14400,
		timezone_max_seconds: -14400,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['en'],
		currencies: ['XCD'],
	},
	VE: {
		id: 236,
		title: 'Venezuela 🇻🇪',
		code: 'VE',
		mcc: ['734'],
		timezone_min_seconds: -14400,
		timezone_max_seconds: -14400,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['es'],
		currencies: ['VES'],
	},
	VG: {
		id: 237,
		title: 'Virgin Islands (UK) 🇻🇬',
		code: 'VG',
		mcc: ['348'],
		timezone_min_seconds: -14400,
		timezone_max_seconds: -14400,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['en'],
		currencies: ['USD'],
	},
	VI: {
		id: 238,
		title: 'Virgin Islands (US) 🇻🇮',
		code: 'VI',
		mcc: ['332'],
		timezone_min_seconds: -14400,
		timezone_max_seconds: -14400,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['en'],
		currencies: ['USD'],
	},
	VN: {
		id: 239,
		title: 'Vietnam 🇻🇳',
		code: 'VN',
		mcc: ['452'],
		timezone_min_seconds: 25200,
		timezone_max_seconds: 25200,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['vi'],
		currencies: ['VND'],
	},
	VU: {
		id: 240,
		title: 'Vanuatu 🇻🇺',
		code: 'VU',
		mcc: ['541'],
		timezone_min_seconds: 39600,
		timezone_max_seconds: 39600,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['bi', 'en', 'fr'],
		currencies: ['VUV'],
	},
	WF: {
		id: 241,
		title: 'Wallis and Futuna 🇼🇫',
		code: 'WF',
		mcc: ['543'],
		timezone_min_seconds: 43200,
		timezone_max_seconds: 43200,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['fr'],
		currencies: ['XPF'],
	},
	WS: {
		id: 242,
		title: 'Samoa 🇼🇸',
		code: 'WS',
		mcc: ['549'],
		timezone_min_seconds: 46800,
		timezone_max_seconds: 46800,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['sm', 'en'],
		currencies: ['WST'],
	},
	YE: {
		id: 243,
		title: 'Yemen 🇾🇪',
		code: 'YE',
		mcc: ['421'],
		timezone_min_seconds: 10800,
		timezone_max_seconds: 10800,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['ar'],
		currencies: ['YER'],
	},
	YT: {
		id: 244,
		title: 'Mayotte 🇾🇹',
		code: 'YT',
		mcc: ['999'],
		timezone_min_seconds: 10800,
		timezone_max_seconds: 10800,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['fr'],
		currencies: ['EUR'],
	},
	ZA: {
		id: 245,
		title: 'South Africa 🇿🇦',
		code: 'ZA',
		mcc: ['655'],
		timezone_min_seconds: 7200,
		timezone_max_seconds: 7200,
		is_metric: 1,
		tier: 2,
		is_activated: 0,
		languages: ['af', 'en', 'nr', 'st', 'ss', 'tn', 'ts', 've', 'xh', 'zu'],
		currencies: ['ZAR'],
	},
	ZM: {
		id: 246,
		title: 'Zambia 🇿🇲',
		code: 'ZM',
		mcc: ['645'],
		timezone_min_seconds: 7200,
		timezone_max_seconds: 7200,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['en'],
		currencies: ['ZMW'],
	},
	ZW: {
		id: 247,
		title: 'Zimbabwe 🇿🇼',
		code: 'ZW',
		mcc: ['648'],
		timezone_min_seconds: 7200,
		timezone_max_seconds: 7200,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['en', 'sn', 'nd'],
		currencies: ['USD', 'ZAR', 'BWP', 'GBP', 'AUD', 'CNY', 'INR', 'JPY'],
	},
	WAKANDA: {
		id: 248,
		title: 'WAKANDA',
		code: 'WAKANDA',
		mcc: ['-1'],
		timezone_min_seconds: 0,
		timezone_max_seconds: 0,
		is_metric: 1,
		tier: 3,
		is_activated: 0,
		languages: ['-1'],
		currencies: ['BITCOIN'],
	},
};
