import {
	IonPage,
	IonHeader,
	IonToolbar,
	IonButtons,
	IonMenuButton,
	IonTitle,
	IonContent,
	IonButton,
	IonProgressBar,
	IonItem,
	IonInput,
	IonLabel,
	IonIcon,
	IonCheckbox,
	IonRadioGroup,
	IonListHeader,
	IonRadio,
	IonImg,
} from '@ionic/react';
import React, { RefObject } from 'react';

import API from '../../API';
import { host, serverAddress } from '../../Constants';

export default class extends React.Component {
	filePickerRef: RefObject<HTMLInputElement>;

	state: any = {
		file: null,
		loading: false,
		percent: 0,
		errorMessage: null,

		title: '',
		subtitle: '',
		texts: '',
		useBg: false,
		mode: 1,

		result: [
			{ fileName: 'logo.png', path: 'public/WfjKdXR27znqUDMdo2aqPzzu7B0fIRsAqwNX/logo.png' },
			{ fileName: 'logo_subtitle.png', path: 'public/WfjKdXR27znqUDMdo2aqPzzu7B0fIRsAqwNX/logo_subtitle.png' },
			{ fileName: 'logo_text.png', path: 'public/WfjKdXR27znqUDMdo2aqPzzu7B0fIRsAqwNX/logo_text.png' },
			{ fileName: 'mockup_0.png', path: 'public/WfjKdXR27znqUDMdo2aqPzzu7B0fIRsAqwNX/mockup_0.png' },
			{ fileName: 'mockup_1.png', path: 'public/WfjKdXR27znqUDMdo2aqPzzu7B0fIRsAqwNX/mockup_1.png' },
			{ fileName: 'mockup_2.png', path: 'public/WfjKdXR27znqUDMdo2aqPzzu7B0fIRsAqwNX/mockup_2.png' },
			{ fileName: 'mockup_3.png', path: 'public/WfjKdXR27znqUDMdo2aqPzzu7B0fIRsAqwNX/mockup_3.png' },
			{ fileName: 'mockups_all.png', path: 'public/WfjKdXR27znqUDMdo2aqPzzu7B0fIRsAqwNX/mockups_all.png' },
			{ fileName: 'phone_0.png', path: 'public/WfjKdXR27znqUDMdo2aqPzzu7B0fIRsAqwNX/phone_0.png' },
			{ fileName: 'phone_1.png', path: 'public/WfjKdXR27znqUDMdo2aqPzzu7B0fIRsAqwNX/phone_1.png' },
			{ fileName: 'phone_2.png', path: 'public/WfjKdXR27znqUDMdo2aqPzzu7B0fIRsAqwNX/phone_2.png' },
			{ fileName: 'phone_3.png', path: 'public/WfjKdXR27znqUDMdo2aqPzzu7B0fIRsAqwNX/phone_3.png' },
			{ fileName: 'text_0.png', path: 'public/WfjKdXR27znqUDMdo2aqPzzu7B0fIRsAqwNX/text_0.png' },
			{ fileName: 'text_1.png', path: 'public/WfjKdXR27znqUDMdo2aqPzzu7B0fIRsAqwNX/text_1.png' },
			{ fileName: 'text_2.png', path: 'public/WfjKdXR27znqUDMdo2aqPzzu7B0fIRsAqwNX/text_2.png' },
			{ fileName: 'text_3.png', path: 'public/WfjKdXR27znqUDMdo2aqPzzu7B0fIRsAqwNX/text_3.png' },
		],
	};

	constructor(props: any) {
		super(props);
		this.filePickerRef = React.createRef();
	}

	async onZipChoosed(e: any) {
		if (e.target.files.length == 0) {
			return;
		}

		this.setState({
			file: e.target.files[0],
		});
	}

	componentDidMount() {}

	render() {
		return (
			<IonPage>
				<IonHeader>
					<IonToolbar>
						<IonButtons slot="start">
							<IonMenuButton />
						</IonButtons>
						<IonTitle>Генератор дизайна</IonTitle>
					</IonToolbar>
				</IonHeader>
				<IonContent>
					<input
						ref={this.filePickerRef}
						style={{ display: 'none' }}
						type="file"
						accept="zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed"
						onChange={this.onZipChoosed.bind(this)}
					/>

					<div className="ion-padding">
						<IonItem
							button
							onClick={() => {
								this.filePickerRef.current?.click();
							}}>
							<IonLabel>{this.state.file ? 'Выбрать другой' : 'Загрузить архив с мокапами'}</IonLabel>
						</IonItem>
						<IonItem>
							<IonInput
								placeholder="Title"
								onIonChange={(e) => {
									this.setState({
										title: e.detail.value!,
									});
								}}
								clearInput></IonInput>
						</IonItem>
						<IonItem>
							<IonInput
								placeholder="Subtitle"
								onIonChange={(e) => {
									this.setState({
										subtitle: e.detail.value!,
									});
								}}
								clearInput></IonInput>
						</IonItem>
						<IonItem>
							<IonInput
								placeholder="Подписи на мокапах РАЗДЕЛЯЕМ ЧЕРЕЗ ЗАПЯТУЮ"
								onIonChange={(e) => {
									this.setState({
										texts: e.detail.value!,
									});
								}}
								clearInput></IonInput>
						</IonItem>
						<IonItem>
							<IonLabel>Использовать фото (+5 секунд)</IonLabel>
							<IonCheckbox
								checked={this.state.useBg}
								onIonChange={(e) => {
									this.setState({
										useBg: e.detail.checked,
									});
								}}></IonCheckbox>
						</IonItem>
						<IonRadioGroup
							value={this.state.mode}
							onIonChange={(e) => {
								this.setState({
									mode: e.detail.value,
								});
							}}>
							<IonListHeader>
								<IonLabel>Выберите шаблон</IonLabel>
							</IonListHeader>
							<IonItem>
								<IonLabel className="ion-text-wrap">все скрины ставятся на всю область</IonLabel>
								<IonRadio slot="start" value="1" />
							</IonItem>
							<IonItem>
								<IonLabel className="ion-text-wrap">все скрины занимают только нижнюю часть мокапов</IonLabel>
								<IonRadio slot="start" value="2" />
							</IonItem>
							<IonItem>
								<IonLabel className="ion-text-wrap">все скрины занимают нижнюю часть, но ставятся немного под углом</IonLabel>
								<IonRadio slot="start" value="3" />
							</IonItem>
							<IonItem>
								<IonLabel className="ion-text-wrap">скрины слегка накладываются друг на друга и занимают центральную часть всей экспозиции</IonLabel>
								<IonRadio slot="start" value="4" />
							</IonItem>
						</IonRadioGroup>
						<IonItem
							button
							onClick={async () => {
								this.setState({
									loading: true,
								});

								API.uploadScreenshots(
									this.state.file,
									this.state.title,
									this.state.subtitle,
									this.state.texts,
									this.state.useBg,
									this.state.mode,
									(percent) => {
										console.log(percent);
										this.setState({
											percent: percent,
										});
									},
									(res) => {
										console.log(res);
										if (res.success) {
											this.setState({
												percent: 0,
												loading: false,
												result: res.data,
											});
										} else {
											alert(JSON.stringify(res.error));
										}
									},
								);
							}}>
							<IonLabel style={{ color: 'green', fontSize: 18, fontWeight: 'bold' }}>Загрузить</IonLabel>
						</IonItem>

						{this.state.loading ? (
							<div>
								<h2>
									{this.state.percent == 1 ? 'Обработка...' : 'Загрузка'} ({Math.round(this.state.percent * 100)}%)
								</h2>
								{this.state.percent != 1 ? <IonProgressBar value={this.state.percent}></IonProgressBar> : false}
							</div>
						) : (
							false
						)}

						{this.state.result.map((r: any) => {
							return (
								<IonItem key={r.fileName}>
									<IonLabel slot="start">{r.fileName}</IonLabel>
									<img
										slot="end"
										style={{ height: 100, backgroundColor: '#dedede' }}
										onClick={() => {
											window.open(host + r.path, '_blank');
										}}
										src={host + r.path}></img>
								</IonItem>
							);
						})}
					</div>
				</IonContent>
			</IonPage>
		);
	}
}
