import { IonPage, IonHeader, IonToolbar, IonButtons, IonMenuButton, IonTitle, IonContent, IonList, IonItem, IonLabel, IonInput, IonButton, IonIcon } from '@ionic/react';
import React from 'react';
import { alertCircleOutline, checkmarkDone } from 'ionicons/icons';

import API from '../../API';
import LoadingFullScreen from '../../components/LoadingFullScreen';
import { countries } from '../../Constants';

export default class extends React.Component {
	state: any = {
		apps: null,
	};

	componentDidMount() {
		this.getData();
	}

	getData() {
		API.getTesterAndroidBuilds().then((res) => {
			this.setState({
				apps: res.data,
			});
		});
	}

	render() {
		return (
			<IonPage>
				<IonHeader>
					<IonToolbar>
						<IonButtons slot="start">
							<IonMenuButton />
						</IonButtons>
						<IonTitle>Android сборки</IonTitle>
					</IonToolbar>
				</IonHeader>
				<IonContent>
					<div className="ion-padding">
						{this.state.apps == null ? (
							<LoadingFullScreen />
						) : (
							<IonList>
								{this.state.apps.map((a: any) => {
									return (
										<IonItem key={'android_app_' + a.id}>
											<IonLabel>
												<h2>
													[{a.id}] {a.title}
												</h2>
												<p>{a.name}</p>
												{a.is_reviewed ? (
													<p style={{ color: 'green' }}>Ревью проведен</p>
												) : a.review_comment ? (
													<p style={{ color: 'orange' }}>Ревью с комментариями</p>
												) : (
													<p style={{ color: 'red' }}>Не проведен ревью</p>
												)}
												<p>
													{a.apk_path ? (
														<>
															<a href={a.apk_path}>APK: {a.apk_path}</a>
															<br />
														</>
													) : (
														false
													)}
												</p>
											</IonLabel>
											{a.is_tested ? (
												<IonIcon color="success" icon={checkmarkDone}></IonIcon>
											) : a.is_need_fixing ? (
												<IonIcon color="danger" icon={alertCircleOutline}></IonIcon>
											) : (
												<IonButtons>
													<IonButton
														onClick={async () => {
															await API.testerSetAppIsTested(a.id);
															this.getData();
														}}
														color="success">
														Tested
													</IonButton>
													<IonButton
														onClick={async () => {
															await API.testerSetAppIsNeedFixing(a.id);
															this.getData();
														}}
														color="danger">
														Need fixing
													</IonButton>
												</IonButtons>
											)}
										</IonItem>
									);
								})}
							</IonList>
						)}
					</div>
				</IonContent>
			</IonPage>
		);
	}
}
