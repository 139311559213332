import { IonPage, IonHeader, IonToolbar, IonButtons, IonTitle, IonContent, IonList, IonButton, IonItem, IonLabel, IonBackButton, IonListHeader, IonSegment, IonIcon, IonSegmentButton, IonInput } from '@ionic/react';
import React from 'react';
import { logoAndroid, logoAppleAppstore, logoGooglePlaystore } from 'ionicons/icons';
import { RouteComponentProps } from 'react-router-dom';

import API from '../../API';
import LoadingFullScreen from '../../components/LoadingFullScreen';

interface Props
	extends RouteComponentProps<{
		id: string;
	}> {}

export default class extends React.Component<Props> {
	state: any = {
		server: null,
	};

	componentDidMount() {
		this.getServers();
	}

	async getServers() {
		const res = await API.farmerGetServer(this.props.match.params.id);
		if (res.success) {
			this.setState({
				server: res.data,
			});
		}
	}

	render() {
		const server = this.state.server;
		return (
			<IonPage>
				<IonHeader>
					<IonToolbar>
						<IonButtons slot="start">
							<IonBackButton defaultHref="/farmer/servers"></IonBackButton>
						</IonButtons>
						<IonTitle>Информация о сервере</IonTitle>
					</IonToolbar>
				</IonHeader>
				<IonContent>
					{this.state.server == null ? (
						<LoadingFullScreen />
					) : (
						<div className="ion-padding">
							<IonList>
								<IonItem key={'server_' + server.id}>
									<IonLabel>
										<h2
											onClick={() => {
												navigator.clipboard.writeText(server.ip);
											}}>
											IP: {server.ip}
										</h2>
										<p
											onClick={() => {
												navigator.clipboard.writeText(server.username);
											}}>
											Пользователь: {server.username}
										</p>
										<p
											onClick={() => {
												navigator.clipboard.writeText(server.password);
											}}>
											Пароль: {server.password}
										</p>
										<p>Статус: {server.status}</p>
									</IonLabel>
									<IonButtons slot="end">
										<IonButton
											onClick={async () => {
												if (window.confirm('Вы действительно хотите удалить сервер ' + server.ip)) {
													await API.farmerDeleteServer(server.id);
													this.getServers();
												}
											}}
											color="danger">
											Удалить
										</IonButton>
									</IonButtons>
								</IonItem>

								<br />
								<IonListHeader>
									<p>Информация об аккаунте</p>
								</IonListHeader>

								<IonSegment
									value={this.state.server.store_type}
									onIonChange={(e) => {
										server.store_type = e.detail.value;
										this.setState({
											server: server,
										});
									}}>
									<IonSegmentButton value="googleplay">
										<IonIcon icon={logoGooglePlaystore} />
										<IonLabel>Google Play</IonLabel>
									</IonSegmentButton>
									<IonSegmentButton value="appstore">
										<IonIcon icon={logoAppleAppstore} />
										<IonLabel>App Store</IonLabel>
									</IonSegmentButton>
									<IonSegmentButton value="appgallery">
										<IonIcon icon={logoAndroid} />
										<IonLabel>App Gallery</IonLabel>
									</IonSegmentButton>
								</IonSegment>

								<IonItem>
									<IonInput
										placeholder="Логин от стора"
										value={server.store_username}
										onIonChange={(e) => {
											server.store_username = e.detail.value;
											this.setState({
												server: server,
											});
										}}
										clearInput></IonInput>
								</IonItem>
								<IonItem>
									<IonInput
										placeholder="Пароль от стора"
										value={server.store_password}
										onIonChange={(e) => {
											server.store_password = e.detail.value;
											this.setState({
												server: server,
											});
										}}
										clearInput></IonInput>
								</IonItem>
								<IonItem>
									<IonInput
										placeholder="Дополнительная информация от стора"
										value={server.store_notes}
										onIonChange={(e) => {
											server.store_notes = e.detail.value;
											this.setState({
												server: server,
											});
										}}
										clearInput></IonInput>
								</IonItem>

								<IonItem>
									<IonLabel>
										<h2>Сохранение изменений</h2>
									</IonLabel>

									<IonButton
										onClick={async () => {
											console.log(server);
											await API.farmerUpdateServerInfo(server);
											this.getServers();
										}}
										slot="end">
										Сохранить информацию о аккаунте
									</IonButton>
								</IonItem>

								<br />
								<IonListHeader>
									<p>Передача сервера</p>
								</IonListHeader>

								<IonButton
									color="success"
									onClick={async () => {
										await API.farmerSendServerToPublisher(server.id);
										console.log('asdsd');
										document.location.href = '/farmer/servers';
									}}>
									Сдать аккаунт
								</IonButton>
							</IonList>
						</div>
					)}
				</IonContent>
			</IonPage>
		);
	}
}
