import { IonPage, IonHeader, IonToolbar, IonButtons, IonMenuButton, IonTitle, IonContent, IonList, IonItem, IonLabel, IonCard, IonCardContent, IonCardTitle, IonCardSubtitle } from '@ionic/react';
import React from 'react';

import API from '../../API';
import LoadingFullScreen from '../../components/LoadingFullScreen';
import WeeklyStatisticsComponent from '../../components/WeeklyStatisticsComponent';

export default class extends React.Component {
	state = {
		stats: null,
	};

	componentDidMount() {
		this.getStats();
	}

	async getStats() {
		const res = await API.getWeeklyStats();
		console.log(res.data);

		this.setState({
			stats: res.data,
		});
	}

	render() {
		const stat = this.state.stats as any;
		return (
			<IonPage>
				<IonHeader>
					<IonToolbar>
						<IonButtons slot="start">
							<IonMenuButton />
						</IonButtons>
						<IonTitle>Недельный отчет</IonTitle>
					</IonToolbar>
				</IonHeader>
				<IonContent>
					{this.state.stats == null ? (
						<LoadingFullScreen />
					) : (
						<div>
							<WeeklyStatisticsComponent report={stat.now} previous={stat.prev} />
							<WeeklyStatisticsComponent report={stat.prev} />
						</div>
					)}
				</IonContent>
			</IonPage>
		);
	}
}
