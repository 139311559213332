import React, { RefObject } from 'react';
import { IonItem, IonLabel, IonCard, IonCardContent, IonList, IonHeader, IonTitle, IonButton, IonToolbar, IonButtons, IonCheckbox, IonInput } from '@ionic/react';

interface SelectProps {
	didSelected: (item: Array<any>) => void;
	didCancelled: () => void;
	items: any[];
	beforeSelected: [];
}

export default class extends React.Component<SelectProps> {
	state = {
		selectedItems: Array<any>(),
		search: '',
	};

	constructor(props: any) {
		super(props);
	}

	componentDidMount() {
		this.setState({
			selectedItems: this.props.beforeSelected,
		});
	}

	render() {
		return (
			<IonCard style={{ overflow: 'scroll' }}>
				<IonHeader>
					<IonToolbar>
						<IonTitle>Выберите вариант</IonTitle>

						<IonButtons slot="end">
							<IonButton
								onClick={() => {
									for (let index = 0; index < this.props.items.length; index++) {
										if (this.props.items[index].tier == 1) {
											this.state.selectedItems.push(this.props.items[index]);
										}
									}
									this.setState({
										selectedItems: this.state.selectedItems,
									});
								}}>
								T1
							</IonButton>
							<IonButton
								onClick={() => {
									for (let index = 0; index < this.props.items.length; index++) {
										if (this.props.items[index].tier == 2) {
											this.state.selectedItems.push(this.props.items[index]);
										}
									}
									this.setState({
										selectedItems: this.state.selectedItems,
									});
								}}>
								T2
							</IonButton>
							<IonButton
								onClick={() => {
									for (let index = 0; index < this.props.items.length; index++) {
										if (this.props.items[index].tier == 3) {
											this.state.selectedItems.push(this.props.items[index]);
										}
									}
									this.setState({
										selectedItems: this.state.selectedItems,
									});
								}}>
								T3
							</IonButton>

							<IonButton
								onClick={() => {
									this.props.didSelected(this.state.selectedItems);
									this.setState({
										selectedItems: Array<any>(),
									});
								}}>
								Выбрать и закрыть
							</IonButton>
						</IonButtons>
					</IonToolbar>
				</IonHeader>
				<IonCardContent>
					<IonList>
						<IonItem>
							<IonInput
								placeholder="Поиск..."
								onIonChange={(e) => {
									this.setState({
										search: e.detail.value!,
									});
								}}
							/>
						</IonItem>
						{this.props.items
							.filter((i: any) => i.title.toLowerCase().includes(this.state.search.toLowerCase()))
							.map((i: any) => {
								return (
									<IonItem key={'new_bank_' + i.id}>
										<IonLabel>
											<h2>{i.title}</h2>
										</IonLabel>
										<IonCheckbox
											checked={this.state.selectedItems.findIndex((b: any) => b.id == i.id) != -1}
											onIonChange={(e) => {
												console.log(e.detail);
												if (e.detail.checked) {
													this.state.selectedItems.push(i);
												} else {
													const index = this.state.selectedItems.findIndex((b2: any) => b2.id == i.id);

													if (index != -1) {
														this.state.selectedItems.splice(index, 1);
													}
												}

												this.setState({
													selectedItems: this.state.selectedItems,
												});
											}}></IonCheckbox>
									</IonItem>
								);
							})}
					</IonList>
				</IonCardContent>
			</IonCard>
		);
	}
}
