import { IonIcon, IonLabel } from '@ionic/react';
import React from 'react';
import { informationCircle } from 'ionicons/icons';

import { getAppTrafRoleById } from '../../pages/RegisterPage';

export default class extends React.Component<{ userTable: any }> {
	render(): React.ReactNode {
		let workedMinutes = this.props.userTable.timeMonthHalf_1 + this.props.userTable.timeMonthHalf_2;
		let normalMinutes = this.props.userTable.businessDaysCount * this.props.userTable.user.workday_duration;
		return (
			<div
				style={{
					height: 100,
					border: '1px solid black',
					minWidth: 150,
					textAlign: 'center',
					backgroundColor: 'white',
					flexGrow: 1,
					verticalAlign: 'center',
				}}>
				<IonLabel>
					<div>
						<p style={{ fontSize: 11, height: 11 }}>{this.props.userTable.user.name}</p>
						<p style={{ fontSize: 8, height: 11 }}>{getAppTrafRoleById(this.props.userTable.user.role)?.description ?? this.props.userTable.user.role ?? 'Без роли'}</p>
						<p style={{ fontSize: 8, height: 11 }}>
							ЗП: 1-15: нал: {Math.floor(this.props.userTable.salaryMonthHalf_1 / 5000) * 5000} сбер: {this.props.userTable.salaryMonthHalf_1 % 5000}
						</p>
						<p style={{ fontSize: 8, height: 11 }}>
							16-31: нал: {Math.floor(this.props.userTable.salaryMonthHalf_2 / 5000) * 5000} сбер: {this.props.userTable.salaryMonthHalf_2 % 5000}
						</p>
						<p style={{ fontSize: 8, height: 11, color: workedMinutes >= normalMinutes? 'green' : 'red' }}>
							Минут: {workedMinutes + "("
								   + (workedMinutes < normalMinutes ? '-' + (normalMinutes - workedMinutes) : '+' + (workedMinutes - normalMinutes))
								   + ")"}
						</p>
					</div>
				</IonLabel>
			</div>
		);
	}
}
