import { IonPage, IonHeader, IonToolbar, IonButtons, IonMenuButton, IonTitle, IonContent, IonList, IonButton, IonItem, IonLabel, IonToast, IonAlert, IonSpinner, IonIcon } from '@ionic/react';
import { pauseSharp, playSharp } from 'ionicons/icons';
import React from 'react';

import API from '../../API';
import LoadingFullScreen from '../../components/LoadingFullScreen';
import GenerateName from '../../Helpers/GenerateName';

export default class extends React.Component {
	state: any = {
		projects: null,
		creatingProject: false,
		play: false,
	};

	constructor(props: any) {
		super(props);
	}

	componentDidMount() {
		this.getProjects();
	}

	async getProjects() {
		const res = await API.whiteAndroidDeveloperAndroidGetProjects();
		if (res.success) {
			this.setState({
				projects: res.data,
			});
		}
	}

	render() {
		return (
			<IonPage>
				<IonAlert
					isOpen={this.state.showCreateProjectAlert}
					onDidDismiss={() => {
						this.setState({ showCreateProjectAlert: false });
					}}
					header="Создание проекта"
					inputs={[
						{
							name: 'companyName',
							type: 'text',
							placeholder: 'Название компании',
						},
						{
							name: 'projectName',
							type: 'text',
							placeholder: 'Название проекта (прилы)',
						},
					]}
					buttons={[
						{
							text: 'Отмена',
							role: 'cancel',
							cssClass: 'secondary',
						},
						{
							text: 'Создать проект',
							handler: async (inputs) => {
								this.setState({
									creatingProject: true,
								});
								await API.whiteAndroidDeveloperAndroidCreateProject(inputs.companyName, inputs.projectName);
								this.getProjects();
								this.setState({
									creatingProject: false,
								});
							},
						},
					]}
				/>
				<IonHeader>
					<IonToolbar>
						<IonButtons slot="start">
							<IonMenuButton />
						</IonButtons>
						<IonTitle>Проекты Android</IonTitle>
						<IonButtons slot="end">
							{this.state.creatingProject ? (
								<IonSpinner></IonSpinner>
							) : (
								<IonButton
									onClick={async () => {
										this.setState({
											showCreateProjectAlert: true,
										});
									}}>
									Создать проект
								</IonButton>
							)}
						</IonButtons>
					</IonToolbar>
				</IonHeader>
				<IonContent>
					{this.state.projects == null ? (
						<LoadingFullScreen />
					) : (
						<div className="ion-padding">
							{this.state.projects.length == 0 ? (
								<div>
									<p>Вы не создавали проекты</p>
								</div>
							) : (
								<IonList>
									{this.state.projects.map((project: any) => {
										return (
											<IonItem routerLink={'/android-developer/projects/' + project.id} key={'developer_android_project_' + project.id}>
												<IonLabel>
													<h2>{project.title}</h2>
													<p>ID: {project.id}</p>
												</IonLabel>
											</IonItem>
										);
									})}
								</IonList>
							)}
						</div>
					)}
				</IonContent>
			</IonPage>
		);
	}
}
