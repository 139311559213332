import {
	IonPage,
	IonHeader,
	IonToolbar,
	IonButtons,
	IonMenuButton,
	IonTitle,
	IonContent,
	IonGrid,
	IonCol,
	IonRow,
	IonCardHeader,
	IonCardTitle,
	IonCard,
	IonCardContent,
	IonFooter,
	IonList,
	IonLabel,
	IonItem,
} from '@ionic/react';
import React from 'react';
import Pagination from 'react-js-pagination';

import API from '../../API';
import LoadingFullScreen from '../../components/LoadingFullScreen';

const PAGE_OFFSET = 200;

export default class extends React.Component {
	state = {
		page: 1,
		users: [],
		totalUsersCount: 0,
		isLoading: false,
	};

	componentDidMount() {
		this.getUsers();
	}

	async getUsers() {
		const res = await API.getUsers(PAGE_OFFSET, this.state.page - 1);
		console.log(res);
		if (res.success) {
			this.setState({
				users: res.data.users,
				totalUsersCount: res.data.totalCount,
			});
		}
	}

	render() {
		return (
			<IonPage>
				<IonHeader>
					<IonToolbar>
						<IonButtons slot="start">
							<IonMenuButton />
						</IonButtons>
						<IonTitle>Пользователи</IonTitle>
					</IonToolbar>
				</IonHeader>
				<IonContent>
					{this.state.users == null ? (
						<LoadingFullScreen />
					) : (
						<IonList>
							{this.state.users.map((user: any) => (
								<IonItem routerLink={'/users/' + user.id} key={'user_' + user.id}>
									<IonLabel>
										<h3>
											№{user.id} | {user.country} {user.city} | {user.ip}
										</h3>
										<p>Статус: {user.status ?? 'Просто открыл'}</p>
									</IonLabel>
								</IonItem>
							))}
						</IonList>
					)}
				</IonContent>
				<IonFooter>
					<IonToolbar style={{ textAlign: 'center' }}>
						<Pagination
							activePage={this.state.page}
							itemsCountPerPage={PAGE_OFFSET}
							totalItemsCount={this.state.totalUsersCount}
							pageRangeDisplayed={10}
							onChange={(data) => {
								this.state.page = data;
								this.setState({
									page: data,
								});
								this.getUsers();
							}}
						/>
					</IonToolbar>
				</IonFooter>
			</IonPage>
		);
	}
}
