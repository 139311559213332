import { IonList, IonCard, IonIcon, IonItem, IonImg } from '@ionic/react';
import { chevronUp, chevronDown, caretForwardOutline, time } from 'ionicons/icons';
import React from 'react';
import AnimateHeight from 'react-animate-height';

interface Props {
	brands: Array<any>;
}

export default class extends React.Component<Props> {
	state: any = {
		brands: this.props.brands,
		status: 0,
	};

	componentDidMount() {
		const needAppsInTop10 = 2;

		for (let index = 0; index < this.props.brands.length; index++) {
			const brand = this.state.brands[index];

			brand.score = (100 / needAppsInTop10) * brand.apps.filter((app: any) => (app.maxTopPosition?.last_position ?? 999) <= 10).length;
		}

		const averageScore = this.state.brands.map((b: any) => b.score as number).reduce((a: number, b: number) => a + b, 0) / this.state.brands.length;

		this.setState({ status: parseFloat(averageScore.toFixed(2)) });
	}

	getTextStatus(status: number) {
		if (status == 0) {
			return 'Очко';
		} else if (status < 20) {
			return 'Очень плохо';
		} else if (status < 40) {
			return 'Плохо';
		} else if (status < 60) {
			return 'Нормально';
		} else if (status < 80) {
			return 'Хорошо';
		} else {
			return 'Очень хорошо';
		}
	}
	getColorByStatus(status: number) {
		if (status == 0) {
			return '#bd0000';
		} else if (status < 20) {
			return 'red';
		} else if (status < 40) {
			return '#ff7300';
		} else if (status < 60) {
			return '#ffbf00';
		} else if (status < 80) {
			return '#73c400';
		} else {
			return 'green';
		}
	}

	render() {
		return (
			<IonList>
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<div>
						<p>
							<span style={{ fontWeight: 800 }}>Текущее состояние:</span> {this.state.status}%
						</p>
						<p>
							<span style={{ fontWeight: 800 }}>Статус:</span>{' '}
							<span
								style={{
									color: this.getColorByStatus(this.state.status),
									fontWeight: 700,
								}}>
								{this.getTextStatus(this.state.status)}
							</span>
						</p>
					</div>
				</div>

				{this.state.brands.map((brand: any) => (
					<IonCard key={'brand_stats_' + brand.id}>
						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								height: 50,
							}}>
							<img src={`/assets/partners_logo/${brand.id}.png`} alt={brand.title} width={100}></img>

							<p
								style={{
									paddingLeft: 16,
									color: 'black',
									marginBottom: 0,
									flex: 1,
								}}>
								<span style={{ fontWeight: 500 }}>Количество приложений:</span> {brand.apps.length}
							</p>
							<p
								style={{
									paddingLeft: 16,
									color: 'black',
									marginBottom: 0,
									flex: 1,
								}}>
								<span style={{ fontWeight: 500 }}>В сторе:</span> {brand.apps.filter((app: any) => app.published_to_store_at != null).length}
							</p>
							<p
								style={{
									paddingLeft: 16,
									color: 'black',
									marginBottom: 0,
									flex: 1,
								}}>
								<span style={{ fontWeight: 500 }}>На проверке:</span> {brand.apps.filter((app: any) => app.published_to_store_at == null).length}
							</p>
							<p
								style={{
									paddingLeft: 16,
									color: 'black',
									marginBottom: 0,
									flex: 1,
								}}>
								<span style={{ fontWeight: 500 }}>В топ 10:</span>{' '}
								<span style={{ color: this.getColorByStatus(brand.score), fontWeight: 500 }}>{brand.apps.filter((app: any) => (app.maxTopPosition?.last_position ?? 999) <= 10).length}</span>
							</p>
							<p
								style={{
									paddingLeft: 16,
									color: 'black',
									marginBottom: 0,
									flex: 1.2,
								}}>
								<span style={{ fontWeight: 500 }}>Состояние:</span> <span style={{ color: this.getColorByStatus(brand.score) }}>{this.getTextStatus(brand.score)}</span>
							</p>

							<div
								style={{
									display: 'flex',
									alignItems: 'center',
									paddingLeft: 16,
									flex: 1,
								}}
								onClick={() => {
									if (brand.isOpen) {
										brand.isOpen = false;
									} else {
										brand.isOpen = true;
									}
									this.setState({
										brands: this.state.brands,
									});
								}}>
								<div style={{ flex: 1 }}></div>
								<IonIcon icon={brand.isOpen ? chevronUp : chevronDown} color="primary"></IonIcon>
								<p
									style={{
										paddingLeft: 4,
										color: '#3880ff',
										marginBottom: 0,
										paddingRight: 16,
									}}>
									{brand.isOpen ? 'Скрыть' : 'Показать'}
								</p>
							</div>
						</div>

						<AnimateHeight duration={100} height={brand.isOpen ? 'auto' : 0}>
							<IonItem>
								<IonIcon icon={caretForwardOutline} style={{ color: 'green' }}></IonIcon>
								<p
									style={{
										paddingTop: 10,
										fontWeight: 600,
										fontSize: 16,
									}}>
									В сторе
								</p>
							</IonItem>
							{brand.apps
								.filter((app: any) => app.published_to_store_at != null)
								.sort((app1: any, app2: any) => {
									if (!app1.maxTopPosition?.last_position) {
										return 1;
									} else if (!app2.maxTopPosition?.last_position) {
										return -1;
									} else if (app1.maxTopPosition?.last_position == app2.maxTopPosition?.last_position) {
										return 0;
									} else {
										return app1.maxTopPosition?.last_position < app2.maxTopPosition?.last_position ? -1 : 1;
									}
								})
								.map((app: any) => {
									return (
										<IonItem routerLink={'/apps/' + app.id} key={'brand_stats_' + brand.id + '_app_' + app.id}>
											<div
												style={{
													display: 'flex',
													alignItems: 'center',
													width: '100%',
												}}>
												<p
													style={{
														paddingTop: 10,
														fontSize: 14,
														width: 100,
													}}>
													<span
														style={{
															fontWeight: 600,
														}}>
														ID:
													</span>{' '}
													{app.id}
												</p>
												<IonImg style={{ height: 15, width: 15, marginRight: 8 }} src={`assets/store_${app.store_id}.svg`}></IonImg>
												<p
													style={{
														paddingLeft: 0,
														paddingTop: 10,
														fontSize: 14,
														flex: 1,
													}}>
													<span
														style={{
															fontWeight: 600,
														}}>
														Название:
													</span>{' '}
													{app.title}
												</p>
												<p
													style={{
														paddingTop: 10,
														fontSize: 14,
														flex: 1,
														textAlign: 'end',
													}}>
													<span
														style={{
															fontWeight: 600,
														}}>
														Установок:
													</span>{' '}
													{app.install_count}
												</p>

												<p
													style={{
														paddingTop: 10,
														fontSize: 14,
														flex: 1,
														textAlign: 'end',
													}}>
													<span
														style={{
															fontWeight: 600,
														}}>
														Позиция:
													</span>{' '}
													{app.maxTopPosition?.last_position ?? 'НЕТ'}
												</p>
											</div>
										</IonItem>
									);
								})}

							<IonItem>
								<IonIcon icon={time} style={{ color: 'red', width: 18 }}></IonIcon>
								<p
									style={{
										paddingLeft: 4,
										paddingTop: 10,
										fontWeight: 600,
										fontSize: 16,
									}}>
									На проверке
								</p>
							</IonItem>

							{brand.apps
								.filter((app: any) => app.published_to_store_at == null)
								.map((app: any) => {
									return (
										<IonItem routerLink={'/apps/' + app.id} key={'brand_stats_' + brand.id + '_app_' + app.id}>
											<div
												style={{
													display: 'flex',
													alignItems: 'center',
													width: '100%',
												}}>
												<p
													style={{
														paddingTop: 10,
														fontSize: 14,
														width: 100,
													}}>
													<span
														style={{
															fontWeight: 600,
														}}>
														ID:
													</span>{' '}
													{app.id}
												</p>
												<IonImg style={{ height: 15, width: 15, marginRight: 8 }} src={`assets/store_${app.store_id}.svg`}></IonImg>
												<p
													style={{
														paddingLeft: 0,
														paddingTop: 10,
														fontSize: 14,
														flex: 1,
													}}>
													<span
														style={{
															fontWeight: 600,
														}}>
														Название:
													</span>{' '}
													{app.title}
												</p>
												<p
													style={{
														paddingTop: 10,
														fontSize: 14,
														flex: 1,
														textAlign: 'end',
													}}>
													<span
														style={{
															fontWeight: 600,
														}}>
														Отправлен:
													</span>{' '}
													{new Date(app.created).toLocaleDateString()}
												</p>
												<p
													style={{
														paddingTop: 10,
														fontSize: 14,
														flex: 1,
														textAlign: 'end',
													}}>
													<span
														style={{
															fontWeight: 600,
														}}>
														Кол-во дней:
													</span>{' '}
													{Math.ceil(Math.abs(new Date().getTime() - new Date(app.created).getTime()) / 86400000)}
												</p>
											</div>
										</IonItem>
									);
								})}
						</AnimateHeight>
					</IonCard>
				))}
			</IonList>
		);
	}
}
