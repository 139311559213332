import { IonPage, IonHeader, IonToolbar, IonButtons, IonMenuButton, IonTitle, IonContent, IonList, IonButton, IonItem, IonLabel, IonIcon, IonSelectOption, IonSelect } from '@ionic/react';
import { filterCircle, filterCircleOutline } from 'ionicons/icons';
import React from 'react';
import AnimateHeight from 'react-animate-height';

import API from '../../API';
import LoadingFullScreen from '../../components/LoadingFullScreen';
import PublisherAppComponent from '../../components/Publisher/PublisherAppComponent';

export default class extends React.Component {
	state: any = {
		apps: null,

		showFilter: false,
		stores: [],
		brands: [],

		store_id: null,
		brand_id: null,
		is_in_store: null,
		is_enabled: null,

		showPopover: false,
	};

	componentDidMount() {
		this.getPublisherApps();
	}

	async getPublisherApps() {
		const res = await API.getPublisherApps(this.state.store_id, this.state.brand_id, this.state.is_in_store, this.state.is_enabled);
		if (res.success) {
			this.setState({
				apps: res.data.offers,
				stores: res.data.stores,
				brands: res.data.brands,
			});
		}
	}

	render() {
		return (
			<IonPage>
				<IonHeader>
					<IonToolbar>
						<IonButtons slot="start">
							<IonMenuButton />
						</IonButtons>
						<IonTitle>Прилки</IonTitle>
						<IonButtons slot="end">
							<IonButton onClick={() => this.setState({ showFilter: !this.state.showFilter })}>
								<IonIcon icon={this.state.showFilter ? filterCircle : filterCircleOutline} slot="start" />
								Фильтр
							</IonButton>
							<IonButton routerLink="/publisher/apps/create">Создать прилу</IonButton>
						</IonButtons>
					</IonToolbar>
				</IonHeader>
				<IonContent>
					<div
						style={{
							position: 'absolute',
							width: '100%',
							height: '100%',
							zIndex: this.state.showFilter ? 101 : undefined,
						}}>
						<AnimateHeight id="example-panel" duration={200} height={this.state.showFilter ? 'auto' : 0}>
							<IonItem>
								<IonLabel>Стор</IonLabel>
								<IonSelect
									value={this.state.store_id}
									placeholder="Выберите"
									onIonChange={(e) => {
										this.setState({
											store_id: e.detail.value,
										});
									}}>
									<IonSelectOption value={null}>Не выбран</IonSelectOption>
									{this.state.stores.map((e: any) => {
										return (
											<IonSelectOption key={'1_exp_a_id_' + e.id} value={e.id}>
												{e.title}
											</IonSelectOption>
										);
									})}
								</IonSelect>
							</IonItem>
							<IonItem>
								<IonLabel>Бренд</IonLabel>
								<IonSelect
									value={this.state.brand_id}
									placeholder="Выберите"
									onIonChange={(e) => {
										this.setState({
											brand_id: e.detail.value,
										});
									}}>
									<IonSelectOption value={null}>Не выбран</IonSelectOption>
									{this.state.brands.map((e: any) => {
										return (
											<IonSelectOption key={'2_exp_a_id_' + e.id} value={e.id}>
												{e.title}
											</IonSelectOption>
										);
									})}
								</IonSelect>
							</IonItem>
							<IonItem>
								<IonLabel>Статус клоаки</IonLabel>
								<IonSelect
									value={this.state.is_enabled}
									placeholder="Выберите"
									onIonChange={(e) => {
										this.setState({
											is_enabled: e.detail.value,
										});
									}}>
									<IonSelectOption value={null}>Не выбран</IonSelectOption>
									<IonSelectOption value={true}>Включен</IonSelectOption>
									<IonSelectOption value={false}>Выключен</IonSelectOption>
								</IonSelect>
							</IonItem>
							<IonItem>
								<IonLabel>Статус публикации</IonLabel>
								<IonSelect
									value={this.state.is_in_store}
									placeholder="Выберите"
									onIonChange={(e) => {
										this.setState({
											is_in_store: e.detail.value,
										});
									}}>
									<IonSelectOption value={null}>Не выбран</IonSelectOption>
									<IonSelectOption value={true}>Опубликован</IonSelectOption>
									<IonSelectOption value={false}>Не опубликован</IonSelectOption>
								</IonSelect>
							</IonItem>

							<IonItem>
								<IonButtons slot="end">
									<IonButton
										color="success"
										onClick={() => {
											this.setState({
												apps: null,
											});

											this.getPublisherApps();

											this.setState({
												showFilter: false,
											});
										}}>
										Применить
									</IonButton>
								</IonButtons>
							</IonItem>
						</AnimateHeight>
					</div>

					{this.state.showFilter ? (
						<div
							style={{
								position: 'absolute',
								width: '100%',
								height: '100%',
								backgroundColor: 'rgba(0, 0, 0, 0.5)',
								zIndex: 100,
							}}
						/>
					) : (
						false
					)}

					{this.state.apps == null ? (
						<LoadingFullScreen />
					) : (
						<div className="ion-padding">
							{this.state.apps.length == 0 ? (
								<p>Вы не запускали прилы</p>
							) : (
								<IonList>
									{this.state.apps.map((app: any) => {
										return <PublisherAppComponent app={app} key={'publisher_app_' + app.id} />;
									})}
								</IonList>
							)}
						</div>
					)}
				</IonContent>
			</IonPage>
		);
	}
}
