import { IonApp, IonBackButton, IonButton, IonButtons, IonContent, IonHeader, IonInput, IonItem, IonItemDivider, IonLabel, IonRadio, IonRadioGroup, IonTitle, IonToolbar } from '@ionic/react';
import React from 'react';

import API from '../API';
import UserService from '../UserService';

export const AppTrafUsersRoles = [
	{
		roleId: 'developer',
		description: 'Разработчик',
	},
	{
		roleId: 'publisher',
		description: 'Публикатор',
	},
	{
		roleId: 'farmer',
		description: 'Фармер',
	},
	{
		roleId: 'pusher',
		description: 'Пушер',
	},
	{
		roleId: 'android-developer',
		description: 'Android разработчик',
	},
	{
		roleId: 'none',
		description: 'Без позиции',
	},
];

export function getAppTrafRoleById(id: string) {
	const expandedRoles = [
		...AppTrafUsersRoles,
		{
			roleId: 'admin',
			description: 'Администратор',
		},
	];
	return expandedRoles.filter((r) => r.roleId == id)[0];
}

export default class extends React.Component {
	login = '';
	password = '';
	name = '';
	role = '';

	render() {
		return (
			<IonApp>
				<IonHeader>
					<IonToolbar>
						<IonButtons slot="start">
							<IonBackButton defaultHref="/auth"></IonBackButton>
						</IonButtons>
						<IonTitle>Регистрация</IonTitle>
					</IonToolbar>
				</IonHeader>
				<IonContent
					style={{
						textAlign: 'center',
					}}>
					<div
						style={{
							bottom: 30,
							left: 30,
							right: 30,
							textAlign: 'center',
						}}>
						<IonItem>
							<IonLabel position="floating">Придумайте логин</IonLabel>
							<IonInput onIonChange={(e) => (this.login = e.detail.value!)} placeholder="Логин" type="text"></IonInput>
						</IonItem>
						<IonItem>
							<IonLabel position="floating">Пароль</IonLabel>
							<IonInput onIonChange={(e) => (this.password = e.detail.value!)} placeholder="Пароль" type="password"></IonInput>
						</IonItem>
						<IonItem>
							<IonLabel position="floating">Ваше имя</IonLabel>
							<IonInput onIonChange={(e) => (this.name = e.detail.value!)} placeholder="Фамилия и Имя" type="text"></IonInput>
						</IonItem>

						<IonButton
							onClick={async () => {
								this.role = 'none'
								const res = await API.register(this.login, this.password, this.role, this.name);
								if (res.success) {
									UserService.setUser(res.data);
									UserService.setAccessToken(res.data.session.token);
									document.location.href = '/';
								}
							}}>
							Регистрация
						</IonButton>
					</div>
				</IonContent>
			</IonApp>
		);
	}
}
