import axios from 'axios';
import { toast, Id } from 'react-toastify';

import { serverAddress } from './Constants';
import UserService from './UserService';

class API {
	static headers = { 'Content-type': 'application/json' };

	static errorMiddleware(request: any) {
		if (request.success === false) {
			if (request.code == 1) {
				UserService.dropUserData();
				alert('Неккоректный токен. Нужно переавторизоваться');
				document.location.href = '/auth';
			} else {
				toast.error(JSON.stringify(request.error));
			}
		}
		return request;
	}

	static getBody(data: any = {}) {
		if (UserService.isLogged()) {
			return JSON.stringify({
				accessToken: UserService.getToken(),
				...data,
			});
		} else {
			return JSON.stringify({
				...data,
			});
		}
	}

	static async auth(login: any, password: any) {
		const responce = await fetch(serverAddress + 'auth', {
			method: 'POST',
			body: this.getBody({
				login: login,
				password: password,
			}),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	static async register(login: any, password: any, role: string, name: string) {
		const responce = await fetch(serverAddress + 'register', {
			method: 'POST',
			body: this.getBody({
				login: login,
				password: password,
				role: role,
				name: name,
			}),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	// ADMIN

	static async getProfile() {
		const responce = await fetch(serverAddress + 'profile', {
			method: 'POST',
			body: this.getBody(),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	static async getStatistics(interval: any) {
		const responce = await fetch(serverAddress + 'statistics', {
			method: 'POST',
			body: this.getBody({
				interval: interval,
			}),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	static async getBrandsStatistics() {
		const responce = await fetch(serverAddress + 'brands-statistics', {
			method: 'POST',
			body: this.getBody({}),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	static async getApps(store_id: any, brand_id: any, is_in_store: any, is_enabled: any, published_user_id: any, is_on_updating: any, is_archived?: boolean, sort?: string) {
		const responce = await fetch(serverAddress + 'apps/list', {
			method: 'POST',
			body: this.getBody({
				store_id: store_id,
				brand_id: brand_id,
				is_in_store: is_in_store,
				is_enabled: is_enabled,
				published_user_id: published_user_id,
				is_on_updating: is_on_updating,
				is_archived: is_archived,
				sort: sort,
			}),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	static async getAppInfo(id: any, countryCode: any) {
		const responce = await fetch(serverAddress + 'apps/info', {
			method: 'POST',
			body: this.getBody({
				appId: id,
				countryCode: countryCode,
			}),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	static async updateApp(app: any) {
		const responce = await fetch(serverAddress + 'apps/update', {
			method: 'POST',
			body: this.getBody({
				app: app,
			}),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	static async changeArchiveStatus(appId: any, isArchived: boolean) {
		const responce = await fetch(serverAddress + 'apps/changeArchiveStatus', {
			method: 'POST',
			body: this.getBody({
				appId: appId,
				isArchived: isArchived,
			}),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	static async appRestoreAppAlive(appId: any) {
		const responce = await fetch(serverAddress + 'apps/restoreAppAlive', {
			method: 'POST',
			body: this.getBody({
				appId: appId,
			}),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	static async addAppComment(appId: any, comment: any) {
		const responce = await fetch(serverAddress + 'apps/comment/create', {
			method: 'POST',
			body: this.getBody({
				comment: comment,
				appId: appId,
			}),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	static async setAppCommentCompleted(commentId: any) {
		const responce = await fetch(serverAddress + 'apps/comment/complete', {
			method: 'POST',
			body: this.getBody({
				commentId: commentId,
			}),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	static async changeAppBrand(brandId: any, appId: any) {
		const responce = await fetch(serverAddress + 'apps/changeBrand', {
			method: 'POST',
			body: this.getBody({
				brandId: brandId,
				appId: appId,
			}),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	static async getKeywordsCountries() {
		const responce = await fetch(serverAddress + 'apps/keywords/countries', {
			method: 'POST',
			body: this.getBody({}),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	static async addKeywordsToApp(appId: any, countriesIds: Array<number>, keywords: Array<string>) {
		const responce = await fetch(serverAddress + 'apps/keywords/add', {
			method: 'POST',
			body: this.getBody({
				appId,
				countriesIds,
				keywords,
			}),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	static async getAppKeywords(offerId: any) {
		const responce = await fetch(serverAddress + 'apps/keywords/positions', {
			method: 'POST',
			body: this.getBody({ offerId }),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	static async getAppRecommendedKeywords(appId: any) {
		const responce = await fetch(serverAddress + 'apps/keywords/recommended', {
			method: 'POST',
			body: this.getBody({ appId }),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	static async fetchAppKeywords(appId: any) {
		const responce = await fetch(serverAddress + 'apps/keywords/fetch', {
			method: 'POST',
			body: this.getBody({ appId }),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	static async getUsers(offset: number, page: number, filter: any = {}) {
		const responce = await fetch(serverAddress + 'users/list', {
			method: 'POST',
			body: this.getBody({
				offset: offset,
				page: page,
				filter: filter,
			}),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	static async getUserInfo(id: any) {
		const responce = await fetch(serverAddress + 'users/info', {
			method: 'POST',
			body: this.getBody({
				userId: id,
			}),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	static async uploadFile(base64: string) {
		const responce = await fetch(serverAddress + 'service/imageUpload', {
			method: 'POST',
			body: this.getBody({
				accessToken: UserService.getToken(),
				userId: UserService.getUserId(),
				image: base64,
			}),
			headers: this.headers,
		});
		return this.errorMiddleware(await responce.json());
	}

	static async uploadLinks(links: string[]) {
		const responce = await fetch(serverAddress + 'publisher/add-links', {
			method: 'POST',
			body: this.getBody({
				links: links,
			}),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	static async analyzeApps(keywords: string[], countries: any[]) {
		const responce = await fetch(serverAddress + 'analyzer/getApps', {
			method: 'POST',
			body: this.getBody({
				keywords: keywords,
				countries: countries,
			}),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	static async analyzeKeywordsScores(keywords: string[], countries: any[]) {
		const responce = await fetch(serverAddress + 'analyzer/keywordScores', {
			method: 'POST',
			body: this.getBody({
				keywords: keywords,
				countries: countries,
			}),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	static async getFinanceOutcomes(from: string, to: string, expenditure_id: any, user_id: any) {
		const responce = await fetch(serverAddress + 'admin/finance/accounting', {
			method: 'POST',
			body: this.getBody({
				from,
				to,
				expenditure_id,
				user_id,
			}),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	static async getDetailedOutcomeTransactions(from: string, to: string, expenditure_id: any, user_id: any) {
		const responce = await fetch(serverAddress + 'admin/finance/accounting/detailedTransactions', {
			method: 'POST',
			body: this.getBody({
				user_id,
				from,
				to,
				expenditure_id,
			}),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	static async addIncomeTransaction(bankId: any, summ: number, comment: string) {
		const responce = await fetch(serverAddress + 'admin/finance/accounting/addIncome', {
			method: 'POST',
			body: this.getBody({
				bankId,
				summ,
				comment,
			}),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	static async getParseFinanceBySubids(subids: Array<string>, dateFrom: string, dateTo: string) {
		const responce = await fetch(serverAddress + 'admin/finance/parse', {
			method: 'POST',
			body: this.getBody({ subids, dateFrom, dateTo }),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	static async linkOfferToAppGallery(offerId: any) {
		const responce = await fetch(serverAddress + 'appgallery/link', {
			method: 'POST',
			body: this.getBody({
				offerId: offerId,
			}),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	static async getAppGalleryApps() {
		const responce = await fetch(serverAddress + 'appgallery/apps', {
			method: 'POST',
			body: this.getBody({}),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	static async getAppGalleryKeywordPosition(offerId: any) {
		const responce = await fetch(serverAddress + 'appgallery/keywords', {
			method: 'POST',
			body: this.getBody({
				offerId: offerId,
			}),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	static async addAppGalleryKeywords(offerId: any, keywords: any, geo: string) {
		const responce = await fetch(serverAddress + 'appgallery/add', {
			method: 'POST',
			body: this.getBody({
				offerId: offerId,
				keywords: keywords,
				geo: geo,
			}),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	static async getAppGalleryUpdatePositions(offerId: any) {
		const responce = await fetch(serverAddress + 'appgallery/updatePositions', {
			method: 'POST',
			body: this.getBody({
				offerId: offerId,
			}),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	static async getEmployees() {
		const responce = await fetch(serverAddress + 'admin/employees/list', {
			method: 'POST',
			body: this.getBody({}),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	static async approveEmployees(id: any) {
		const responce = await fetch(serverAddress + 'admin/employees/approve', {
			method: 'POST',
			body: this.getBody({
				user_id: id,
			}),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	static async declineEmployees(id: any) {
		const responce = await fetch(serverAddress + 'admin/employees/decline', {
			method: 'POST',
			body: this.getBody({
				user_id: id,
			}),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	static async banEmployees(id: any) {
		const responce = await fetch(serverAddress + 'admin/employees/ban', {
			method: 'POST',
			body: this.getBody({
				user_id: id,
			}),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	static async unbanEmployees(id: any) {
		const responce = await fetch(serverAddress + 'admin/employees/unban', {
			method: 'POST',
			body: this.getBody({
				user_id: id,
			}),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	static async getEmployer(id: any) {
		const responce = await fetch(serverAddress + 'admin/employees/get', {
			method: 'POST',
			body: this.getBody({
				id: id,
			}),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	static async getEmployeesKpi() {
		const responce = await fetch(serverAddress + 'admin/employees/kpi', {
			method: 'POST',
			body: this.getBody({}),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	static async getFarmersKpi(from: Date, to: Date) {
		const responce = await fetch(serverAddress + 'admin/farmers/kpi', {
			method: 'POST',
			body: this.getBody({
				from: from,
				to: to,
			}),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	static async getWeeklyStats() {
		const responce = await fetch(serverAddress + 'admin/weeklyStats', {
			method: 'POST',
			body: this.getBody({}),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	static async editEmployerAccountingExpenditures(userId: any, action: 'add' | 'delete', aeId: any) {
		const responce = await fetch(serverAddress + 'admin/employees/edit', {
			method: 'POST',
			body: this.getBody({
				id: userId,
				action: action,
				aeId: aeId,
			}),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	static async editEmployeBankAccess(userId: any, action: 'add' | 'delete', bankId: any) {
		const responce = await fetch(serverAddress + 'admin/employees/bankAccessEdit', {
			method: 'POST',
			body: this.getBody({
				id: userId,
				action: action,
				bankId: bankId,
			}),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	static async getWorkTimeTable(month: string) {
		const responce = await fetch(serverAddress + 'admin/employees/table', {
			method: 'POST',
			body: this.getBody({
				month,
			}),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	static async getWorkTimeTableIosApps(month: string) {
		const responce = await fetch(serverAddress + 'admin/employees/tableIosApps', {
			method: 'POST',
			body: this.getBody({
				month,
			}),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	static async updateWorkTime(wd: any) {
		const responce = await fetch(serverAddress + 'admin/worktime/update', {
			method: 'POST',
			body: this.getBody(wd),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	static async worktimeAddHolidays(date: string) {
		const responce = await fetch(serverAddress + 'admin/worktime/addHolidays', {
			method: 'POST',
			body: this.getBody({
				date: date.split('T')[0],
			}),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	// templates upload
	static async AG_uploadTemplate(template: any, title: string, percentCompleted: (percent: number) => void, didCompleted: (res: any) => void) {
		const formData = new FormData();
		formData.append('template', template);
		formData.append('accessToken', UserService.getToken());
		formData.append('title', title);

		axios
			.request({
				method: 'POST',
				url: serverAddress + 'admin/appGenerator/templates/upload',
				data: formData,

				onUploadProgress: (p) => {
					percentCompleted((1 / p.total) * p.loaded);
				},
			})
			.then((data) => {
				didCompleted(data.data);
			});
	}

	static async AG_getTemplates() {
		const responce = await fetch(serverAddress + 'admin/appGenerator/templates/list', {
			method: 'POST',
			body: this.getBody(),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	// MEDIA BUYER

	static async getTraffic() {
		const responce = await fetch(serverAddress + 'admin/traffic', {
			method: 'POST',
			body: this.getBody({}),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	static async getMBCampaigns() {
		const responce = await fetch(serverAddress + 'mb/compaigns/list', {
			method: 'POST',
			body: this.getBody(),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	static async MBGetApps() {
		const responce = await fetch(serverAddress + 'mb/apps/list', {
			method: 'POST',
			body: this.getBody(),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	static async adminCreateTransaction(user_id: any, amount: number, comment: string, in_hold: boolean) {
		const responce = await fetch(serverAddress + 'admin/transactions/add', {
			method: 'POST',
			body: this.getBody({
				transaction: {
					user_id,
					amount,
					comment,
					in_hold,
				},
			}),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	static async adminGetAllTransactions(offset: number, page: number, filter: any = {}) {
		const responce = await fetch(serverAddress + 'admin/transactions/list', {
			method: 'POST',
			body: this.getBody({
				offset: offset,
				page: page,
				filter: filter,
			}),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	static async adminGetTransactionsByApp(appId: number) {
		const responce = await fetch(serverAddress + 'admin/transactions/byapp', {
			method: 'POST',
			body: this.getBody({
				appId: appId,
			}),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	static async adminPayKpiByApp(appId: number, transactions: Array<any>) {
		const responce = await fetch(serverAddress + 'admin/transactions/paykpi', {
			method: 'POST',
			body: this.getBody({
				appId: appId,
				transactions: transactions,
			}),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	static async adminWithdrawBalance(userId: number, sum: number) {
		const responce = await fetch(serverAddress + 'admin/transactions/withdraw', {
			method: 'POST',
			body: this.getBody({
				userId: userId,
				sum: sum,
			}),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	// DEVELOPER

	static async adminGetAppsKpi() {
		const responce = await fetch(serverAddress + 'admin/transactions/appskpi', {
			method: 'POST',
			body: this.getBody({}),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	static async uploadXcode(xcode: any, name: string, percentCompleted: (percent: number) => void, didCompleted: (res: any) => void) {
		const formData = new FormData();
		formData.append('xcode', xcode);
		formData.append('accessToken', UserService.getToken());
		formData.append('projectName', name);

		axios
			.request({
				method: 'POST',
				url: serverAddress + 'developer/fileUpload',
				data: formData,

				onUploadProgress: (p) => {
					percentCompleted((1 / p.total) * p.loaded);
				},
			})
			.then((data) => {
				didCompleted(data.data);
			});
	}

	static async developerDeployServer() {
		const responce = await fetch(serverAddress + 'developer/servers/deploy', {
			method: 'POST',
			body: this.getBody({}),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	static async developerGetServersList() {
		const responce = await fetch(serverAddress + 'developer/servers/list', {
			method: 'POST',
			body: this.getBody({}),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	static async developerDeleteServer(id: any) {
		const responce = await fetch(serverAddress + 'developer/servers/delete', {
			method: 'POST',
			body: this.getBody({
				id: id,
			}),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	static async developerHideData(data: any) {
		const responce = await fetch(serverAddress + 'developer/hideData', {
			method: 'POST',
			body: this.getBody({
				data: data,
			}),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	static async developerAndroidCreateProject(companyName: string, projectName: string) {
		const responce = await fetch(serverAddress + 'developer/android/createProject', {
			method: 'POST',
			body: this.getBody({
				companyName: companyName,
				projectName: projectName,
			}),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	static async developerAndroidGetProjects(white: boolean) {
		const responce = await fetch(serverAddress + 'developer/android/projects', {
			method: 'POST',
			body: this.getBody({ white }),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	static async developerGetApps(page: number, offset: number) {
		const responce = await fetch(serverAddress + 'developer/myapps', {
			method: 'POST',
			body: this.getBody({ page, offset }),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	static async developerUpdateApp(id: number, success: string, error: string, successHeader: string, errorHeader: string) {
		const responce = await fetch(serverAddress + 'developer/updateapp', {
			method: 'POST',
			body: this.getBody({ id, success, error, successHeader, errorHeader }),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	static async developerUpdateProxyForApp(id: number, endpoint: string, type: string, check_payload: string) {
		const responce = await fetch(serverAddress + 'developer/updateproxy', {
			method: 'POST',
			body: this.getBody({ id, endpoint, type, check_payload }),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	static async developerAndroidGetFullProject(id: number) {
		const responce = await fetch(serverAddress + 'developer/android/get', {
			method: 'POST',
			body: this.getBody({
				id: id,
			}),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}
	static async developerAndroidToggleCloseOpenStatus(id: any) {
		const responce = await fetch(serverAddress + 'developer/android/toggleCloseStatus', {
			method: 'POST',
			body: this.getBody({
				id: id,
			}),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	static async developerAndroidReviewBuild(id: any, review: string) {
		const responce = await fetch(serverAddress + 'developer/android/review', {
			method: 'POST',
			body: this.getBody({
				id: id,
				review: review,
			}),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	static async developerAndoirdReviewComplete(id: any) {
		const responce = await fetch(serverAddress + 'developer/android/review-complete', {
			method: 'POST',
			body: this.getBody({
				id: id,
			}),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	static async uploadAndroidProject(project: any, id: any, percentCompleted: (percent: number) => void, didCompleted: (res: any) => void) {
		const formData = new FormData();
		formData.append('project', project);
		formData.append('accessToken', UserService.getToken());
		formData.append('id', id);

		axios
			.request({
				method: 'POST',
				url: serverAddress + 'developer/android/upload',
				data: formData,

				onUploadProgress: (p) => {
					percentCompleted((1 / p.total) * p.loaded);
				},
			})
			.then((data) => {
				didCompleted(data.data);
			});
	}

	static async uploadScreenshots(project: any, title: string, subtitle: string, texts: string, useBg: boolean, mode: number, percentCompleted: (percent: number) => void, didCompleted: (res: any) => void) {
		const formData = new FormData();
		formData.append('project', project);
		formData.append('accessToken', UserService.getToken());
		formData.append('title', title);
		formData.append('subtitle', subtitle);
		formData.append('texts', texts);
		formData.append('useBg', useBg ? 'true' : 'false');
		formData.append('mode', mode.toString());

		axios
			.request({
				method: 'POST',
				url: serverAddress + 'developer/design/generator',
				data: formData,

				onUploadProgress: (p) => {
					percentCompleted((1 / p.total) * p.loaded);
				},
			})
			.then((data) => {
				didCompleted(data.data);
			});
	}

	static async getDeveloperMaskedIps() {
		const responce = await fetch(serverAddress + 'developer/maskedip/list', {
			method: 'POST',
			body: this.getBody({}),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	static async getDeveloperMaskedIpsAdd(ip: string, country: string) {
		const responce = await fetch(serverAddress + 'developer/maskedip/add', {
			method: 'POST',
			body: this.getBody({
				ip: ip,
				country: country,
			}),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	static async getDeveloperMaskedIpsRemove(id: string) {
		const responce = await fetch(serverAddress + 'developer/maskedip/remove', {
			method: 'POST',
			body: this.getBody({
				id: id,
			}),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	// Android Developer

	static async closeTestedApplication() {
		const responce = await fetch(serverAddress + 'developer/android/close-tested', {
			method: 'POST',
			body: this.getBody(),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	static async whiteAndroidDeveloperAndroidCreateProject(companyName: string, projectName: string) {
		const responce = await fetch(serverAddress + 'android-developer/android/createProject', {
			method: 'POST',
			body: this.getBody({
				companyName: companyName,
				projectName: projectName,
			}),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	static async whiteAndroidDeveloperAndroidGetProjects() {
		const responce = await fetch(serverAddress + 'android-developer/android/projects', {
			method: 'POST',
			body: this.getBody({}),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	static async whiteAndroidDeveloperAndroidGetFullProject(id: any) {
		const responce = await fetch(serverAddress + 'android-developer/android/get', {
			method: 'POST',
			body: this.getBody({
				id: id,
			}),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	static async whiteAndroidUploadAndroidProject(project: any, id: any, percentCompleted: (percent: number) => void, didCompleted: (res: any) => void) {
		const formData = new FormData();
		formData.append('project', project);
		formData.append('accessToken', UserService.getToken());
		formData.append('id', id);

		axios
			.request({
				method: 'POST',
				url: serverAddress + 'android-developer/android/upload',
				data: formData,

				onUploadProgress: (p) => {
					percentCompleted((1 / p.total) * p.loaded);
				},
			})
			.then((data) => {
				didCompleted(data.data);
			});
	}

	static async developerAndroidTrashCodegenerator(key: number, proxy: string, classesCount: number, methodsCount: number) {
		const responce = await fetch(serverAddress + 'developer/androidtrashcode', {
			method: 'POST',
			body: this.getBody({
				key: key,
				proxy: proxy,
				classesCount: classesCount,
				methodsCount: methodsCount,
			}),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	// PUBLISHER

	static async getPublisherApps(store_id: any, brand_id: any, is_in_store: any, is_enabled: any) {
		const responce = await fetch(serverAddress + 'publisher/apps', {
			method: 'POST',
			body: this.getBody({
				store_id: store_id,
				brand_id: brand_id,
				is_in_store: is_in_store,
				is_enabled: is_enabled,
			}),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	static async getPublisherAppInfo(appId: string) {
		const responce = await fetch(serverAddress + 'publisher/app-info', {
			method: 'POST',
			body: this.getBody({
				id: appId,
			}),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	static async publishedSendPush(appId: string) {
		const responce = await fetch(serverAddress + 'publisher/test-push', {
			method: 'POST',
			body: this.getBody({
				id: appId,
			}),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	static async PBCreateApp(
		title: string,
		brandId: string,
		p12: string,
		bundle_id: string,
		gcm_key: string,
		gcm_sender_id: string,
		os: string,
		storeId: string,
		developer_id: any,
		designer_id: any,
		integrator_id: any,
		proxy_server_id: any,
		farmed_account_id: any,
		is_rent_app: boolean,
	) {
		const responce = await fetch(serverAddress + 'publisher/create-app', {
			method: 'POST',
			body: this.getBody({
				title: title,
				brandId: brandId,
				p12: p12,
				bundle_id: bundle_id,
				gcm_key: gcm_key,
				gcm_sender_id: gcm_sender_id,
				os: os,
				storeId: storeId,
				developer_id: developer_id,
				designer_id: designer_id,
				integrator_id: integrator_id,
				proxy_server_id: proxy_server_id,
				farmed_account_id: farmed_account_id,
				is_rent_app: is_rent_app,
			}),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	static async PBChangeAppName(appId: any, appName: string) {
		const responce = await fetch(serverAddress + 'publisher/change-app-name', {
			method: 'POST',
			body: this.getBody({
				id: appId,
				appName: appName,
			}),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	static async PBGetReferences() {
		const responce = await fetch(serverAddress + 'publisher/dependencyReferences', {
			method: 'POST',
			body: this.getBody({}),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	static async PBGetStatus() {
		const responce = await fetch(serverAddress + 'publisher/status', {
			method: 'POST',
			body: this.getBody({}),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	static async PBChangeCloacaStatus(appId: any, isEnabled: boolean, reason: string) {
		const responce = await fetch(serverAddress + 'publisher/cloaca-status', {
			method: 'POST',
			body: this.getBody({
				id: appId,
				isEnabled: isEnabled,
				reason: reason,
			}),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	static async PBChangeUpdateOnUpdateStatus(appId: any, isOnUpdate: boolean) {
		const responce = await fetch(serverAddress + 'publisher/is-in-update-status', {
			method: 'POST',
			body: this.getBody({
				id: appId,
				isOnUpdate: isOnUpdate,
			}),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	static async PBChangeIsRemoteFarmer(appId: any, is_remote_farmer: boolean) {
		const responce = await fetch(serverAddress + 'publisher/apps/is_remote_farmer', {
			method: 'POST',
			body: this.getBody({
				id: appId,
				is_remote_farmer: is_remote_farmer,
			}),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	static async publisherConnectOneSignalToApp(appId: any, gcm_key: any, gcm_sender_id: any) {
		const responce = await fetch(serverAddress + 'publisher/apps/connectPushOneSignal', {
			method: 'POST',
			body: this.getBody({
				offerId: appId,
				gcm_key: gcm_key,
				gcm_sender_id: gcm_sender_id,
			}),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	static async PBChangeAppCountries(appId: any, countries: Array<number>) {
		const responce = await fetch(serverAddress + 'publisher/change-country', {
			method: 'POST',
			body: this.getBody({
				id: appId,
				allowed_country_ids: countries,
			}),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	static async publisherGetServers() {
		const responce = await fetch(serverAddress + 'publisher/servers/list', {
			method: 'POST',
			body: this.getBody(),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	static async publisherDeleteServer(id: any) {
		const responce = await fetch(serverAddress + 'publisher/servers/delete', {
			method: 'POST',
			body: this.getBody({
				id: id,
			}),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	static async publisherGetServer(id: any) {
		const responce = await fetch(serverAddress + 'publisher/servers/info', {
			method: 'POST',
			body: this.getBody({
				id: id,
			}),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	static async publisherStartStopServer(serverId: any, action: 'start' | 'stop') {
		const responce = await fetch(serverAddress + 'publisher/servers/startStop', {
			method: 'POST',
			body: this.getBody({
				id: serverId,
				action: action,
			}),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	static async getPublisherAppsInReview() {
		const responce = await fetch(serverAddress + 'publisher/reviewapps/list', {
			method: 'POST',
			body: this.getBody({}),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	static async getPublisherAddAppInReview(bundleId: string, store: string, appleId?: string) {
		const responce = await fetch(serverAddress + 'publisher/reviewapps/add', {
			method: 'POST',
			body: this.getBody({
				bundleId: bundleId,
				store: store,
				appleId: appleId,
			}),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	static async publisherDeleteAppInReview(appId: any) {
		const responce = await fetch(serverAddress + 'publisher/reviewapps/delete', {
			method: 'POST',
			body: this.getBody({
				id: appId,
			}),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	static async publisherGetVPNServers() {
		const responce = await fetch(serverAddress + 'publisher/vpn/list', {
			method: 'POST',
			body: this.getBody(),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	static async publisherDeleteVPNServer(id: any) {
		const responce = await fetch(serverAddress + 'publisher/vpn/delete', {
			method: 'POST',
			body: this.getBody({
				id: id,
			}),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	static async publisherCreateVPNServer(country: any) {
		const responce = await fetch(serverAddress + 'publisher/vpn/create', {
			method: 'POST',
			body: this.getBody({
				country: country,
			}),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	static async publisherGetBrands() {
		const responce = await fetch(serverAddress + 'publisher/brands/list', {
			method: 'POST',
			body: this.getBody({}),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	static async publisherGetStores() {
		const responce = await fetch(serverAddress + 'publisher/stores/list', {
			method: 'POST',
			body: this.getBody({}),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	static async publisherBuyProxyServer(count: number) {
		const responce = await fetch(serverAddress + 'publisher/proxy/create', {
			method: 'POST',
			body: this.getBody({ count }),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	static async publisherAddProxyServer(ip: string) {
		const responce = await fetch(serverAddress + 'publisher/proxy/addCustom', {
			method: 'POST',
			body: this.getBody({ ip }),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	static async publisherGetProxyServers() {
		const responce = await fetch(serverAddress + 'publisher/proxy/list', {
			method: 'POST',
			body: this.getBody({}),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	static async publisherDeleteProxyServer(id: any) {
		const responce = await fetch(serverAddress + 'publisher/proxy/delete', {
			method: 'POST',
			body: this.getBody({
				id,
			}),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	static async publisherProxyServerConnectApp(appId: any) {
		const responce = await fetch(serverAddress + 'publisher/proxy/connectApp', {
			method: 'POST',
			body: this.getBody({
				appId,
			}),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	static async publisherProxyServerConnectDomain(proxyId: any, domain: string) {
		const responce = await fetch(serverAddress + 'publisher/proxy/connect-domain', {
			method: 'POST',
			body: this.getBody({
				proxyId,
				domain,
			}),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	static async publisherSetAppCommentCompleted(commentId: any) {
		const responce = await fetch(serverAddress + 'publisher/comment/complete', {
			method: 'POST',
			body: this.getBody({
				commentId: commentId,
			}),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	static async publisherSetAppCommentInProgress(commentId: any) {
		const responce = await fetch(serverAddress + 'publisher/comment/inProgress', {
			method: 'POST',
			body: this.getBody({
				commentId: commentId,
			}),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	static async publisherUpdateAppLinks(app: any) {
		const responce = await fetch(serverAddress + 'publisher/updateAppLinks', {
			method: 'POST',
			body: this.getBody({
				appId: app.id,
				designerId: app.designer_id,
				developerId: app.developer_id,
				farmed_account_id: app.farmed_account_id,
				proxy_server_id: app.proxy_server_id,
				integrator_id: app.integrator_id,
			}),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	static async publisherGetAllAppComments() {
		const responce = await fetch(serverAddress + 'publisher/comment/all', {
			method: 'POST',
			body: this.getBody(),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	static async publisherGetFarmAccounts(store_id: any, is_remote: any) {
		const responce = await fetch(serverAddress + 'publisher/accounts/list', {
			method: 'POST',
			body: this.getBody({
				store_id: store_id,
				is_remote: is_remote,
			}),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	static async publisherGetAndroidBuilds() {
		const responce = await fetch(serverAddress + 'publisher/androidbuilds', {
			method: 'POST',
			body: this.getBody({}),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	// MANAGER

	static async managerUpdateWorkTime(wd: any) {
		const responce = await fetch(serverAddress + 'manager/worktime/update', {
			method: 'POST',
			body: this.getBody(wd),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	static async managerGetWorkTimeTable(month: string) {
		const responce = await fetch(serverAddress + 'manager/employees/table', {
			method: 'POST',
			body: this.getBody({
				month,
			}),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	static async managerGetEmpplouersList() {
		const responce = await fetch(serverAddress + 'manager/employees/list', {
			method: 'POST',
			body: this.getBody({}),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	static async managerEditAdmin(id: number, action: string, salary: number, allowedRemoteMode: boolean) {
		const responce = await fetch(serverAddress + 'manager/employees/edit', {
			method: 'POST',
			body: this.getBody({
				id: id,
				action: action,
				salary: salary,
				allowedRemoteMode: allowedRemoteMode,
			}),
			headers: this.headers,
		});
		return this.errorMiddleware(await responce.json());
	}

	static async managerGetTransactions() {
		const responce = await fetch(serverAddress + 'manager/transactions/list', {
			method: 'POST',
			body: this.getBody(),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	static async managerCompleteWithdraw(userId: any, amount: number) {
		const responce = await fetch(serverAddress + 'manager/transactions/withdraw', {
			method: 'POST',
			body: this.getBody({
				userId: userId,
				amount: amount,
			}),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	static async managerAccountingBanks() {
		const responce = await fetch(serverAddress + 'manager/accounting/banks', {
			method: 'POST',
			body: this.getBody({}),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	static async managerAccountingGetLogList() {
		const responce = await fetch(serverAddress + 'manager/accounting/getList', {
			method: 'POST',
			body: this.getBody({}),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	static async managerAddAccountingRecord(summ: number, bankId: number) {
		const responce = await fetch(serverAddress + 'manager/accounting/add', {
			method: 'POST',
			body: this.getBody({
				summ: summ,
				bankId: bankId,
			}),
			headers: this.headers,
		});
		return this.errorMiddleware(await responce.json());
	}

	// MANAGERIOSAPPS

	static async managerIosAppsGetWorkTimeTable(month: string) {
		const responce = await fetch(serverAddress + 'manageriosapps/employees/table', {
			method: 'POST',
			body: this.getBody({
				month,
			}),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	// FARMER

	static async farmerGetServers() {
		const responce = await fetch(serverAddress + 'farmer/servers/list', {
			method: 'POST',
			body: this.getBody(),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	static async farmerDeployServer(count: number, region: string) {
		const responce = await fetch(serverAddress + 'farmer/servers/deploy', {
			method: 'POST',
			body: this.getBody({
				count: count,
				region: region,
			}),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	static async farmerDeleteServer(id: any) {
		const responce = await fetch(serverAddress + 'farmer/servers/delete', {
			method: 'POST',
			body: this.getBody({
				id: id,
			}),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	static async farmerGetServer(id: any) {
		const responce = await fetch(serverAddress + 'farmer/servers/info', {
			method: 'POST',
			body: this.getBody({
				id: id,
			}),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	static async farmerUpdateServerInfo(data: any) {
		const responce = await fetch(serverAddress + 'farmer/servers/update', {
			method: 'POST',
			body: this.getBody(data),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	static async farmerSendServerToPublisher(serverId: any) {
		const responce = await fetch(serverAddress + 'farmer/servers/sendToPublisher', {
			method: 'POST',
			body: this.getBody({
				id: serverId,
			}),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	static async farmerCreateManualServer(username: string, password: string, ip: string) {
		const responce = await fetch(serverAddress + 'farmer/servers/createManual', {
			method: 'POST',
			body: this.getBody({
				username: username,
				password: password,
				ip: ip,
			}),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	static async farmerStartStopServer(serverId: any, action: 'start' | 'stop') {
		const responce = await fetch(serverAddress + 'farmer/servers/startStop', {
			method: 'POST',
			body: this.getBody({
				id: serverId,
				action: action,
			}),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	static async farmerCreateAccount() {
		const responce = await fetch(serverAddress + 'farmer/accounts/create', {
			method: 'POST',
			body: this.getBody({}),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	static async farmerGetAccountsList() {
		const responce = await fetch(serverAddress + 'farmer/accounts/list', {
			method: 'POST',
			body: this.getBody({}),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	static async farmerAccountInfo(id: any) {
		const responce = await fetch(serverAddress + 'farmer/accounts/info', {
			method: 'POST',
			body: this.getBody({
				id: id,
			}),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	static async farmerSaveAccountData(account: any) {
		const responce = await fetch(serverAddress + 'farmer/accounts/save', {
			method: 'POST',
			body: this.getBody({
				account: account,
			}),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	static async farmerCompleteAccountFarm(id: any) {
		const responce = await fetch(serverAddress + 'farmer/accounts/complete', {
			method: 'POST',
			body: this.getBody({
				id: id,
			}),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	static async farmerCheckAntifrod(decoded_hash: string) {
		const responce = await fetch(serverAddress + 'farmer/antifrod/check', {
			method: 'POST',
			body: this.getBody({
				decoded_hash: decoded_hash,
			}),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	static async farmerAddAntifrodUser(decoded_hash: string) {
		const responce = await fetch(serverAddress + 'farmer/antifrod/addUser', {
			method: 'POST',
			body: this.getBody({
				decoded_hash: decoded_hash,
			}),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	// PUSHER
	static async pusherGetUsersByLink(link: string, status: string) {
		const responce = await fetch(serverAddress + 'pusher/users', {
			method: 'POST',
			body: this.getBody({
				link,
				status,
			}),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	static async pusherSendPush(usersIdx: Array<number>, title: string, body: string) {
		const responce = await fetch(serverAddress + 'pusher/send', {
			method: 'POST',
			body: this.getBody({
				usersIdx: usersIdx,
				title: title,
				body: body,
			}),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	static async pusherFolderCreate() {
		const responce = await fetch(serverAddress + 'pusher/folders/create', {
			method: 'POST',
			body: this.getBody({}),
			headers: this.headers,
		});
		return this.errorMiddleware(await responce.json());
	}

	static async pusherFolderList() {
		const responce = await fetch(serverAddress + 'pusher/folders/list', {
			method: 'POST',
			body: this.getBody({}),
			headers: this.headers,
		});
		return this.errorMiddleware(await responce.json());
	}

	static async pusherFolderRename(folder_id: number, name: string) {
		const responce = await fetch(serverAddress + 'pusher/folders/rename', {
			method: 'POST',
			body: this.getBody({ folder_id, name }),
			headers: this.headers,
		});
		return this.errorMiddleware(await responce.json());
	}

	static async pusherFolderGetLinks(folder_id: number) {
		const responce = await fetch(serverAddress + 'pusher/folders/getLinks', {
			method: 'POST',
			body: this.getBody({ folder_id }),
			headers: this.headers,
		});
		return this.errorMiddleware(await responce.json());
	}

	static async pusherFolderInsertLink(folder_id: number, link: string) {
		const responce = await fetch(serverAddress + 'pusher/folders/insertLink', {
			method: 'POST',
			body: this.getBody({ folder_id, link }),
			headers: this.headers,
		});
		return this.errorMiddleware(await responce.json());
	}

	// PM

	static async PM_getAppInfo(id: any, countryCode: any) {
		const responce = await fetch(serverAddress + 'pm/apps/info', {
			method: 'POST',
			body: this.getBody({
				appId: id,
				countryCode: countryCode,
			}),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	static async pmGetApps(store_id: any, brand_id: any, is_in_store: any, is_enabled: any, published_user_id: any, is_on_updating: any, is_archived?: boolean, sort?: string) {
		const responce = await fetch(serverAddress + 'pm/apps/list', {
			method: 'POST',
			body: this.getBody({
				store_id: store_id,
				brand_id: brand_id,
				is_in_store: is_in_store,
				is_enabled: is_enabled,
				published_user_id: published_user_id,
				is_on_updating: is_on_updating,
				is_archived: is_archived,
				sort: sort,
			}),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	// TESTER

	static async TesterChangeCloacaStatus(appId: any, isEnabled: boolean, reason: string) {
		const responce = await fetch(serverAddress + 'tester/cloaca-status', {
			method: 'POST',
			body: this.getBody({
				id: appId,
				isEnabled: isEnabled,
				reason: reason,
			}),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	static async getTesterApps(store_id: any, brand_id: any, is_in_store: any, is_enabled: any) {
		const responce = await fetch(serverAddress + 'tester/apps', {
			method: 'POST',
			body: this.getBody({
				store_id: store_id,
				brand_id: brand_id,
				is_in_store: is_in_store,
				is_enabled: is_enabled,
			}),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	static async getTesterAppInfo(appId: string) {
		const responce = await fetch(serverAddress + 'tester/app-info', {
			method: 'POST',
			body: this.getBody({
				id: appId,
			}),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	static async getTesterLastBots(ip: string) {
		const responce = await fetch(serverAddress + 'tester/bots', {
			method: 'POST',
			body: this.getBody({
				ip: ip,
			}),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	static async testerAddAppComment(appId: any, comment: any) {
		const responce = await fetch(serverAddress + 'tester/add-comment', {
			method: 'POST',
			body: this.getBody({
				comment: comment,
				id: appId,
			}),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	static async getTesterMaskedIps() {
		const responce = await fetch(serverAddress + 'tester/maskedip/list', {
			method: 'POST',
			body: this.getBody({}),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	static async getTesterMaskedIpsAdd(ip: string, country: string) {
		const responce = await fetch(serverAddress + 'tester/maskedip/add', {
			method: 'POST',
			body: this.getBody({
				ip: ip,
				country: country,
			}),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	static async getTesterMaskedIpsRemove(id: string) {
		const responce = await fetch(serverAddress + 'tester/maskedip/remove', {
			method: 'POST',
			body: this.getBody({
				id: id,
			}),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	static async getTesterAndroidBuilds() {
		const responce = await fetch(serverAddress + 'tester/androidapps/list', {
			method: 'POST',
			body: this.getBody({}),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	static async testerSetAppIsTested(appId: any) {
		const responce = await fetch(serverAddress + 'tester/androidapps/setTested', {
			method: 'POST',
			body: this.getBody({
				appId: appId,
			}),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	static async testerSetAppIsNeedFixing(appId: any) {
		const responce = await fetch(serverAddress + 'tester/androidapps/setIsNeedFixing', {
			method: 'POST',
			body: this.getBody({
				appId: appId,
			}),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	// SHARED

	static async sharedWorkTimeGetCurrentTime() {
		const responce = await fetch(serverAddress + 'worktime/currentTime', {
			method: 'POST',
			body: this.getBody({}),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	static async sharedWorkTimeStartWork(workMode: any, hospitalProof: string | null) {
		const responce = await fetch(serverAddress + 'worktime/start', {
			method: 'POST',
			body: this.getBody({
				workMode: workMode,
				hospitalProof: hospitalProof,
			}),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	static async sharedWorkTimeEndWork(report: string) {
		const responce = await fetch(serverAddress + 'worktime/end', {
			method: 'POST',
			body: this.getBody({
				report: report,
			}),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	static async sharedWorkSendReport(id: number, report: string) {
		const responce = await fetch(serverAddress + 'worktime/sendReport', {
			method: 'POST',
			body: this.getBody({
				id: id,
				report: report,
			}),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	static async sharedWorkTimeGetStatistics() {
		const responce = await fetch(serverAddress + 'worktime/statistics', {
			method: 'POST',
			body: this.getBody({}),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	static async sharedGetTransactions() {
		const responce = await fetch(serverAddress + 'transactions/list', {
			method: 'POST',
			body: this.getBody({}),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}

	static async sharedGetReports(date: string) {
		const responce = await fetch(serverAddress + 'd-report/get', {
			method: 'POST',
			body: this.getBody({ date }),
			headers: this.headers,
		});

		return this.errorMiddleware(await responce.json());
	}
}
export default API;
