import { IonPage, IonHeader, IonToolbar, IonButtons, IonMenuButton, IonTitle, IonContent, IonList, IonButton, IonItem, IonLabel, IonIcon, IonAlert, IonPopover, IonInput } from '@ionic/react';
import React from 'react';
import crypto from 'crypto-js';

import API from '../../API';
import LoadingFullScreen from '../../components/LoadingFullScreen';

export default class extends React.Component {
	state: any = {
		encyptedHash: '',
		message: '',
	};

	componentDidMount() {}

	render() {
		return (
			<IonPage>
				<IonHeader>
					<IonToolbar>
						<IonButtons slot="start">
							<IonMenuButton />
						</IonButtons>
						<IonTitle>Антифрод расшифровка</IonTitle>
					</IonToolbar>
				</IonHeader>
				<IonContent>
					<div className="ion-padding">
						<IonItem>
							<IonInput
								placeholder="Хеш пользователя"
								value={this.state.encyptedHash.trim()}
								onIonChange={(e) => {
									let message = '';
									try {
										const bytes = crypto.AES.decrypt(e.detail.value!, 'N%W2vFHw8g1wV3w8^lj3n');

										message = bytes.toString(crypto.enc.Utf8);

										if (message.length == 0) {
											throw 'Ошибка';
										}

										API.farmerCheckAntifrod(message).then(({data})=>{
											this.setState({
												foundSame: data,
											});
										});
									} catch (error) {
										message = 'Ошибка расшифровки';
									}

									this.setState({
										message: message,
										encyptedHash: e.detail.value,
										addAntifrodUserGoodResult: false,
										addAntifrodUserBadResult: false,
									});
								}}
								clearInput></IonInput>
						</IonItem>
						
						{(this.state.message && this.state.message !== 'Ошибка расшифровки')?
						<div>
							<br/>
							{this.state.foundSame?
							(this.state.foundSame.foundSameMachineId
							? <p style={{fontSize: '1.25em', color: 'red'}}> ID машины был замечен ранее </p>
							: <p style={{fontSize: '1.25em', color: 'green'}}> Совпадений по ID машины не найдено </p>): null}

							{this.state.foundSame?
							(this.state.foundSame.foundSameIp
							? <p style={{fontSize: '1.25em', color: 'red'}}> IP был замечен ранее </p>
							: <p style={{fontSize: '1.25em', color: 'green'}}> Совпадений по IP не найдено </p>): null}

							<IonButton
								onClick={ (event)=> {
									event.currentTarget.disabled = true;
									API.farmerAddAntifrodUser(this.state.message).then(({success})=>{
										if(success){
											this.setState({
												addAntifrodUserGoodResult: true,
											});
										}
										else
											this.setState({
												addAntifrodUserBadResult: true,
											});
									}).catch(()=>{
										this.setState({
											addAntifrodUserBadResult: true,
										});
									});
								}
							}> Добавить пользователя в базу</IonButton>
							<br/>
						</div> : null}

						{this.state.addAntifrodUserGoodResult
						? <p style={{fontSize: '1.25em', color: 'green'}}> Пользователь успешно добавлен </p>: null}
						{this.state.addAntifrodUserBadResult
						? <p style={{fontSize: '1.25em', color: 'red'}}> Произошла ошибка, пользователь не добавлен </p>: null}

						{this.state.addAntifrodUserGoodResult
						? <IonButton
							onClick={ ()=> {
							let excelSplit = "	";
							let machine_id = this.state.message.split("ID машины: ")[1].split("\n")[0];
							let isp = this.state.message.split("ISP: ")[1].split("\n")[0];
							let ip = this.state.message.split("IP: ")[1].split("\n")[0];
							let country = this.state.message.split("Страна: ")[1].split("\n")[0];
							navigator.clipboard.writeText(machine_id + excelSplit + isp + excelSplit + ip + excelSplit + country);
							}
						}> Скопировать данные</IonButton> : null}
						{(this.state.addAntifrodUserGoodResult || this.state.message ==='Ошибка расшифровки')
						?(this.state.message.split('\n').map((line: string, index: number) => (
							[
								(index === 4)? <br/> : null,
								<p key={'decypted_text_line_' + index} style={{fontSize: '1.25em'}}>  {line}  </p>
							]
						))) : null}
						
					</div>
				</IonContent>
			</IonPage>
		);
	}
}
