import { IonProgressBar, IonSpinner } from '@ionic/react';
import React from 'react';

import UserService from '../UserService';

export default class extends React.Component {
	state = {
		percent: 0,
	};

	intervalId: any;

	startTime = new Date().getTime();

	componentDidMount() {
		const needTime = UserService.getLoadingTime(window.location.pathname);

		console.log('need_time: ' + needTime);

		const waitTime = (needTime ?? 1) * 1000;
		const started = new Date().getTime();

		this.intervalId = setInterval(() => {
			let current = (started - new Date().getTime()) * -1;
			if (waitTime <= current) {
				clearInterval(this.intervalId);
			}

			if (current > waitTime) current = waitTime;

			this.setState({
				percent: current / waitTime,
			});
		}, 100);
	}

	componentWillUnmount() {
		clearInterval(this.intervalId);
		UserService.setLoadingTime(window.location.pathname, (new Date().getTime() - this.startTime) / 1000);
	}

	render() {
		return (
			<div
				style={{
					paddingTop: '40vh',
					width: '100%',
					height: '100%',
					textAlign: 'center',
				}}>
				<IonSpinner name="dots" />
				<p>Загрузка... {Math.floor(this.state.percent * 100)}%</p>
				<div style={{ width: '100%', textAlign: 'center', display: 'grid', justifyContent: 'center' }}>
					<IonProgressBar style={{ width: 200 }} value={this.state.percent}></IonProgressBar>
				</div>
			</div>
		);
	}
}
