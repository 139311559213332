import {
	IonPage,
	IonHeader,
	IonToolbar,
	IonButtons,
	IonMenuButton,
	IonTitle,
	IonContent,
	IonItem,
	IonLabel,
	IonDatetime,
	IonPopover,
	IonText,
	IonCard,
	IonCardHeader,
	IonCardTitle,
	IonCardContent,
	IonGrid,
	IonCol,
	IonRow,
} from '@ionic/react';
import React from 'react';
import { Area, AreaChart, CartesianGrid, Tooltip, XAxis, YAxis } from 'recharts';

import API from '../../API';
import LoadingFullScreen from '../../components/LoadingFullScreen';

export default class extends React.Component {
	map: any = null;
	state: any = {
		farmers: null,
		stores: [],

		filterDateFrom: new Date('06/12/2022'),
		filterDateTo: new Date('06/16/2022'),
	};

	componentDidMount() {
		this.state.filterDateFrom = new Date();
		this.state.filterDateFrom.setDate(this.state.filterDateFrom.getDate() - 7);
		this.state.filterDateTo = new Date();

		this.getStatistics();
	}

	componentDidCatch() {}

	async getStatistics() {
		this.setState({
			farmers: null,
			allAccounts: [],
		});
		const res = await API.getFarmersKpi(this.state.filterDateFrom, this.state.filterDateTo);
		this.setState({
			farmers: res.data.farmers,
			stores: res.data.stores,
			allAccounts: res.data.allAccounts,
		});
	}

	render() {
		return (
			<IonPage>
				<IonHeader>
					<IonToolbar>
						<IonButtons slot="start">
							<IonMenuButton />
						</IonButtons>
						<IonTitle>KPI Фармеров</IonTitle>
					</IonToolbar>
				</IonHeader>
				<IonContent>
					{this.state.farmers != null ? (
						<>
							<IonItem button id="interval-from">
								<IonLabel>Интервал от</IonLabel>
								<IonText slot="end">{this.state.filterDateFrom ? this.state.filterDateFrom.toLocaleString() : 'Не задано'}</IonText>
								<IonPopover trigger="interval-from" showBackdrop={false}>
									<IonDatetime
										presentation="date"
										onIonChange={(ev) => {
											const date = new Date(ev.detail.value! as string);
											date.setHours(0, 0, 0, 0);
											this.setState({
												filterDateFrom: date,
											});
											this.getStatistics();
										}}
									/>
								</IonPopover>
							</IonItem>
							<IonItem button id="interval-to">
								<IonLabel>Интервал до</IonLabel>
								<IonText slot="end">{this.state.filterDateTo ? this.state.filterDateTo.toLocaleString() : 'Не задано'}</IonText>
								<IonPopover trigger="interval-to" showBackdrop={false}>
									<IonDatetime
										presentation="date"
										onIonChange={(ev) => {
											const date = new Date(ev.detail.value! as string);
											date.setHours(23, 59, 59, 0);
											this.setState({
												filterDateTo: date,
											});
											this.getStatistics();
										}}
									/>
								</IonPopover>
							</IonItem>

							<IonCard>
								<IonCardHeader>
									<IonCardTitle>Общая статистика</IonCardTitle>
								</IonCardHeader>
								<IonCardContent>
									<IonGrid>
										<IonRow>
											<IonCol size="8">
												<AreaChart width={500} height={300} data={this.state.allAccounts}>
													<CartesianGrid strokeDasharray="3 3" />
													<XAxis dataKey="date" />
													<YAxis />
													<Tooltip />

													{this.state.stores.map((s: any) => {
														return <Area key={'farmer_kpi_area_total_store_' + s.id} type="monotone" dataKey={s.title} stroke={s.color} fill={s.color} />;
													})}
												</AreaChart>
											</IonCol>
											<IonCol>
												<h2>Всего:</h2>
												{this.state.stores.map((s: any) => {
													return (
														<p
															key={'farmer_all_kpi_total_store_' + s.id}
															style={{
																color: s.color,
																fontSize: 18,
																fontWeight: 'bold',
															}}>
															{s.title} :{' '}
															{this.state.allAccounts
																.map((stat: any) => {
																	return stat[s.title];
																})
																.reduce((a: number, b: number) => a + b, 0)
																.toString()}
														</p>
													);
												})}
											</IonCol>
										</IonRow>
									</IonGrid>
								</IonCardContent>
							</IonCard>

							{this.state.farmers.map((farmer: any) => {
								return (
									<IonCard key={'farmer_kpi_' + farmer.id}>
										<IonCardHeader>
											<IonCardTitle>{farmer.name}</IonCardTitle>
										</IonCardHeader>
										<IonCardContent>
											<IonGrid>
												<IonRow>
													<IonCol size="8">
														<AreaChart width={500} height={300} data={farmer.accounts}>
															<CartesianGrid strokeDasharray="3 3" />
															<XAxis dataKey="date" />
															<YAxis />
															<Tooltip />

															{this.state.stores.map((s: any) => {
																return <Area key={'farmer_kpi_area_' + farmer.id + '_store_' + s.id} type="monotone" dataKey={s.title} stroke={s.color} fill={s.color} />;
															})}
														</AreaChart>
													</IonCol>
													<IonCol>
														<h2>Всего:</h2>
														{this.state.stores.map((s: any) => {
															return (
																<p
																	key={'farmer_all_kpi_' + farmer.id + '_store_' + s.id}
																	style={{
																		color: s.color,
																		fontSize: 18,
																		fontWeight: 'bold',
																	}}>
																	{s.title} :{' '}
																	{farmer.accounts
																		.map((stat: any) => {
																			return stat[s.title];
																		})
																		.reduce((a: number, b: number) => a + b, 0)
																		.toString()}
																</p>
															);
														})}
													</IonCol>
												</IonRow>
											</IonGrid>
										</IonCardContent>
									</IonCard>
								);
							})}

							{/* <ResponsiveContainer width="100%" height="90%">
                <BarChart
                  width={500}
                  height={300}
                  data={this.state.chart}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip
                    content={({ active, payload, label }) => {
                      if (active && payload && payload.length) {
                        return (
                          <div style={{ background: "white", padding: 8 }}>
                            <p>{`${label} : ${payload[0].value}`}</p>
                          </div>
                        );
                      }
                      return "";
                    }}
                  />

                  <Legend />
                  <Bar dataKey="apps" barSize={20} fill="#8884d8" />
                </BarChart>
              </ResponsiveContainer> */}
						</>
					) : (
						<LoadingFullScreen />
					)}
				</IonContent>
			</IonPage>
		);
	}
}
