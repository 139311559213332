import { IonButtons, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonBackButton, IonList, IonItem, IonLabel, IonInput, IonButton, IonModal, IonIcon } from '@ionic/react';
import React from 'react';
import { RouteComponentProps } from 'react-router';
import { logoAppleAppstore, person, calendar, extensionPuzzle, build, barChart, chevronDown, chevronUp, link, chatbubble, personRemove, globe, pushOutline } from 'ionicons/icons';
import { Area, AreaChart, Brush, CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import Flag from 'react-world-flags';
import AnimateHeight from 'react-animate-height';

import API from '../../../API';
import LoadingFullScreen from '../../../components/LoadingFullScreen';
import TypeSelectorComponent from '../../../components/TypeSelectorComponent';
import { countries } from '../../../Constants';
import BrandLogo from '../../../components/BrandLogo';
import BlockHeaderComponent from '../../../components/BlockHeaderComponent';
import AppEmployerItem from '../../../components/App/AppEmployerItem';
import AppDateItem from '../../../components/App/AppDateItem';
import AppYesOrNo from '../../../components/App/AppYesOrNo';
import AppInfoItem from '../../../components/App/AppInfoItem';
import AppEnableDisableComponent from '../../../components/App/AppEnableDisableComponent';

interface Props
	extends RouteComponentProps<{
		id: string;
	}> {}

export default class extends React.Component<Props> {
	state: any = {
		app: null,
		countries: [],
		selectedCountries: [],
		selectCountiesOpened: false,
		linkStartsWithoutSSL: false,
		selectedCountryForChart: null,
		newComment: '',
	};

	componentDidMount() {
		this.getAppInfo();
	}

	getCountryById(id: any) {
		let country = null;

		for (let index = 0; index < this.state.countries.length; index++) {
			const c = this.state.countries[index];

			if (c.id == id) {
				country = c;
				break;
			}
		}

		return country;
	}

	getCountryByCode(code: any) {
		let country = null;

		for (let index = 0; index < this.state.countries.length; index++) {
			const c = this.state.countries[index];

			if (c.code == code) {
				country = c;
				break;
			}
		}

		return country;
	}

	getAppInfo() {
		API.getAppInfo(this.props.match.params.id, this.state.selectedCountryForChart).then((res) => {
			const selectedCountries: Array<any> = [];
			this.state.countries = [];
			for (const [key, value] of Object.entries(countries)) {
				const country: any = value;
				this.state.countries.push(value);
				if ((res.data.allowed_country_ids as Array<number>).includes(country.id)) {
					selectedCountries.push(value);
				}
			}

			if (res.data.smartlink) {
				res.data.smartlink = Object.entries(JSON.parse(res.data.smartlink)).map(([key, value]) => {
					return {
						code: key,
						value: value,
					};
				});
			}

			if (res.data.account) {
				res.data.account.tags = JSON.parse(res.data.account?.tags || '[]');
			}
			this.setState({
				app: res.data,
				countries: this.state.countries,
				selectedCountries: selectedCountries,
				linkStartsWithoutSSL: res.data.link.startsWith('http://'),
			});
		});
	}

	async updateAppData() {
		const app = this.state.app;
		const appCopied = {
			...app,
		};

		try {
			new URL(this.state.app.link);
		} catch (e) {
			alert('link should start from http:// or http://');
			return;
		}

		if (appCopied.smartlink != null) {
			appCopied.smartlink = {};
			for (let index = 0; index < app.smartlink.length; index++) {
				const smart = app.smartlink[index];
				appCopied.smartlink[smart.code.toUpperCase()] = smart.value;
			}
		}
		console.log(appCopied);
		const res = await API.updateApp(appCopied);
		if (res.success) {
			alert('Данные обновлены');
			this.getAppInfo();
		}
	}

	render() {
		const app = this.state.app;

		return (
			<IonPage key={'app_page' + this.props.match.params.id}>
				<IonModal
					isOpen={this.state.selectCountiesOpened}
					onDidDismiss={() => {
						this.setState({ selectCountiesOpened: false });
					}}>
					<TypeSelectorComponent
						beforeSelected={this.state.selectedCountries}
						items={this.state.countries}
						didSelected={(items: any) => {
							app.allowed_country_ids = items.map((i: any) => i.id);
							this.setState({
								app: app,
								selectedCountries: items,
								selectCountiesOpened: false,
							});
						}}
						didCancelled={() => this.setState({ selectCountiesOpened: false })}
					/>
				</IonModal>

				<IonHeader>
					<IonToolbar>
						<IonButtons slot="start">
							<IonBackButton defaultHref="/apps"></IonBackButton>
						</IonButtons>
						<IonTitle>Информация о приложении {this.props.match.params.id}</IonTitle>
						<IonButtons slot="end">
							<IonButton routerLink={'/keywords/' + this.props.match.params.id}>Ключевые слова</IonButton>
						</IonButtons>
					</IonToolbar>
				</IonHeader>
				<IonContent>
					{app == null ? (
						<LoadingFullScreen />
					) : (
						<div>
							<div className="ion-padding">
								<div style={{ display: 'flex' }}>
									<div style={{ width: 250 }}>
										{app.is_rent_app ? <h2 style={{ color: 'green' }}>PRILS.RENT</h2> : <BrandLogo id={app.brand_id} style={{ width: 100 }} />}

										<AppEmployerItem role={app.title} name={app.bundle_id} />

										<div style={{ width: '100%', height: 0.5, backgroundColor: '#3e3e3e' }}></div>

										<BlockHeaderComponent icon={person} text="Сотрудники:" />
										<div style={{ height: 8 }} />
										<AppEmployerItem role="Разработчик" name={app.developer?.name} />
										<AppEmployerItem role="Дизайнер" name={app.designer?.name} />
										<AppEmployerItem role="Интегратор" name={app.integrator?.name} />
										<AppEmployerItem role="Публикатор" name={app.publisher?.name} />

										<div style={{ width: '100%', height: 0.5, backgroundColor: '#3e3e3e' }}></div>

										<BlockHeaderComponent icon={calendar} text="Дата:" />

										<AppDateItem title="Фарм:" date={app.account?.farmed_at} />
										<AppDateItem title="Отправка:" date={app.created} />
										<AppDateItem title="Выход:" date={app.account?.published_to_store_at} />
										<AppDateItem title="Бан:" date={app.account?.banned_at} />

										<div style={{ width: '100%', height: 0.5, backgroundColor: '#3e3e3e' }}></div>
										<BlockHeaderComponent icon={pushOutline} text="Пуши:" />

										<AppEmployerItem role="One Signal:" name={app.one_signal_app_id || 'Отсутствует'} />
									</div>
									<div style={{ flex: 1 }}>
										<BlockHeaderComponent icon={barChart} text="График:" />
										{app.metrics.length != 0 ? (
											<div style={{ height: 500, width: '100%' }}>
												<ResponsiveContainer height="100%" width="100%">
													<LineChart
														data={app.metrics.map((stat: any) => {
															stat.date_string = new Date(stat.date).toLocaleDateString();
															return stat;
														})}
														margin={{ top: 0, right: 0, bottom: 0, left: 0 }}>
														<CartesianGrid vertical={false} />
														<XAxis dataKey="date_string" label="Дата" />
														<YAxis domain={['auto', 'auto']} />
														<Tooltip
															wrapperStyle={{
																borderColor: 'white',
																boxShadow: '2px 2px 3px 0px rgb(204, 204, 204)',
															}}
															contentStyle={{
																backgroundColor: 'rgba(255, 255, 255, 0.8)',
															}}
															labelStyle={{ fontWeight: 'bold', color: '#666666' }}
														/>
														<Line dataKey="installs" stroke="#ff7300" dot={false} />
														<Line dataKey="clicks" stroke="#ff0f33" dot={false} />
														<Line dataKey="cpaProfit" stroke="#0ffc03" dot={false} />
														<Line dataKey="deposits" stroke="#001beb" dot={false} />
														<Line dataKey="registrations" stroke="#ff7300" dot={false} />
														<Line dataKey="rsProfit" stroke="#34eb55" dot={false} />
														{app.metrics.length > 40 ? (
															<Brush dataKey="date" startIndex={app.metrics.length - 40}>
																<AreaChart>
																	<CartesianGrid />
																	<YAxis hide domain={['auto', 'auto']} />
																	<Area dataKey="installs" stroke="#ff7300" fill="#ff7300" dot={false} />
																</AreaChart>
															</Brush>
														) : null}
													</LineChart>
												</ResponsiveContainer>
											</div>
										) : (
											<p>Нет данных для отображения графика</p>
										)}
									</div>
								</div>
								<div style={{ width: '100%', height: 0.5, backgroundColor: '#3e3e3e' }}></div>

								<div style={{ display: 'flex' }}>
									<div style={{ flex: 1 }}>
										<BlockHeaderComponent icon={extensionPuzzle} text="Аккаунт:" />

										<div style={{ display: 'flex' }}>
											<div style={{ flex: 1 }}>
												<AppInfoItem title="Дата создания:" value={app.account?.created ? new Date(app.account.created).toLocaleString() : 'Отсутствует'} />
												<AppYesOrNo title="Удаленный аккаунт:" value={app.account?.is_remote} />
											</div>

											<div style={{ flex: 1 }}>
												<AppInfoItem title="Платежка:" value={app.account?.payment_name ?? 'NONE'} />
												<AppInfoItem title="Карта:" value={(app.account?.card_type ?? 'NONE') + '*' + (app.account?.card_last_numbers ?? 'NONE')} />
											</div>
										</div>
										<div>
											<div style={{ flex: 1 }}>
												<AppInfoItem title="Теги:" value={app.account?.tags.map((tag: string) => tag).join(' | ')} />
											</div>
										</div>
									</div>
									<div style={{ flex: 1 }}>
										<BlockHeaderComponent icon={build} text="Управление:" />

										<div style={{ display: 'flex' }}>
											<div style={{ flex: 1 }}>
												<AppEnableDisableComponent
													title="Кэширование ссылок:"
													value={app.cache_enabled}
													didChanged={(newValue) => {
														app.cache_enabled = newValue;
														this.setState({
															app: app,
														});
													}}
												/>
											</div>
											<div style={{ flex: 1 }}>
												<AppEnableDisableComponent
													title="Клоака:"
													value={app.is_enabled}
													didChanged={(newValue) => {
														app.is_enabled = newValue;
														this.setState({
															app: app,
														});
													}}
												/>
											</div>
										</div>

										<div style={{ display: 'flex' }}>
											<div style={{ flex: 8 }}>
												<p style={{ fontSize: 12, fontWeight: 700, marginBottom: 2 }}>Страна</p>
											</div>
											<div style={{ flex: 3 }}>
												<p style={{ fontSize: 12, fontWeight: 700, marginBottom: 2 }}>Юзеры</p>
											</div>
											<div style={{ flex: 3 }}>
												<p style={{ fontSize: 12, fontWeight: 700, marginBottom: 2 }}>Боты</p>
											</div>
											<div
												style={{ display: 'flex', width: 100 }}
												onClick={() => {
													if (app.showFullStats) {
														app.showFullStats = false;
													} else {
														app.showFullStats = true;
													}
													this.setState({
														brands: this.state.brands,
													});
												}}>
												<IonIcon icon={app.showFullStats ? chevronUp : chevronDown} color="primary"></IonIcon>
												<p
													style={{
														paddingLeft: 4,
														color: '#3880ff',
														marginBottom: 0,
														paddingRight: 16,
													}}>
													{app.showFullStats ? 'Скрыть' : 'Показать'}
												</p>
											</div>
										</div>
										<AnimateHeight duration={100} height={app.showFullStats ? 'auto' : 60}>
											{app.oneBuildedStats.map((stat: any) => {
												return (
													<div key={'app_' + app.id + '_stats_' + stat.country} style={{ display: 'flex', height: 20 }}>
														<div style={{ flex: 8, display: 'flex', alignItems: 'center' }}>
															<Flag code={stat.country} width={15} height={10} />
															&nbsp;<p style={{ marginBottom: 0, fontSize: 12, maxLines: 0, overflow: 'hidden' }}>{(countries as any)[stat.country]?.title}</p>
														</div>

														<div style={{ flex: 3 }}>{stat.users}</div>
														<div style={{ flex: 3 }}>{stat.bots}</div>
														<div style={{ width: 100 }} />
													</div>
												);
											})}
										</AnimateHeight>
									</div>
								</div>
								<div style={{ width: '100%', height: 0.5, backgroundColor: '#3e3e3e' }}></div>

								<div>
									<BlockHeaderComponent icon={link} text="Ссылки:" />
									<IonItem>
										<IonLabel position="floating">
											Ссылка <span style={{ color: 'yellow' }}>{this.state.linkStartsWithoutSSL ? 'Не рекомендуем ставить ссылку без SSL' : ''}</span>
										</IonLabel>
										<IonInput
											className="small-input"
											value={app.link}
											onIonChange={(e) => {
												app.link = e.detail.value?.trim();
												this.setState({
													linkStartsWithoutSSL: app.link.startsWith('http://'),
												});
											}}></IonInput>
									</IonItem>
									{app.smartlink == null ? (
										<IonItem>
											<IonButtons>
												<IonButton
													fill="outline"
													style={{ '--border-width': 1 }}
													color="success"
													onClick={() => {
														app.smartlink = [
															{
																code: 'XX',
																value: 'http://google.com',
															},
														];
														this.setState({
															app: app,
														});
													}}>
													Включить смартлинк
												</IonButton>
											</IonButtons>
										</IonItem>
									) : (
										<IonList>
											<p>Вместо XX введите код страны с больших букв. Далее на втором поле ссылку которая должна включиться для страны. Если страны нет то включится главная ссылка</p>
											{app.smartlink.map((smart: any, index: number) => {
												return (
													<div style={{ display: 'flex', width: '100%', alignItems: 'center' }} key={'smartlink_' + smart.code + new Date().toString()}>
														<div style={{ width: 40, height: 20, textAlign: 'center' }}>
															<Flag code={smart.code} width={15} height={10} />
														</div>
														<div style={{ flex: 1 }}>
															<IonInput
																className="small-input"
																onIonChange={(e) => {
																	e.detail.value = e.detail.value?.toUpperCase().trim();
																	smart.code = e.detail.value?.toUpperCase().trim();

																	this.setState({
																		app: app,
																	});
																}}
																value={smart.code}></IonInput>
														</div>
														<div style={{ flex: 11 }}>
															<IonInput
																className="small-input"
																onIonChange={(e) => {
																	smart.value = e.detail.value?.trim();
																}}
																value={smart.value as string}></IonInput>
														</div>
														<div style={{ flex: 1, textAlign: 'end' }}>
															<p
																style={{ color: 'red' }}
																onClick={() => {
																	delete app.smartlink[index];
																	this.setState({
																		app: app,
																	});
																}}>
																Удалить
															</p>
														</div>
													</div>
												);
											})}

											<IonItem>
												<IonButtons>
													<IonButton
														fill="outline"
														style={{ '--border-width': 1 }}
														color="success"
														onClick={() => {
															app.smartlink.push({
																code: 'XX',
																value: 'http://google.com',
															});
															this.setState({
																app: app,
															});
														}}>
														Добавить страну +
													</IonButton>
													&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
													<IonButton
														fill="outline"
														style={{ '--border-width': 1 }}
														color="danger"
														onClick={() => {
															app.smartlink = null;
															this.setState({
																app: app,
															});
														}}>
														Удалить все смартлинки
													</IonButton>
												</IonButtons>
											</IonItem>
										</IonList>
									)}
								</div>

								<div style={{ width: '100%', height: 0.5, backgroundColor: '#3e3e3e' }}></div>

								<div>
									<BlockHeaderComponent icon={chatbubble} text="Комментарии:" />
									{app.comments.length == 0 ? <h2 className="ion-padding">Комментариев нет</h2> : false}
									{app.comments.map((comment: any) => {
										return (
											<IonItem key={'app_comment_' + comment.id}>
												<IonLabel className="ion-text-wrap">
													<h5>
														{comment.is_completed ? '✅' : '⏳'} {comment.comment}
													</h5>
													<p
														onClick={() => {
															window.open('/employees/' + comment.user_id, '_blank');
														}}>
														{comment.user_name} [{comment.user_role}] {new Date(comment.created).toLocaleString()}
													</p>
													{comment.is_completed ? <p>Завершен: {new Date(comment.completed_at).toLocaleString()}</p> : false}
												</IonLabel>
												<IonButtons slot="end">
													{comment.is_completed ? (
														false
													) : (
														<IonButton
															onClick={() => {
																API.setAppCommentCompleted(comment.id).then(() => {
																	this.getAppInfo();
																});
															}}>
															Выполнен
														</IonButton>
													)}
												</IonButtons>
											</IonItem>
										);
									})}
									<IonItem>
										<IonLabel position="floating">Добавить комментарий</IonLabel>
										<IonInput
											className="small-input"
											placeholder="Комментарий"
											value={this.state.newComment}
											onIonChange={(e) => {
												this.setState({
													newComment: e.detail.value!,
												});
											}}></IonInput>
										<IonButtons slot="end">
											<IonButton
												onClick={() => {
													API.addAppComment(app.id, this.state.newComment).then(() => {
														this.setState({
															newComment: '',
														});
														this.getAppInfo();
													});
												}}>
												Отправить
											</IonButton>
										</IonButtons>
									</IonItem>
								</div>

								{app.is_in_store ? (
									<p>Прила в сторе</p>
								) : (
									<div>
										<p style={{ color: 'red' }}>Прила забанена {app.banned_at ? new Date(app.banned_at).toLocaleDateString() : 'Дата не известна'}</p>
										<IonButton
											fill="outline"
											style={{ '--border-width': 1 }}
											color="success"
											onClick={async () => {
												await API.appRestoreAppAlive(app.id);
												this.getAppInfo();
											}}>
											Восстановить прилу
										</IonButton>
									</div>
								)}
								<IonButton
									fill="outline"
									style={{ '--border-width': 1 }}
									color="primary"
									onClick={() => {
										if (app.store_id == 2) {
											window.open(app.store_link, '_blank');
										} else {
											if (!app.store_link) {
												return alert('Ссылки нет на прилу');
											}
											window.open(app.store_link, '_blank');
										}
									}}>
									<IonIcon icon={logoAppleAppstore}></IonIcon>
									&nbsp;Открыть в сторе
								</IonButton>
								<IonButton
									fill="outline"
									style={{ '--border-width': 1 }}
									color="danger"
									onClick={() => {
										app.is_in_store = false;
										app.banned_at = new Date();
										this.setState({
											app: app,
										});
										this.updateAppData();
									}}>
									Прила была забанена
								</IonButton>

								<div style={{ width: '100%', height: 0.5, backgroundColor: '#3e3e3e' }}></div>

								<div>
									<BlockHeaderComponent icon={globe} text="Страны:" />
									<IonItem>
										<IonLabel>
											<h2>Страны</h2>
											{app.allowed_country_ids.map((c: any) => {
												return <p key={'app_country_' + c}>{this.getCountryById(c)?.title}</p>;
											})}
										</IonLabel>
									</IonItem>
									<IonItem>
										<IonLabel>Выбрано стран: {this.state.selectedCountries.length}</IonLabel>
										<IonButtons slot="end">
											<IonButton
												onClick={() =>
													this.setState({
														selectCountiesOpened: true,
													})
												}>
												Выбрать страны
											</IonButton>
										</IonButtons>
									</IonItem>

									<br />
									<IonButtons>
										<IonButton
											color="success"
											onClick={() => {
												this.updateAppData();
											}}>
											Сохранить
										</IonButton>
									</IonButtons>

									<div style={{ width: '100%', height: 0.5, backgroundColor: '#3e3e3e' }}></div>

									<div>
										<BlockHeaderComponent icon={personRemove} text="Последние боты:" />

										<IonList>
											{app.lastBots.map((b: any) => {
												return (
													<IonItem key={'last_' + 'app_' + app.id + '_bot' + b.id}>
														<IonLabel>
															<h2>{b.reason}</h2>
															<p>
																{b.ip +
																	' | ' +
																	// @ts-ignore
																	countries[b.country]?.title +
																	' | ' +
																	new Date(b.created).toLocaleString()}
															</p>
														</IonLabel>
													</IonItem>
												);
											})}
										</IonList>
									</div>
								</div>
							</div>
						</div>
					)}
				</IonContent>
			</IonPage>
		);
	}
}
