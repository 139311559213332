import { IonPage, IonHeader, IonToolbar, IonButtons, IonMenuButton, IonTitle, IonContent, IonList, IonItemDivider, IonLabel, IonItem, IonButton } from '@ionic/react';
import React from 'react';

import API from '../../../API';
import LoadingFullScreen from '../../../components/LoadingFullScreen';
import { getAppTrafRoleById } from '../../RegisterPage';

export default class extends React.Component {
	state: any = {
		employees: [],
		employeesNotConfirmed: [],
		employeeBanned: [],
	};

	componentDidMount() {
		this.getEmployees();
	}

	async getEmployees() {
		const res = await API.getEmployees();
		if (res.success) {
			this.setState({
				employees: res.data.employees,
				employeesNotConfirmed: res.data.employeesNotConfirmed,
				employeeBanned: res.data.employeeBanned,
			});
		}
	}

	render() {
		return (
			<IonPage>
				<IonHeader>
					<IonToolbar>
						<IonButtons slot="start">
							<IonMenuButton />
						</IonButtons>
						<IonTitle>Работники</IonTitle>
					</IonToolbar>
				</IonHeader>
				<IonContent>
					{this.state.employees == null ? (
						<LoadingFullScreen />
					) : (
						<IonList>
							<IonItemDivider>
								<IonLabel>Активные</IonLabel>
							</IonItemDivider>

							{this.state.employees.map((e: any) => {
								return (
									<IonItem routerLink={'/employees/' + e.id} key={'apptraf_empl_active_' + e.id}>
										<IonLabel>
											<h2>
												ID: {e.id} | Login: {e.login} | Имя: {e.name}
											</h2>
											<p>Роль: {getAppTrafRoleById(e.role)?.description ?? 'НЕ ОПРЕДЕЛЕН'}</p>
										</IonLabel>

										<IonButtons slot="end">
											<IonButton
												disabled={e.is_banned}
												color="danger"
												onClick={async () => {
													const res = await API.banEmployees(e.id);
													if (res.success) {
														this.getEmployees();
													}
												}}>
												Забанить
											</IonButton>
										</IonButtons>
									</IonItem>
								);
							})}

							<IonItemDivider>
								<IonLabel>Аккаунты на модерации</IonLabel>
							</IonItemDivider>

							{this.state.employeesNotConfirmed.map((e: any) => {
								return (
									<IonItem key={'apptraf_empl_in_review_' + e.id}>
										<IonLabel>
											<h2>
												ID: {e.id} | Login: {e.login} | Имя: {e.name}
											</h2>
											<p>Роль: {getAppTrafRoleById(e.role)?.description ?? 'НЕ ОПРЕДЕЛЕН'}</p>
										</IonLabel>
										<IonButtons slot="end">
											<IonButton
												color="success"
												onClick={async () => {
													const res = await API.approveEmployees(e.id);
													if (res.success) {
														this.getEmployees();
													}
												}}>
												Одобрить
											</IonButton>
											<IonButton
												color="danger"
												onClick={async () => {
													const res = await API.declineEmployees(e.id);
													if (res.success) {
														this.getEmployees();
													}
												}}>
												Удалить
											</IonButton>
										</IonButtons>
									</IonItem>
								);
							})}

							<IonItemDivider>
								<IonLabel>Забанненные</IonLabel>
							</IonItemDivider>

							{this.state.employeeBanned.map((e: any) => {
								return (
									<IonItem key={'apptraf_empl_active_' + e.id}>
										<IonLabel>
											<h2>
												ID: {e.id} | Login: {e.login} | Имя: {e.name}
											</h2>
											<p>Роль: {getAppTrafRoleById(e.role)?.description ?? 'НЕ ОПРЕДЕЛЕН'}</p>
										</IonLabel>

										<IonButtons slot="end">
											<IonButton
												color="success"
												onClick={async () => {
													const res = await API.unbanEmployees(e.id);
													if (res.success) {
														this.getEmployees();
													}
												}}>
												Разбанить
											</IonButton>
										</IonButtons>
									</IonItem>
								);
							})}
						</IonList>
					)}
				</IonContent>
			</IonPage>
		);
	}
}
