const APP_NAME = 'apptraf';

export default class UserService {
	static dropUserData() {
		localStorage.clear();
	}

	static setAccessToken(token: string) {
		localStorage.setItem(APP_NAME + '_token', token);
	}

	static getToken(): string {
		return localStorage.getItem(APP_NAME + '_token') || '';
	}

	static getUserId(): number {
		return parseInt(this.getToken().split('#')[0]);
	}

	static isLogged(): boolean {
		return this.getToken().length != 0;
	}

	static setUser(user: any) {
		localStorage.setItem(APP_NAME + '_user', JSON.stringify(user));
	}

	static getUser(): any {
		return JSON.parse(localStorage.getItem(APP_NAME + '_user') ?? '{}');
	}

	static setLoadingTime(key: string, value: number) {
		return localStorage.setItem(APP_NAME + 'loading_time' + key, value.toString());
	}

	static getLoadingTime(key: string) {
		return parseFloat(localStorage.getItem(APP_NAME + 'loading_time' + key) ?? '1') ?? 1;
	}
}
