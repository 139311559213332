import { IonPage, IonHeader, IonToolbar, IonButtons, IonMenuButton, IonTitle, IonContent, IonList, IonItem, IonLabel, IonButton, IonItemDivider } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { getAllByDisplayValue } from '@testing-library/react';
import { toast } from 'react-toastify';
import { act } from 'react-dom/test-utils';

import LoadingFullScreen from '../../components/LoadingFullScreen';
import API from '../../API';

const ManagerVerificationPage: React.FC = () => {
	const [admins, setAdmins] = useState<Array<any>>([]);

	useEffect(() => {
		getAdmins();
	}, []);

	function getAdmins() {
		API.managerGetEmpplouersList()
			.then((res) => {
				setAdmins(res.data);
			})
			.catch((e) => {
				toast.error(`${e}`);
			});
	}

	function actionEmployer(employer: any, action: 'ban' | 'confirm' | 'unban' | 'changeSalary' | 'changeRemote') {
		API.managerEditAdmin(employer.id, action, employer.salary, employer.allowed_remote_work)
			.then((res) => {
				if (res.success) {
					toast.success('Обновлено');
					getAdmins();
				}
			})
			.catch((e) => {
				toast.error(`${e}`);
			});
	}

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonButtons slot="start">
						<IonMenuButton />
					</IonButtons>
					<IonTitle>Сотрудники</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent>
				<IonList>
					{admins.map((admin) => {
						return (
							<IonItem key={`admin_` + admin.id}>
								<IonLabel>
									<h3>
										{admin.name} | <span>{admin.is_confirmed ? 'Подтвержден' : 'На модерации'}</span>{' '}
									</h3>
									<p>{admin.salary} руб.</p>
								</IonLabel>

								<IonButtons slot="end">
									<IonButton
										color="tertiary"
										onClick={() => {
											const stringSalary = prompt('Введите полную ЗП');
											if (stringSalary == null) {
												return;
											}
											admin.salary = parseInt(stringSalary);
											actionEmployer(admin, 'changeSalary');
										}}>
										Изменить зп
									</IonButton>

									{!admin.is_confirmed && !admin.is_banned ? (
										<>
											<IonButton
												color="success"
												onClick={() => {
													actionEmployer(admin, 'confirm');
												}}>
												Подтвердить
											</IonButton>
											<IonButton
												color="danger"
												onClick={() => {
													actionEmployer(admin, 'ban');
												}}>
												Забанить
											</IonButton>
										</>
									) : null}

									{admin.is_confirmed && !admin.is_banned ? (
										<>
											<IonButton
												color="tertiary"
												onClick={() => {
													admin.allowed_remote_work = !admin.allowed_remote_work;
													actionEmployer(admin, 'changeRemote');
												}}>
												{admin.allowed_remote_work ? 'Выкл удаленку' : 'Вкл удаленку'}
											</IonButton>

											<IonButton
												color="danger"
												onClick={() => {
													actionEmployer(admin, 'ban');
												}}>
												Забанить
											</IonButton>
										</>
									) : null}
									{admin.is_banned ? (
										<>
											<IonButton
												color="success"
												onClick={() => {
													actionEmployer(admin, 'unban');
												}}>
												Разбанить
											</IonButton>
										</>
									) : null}
								</IonButtons>
							</IonItem>
						);
					})}
				</IonList>
			</IonContent>
		</IonPage>
	);
};

export default ManagerVerificationPage;
