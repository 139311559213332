import { IonPage, IonHeader, IonToolbar, IonButtons, IonMenuButton, IonTitle, IonContent, IonTextarea, IonLabel, IonItem, IonButton, IonInput, IonCheckbox } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { toast } from 'react-toastify';

import API from '../../API';

const FinancesImportPage: React.FC = () => {
	const [subids, setSudids] = useState('');
	const [dateFrom, setDateFrom] = useState(new Date().toJSON().split('T')[0]);
	const [dateTo, setDateTo] = useState(new Date().toJSON().split('T')[0]);

	const [results, setResults] = useState<
		Array<{
			sub: string;
			income: number;
			success: boolean;
		}>
	>([]);

	const [showError, setShowError] = useState(true);

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonButtons slot="start">
						<IonMenuButton />
					</IonButtons>
					<IonTitle>Финансы по сабам</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent className="ion-padding">
				<IonItem>
					<IonLabel position="floating">
						<p>Дата от</p>
					</IonLabel>
					<IonInput value={dateFrom} onIonChange={(e) => setDateFrom(e.detail.value ?? '')}></IonInput>
				</IonItem>
				<IonItem>
					<IonLabel position="floating">
						<p>Дата до</p>
					</IonLabel>
					<IonInput value={dateTo} onIonChange={(e) => setDateTo(e.detail.value ?? '')}></IonInput>
				</IonItem>
				<IonItem>
					<IonLabel>Показывать ошибки</IonLabel>
					<IonCheckbox slot="end" checked={showError} onIonChange={(e) => setShowError(e.detail.checked)}></IonCheckbox>
				</IonItem>
				<IonItem>
					<IonLabel position="floating">
						<p>Введите сабы разделенные через запятую</p>
					</IonLabel>
					<IonTextarea
						onIonChange={(e) => {
							setSudids(e.detail.value ?? '');
						}}
						value={subids}
						autoGrow={true}
						placeholder="wp_w47260p785_casumo,wp_w47260p785_cool2,wp_w47260p785_b99andca22"></IonTextarea>
				</IonItem>
				<IonButtons>
					<IonButton
						disabled={subids.length == 0}
						onClick={async () => {
							setResults([]);
							const chunks = subids.split(',').map((e) => e.split('\n').join('').trim());

							for (let index = 0; index < chunks.length; index++) {
								const element = chunks[index];

								try {
									const res = await API.getParseFinanceBySubids([element], dateFrom, dateTo);
									setResults((prev) => [...prev, ...res.data]);
								} catch (e) {
									toast.error('Ошибка при парсинге ' + element);
									setResults((prev) => [
										...prev,
										{
											sub: element,
											income: 0,
											success: false,
										},
									]);
								}
							}
						}}>
						Запустить
					</IonButton>
				</IonButtons>

				<DataTable
					columns={[
						{
							name: 'Саб',
							selector: (row) => row.sub,
						},
						{
							name: 'Приход',
							selector: (row) => row.income,
						},
						{
							name: 'Ошибка',
							selector: (row) => (row.success ? 'Нет' : 'Да'),
						},
					]}
					data={showError ? results : results.filter((r) => r.success)}
				/>
			</IonContent>
		</IonPage>
	);
};

export default FinancesImportPage;
