import { IonPage, IonHeader, IonToolbar, IonButtons, IonMenuButton, IonTitle, IonContent, IonList, IonItem, IonLabel, IonInput } from '@ionic/react';
import React from 'react';

import API from '../../API';
import { countries } from '../../Constants';

export default class extends React.Component {
	state: any = {
		bots: [],
	};

	ip = '';

	componentDidMount() {}

	render() {
		return (
			<IonPage>
				<IonHeader>
					<IonToolbar>
						<IonButtons slot="start">
							<IonMenuButton />
						</IonButtons>
						<IonTitle>Боты</IonTitle>
					</IonToolbar>
				</IonHeader>
				<IonContent>
					<IonItem>
						<IonInput
							placeholder="IP"
							onIonChange={(e) => {
								this.ip = e.detail.value!;
							}}
							clearInput></IonInput>
					</IonItem>
					<IonItem
						button
						onClick={async () => {
							const res = await API.getTesterLastBots(this.ip);
							this.setState({
								bots: res.data,
							});
						}}>
						<IonLabel>Поиск</IonLabel>
					</IonItem>

					<div className="ion-padding">
						{this.state.bots.length == 0 ? (
							<p>Ботов нет</p>
						) : (
							<IonList>
								{this.state.bots.map((b: any) => {
									return (
										<IonItem key={'last_bot_' + b.id}>
											<IonLabel>
												<h2>{b.reason}</h2>
												<p>
													{b.ip +
														' | ' +
														// @ts-ignore
														countries[b.country]?.title +
														' | ' +
														new Date(b.created).toLocaleString()}
												</p>
											</IonLabel>
										</IonItem>
									);
								})}
							</IonList>
						)}
					</div>
				</IonContent>
			</IonPage>
		);
	}
}
