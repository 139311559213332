import { IonPage, IonHeader, IonToolbar, IonButtons, IonMenuButton, IonTitle, IonContent, IonList, IonItem, IonLabel, IonInput, IonButton } from '@ionic/react';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import DataTable from 'react-data-table-component';

import API from '../../API';

const PusherUsersPage: React.FC = () => {
	const [downloadedUsersRequest, setDownloadedUsersRequest] = useState('');
	const [users, setUsers] = useState<Array<any> | null>(null);

	const [link, setLink] = useState('');
	const [status, setStatus] = useState('');

	const [usersExporting, setUsersExporing] = useState(false);

	let selectedUsersIdx: Array<number> = [];

	const [title, setTitle] = useState('');
	const [message, setMessage] = useState('');

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonButtons slot="start">
						<IonMenuButton />
					</IonButtons>
					<IonTitle>Пользователи</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent>
				<IonItem>
					<IonLabel position="stacked">Ссылка, оффер, саб</IonLabel>
					<IonInput
						placeholder="br1803"
						value={link}
						onIonChange={(e) => {
							setLink(e.detail.value || '');
						}}></IonInput>
				</IonItem>

				<IonItem>
					<IonLabel position="stacked">Статус</IonLabel>
					<IonInput
						placeholder="reg,dep"
						value={status}
						onIonChange={(e) => {
							setStatus(e.detail.value || '');
						}}></IonInput>
				</IonItem>

				<IonItem>
					<IonLabel>Поиск</IonLabel>
					<IonButtons slot="end">
						<IonButton
							onClick={() => {
								if (usersExporting) {
									toast.info('Выполняется запрос...');
									return;
								}
								setUsersExporing(true);
								API.pusherGetUsersByLink(link, status)
									.then(({ data }) => {
										setUsers(data);
									})
									.finally(() => {
										setDownloadedUsersRequest(link);
										setUsersExporing(false);
									});
							}}>
							{usersExporting ? 'Загрузка...' : 'Выгрузить'}
						</IonButton>
					</IonButtons>
				</IonItem>

				{users != null ? (
					<>
						<DataTable
							title={downloadedUsersRequest}
							pagination
							selectableRows
							onSelectedRowsChange={(data) => {
								selectedUsersIdx = data.selectedRows.map((row) => row.id);
							}}
							data={users}
							columns={[
								{
									name: 'ID',
									selector: (row) => row.id,
									sortable: true,
								},
								{
									name: 'Bundle id',
									selector: (row) => row.bundle_id,
								},
								{
									name: 'IP',
									selector: (row) => row.ip,
								},
								{
									name: 'Страна',
									selector: (row) => row.country,
								},
								{
									name: 'Таймзона',
									selector: (row) => row.timezone,
								},
								{
									name: 'Онлайн был',
									selector: (row) => new Date(row.last_active).toLocaleString(),
								},
								{
									name: 'Установка',
									selector: (row) => new Date(row.created).toLocaleString(),
								},
								{
									name: 'Статус',
									selector: (row) => row.status,
									sortable: true,
								},
							]}
						/>

						<IonItem>
							<IonLabel position="stacked">Title</IonLabel>
							<IonInput
								value={title}
								onIonChange={(e) => {
									setTitle(e.detail.value || '');
								}}></IonInput>
						</IonItem>

						<IonItem>
							<IonLabel position="stacked">Content</IonLabel>
							<IonInput
								value={message}
								onIonChange={(e) => {
									setMessage(e.detail.value || '');
								}}></IonInput>
						</IonItem>

						<IonItem>
							<IonLabel>ПУШ</IonLabel>
							<IonButtons slot="end">
								<IonButton
									onClick={() => {
										API.pusherSendPush(selectedUsersIdx, title, message)
											.then(({ data }) => {
												toast.success('Отправлено');
											})
											.finally(() => {});
									}}>
									Отправить
								</IonButton>
							</IonButtons>
						</IonItem>
					</>
				) : null}
			</IonContent>
		</IonPage>
	);
};

export default PusherUsersPage;
