import React, { useEffect, useState } from 'react';
import { IonButton, IonButtons, IonIcon, IonItem, IonLabel } from '@ionic/react';
import { add } from 'ionicons/icons';
import DataTable from 'react-data-table-component';

import API from '../../API';

const PusherFolderLinksComponent: React.FC<{ folder_id: number }> = ({ folder_id }) => {
	const [links, setLinks] = useState<Array<any>>([]);

	useEffect(() => {
		API.pusherFolderGetLinks(folder_id).then(({ data }) => {
			setLinks(data);
		});
	}, [folder_id]);

	return (
		<div>
			<IonItem>
				<IonLabel>
					<h2>Всего ссылок: {links.length}</h2>
				</IonLabel>
				<IonButtons slot="end">
					<IonButton
						onClick={() => {
							const promt = prompt('Вставьте ссылку');
							if (promt) {
								API.pusherFolderInsertLink(folder_id, promt).then(({ data }) => {
									setLinks(data);
								});
							}
						}}>
						Добавить ссылку&nbsp;
						<IonIcon icon={add}></IonIcon>
					</IonButton>
				</IonButtons>
			</IonItem>
			<DataTable
				data={links}
				columns={[
					{
						name: 'ID',
						selector: (row) => row.id,
					},
					{
						name: 'Ссылка',
						selector: (row) => row.link,
					},
				]}
			/>
		</div>
	);
};

export default PusherFolderLinksComponent;
