import { IonContent, IonIcon, IonItem, IonLabel, IonList, IonMenuToggle } from '@ionic/react';
import React from 'react';
import { RouteComponentProps, useLocation } from 'react-router-dom';
import {
	analyticsSharp,
	appsSharp,
	calendarOutline,
	colorWandOutline,
	logoAppleAppstore,
	logoGooglePlaystore,
	logoXbox,
	peopleOutline,
	personSharp,
	playSharp,
	statsChartOutline,
	statsChartSharp,
	swapVerticalSharp,
	trendingDown,
	trendingUp,
	trophyOutline,
	walletOutline,
} from 'ionicons/icons';

import './Menu.css';
import StatisticsPage from '../pages/Admin/StatisticsPage';
import AppListPage from '../pages/Admin/Apps/AppListPage';
import UsersPage from '../pages/Admin/UsersPage';
import UploadLinksPage from '../pages/Admin/UploadLinksPage';
import AppsAnalyzer from '../pages/Admin/Analyzer/AppsAnalyzer';
import KeywordAnalyzer from '../pages/Admin/Analyzer/KeywordAnalyzer';
import AppGalleryAppsPage from '../pages/Admin/Analyzer/AppGalleryAppsPage';
import GetEmployees from '../pages/Admin/Empoyeers/GetEmployees';
import WorkTimeTable from '../pages/Admin/WorkTimeTable';
import TrafficMonitoringPage from '../pages/Admin/TrafficMonitoringPage';
import EmployersKPIPage from '../pages/Admin/EmployersKPIPage';
import AdminAllTransactionsPage from '../pages/Admin/Transactions/AdminAllTransactionsPage';
import AdminKPIBonusesByApps from '../pages/Admin/AdminKPIBonusesByApps';
import WeeklyStatistics from '../pages/Admin/WeeklyStatistics';
import BrandsStatisticsPage from '../pages/Admin/BrandsStatisticsPage';
import AccountingPage from '../pages/Admin/AccountingPage';
import FinancesImportPage from '../pages/Admin/FinancesImportPage';
import worktimetableIosApps from '../pages/Admin/WorkTimeTableIosApps';

interface AppPage {
	url: string;
	icon: string;
	title: string;
	component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
}

export const appPages: AppPage[] = [
	{
		title: 'Статка по брендам',
		url: '/brands-statistics',
		icon: trophyOutline,
		component: BrandsStatisticsPage,
	},
	{
		title: 'KPI бонусы',
		url: '/appkpi',
		icon: walletOutline,
		component: AdminKPIBonusesByApps,
	},
	{
		title: 'Недельная статка',
		url: '/weekly',
		icon: calendarOutline,
		component: WeeklyStatistics,
	},
	{
		title: 'Общий трафик',
		url: '/traffic',
		icon: swapVerticalSharp,
		component: TrafficMonitoringPage,
	},
	{
		title: 'Финансы по сабам',
		url: '/pp-finance-parser',
		icon: walletOutline,
		component: FinancesImportPage,
	},
	{
		title: 'Бухгалтерия',
		url: '/accounting',
		icon: trendingDown,
		component: AccountingPage,
	},
	{
		title: 'Приложения',
		url: '/apps',
		icon: appsSharp,
		component: AppListPage,
	},
	{
		title: 'KPI',
		url: '/kpi',
		icon: statsChartSharp,
		component: EmployersKPIPage,
	},
	{
		title: 'Внутренняя бухгалтерия',
		url: '/inner-accounting',
		icon: trendingUp,
		component: AdminAllTransactionsPage,
	},
	{
		title: 'Прилы по ключам',
		url: '/analyzer',
		icon: statsChartOutline,
		component: AppsAnalyzer,
	},
	{
		title: 'Рейтинг ключей',
		url: '/keyword-scores',
		icon: colorWandOutline,
		component: KeywordAnalyzer,
	},
	{
		title: 'Ссылки в стор',
		url: '/store-links',
		icon: logoAppleAppstore,
		component: UploadLinksPage,
	},
	{
		title: 'Статистика',
		url: '/statistics',
		icon: analyticsSharp,
		component: StatisticsPage,
	},
	{
		title: 'Пользователи',
		url: '/users',
		icon: personSharp,
		component: UsersPage,
	},
	{
		title: 'AppGallery',
		url: '/appgallery',
		icon: logoGooglePlaystore,
		component: AppGalleryAppsPage,
	},
	{
		title: 'Работники',
		url: '/employees',
		icon: peopleOutline,
		component: GetEmployees,
	},
	{
		title: 'Табель',
		url: '/worktimetable',
		icon: calendarOutline,
		component: WorkTimeTable,
	},
	{
		title: 'Табель IOS Apps',
		url: '/ios-apps-worktimetable',
		icon: calendarOutline,
		component: worktimetableIosApps,
	},
];

const Menu: React.FC = () => {
	const location = useLocation();

	return (
		<IonContent>
			<IonList id="inbox-list">
				{appPages.map((appPage, index) => {
					return (
						<IonMenuToggle key={index} autoHide={false}>
							<IonItem
								style={{
									'--height': 20,
								}}
								className={location.pathname.startsWith(appPage.url) ? 'selected' : ''}
								routerLink={appPage.url}
								routerDirection="none"
								lines="none"
								detail={false}>
								<IonIcon icon={appPage.icon} size={'4'} />
								<IonLabel style={{ paddingLeft: 8, fontSize: 8 }}>{appPage.title}</IonLabel>
							</IonItem>
						</IonMenuToggle>
					);
				})}
			</IonList>
		</IonContent>
	);
};

export default Menu;
