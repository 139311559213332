import React from 'react';

export default class extends React.Component<{ title: string; date: Date }> {
	render(): React.ReactNode {
		return (
			<p>
				<span style={{ fontSize: 12, fontWeight: 700, marginBottom: 0 }}>{this.props.title}</span>
				&nbsp;
				<span style={{ fontSize: 12, fontWeight: 400 }}>{this.props.date ? new Date(this.props.date).toLocaleString() : 'Отсутствует'}</span>
			</p>
		);
	}
}
