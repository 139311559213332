import React, { useEffect, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { useHistory } from 'react-router';

import API from '../../API';

const PublisherAppComponent: React.FC<{ app: any }> = ({ app }) => {
	const history = useHistory();

	return (
		<div>
			<div style={{ display: 'flex' }}>
				<div style={{ flex: 3 }}>
					<p style={{ fontSize: 11 }}>
						[{app.id}] {app.title}
					</p>
				</div>
				<div style={{ flex: 4 }}>
					<p style={{ fontSize: 11, color: app.is_in_store ? 'green' : 'red' }}>
						{app.bundle_id} | {app.is_in_store ? 'В сторе' : 'Не опубликован'}
					</p>
				</div>
				<div style={{ flex: 2 }}>
					<p style={{ fontSize: 11 }}>
						Клоака:{' '}
						<span
							style={{
								color: app.is_enabled ? 'green' : 'red',
							}}>
							{app.is_enabled ? 'ВКЛ' : 'ВЫКЛ'}
						</span>{' '}
						| На обновлении:{' '}
						<span
							style={{
								color: app.is_on_updating ? 'orange' : 'green',
							}}>
							{app.is_on_updating ? 'ДА' : 'НЕТ'}
						</span>
					</p>
				</div>
				<div style={{ flex: 1 }}>
					<p style={{ fontSize: 11, color: app.farmed_account_id ? 'green' : 'red', fontWeight: 'bold' }}>АККАУНТ</p>
				</div>
				<div style={{ flex: 1 }}>
					<p
						onClick={() => {
							history.push('/publisher/apps/' + app.id);
						}}
						style={{ fontSize: 11 }}>
						Показать инфу
					</p>
				</div>
			</div>
		</div>
	);
};

export default PublisherAppComponent;
