import { IonPage, IonHeader, IonToolbar, IonButtons, IonMenuButton, IonTitle, IonContent, IonList, IonButton, IonItem, IonLabel, IonIcon } from '@ionic/react';
import { refreshOutline } from 'ionicons/icons';
import React from 'react';

import API from '../../API';
import LoadingFullScreen from '../../components/LoadingFullScreen';

export default class extends React.Component {
	state: any = {
		servers: null,
	};

	componentDidMount() {
		this.getServers();
	}

	async getServers() {
		const res = await API.publisherGetProxyServers();
		if (res.success) {
			this.setState({
				servers: res.data,
			});
		}
	}

	render() {
		return (
			<IonPage>
				<IonHeader>
					<IonToolbar>
						<IonButtons slot="start">
							<IonMenuButton />
						</IonButtons>
						<IonTitle>Прокси серверы</IonTitle>
						<IonButtons slot="end">
							<IonButton
								onClick={async () => {
									this.getServers();
								}}>
								<IonIcon icon={refreshOutline} />
							</IonButton>
							<IonButton
								onClick={async () => {
									const ip = window.prompt('IP Адрес');
									if (ip) {
										await API.publisherAddProxyServer(ip);
										this.getServers();
									}
								}}>
								Добавить кастом
							</IonButton>
						</IonButtons>
					</IonToolbar>
				</IonHeader>
				<IonContent>
					{this.state.servers == null ? (
						<LoadingFullScreen />
					) : (
						<div className="ion-padding">
							{this.state.servers.length == 0 ? (
								<p>Нет доступных PROXY серверов</p>
							) : (
								<IonList>
									{this.state.servers.map((server: any) => {
										return (
											<IonItem key={'proxy_server_' + server.id}>
												<IonLabel>
													<h4>
														<span style={{ fontWeight: 700 }}>{server.ip}</span> <span style={{ color: 'grey' }}>[{new Date(server.created).toLocaleString()}]</span>
													</h4>
													{server.connected_domains.split(',').map((domain: string) => {
														return <p key={'proxy_' + server.id + '_d_' + domain}>{domain}</p>;
													})}
												</IonLabel>
												<IonButtons slot="end">
													<IonButton
														onClick={async () => {
															const domain = window.prompt('Название домена');
															if (domain == null) {
																return;
															}
															await API.publisherProxyServerConnectDomain(server.id, domain);
															this.getServers();
														}}>
														Доб. домен
													</IonButton>
													<IonButton
														color="danger"
														onClick={async () => {
															if (window.confirm(`Вы дейстительно хотите удалить сервер ${server.connected_domains}?`)) {
																await API.publisherDeleteProxyServer(server.id);
																this.getServers();
															}
														}}>
														Удалить
													</IonButton>
												</IonButtons>
											</IonItem>
										);
									})}
								</IonList>
							)}
						</div>
					)}
				</IonContent>
			</IonPage>
		);
	}
}
