import { IonIcon } from '@ionic/react';
import { alertCircleOutline } from 'ionicons/icons';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

const TesterAppItemComponent: React.FC<{ app: any }> = ({ app }) => {
	const history = useHistory();

	return (
		<div style={{ display: 'flex' }}>
			<div style={{ flex: 3 }}>
				<p style={{ fontSize: 11 }}>
					[ID: {app.id}] {app.title}
				</p>
			</div>
			<div style={{ flex: 4 }}>
				<p style={{ fontSize: 11, color: app.banned_at == null ? 'green' : 'red' }}>
					{app.bundle_id} | {app.banned_at == null ? 'В сторе' : 'Забанен'}
				</p>
			</div>
			<div style={{ flex: 2 }}>
				<p style={{ fontSize: 11 }}>
					<span
						style={{
							color: app.is_in_store ? 'green' : 'black',
							fontWeight: app.is_in_store ? 800 : undefined,
						}}>
						{app.is_in_store ? 'В сторе' : 'Не опубликован'}
					</span>
				</p>
			</div>
			<div style={{ flex: 2 }}>
				<p style={{ fontSize: 11 }}>
					Клоака:{' '}
					<span
						style={{
							color: app.is_enabled ? 'green' : 'red',
						}}>
						{app.is_enabled ? 'ВКЛ' : 'ВЫКЛ'}
					</span>{' '}
					| На обновлении:{' '}
					<span
						style={{
							color: app.is_on_updating ? 'orange' : 'green',
						}}>
						{app.is_on_updating ? 'ДА' : 'НЕТ'}
					</span>
				</p>
			</div>
			<div style={{ flex: 1 }}>
				<p style={{ fontSize: 11 }}>Установок: {app.install_count}</p>
			</div>
			<div style={{ flex: 1 }}>
				<p style={{ fontSize: 11 }}>
					В топе:{' '}
					<span
						style={{
							color: (app.store_keywords[0]?.last_position || 999) < 10 ? 'green' : 'black',
						}}>
						{(app.store_keywords[0]?.last_position || 999) < 10 ? `ДА (Место ${app.store_keywords[0]?.last_position})` : 'НЕТ'}
					</span>
				</p>
			</div>
			<div
				onClick={() => {
					history.push('/tester/apps/' + app.id);
				}}>
				<IonIcon icon={alertCircleOutline}></IonIcon>
			</div>
		</div>
	);
};

export default TesterAppItemComponent;
