import { IonPage, IonHeader, IonToolbar, IonButtons, IonMenuButton, IonTitle, IonContent, IonButton, IonInput, IonProgressBar } from '@ionic/react';
import React, { RefObject } from 'react';

import API from '../../API';
import LoadingFullScreen from '../../components/LoadingFullScreen';

export default class extends React.Component {
	imagePickerRef: RefObject<HTMLInputElement>;

	state = {
		loading: false,
		projectName: '',
		percent: 0,
	};

	constructor(props: any) {
		super(props);
		this.imagePickerRef = React.createRef();
	}

	async onZipChoosed(e: any) {
		if (e.target.files.length == 0) {
			return;
		}

		this.setState({
			loading: true,
		});

		API.uploadXcode(
			e.target.files[0],
			this.state.projectName,
			(percent) => {
				console.log(percent);
				this.setState({
					percent: percent,
				});
			},
			(res) => {
				console.log(res);
				if (res.success) {
					alert('Проект успешно загружен!');
					window.location.reload();
				} else {
					alert(JSON.stringify(res.error));
				}
			},
		);
	}

	render() {
		return (
			<IonPage>
				<IonHeader>
					<IonToolbar>
						<IonButtons slot="start">
							<IonMenuButton />
						</IonButtons>
						<IonTitle>Сдать проект</IonTitle>
					</IonToolbar>
				</IonHeader>
				<IonContent>
					<input
						ref={this.imagePickerRef}
						style={{ display: 'none' }}
						type="file"
						accept="zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed"
						onChange={this.onZipChoosed.bind(this)}
					/>

					{this.state.loading ? (
						<div className="ion-padding">
							<h2>Загрузка ({Math.round(this.state.percent * 100)}%)</h2>
							<IonProgressBar value={this.state.percent}></IonProgressBar>
						</div>
					) : (
						<div className="ion-padding">
							<p>Нужно заархивировать проект в формате zip и загрузить его.</p>

							<IonInput
								placeholder="Название проекта"
								onIonChange={(e) =>
									this.setState({
										projectName: e.detail!.value,
									})
								}></IonInput>

							<IonButton
								disabled={this.state.projectName.length == 0}
								onClick={() => {
									this.imagePickerRef.current?.click();
								}}>
								Загрузить архив
							</IonButton>
						</div>
					)}
				</IonContent>
			</IonPage>
		);
	}
}
