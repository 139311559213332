import { IonPage, IonHeader, IonToolbar, IonButtons, IonTitle, IonContent, IonButton, IonSearchbar, IonItem, IonLabel, IonBackButton } from '@ionic/react';
import React from 'react';

import API from '../../../API';
import LoadingFullScreen from '../../../components/LoadingFullScreen';

export default class extends React.Component {
	state: any = {
		apps: null,
		searchFilter: '',
	};

	componentDidMount() {
		this.getStatistics();
	}

	async getStatistics() {
		const res = await API.getApps(null, null, null, null, null, null, true);
		if (res.success) {
			this.setState({
				apps: res.data.offers,
				stores: res.data.stores,
				brands: res.data.brands,
				publishers: res.data.publishers,
			});
		}
	}

	render() {
		return (
			<IonPage>
				<IonHeader>
					<IonToolbar>
						<IonButtons slot="start">
							<IonBackButton defaultHref="/apps"></IonBackButton>
						</IonButtons>
						<IonTitle>Архив</IonTitle>
					</IonToolbar>
					<IonToolbar>
						<IonSearchbar placeholder="Поиск..." onIonChange={(e) => this.setState({ searchFilter: e.detail.value! })}></IonSearchbar>
					</IonToolbar>
				</IonHeader>
				<IonContent>
					{this.state.apps == null ? (
						<LoadingFullScreen />
					) : (
						(this.state.searchFilter.length == 0
							? this.state.apps
							: this.state.apps.filter(
									(app: any) =>
										app.title.toLowerCase().includes(this.state.searchFilter.toLowerCase()) ||
										app.bundle_id.toLowerCase().includes(this.state.searchFilter.toLowerCase()) ||
										app.store_link?.toLowerCase().includes(this.state.searchFilter.toLowerCase()) ||
										app.brand_title?.toLowerCase().includes(this.state.searchFilter.toLowerCase()) ||
										app.publisher_name?.toLowerCase().includes(this.state.searchFilter.toLowerCase()),
							  )
						).map((app: any) => (
							<IonItem key={'archive_app_' + app.id}>
								<IonLabel>
									<h2>{app.title}</h2>
									<p>{app.brand_title || 'Без бренда'}</p>
								</IonLabel>
								<IonButtons>
									<IonButton
										onClick={async () => {
											await API.changeArchiveStatus(app.id, false);
											this.getStatistics();
										}}>
										Восстановить из архива
									</IonButton>
									<IonButton routerLink={'/apps/' + app.id}>Информация</IonButton>
								</IonButtons>
							</IonItem>
						))
					)}
				</IonContent>
			</IonPage>
		);
	}
}
