import { IonPage, IonHeader, IonToolbar, IonButtons, IonMenuButton, IonTitle, IonContent, IonList, IonItem, IonLabel, IonInput, IonButton } from '@ionic/react';
import React from 'react';

import API from '../../API';
import { countries } from '../../Constants';

export default class extends React.Component {
	state: any = {
		ips: [],
	};

	ip = '';
	country = '';

	componentDidMount() {
		this.getData();
	}

	async getData() {
		const res = await API.getDeveloperMaskedIps();
		this.setState({
			ips: res.data,
		});
	}

	render() {
		return (
			<IonPage>
				<IonHeader>
					<IonToolbar>
						<IonButtons slot="start">
							<IonMenuButton />
						</IonButtons>
						<IonTitle>Masked IP</IonTitle>
					</IonToolbar>
				</IonHeader>
				<IonContent>
					<IonItem>
						<IonInput
							placeholder="IP"
							onIonChange={(e) => {
								this.ip = e.detail.value!;
							}}
							clearInput></IonInput>
					</IonItem>
					<IonItem>
						<IonInput
							placeholder="Country code"
							onIonChange={(e) => {
								this.country = e.detail.value!;
							}}
							clearInput></IonInput>
					</IonItem>
					<IonItem
						button
						onClick={async () => {
							await API.getDeveloperMaskedIpsAdd(this.ip, this.country);
							this.getData();
						}}>
						<IonLabel>Добавить</IonLabel>
					</IonItem>

					<div className="ion-padding">
						{this.state.ips.length == 0 ? (
							<p>IP нет</p>
						) : (
							<IonList>
								{this.state.ips.map((ip: any) => {
									return (
										<IonItem key={'masked_ip_' + ip.id}>
											<IonLabel>
												<h2>{ip.ip || 'Не указана'}</h2>
												<p>
													{
														// @ts-ignore
														(countries[ip.country]?.title || 'Страна не указана') + ' | ' + new Date(ip.created).toLocaleString()
													}
												</p>
											</IonLabel>
											<IonButtons slot="end">
												<IonButton
													onClick={async () => {
														await API.getDeveloperMaskedIpsRemove(ip.id);
														this.getData();
													}}>
													Удалить
												</IonButton>
											</IonButtons>
										</IonItem>
									);
								})}
							</IonList>
						)}
					</div>
				</IonContent>
			</IonPage>
		);
	}
}
