import { IonContent, IonIcon, IonItem, IonLabel, IonList, IonListHeader, IonMenu, IonMenuToggle, IonNote } from '@ionic/react';
import React from 'react';
import { RouteComponentProps, useLocation } from 'react-router-dom';
import { appsOutline, bagHandle, desktopOutline, eyeOff, gitMerge, lockClosed, logoAndroid, logoAppleAppstore, logoBitcoin, trashOutline } from 'ionicons/icons';

import './Menu.css';
import DeveloperProjectCreatePage from '../pages/Developer/DeveloperProjectCreatePage';
import DeveloperVirtualServers from '../pages/Developer/DeveloperVirtualServers';
import DeveloperHideMetrics from '../pages/Developer/DeveloperHideMetrics';
import DeveloperAndroidProjectsPage from '../pages/Developer/DeveloperAndroidProjectsPage';
import DeveloperCreateMockups from '../pages/Developer/DeveloperCreateMockups';
import DeveloperCaesarGenerator from '../pages/Developer/DeveloperCaesarGenerator';
import DeveloperAndroidActivityGenerator from '../pages/Developer/DeveloperAndroidActivityGenerator';
import DeveloperAppsListPage from '../pages/Developer/DeveloperAppsListPage';
import DeveloperMaskedIPPage from '../pages/Developer/DeveloperMaskedIPPage';

interface AppPage {
	url: string;
	icon: string;
	title: string;
	component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
}

export const appPages: AppPage[] = [
	{
		title: 'Мои прилы',
		url: '/developer/myapps',
		icon: appsOutline,
		component: DeveloperAppsListPage,
	},
	{
		title: 'Сдать проект',
		url: '/developer/project-upload',
		icon: logoAppleAppstore,
		component: DeveloperProjectCreatePage,
	},
	{
		title: 'Виртуальные столы',
		url: '/developer/virtual-servers',
		icon: desktopOutline,
		component: DeveloperVirtualServers,
	},
	{
		title: 'Метрики (Android)',
		url: '/developer/hideMetrics',
		icon: eyeOff,
		component: DeveloperHideMetrics,
	},
	{
		title: 'Проекты Android',
		url: '/developer/android/projects',
		icon: logoAndroid,
		component: DeveloperAndroidProjectsPage,
	},
	{
		title: 'Генератор',
		url: '/developer/generator',
		icon: logoBitcoin,
		component: DeveloperCreateMockups,
	},
	{
		title: 'Цезарь генератор',
		url: '/developer/caesar',
		icon: lockClosed,
		component: DeveloperCaesarGenerator,
	},
	{
		title: 'Генератор мусора Java',
		url: '/developer/trashGenerator',
		icon: trashOutline,
		component: DeveloperAndroidActivityGenerator,
	},
	{
		title: 'Masked IP',
		url: '/developer/maskedip',
		icon: gitMerge,
		component: DeveloperMaskedIPPage,
	},
];

const AppMenu: React.FC = () => {
	const location = useLocation();

	return (
		<IonContent>
			<IonList id="inbox-list">
				<IonListHeader>22Byte</IonListHeader>
				<IonNote>Developer</IonNote>
				{appPages.map((appPage, index) => {
					return (
						<IonMenuToggle key={index} autoHide={false}>
							<IonItem
								style={{
									'--height': 20,
								}}
								className={location.pathname.startsWith(appPage.url) ? 'selected' : ''}
								routerLink={appPage.url}
								routerDirection="none"
								lines="none"
								detail={false}>
								<IonIcon icon={appPage.icon} size={'4'} />
								<IonLabel style={{ paddingLeft: 8, fontSize: 8 }}>{appPage.title}</IonLabel>
							</IonItem>
						</IonMenuToggle>
					);
				})}
			</IonList>
		</IonContent>
	);
};

export default AppMenu;
