import { IonPage, IonHeader, IonToolbar, IonButtons, IonMenuButton, IonTitle, IonContent, IonFab, IonFabButton, IonIcon, IonSpinner, IonList, IonItem, IonLabel } from '@ionic/react';
import { personAdd } from 'ionicons/icons';
import React from 'react';

import API from '../../API';
import LoadingFullScreen from '../../components/LoadingFullScreen';

export default class extends React.Component {
	state: any = {
		creatingAccount: false,
		accounts: null,
	};

	componentDidMount() {
		this.getAccounts();
	}

	async getAccounts() {
		const accounts = await API.farmerGetAccountsList();
		this.setState({
			accounts: accounts.data,
		});
	}

	render() {
		return (
			<IonPage>
				<IonHeader>
					<IonToolbar>
						<IonButtons slot="start">
							<IonMenuButton />
						</IonButtons>
						<IonTitle>Аккаунты</IonTitle>
					</IonToolbar>
				</IonHeader>
				<IonContent>
					<div className="ion-padding">
						{this.state.accounts == null ? (
							<LoadingFullScreen />
						) : this.state.accounts.length == 0 ? (
							<p>Вы не создавали аккаунты</p>
						) : (
							<IonList>
								{this.state.accounts.map((acc: any) => {
									return (
										<IonItem routerLink={'accounts/' + acc.id} key={'farmed_account_' + acc.id}>
											<IonLabel>
												<p style={{ color: acc.store_title ? 'green' : 'red' }}>Стор: {acc.store_title ?? 'Не указан'}</p>
												<p style={{ color: acc.login ? 'green' : 'red' }}>Логин: {acc.login ?? 'Не указан'}</p>
												<p style={{ color: acc.is_farmed ? 'green' : 'red' }}>Статус фарма: {acc.is_farmed ? 'Зафармлен' : 'В процессе'}</p>
												{acc.bundle_id ? <p>Bundle id: {acc.bundle_id}</p> : false}
												<p style={{ fontWeight: 'bold' }}>Создан: {new Date(acc.created).toLocaleString()}</p>
											</IonLabel>
										</IonItem>
									);
								})}
							</IonList>
						)}
					</div>

					<IonFab vertical="bottom" horizontal="end" slot="fixed">
						<IonFabButton
							onClick={async () => {
								if (this.state.creatingAccount) {
									return;
								}
								this.setState({
									creatingAccount: true,
								});

								const account = await API.farmerCreateAccount();
								console.log(account);

								this.setState({
									creatingAccount: false,
								});

								this.getAccounts();
							}}>
							{this.state.creatingAccount ? <IonSpinner></IonSpinner> : <IonIcon icon={personAdd} />}
						</IonFabButton>
					</IonFab>
				</IonContent>
			</IonPage>
		);
	}
}
