import { IonPage, IonHeader, IonToolbar, IonButtons, IonMenuButton, IonTitle, IonContent, IonTextarea } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';

import API from '../../API';
import LoadingFullScreen from '../../components/LoadingFullScreen';
import { getCountryById } from '../../Constants';
import DeveloperMyAppComponent from '../../components/Developer/DeveloperMyAppComponent';

const DeveloperAppsListPage: React.FC = () => {
	const [apps, setApps] = useState<Array<any> | null>(null);
	const [page, setPage] = useState(0);
	const [total, setTotal] = useState(0);
	const [offset, setOffset] = useState(10);

	useEffect(() => {
		API.developerGetApps(page, offset).then(({ data }) => {
			setApps(data.offers);
			setTotal(data.total);
		});
	}, [page, offset]);

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonButtons slot="start">
						<IonMenuButton />
					</IonButtons>
					<IonTitle>Мои прилы</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent>
				{apps == null ? (
					<LoadingFullScreen />
				) : (
					<DataTable
						columns={[
							{
								name: 'ID',
								selector: (row: any) => row.id,
								maxWidth: '50px',
							},
							{
								name: 'Bundle ID',
								selector: (row: any) => row.bundle_id,
							},
							{
								name: 'Название',
								selector: (row: any) => row.title,
							},
							{
								name: 'Клоака',
								selector: (row: any) => (row.is_enabled ? <p style={{ color: 'green' }}>Включена</p> : <p style={{ color: 'red' }}>Выключена</p>),
							},
							{
								name: 'Страны',
								selector: (row: any) => (
									<p style={{ whiteSpace: 'pre-wrap' }}>
										{JSON.parse(row.allowed_country_ids)
											.map((id: number) => {
												return getCountryById(id).title;
											})
											.join(' | ')}
									</p>
								),
							},
						]}
						data={apps}
						fixedHeader
						expandableRows
						expandableRowsComponent={DeveloperMyAppComponent}
						theme="default"
						pagination
						paginationServer
						paginationRowsPerPageOptions={[50, 100, 150, 200, 250, 500]}
						paginationTotalRows={total}
						onChangeRowsPerPage={(newOffset, page) => {
							setOffset(newOffset);
							setPage(page - 1);
						}}
						onChangePage={(_page) => {
							setPage(_page - 1);
						}}
						paginationComponentOptions={{ rowsPerPageText: 'Показывать по' }}
					/>
				)}
			</IonContent>
		</IonPage>
	);
};

export default DeveloperAppsListPage;
