import { IonPage, IonHeader, IonToolbar, IonButtons, IonMenuButton, IonTitle, IonContent, IonList, IonButton, IonItem, IonLabel, IonIcon } from '@ionic/react';
import { refreshOutline } from 'ionicons/icons';
import React from 'react';

import API from '../../API';
import LoadingFullScreen from '../../components/LoadingFullScreen';

export default class extends React.Component {
	state: any = {
		servers: null,
	};

	componentDidMount() {
		this.getServers();
	}

	async getServers() {
		const res = await API.publisherGetVPNServers();
		if (res.success) {
			this.setState({
				servers: res.data,
			});
		}
	}

	render() {
		return (
			<IonPage>
				<IonHeader>
					<IonToolbar>
						<IonButtons slot="start">
							<IonMenuButton />
						</IonButtons>
						<IonTitle>VPN Серверы</IonTitle>
						<IonButtons slot="end">
							<IonButton
								onClick={async () => {
									this.getServers();
								}}>
								<IonIcon icon={refreshOutline} />
							</IonButton>
							<IonButton
								onClick={async () => {
									await API.publisherCreateVPNServer('GB');
								}}>
								NEW VPN | GB
							</IonButton>
						</IonButtons>
					</IonToolbar>
				</IonHeader>
				<IonContent>
					{this.state.servers == null ? (
						<LoadingFullScreen />
					) : (
						<div className="ion-padding">
							{this.state.servers.length == 0 ? (
								<p>Нет доступных VPN серверов</p>
							) : (
								<IonList>
									{this.state.servers.map((server: any) => {
										return (
											<IonItem key={'vpn_server_' + server.id}>
												<IonLabel>
													<h2
														onClick={() => {
															navigator.clipboard.writeText(server.ip);
														}}>
														IP: {server.ip}
													</h2>
													<p>{server.status}</p>
												</IonLabel>
												<IonButtons slot="end">
													<IonButton
														onClick={async () => {
															const res = await API.publisherDeleteVPNServer(server.id);
															if (res.success) {
																this.getServers();
															}
														}}
														color={'danger'}>
														Удалить
													</IonButton>
												</IonButtons>
											</IonItem>
										);
									})}
								</IonList>
							)}
						</div>
					)}
				</IonContent>
			</IonPage>
		);
	}
}
