import { IonApp, IonButton, IonContent, IonInput, IonItem, IonLabel } from '@ionic/react';
import React from 'react';

import API from '../API';
import UserService from '../UserService';

export default class extends React.Component {
	login = '';
	password = '';

	render() {
		return (
			<IonApp>
				<IonContent
					style={{
						textAlign: 'center',
					}}>
					<div style={{ paddingTop: '30vh' }}>
						<h1>AppTraf</h1>
						<p>Admin panel</p>
					</div>

					<div
						style={{
							position: 'absolute',
							bottom: 30,
							left: 30,
							right: 30,
							textAlign: 'center',
						}}>
						<IonItem>
							<IonLabel position="floating">Логин</IonLabel>
							<IonInput onIonChange={(e) => (this.login = e.detail.value!)} placeholder="Логин" type="text"></IonInput>
						</IonItem>
						<IonItem>
							<IonLabel position="floating">Пароль</IonLabel>
							<IonInput onIonChange={(e) => (this.password = e.detail.value!)} placeholder="Пароль" type="password"></IonInput>
						</IonItem>

						<IonButton
							onClick={async () => {
								const res = await API.auth(this.login, this.password);
								if (res.success) {
									UserService.setUser(res.data);
									UserService.setAccessToken(res.data.session.token);
									document.location.href = '/';
								}
							}}>
							Войти
						</IonButton>

						<br />
						<br />
						<IonButton routerLink="/register">Регистрация</IonButton>
					</div>
				</IonContent>
			</IonApp>
		);
	}
}
