import React, { CSSProperties } from 'react';

interface Props {
	items: Array<React.ReactNode>;
	isHeader?: boolean;
	style?: CSSProperties;
}

export default class extends React.Component<Props> {
	render(): React.ReactNode {
		return (
			<>
				<div
					style={{
						width: '100%',
						display: 'flex',
						backgroundColor: 'white',
						position: this.props.isHeader ? 'fixed' : 'relative',
						zIndex: this.props.isHeader ? 1000 : 100,
						...this.props.style,
					}}>
					{this.props.items.map((item, index) => {
						return (
							<div
								style={{
									height: 100,
									border: '1px solid black',
									minWidth: 150,
									textAlign: 'center',
									backgroundColor: 'white',
									flexGrow: 1,
									verticalAlign: 'center',
								}}>
								{item}
							</div>
						);
					})}
				</div>
				{this.props.isHeader ? <div style={{ height: 100 }}></div> : undefined}
			</>
		);
	}
}
