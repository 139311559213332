import {
	IonPage,
	IonHeader,
	IonToolbar,
	IonButtons,
	IonMenuButton,
	IonTitle,
	IonContent,
	IonTextarea,
	IonButton,
	IonItem,
	IonInput,
	IonModal,
	IonList,
	IonSpinner,
	IonItemDivider,
	IonLabel,
	IonImg,
	IonCol,
	IonGrid,
	IonRow,
	IonIcon,
} from '@ionic/react';
import { shareOutline } from 'ionicons/icons';
import React from 'react';

import API from '../../../API';
import TypeSelectorComponent from '../../../components/TypeSelectorComponent';
import { countries } from '../../../Constants';

export default class extends React.Component {
	keywords = '';

	state: any = {
		selectCountiesOpened: false,
		selectedCountries: [],
		table: [],
		isLoading: false,
		apps: [],
	};

	_countries: any = [];

	componentDidMount() {
		for (const [key, value] of Object.entries(countries)) {
			this._countries.push(value);
		}

		API.getApps(null, null, null, null, null, null).then((res) => {
			this.setState({
				apps: res.data.offers,
			});
		});
	}

	getAppList(app: any) {
		let ourApp = this.state.apps.filter((_app: any) => _app.bundle_id == app.bundleId);

		if (ourApp.length == 0) {
			ourApp = null;
		} else {
			console.log(ourApp);

			ourApp = ourApp[0];
		}

		return (
			<IonItem
				button
				onClick={() => {
					if (!ourApp) {
						window.open(app.storeUrl, '_blank');
					}
				}}>
				<IonImg style={{ height: 50, width: 50 }} slot="start" src={app.icon}></IonImg>
				<IonLabel>
					<h2>{app.title}</h2>
					<p>{app.bundleId}</p>
				</IonLabel>

				{ourApp ? (
					<IonButtons>
						<IonButton
							onClick={() => {
								window.open(app.storeUrl, '_blank');
							}}>
							<IonIcon icon={shareOutline} />
						</IonButton>
						<IonButton
							onClick={() => {
								window.open('/apps/' + ourApp.id, '_blank');
							}}>
							Инфо
						</IonButton>
					</IonButtons>
				) : (
					false
				)}
			</IonItem>
		);
	}

	render() {
		return (
			<IonPage>
				<IonModal
					isOpen={this.state.selectCountiesOpened}
					onDidDismiss={() => {
						this.setState({ selectCountiesOpened: false });
					}}>
					<TypeSelectorComponent
						beforeSelected={this.state.selectedCountries}
						items={this._countries}
						didSelected={(items: any) => {
							this.setState({
								selectedCountries: items,
								selectCountiesOpened: false,
							});
						}}
						didCancelled={() => this.setState({ selectCountiesOpened: false })}
					/>
				</IonModal>
				<IonHeader>
					<IonToolbar>
						<IonButtons slot="start">
							<IonMenuButton />
						</IonButtons>
						<IonTitle>Прилы по ключам</IonTitle>
					</IonToolbar>
				</IonHeader>
				<IonContent>
					<div className="ion-padding">
						{this.state.selectedCountries.length == 0 ? (
							<IonButton onClick={() => this.setState({ selectCountiesOpened: true })}>Выбрать страны</IonButton>
						) : (
							<div>
								<p>
									Выбранные страны{' '}
									<span onClick={() => this.setState({ selectCountiesOpened: true })} style={{ color: 'green' }}>
										СМЕНИТЬ
									</span>
									&nbsp;&nbsp;&nbsp;
								</p>

								{this.state.selectedCountries.map((country: any) => (
									<p>{country.title}</p>
								))}
								<p onClick={() => this.setState({ selectedCountries: [] })} style={{ color: 'red' }}>
									Удалить все
								</p>
							</div>
						)}
						<p>Разделите кейворды через запятую ","</p>
						<IonItem>
							<IonInput placeholder="1xbet,vulkan,pin up,joy" onIonChange={(e) => (this.keywords = e.detail.value!)}></IonInput>
						</IonItem>
						<IonButton
							onClick={async () => {
								if (this.state.isLoading) {
									return;
								}
								if (this.state.selectedCountries.length == 0) {
									alert('Выберите страны');
									return;
								}
								if (this.keywords.split(',').length == 0) {
									alert('Введите ключевые слова');
									return;
								}
								this.setState({
									isLoading: true,
								});
								const res = await API.analyzeApps(
									this.keywords.split(',').map((k) => k.trim()),
									this.state.selectedCountries,
								);

								this.setState({
									isLoading: false,
									table: res.data,
								});
								console.log(res);
							}}>
							{this.state.isLoading ? <IonSpinner></IonSpinner> : false}
							&nbsp;Загрузить
						</IonButton>

						<br />
						<br />
						<br />
						<p>Результаты</p>

						<IonList>
							{this.state.table.map((country: any) => {
								return (
									<div>
										<h2>{country.countryTitle}</h2>
										{country.keywords.map((keyword: any) => {
											return (
												<div>
													<IonItemDivider>
														<IonLabel>
															<h2>Ключ: {keyword.keyword}</h2>
														</IonLabel>
													</IonItemDivider>

													<IonGrid>
														<IonRow>
															<IonCol size="6">
																<h2>Android</h2>
																{keyword.android.slice(0, 9).map(this.getAppList.bind(this))}
															</IonCol>
															<IonCol size="6">
																<h2>iOS</h2>
																{keyword.ios.slice(0, 9).map(this.getAppList.bind(this))}
															</IonCol>
														</IonRow>
													</IonGrid>
												</div>
											);
										})}
									</div>
								);
							})}
						</IonList>
					</div>
				</IonContent>
			</IonPage>
		);
	}
}
