import { IonPage, IonHeader, IonToolbar, IonButtons, IonMenuButton, IonTitle, IonContent, IonList, IonButton, IonItem, IonLabel, IonIcon, IonAlert, IonPopover } from '@ionic/react';
import React from 'react';
import { refreshOutline } from 'ionicons/icons';

import API from '../../API';
import LoadingFullScreen from '../../components/LoadingFullScreen';

export default class extends React.Component {
	state: any = {
		servers: null,
		showCreateServerAlert: false,
		showAwsSelectRegion: false,
	};

	componentDidMount() {
		this.getServers();
	}

	async getServers() {
		const res = await API.farmerGetServers();
		if (res.success) {
			this.setState({
				servers: res.data,
			});
		}
	}

	render() {
		return (
			<IonPage>
				<IonAlert
					isOpen={this.state.showCreateServerAlert}
					onDidDismiss={() => {
						this.setState({ showCreateServerAlert: false });
					}}
					header="Создание сервера"
					inputs={[
						{
							name: 'ip',
							type: 'text',
							placeholder: 'IP Address',
						},
						{
							name: 'username',
							type: 'text',
							placeholder: 'Username',
							value: 'Administrator',
						},
						{
							name: 'password',
							type: 'text',
							placeholder: 'Password',
						},
					]}
					buttons={[
						{
							text: 'Отмена',
							role: 'cancel',
							cssClass: 'secondary',
						},
						{
							text: 'Создать сервер',
							handler: async (inputs) => {
								await API.farmerCreateManualServer(inputs.username, inputs.password, inputs.ip);
								this.getServers();
							},
						},
					]}
				/>
				<IonHeader>
					<IonToolbar>
						<IonButtons slot="start">
							<IonMenuButton />
						</IonButtons>
						<IonTitle>Серверы</IonTitle>
						<IonButtons slot="end">
							<IonButton
								onClick={async () => {
									this.getServers();
								}}>
								<IonIcon icon={refreshOutline} />
							</IonButton>
							<IonButton
								onClick={() => {
									this.setState({
										showCreateServerAlert: true,
									});
								}}>
								Создать сервер вручную
							</IonButton>
							<IonButton
								id="create-aws-server"
								onClick={async () => {
									this.setState({
										showAwsSelectRegion: true,
									});
								}}>
								Создать сервер AWS
							</IonButton>
							<IonPopover isOpen={this.state.showAwsSelectRegion} trigger="create-aws-server">
								<IonContent>
									<IonItem
										button={true}
										onClick={async () => {
											this.setState({
												showAwsSelectRegion: false,
											});

											const count = parseInt(window.prompt('Введите количество серверов') || '0');
											if (count) {
												await API.farmerDeployServer(count, 'eu-west-2');
											}
										}}>
										<IonLabel>London</IonLabel>
									</IonItem>
									<IonItem
										button={true}
										onClick={async () => {
											this.setState({
												showAwsSelectRegion: false,
											});
											const count = parseInt(window.prompt('Введите количество серверов') || '0');
											if (count) {
												await API.farmerDeployServer(count, 'eu-north-1');
											}
										}}>
										<IonLabel>Stockholm</IonLabel>
									</IonItem>
								</IonContent>
							</IonPopover>
						</IonButtons>
					</IonToolbar>
				</IonHeader>
				<IonContent>
					{this.state.servers == null ? (
						<LoadingFullScreen />
					) : (
						<div className="ion-padding">
							{this.state.servers.length == 0 ? (
								<p>Вы не запускали серверы</p>
							) : (
								<IonList>
									{this.state.servers.map((server: any) => {
										return (
											<IonItem key={'server_' + server.id}>
												<IonLabel>
													<h2
														onClick={() => {
															navigator.clipboard.writeText(server.ip);
														}}>
														IP: {server.ip}
													</h2>
													<p
														onClick={() => {
															navigator.clipboard.writeText(server.username);
														}}>
														Пользователь: {server.username}
													</p>
													<p
														onClick={() => {
															navigator.clipboard.writeText(server.password);
														}}>
														Пароль: {server.password}
													</p>
													<p>Статус: {server.status}</p>
												</IonLabel>
												<IonButtons slot="end">
													<IonButton color="success" routerLink={'/farmer/servers/' + server.id}>
														Информация
													</IonButton>
													{server.status == 'stopped' || server.status == 'completed' ? (
														<IonButton
															onClick={async () => {
																const res = await API.farmerStartStopServer(server.id, server.status == 'stopped' ? 'start' : 'stop');
																if (res.success) {
																	this.getServers();
																}
															}}
															color={server.status == 'completed' ? 'danger' : 'success'}>
															{server.status == 'completed' ? 'Остановить' : 'Запустить'}
														</IonButton>
													) : (
														false
													)}
												</IonButtons>
											</IonItem>
										);
									})}
								</IonList>
							)}
						</div>
					)}
				</IonContent>
			</IonPage>
		);
	}
}
